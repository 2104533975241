import { useSuspenseQuery } from '@tanstack/react-query';
import { ReactNode, Suspense } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Form,
    IntegerNumberField,
    Panel,
    SaveBar,
    useForm,
} from '@mirakl/roma';

import useLoginContext from '../../../../../config/login/LoginProvider';
import useOperatorAccountContext from '../../../../OperatorAccountContext';
import useTicketingLicenceApi from '../api/useTicketingLicenceApi';

const TicketingLicenceLimitPanel = () => {
    const { formatMessage } = useI18n();
    const { id: operatorAccountId } = useOperatorAccountContext();
    const { fetchLicensedUsersQuery } =
        useTicketingLicenceApi(operatorAccountId);

    const {
        miraklSupport: { isOperatorSupportReadOnly },
    } = useLoginContext();

    const { data: licensedUsers } = useSuspenseQuery(fetchLicensedUsersQuery());

    const validateLimit = (limit: number) => {
        // Valid or negative or above max (1000); in the latter cases the form provides an error message
        if (limit < 0 || limit >= licensedUsers.length) {
            return true;
        }
        return formatMessage({
            id: 'operator.accounts.ticketing.licence.limit.validation.error',
        });
    };

    return (
        <Panel>
            <Panel.Header
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.limit',
                })}
            />
            <Panel.Content>
                <IntegerNumberField
                    disabled={isOperatorSupportReadOnly}
                    label={formatMessage({
                        id: 'operator.accounts.ticketing.licence.limit.label',
                    })}
                    max={1000}
                    name="limit"
                    validate={validateLimit}
                />
            </Panel.Content>
        </Panel>
    );
};

const LoadedTicketingLicenceLimitForm = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { formatMessage } = useI18n();
    const { id: operatorAccountId } = useOperatorAccountContext();
    const { fetchLicenceLimitQuery, updateLicenceLimit } =
        useTicketingLicenceApi(operatorAccountId);

    const { data: licenceLimit } = useSuspenseQuery(fetchLicenceLimitQuery());

    const licenceLimitFormData = useForm({
        defaultValues: { limit: licenceLimit },
    });
    const {
        formState: { isDirty, isSubmitting, isValid },
        handleSubmit,
        reset,
    } = licenceLimitFormData;

    const onSave = (values: { limit: number }) =>
        updateLicenceLimit(values.limit).then(() => {
            reset(values);
        });

    return (
        <Form {...licenceLimitFormData}>
            <TicketingLicenceLimitPanel />
            {children}
            {isDirty && (
                <SaveBar
                    actions={[
                        <Button
                            disabled={!isValid}
                            key="save"
                            label={formatMessage({
                                id: 'button.save',
                            })}
                            state={isSubmitting ? 'loading' : 'default'}
                            onClick={(event) => handleSubmit(onSave)(event)}
                        />,
                        <Button
                            key="cancel"
                            label={formatMessage({
                                id: 'button.cancel',
                            })}
                            variant="secondary"
                            onClick={() => reset()}
                        />,
                    ]}
                />
            )}
        </Form>
    );
};

const LoadingTicketingLicenceLimitForm = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <>
            <Panel loading noSpaceBottom />
            {children}
        </>
    );
};

const TicketingLicenceLimitForm = ({ children }: { children: ReactNode }) => {
    // Passing children to the loading component will allow them to start loading while the form is loading
    return (
        <Suspense
            fallback={
                <LoadingTicketingLicenceLimitForm>
                    {children}
                </LoadingTicketingLicenceLimitForm>
            }
        >
            <LoadedTicketingLicenceLimitForm>
                {children}
            </LoadedTicketingLicenceLimitForm>
        </Suspense>
    );
};

export default TicketingLicenceLimitForm;
