import { Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import { WarehouseCreatePage } from './WarehouseCreatePage';
import { WarehouseEditPage } from './WarehouseEditPage';
import { WarehouseListPage } from './list/WarehouseListPage';

const WarehousePages = () => {
    return (
        <Routes>
            <Route element={<WarehouseCreatePage />} path="/create" />
            <Route element={<WarehouseEditPage />} path="/:warehouseUuid" />
            <Route element={<WarehouseListPage />} index />
        </Routes>
    );
};

export default WarehousePages;
