import { Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';
import { useI18n } from '@mirakl/i18n';
import { useErrorHandler } from '@mirakl/roma';

import { useFeatureToggle } from '../../common/toggle/FeatureToggleProvider';

import SupportOperatorSmtpDetailsPage from './details/SupportOperatorSmtpDetailsPage';
import SupportOperatorSmtpListingPage from './listing/SupportOperatorSmtpListingPage';

const SupportOperatorSmtpPages = () => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { isFeatureEnable } = useFeatureToggle();

    if (!isFeatureEnable('smtp')) {
        handleError(new Error(formatMessage({ id: 'error.404.title' })));
    }

    return (
        <Routes>
            <Route element={<SupportOperatorSmtpListingPage />} index />
            <Route
                element={<SupportOperatorSmtpDetailsPage />}
                path="/:smtpId"
            />
        </Routes>
    );
};

export default SupportOperatorSmtpPages;
