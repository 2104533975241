import { useI18n } from '@mirakl/i18n';
import {
    LayoutContainer,
    LayoutItem,
    PageLayout,
    PageTitle,
} from '@mirakl/roma';

import OperatorUserGlobalLayout from '../../../common/layout/OperatorUserGlobalLayout';

import CompanyInformationPanel from './CompanyInformationPanel';

const OperatorCompanyInformationPage = () => {
    const { formatMessage } = useI18n();

    return (
        <OperatorUserGlobalLayout>
            <PageTitle
                title={formatMessage({
                    id: 'operator.accounts.detail.company.information',
                })}
            />
            <PageLayout size="md">
                <LayoutContainer>
                    <LayoutItem>
                        <CompanyInformationPanel />
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </OperatorUserGlobalLayout>
    );
};

export default OperatorCompanyInformationPage;
