import { AxiosError } from 'axios';

import { useI18n } from '@mirakl/i18n';
import { useSnackbar } from '@mirakl/roma';

import { getErrorMessage, isDebugMode } from './errorUtils';

const shouldDisplayRealErrorMessage = (
    error: Error,
    keepRealMessageForStatus: number[]
) =>
    isDebugMode() ||
    (error instanceof AxiosError &&
        error.response &&
        keepRealMessageForStatus.includes(error.response.status));

const useErrorToast = () => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();

    /**
     * Shows an error toast with a more specific error message in local and test environments only,
     * and a generic error message in production. This can be handy to help mirakl workers understand
     * errors faster.
     * @param error the error that occurred
     * @param keepRealMessageForStatus the status for which to display the real error message in production instead of the generic one
     */
    const showErrorToast = (
        error: Error,
        keepRealMessageForStatus: number[] = []
    ) => {
        const errorMessage = shouldDisplayRealErrorMessage(
            error,
            keepRealMessageForStatus
        )
            ? getErrorMessage(error, formatMessage)
            : formatMessage({
                  id: 'snackbar.edit.fail',
              });

        addSnackbar({
            message: errorMessage,
            status: 'error',
        });
    };

    return showErrorToast;
};

export default useErrorToast;
