import { useI18n } from '@mirakl/i18n';
import { ConfirmModal } from '@mirakl/roma';

type OrganizationMergeModalType = {
    mainOrganization: string;
    mergeCallback: (
        mainOrganization: string,
        organizations: string[]
    ) => Promise<void>;
    organizations: string[];
};

function OrganizationMergeModal({
    mainOrganization,
    mergeCallback,
    organizations,
}: OrganizationMergeModalType) {
    const { formatMessage } = useI18n();

    return (
        <ConfirmModal
            confirmButton={{
                destructive: true,
                label: formatMessage({
                    id: 'organization.merge.modal.button.confirm.label',
                }),
                onClick: (close: () => void) => {
                    return mergeCallback(mainOrganization, organizations).then(
                        close
                    );
                },
            }}
            content={formatMessage({
                id: 'organization.merge.modal.description',
            })}
            title={formatMessage({ id: 'organization.merge.page.title' })}
        />
    );
}

export default OrganizationMergeModal;
