import { useCallback } from 'react';

import { useI18n } from '@mirakl/i18n';
import { createQueryParamsHelper, useSnackbar } from '@mirakl/roma';

import useAuthenticatedFetch from '../fetch/useAuthenticatedFetch';

export type OperatorAccountTypeEnum = 'CUSTOMER' | 'PARTNER';

type OperatorAccountPayload = {
    dateCreated: string;
    id: string;
    isClientAccount: boolean;
    name: string;
    products: string[];
};

type OperatorAccountsPayload = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: OperatorAccountPayload[];
};

type LogoConfigPayload = {
    allowedContentTypes: string[];
    maxHeightPx: number;
    maxSizeBytes: number;
    maxWidthPx: number;
};

type CompanyInformationPayload = {
    externalId: string;
    isClientAccount: boolean;
    name: string;
};

type CompanyInformationPutPayload = {
    id: string;
    logo?: File[];
    name: string;
    shouldUpdateLogo?: boolean;
};

type ExportFiltersType = {
    clientAccounts?: boolean;
    operatorName?: string;
    products?: string[];
};

export const operatorAccountListingQueryParamsHelper = createQueryParamsHelper<{
    products: {
        type: 'multipleOptions';
        value: string;
    };
    types: {
        type: 'multipleOptions';
        value: OperatorAccountTypeEnum;
    };
}>({
    products: 'multipleOptions',
    types: 'multipleOptions',
})
    .withSeekPagination()
    .withSort('sort')
    .withSearch('operatorName');

type OperatorAccountQueryParams = Parameters<
    Parameters<
        (typeof operatorAccountListingQueryParamsHelper)['useQueryParamsChange']
    >[0]
>[0];

export const useOperatorAccountClientApi = () => {
    const { apiGet, apiPost } = useAuthenticatedFetch();
    const { addSnackbar } = useSnackbar();
    const { formatMessage } = useI18n();

    const getOperatorAccounts: (
        params: OperatorAccountQueryParams
    ) => Promise<OperatorAccountsPayload> = useCallback(
        async (params) => {
            const mapClientAccountsFilter = (
                types?: OperatorAccountTypeEnum[]
            ) => {
                if (types && types.length === 1) {
                    return types[0] === 'CUSTOMER';
                }
                return null;
            };
            return apiGet<OperatorAccountsPayload>('/private/operators', {
                params: {
                    ...params,
                    clientAccounts: mapClientAccountsFilter(params.types),
                },
            }).then(({ data }) => data);
        },
        [apiGet]
    );

    const exportOperatorAccounts: (params: ExportFiltersType) => Promise<void> =
        useCallback(
            (params) => {
                // We can only export in CSV, we can ignore the requested export type
                return apiGet<BlobPart>('/private/operators/export', {
                    params,
                    responseType: 'blob',
                }).then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'text/csv',
                    });
                    const linkElement = document.createElement('a');
                    linkElement.download = decodeURI(
                        response.headers['content-disposition'].match(
                            /filename="(.+)"/
                        )[1]
                    );
                    const tmpUrl = window.URL.createObjectURL(blob);
                    linkElement.href = tmpUrl;
                    document.body.appendChild(linkElement);
                    linkElement.click();

                    document.body.removeChild(linkElement);
                    window.URL.revokeObjectURL(tmpUrl);
                });
            },
            [apiGet]
        );

    const getOperatorAccountDetails: (
        id: string
    ) => Promise<CompanyInformationPayload> = useCallback(
        async (id) =>
            apiGet<CompanyInformationPayload>(
                `/private/operators/${id}/company`
            ).then(({ data }) => data),
        [apiGet]
    );

    const getOperatorAccountLogo = useCallback(
        async (id: string) =>
            apiGet<BlobPart>(`/private/operators/${id}/company/logo`, {
                responseType: 'blob',
            }).then((payload) => {
                if (!payload.status || payload.status === 204) {
                    return undefined;
                }
                return {
                    data: payload.data,
                    filename: decodeURI(
                        payload.headers['content-disposition']
                            .split('filename=')[1]
                            .replace(/"/g, '') // Remove quotes
                    ),
                    contentType: payload.headers['content-type'],
                };
            }),
        [apiGet]
    );

    const getOperatorAccountLogoConfig = useCallback(
        () =>
            apiGet<LogoConfigPayload>('/private/operators/logo/config').then(
                ({ data }) => data
            ),
        [apiGet]
    );

    const updateCompanyInformation: (
        id: string,
        params: CompanyInformationPutPayload,
        shouldUpdateLogo?: boolean
    ) => Promise<void> = useCallback(
        async (id, params, shouldUpdateLogo) => {
            const headers = { 'Content-Type': 'multipart/form-data' };

            const data = new FormData();

            const file = params.logo;

            if (shouldUpdateLogo && file && file[0]) {
                data.append('logo', file[0]);
            }

            params.shouldUpdateLogo = shouldUpdateLogo;
            data.append(
                'companyInformation',
                new Blob([JSON.stringify(params)], {
                    type: 'application/json',
                })
            );

            return apiPost(`/private/operators/${id}/company`, data, {
                headers,
            }).then(() =>
                addSnackbar({
                    status: 'success',
                    message: formatMessage({ id: 'snackbar.edit.success' }),
                })
            );
        },
        [addSnackbar, apiPost, formatMessage]
    );

    const updateUserRole = useCallback(
        (accountId: string, userId: string, asAdmin: boolean) => {
            const data = {
                admin: asAdmin,
            };
            return apiPost(
                `/private/operators/${accountId}/company/users/${userId}`,
                data
            );
        },
        [apiPost]
    );

    return {
        getOperatorAccounts,
        exportOperatorAccounts,
        getOperatorAccountLogo,
        getOperatorAccountDetails,
        getOperatorAccountLogoConfig,
        updateCompanyInformation,
        updateUserRole,
    };
};
