import { AddressType } from '../../../common/address/form/AddressField';

export const FORM_UUID = 'uuid';
export const FORM_ORGANIZATION_NAME = 'name';
export const FORM_BUSINESS_REGISTRATION_NUMBER = 'businessRegistrationNumber';
export const FORM_TAX_ID_NUMBER = 'taxId';

export type OrganizationFormType = AddressType & {
    [FORM_BUSINESS_REGISTRATION_NUMBER]: string;
    [FORM_ORGANIZATION_NAME]: string;
    [FORM_TAX_ID_NUMBER]: string;
};
