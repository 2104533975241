import { useI18n } from '@mirakl/i18n';
import {
    AsyncMultipleSelectionListFilter,
    CompletableOptionsType,
} from '@mirakl/roma';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

const FILTER_LIMIT = 25;

type AdminEmailSuggestionPayload = {
    admins: { email: string; uuid: string }[];
};

function OrganizationAdminAsyncFilter() {
    const { formatMessage } = useI18n();
    const { apiGet } = useAuthenticatedFetch();

    const onSearchChange = (
        email: string,
        offset = 0
    ): Promise<CompletableOptionsType<string>> => {
        return apiGet<AdminEmailSuggestionPayload>(
            '/private/organizations/administrators',
            {
                params: { email, offset, limit: FILTER_LIMIT },
            }
        )
            .then((payload) => payload.data)
            .then((data) => ({
                loadMore:
                    data.admins.length === FILTER_LIMIT
                        ? () => onSearchChange(email, offset + FILTER_LIMIT)
                        : undefined,
                options: data.admins.map((admin) => ({
                    id: admin.uuid,
                    label: admin.email,
                    value: admin.uuid,
                })),
            }));
    };

    return (
        <AsyncMultipleSelectionListFilter
            a11ySearchLabel={formatMessage({
                id: 'datatable.organizations.filter.administrators.a11y.label',
            })}
            id="organization-name-filter"
            optionFilterPlaceholder={formatMessage({
                id: 'datatable.filter.placeholder',
            })}
            onSearchChange={onSearchChange}
        />
    );
}

export default OrganizationAdminAsyncFilter;
