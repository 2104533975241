import SupportOperatorAccountDetailsLayout from '../../detail/support/SupportOperatorAccountDetailsLayout';

import OperatorAccountUserListingPanel from './OperatorAccountUserListingPanel';

const SupportOperatorAccountUserListingPage = () => {
    return (
        <SupportOperatorAccountDetailsLayout size="md">
            <OperatorAccountUserListingPanel />
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportOperatorAccountUserListingPage;
