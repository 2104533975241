import axios from 'axios';

type ProfileType = {
    firstName: string;
    language: string;
    lastName: string;
    password: string;
    timezone: string;
};

export const createProfile = (
    token: string,
    profile: ProfileType
): Promise<string> =>
    axios
        .post('/private/invitation/create-profile', {
            token: token,
            ...profile,
        })
        .then(({ data }) => data.location);

type InvitationData = {
    email: string;
    language: string;
    productName: string;
};

export const fetchInvitation = (token: string) =>
    axios
        .get<InvitationData>(`/private/invitation/${token}`)
        .then(({ data }) => data);
