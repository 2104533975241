import { useEffect } from 'react';

import { useErrorHandler } from '@mirakl/roma';

import { useInternalSignup } from './useInternalSignup';

/**
 * This signup page is meant to be used for a user that want to access mirakl from the inside, i.e from another mirakl product (for example
 * through the app switcher):
 * - If the user is already logged in mirakl eco-system, submit the invitation and directly redirect to the caller
 * - If the user is not logged in display an error page. This case should not happen since user is supposed to be logged
 *   in through another mirakl product
 */
const SignupInternalPage = () => {
    const { isReady, onSubmit, user } = useInternalSignup();
    const handleError = useErrorHandler();

    useEffect(() => {
        if (isReady && user.isLoggedIn) {
            onSubmit().catch(handleError);
        } else if (isReady && !user.isLoggedIn) {
            handleError(
                new Error(
                    'User is not authenticated. If this is not a manual test, please consider using external signup instead of internal signup for your case.'
                )
            );
        }
    }, [handleError, isReady, onSubmit, user.isLoggedIn]);

    return null;
};

export default SignupInternalPage;
