import { Box, Paragraph, Scrollable, TextCellContent } from '@mirakl/roma';

function OrganizationAdminsCellContent({
    administrators,
}: {
    administrators: string[];
}) {
    return (
        <TextCellContent
            renderExpandableContent={
                administrators.length > 0
                    ? () => (
                          <Scrollable hideScrollbars maxHeight="400px">
                              <Box>
                                  {administrators.map((email, index) => (
                                      <Paragraph
                                          key={email}
                                          noSpaceBottom={
                                              index ===
                                              administrators.length - 1
                                          }
                                      >
                                          {email}
                                      </Paragraph>
                                  ))}
                              </Box>
                          </Scrollable>
                      )
                    : undefined
            }
            text={`${administrators.length}`}
        />
    );
}

export default OrganizationAdminsCellContent;
