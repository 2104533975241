import { Navigate } from 'react-router-dom-v5-compat';

import useLoginContext from './config/login/LoginProvider';
import useOperatorAccountsUrls from './operators/useOperatorAccountsUrls';

const LandingPage = () => {
    const { miraklSupport } = useLoginContext();
    const { defaultListingUrl: operatorAccountsUrl } =
        useOperatorAccountsUrls();

    if (miraklSupport.isOperatorSupportUser) {
        return <Navigate replace to={operatorAccountsUrl} />;
    } else if (miraklSupport.isSellerSupportUser) {
        return <Navigate replace to="/organizations" />;
    }
    return <Navigate replace to="/profile/information" />;
};

export default LandingPage;
