import { useI18n } from '@mirakl/i18n';
import { MultipleSelectionListFilter } from '@mirakl/roma';

function TypeFilter() {
    const { formatMessage } = useI18n();

    const options = [
        {
            id: 'customer',
            label: formatMessage({
                id: 'operator.accounts.listing.datatable.type.customer',
            }),
            value: 'CUSTOMER',
        },
        {
            id: 'partner',
            label: formatMessage({
                id: 'operator.accounts.listing.datatable.type.partner',
            }),
            value: 'PARTNER',
        },
    ];

    return (
        <MultipleSelectionListFilter id="product-filter" options={options} />
    );
}

export default TypeFilter;
