import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { queryParamsUrlBuilder } from '@mirakl/roma';

import { basePath } from '../Root';

import { OperatorAccountTypeEnum } from './operatorAccountClientApi';

const useOperatorAccountsUrls = () => {
    const { formatMessage } = useI18n();

    const defaultListingUrl = useMemo(
        () =>
            `${basePath}/operators?${queryParamsUrlBuilder()
                .addParam('types', 'multipleOptions', [
                    {
                        value: 'CUSTOMER' satisfies OperatorAccountTypeEnum,
                        displayValue: formatMessage({
                            id: 'operator.accounts.listing.datatable.type.customer',
                        }),
                    },
                ])
                .build()}`,
        [formatMessage]
    );

    return {
        defaultListingUrl,
    };
};

export default useOperatorAccountsUrls;
