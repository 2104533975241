import { ReactNode } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    ActionMenu,
    Button,
    LayoutContainer,
    LayoutItem,
    MenuButton,
    PageLayout,
    PageNavigationItemType,
    PageTitle,
    useModal,
    useNavigate,
} from '@mirakl/roma';

import AccountGlobalLayout from '../../common/layout/AccountGlobalLayout';
import { useFeatureToggle } from '../../common/toggle/FeatureToggleProvider';
import useLoginContext from '../../config/login/LoginProvider';
import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';
import {
    BackLinkType,
    PageLayoutSize,
    PageTitleActionsType,
} from '../../types/romaTypes';

import useOrganizationContext from './OrganizationContext';
import OrganizationDeleteModal from './shared/OrganizationDeleteModal';
import OrganizationShopUnlinkModal from './shared/OrganizationShopUnlinkModal';
import useOrganizationShopUnlink from './shared/useOrganizationShopUnlink';

const DELETE_ORGANIZATION_ACTION_ID = 'delete-organization-action';

function OrganizationRunPage({
    children,
    customActions,
    pageTitle,
    refreshPage,
    size = 'md',
}: {
    children: ReactNode | ReactNode[];
    customActions?: PageTitleActionsType;
    pageTitle: string;
    refreshPage?: () => void;
    size: PageLayoutSize;
}) {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();

    const { miraklSupport } = useLoginContext();
    const {
        name: organizationName,
        refresh: refreshOrganizationContext,
        shopsCount,
        uuid: organizationUuid,
    } = useOrganizationContext();

    const { fetchShopsCallback, unlinkShopCallback } =
        useOrganizationShopUnlink();

    const { showModal } = useModal();
    const { apiDelete } = useAuthenticatedFetch();

    const { isFeatureEnable } = useFeatureToggle();
    const sellerMonetizationEnable = isFeatureEnable('seller-monetization');
    const warehouseEnable = isFeatureEnable('warehouse');

    const backLink: BackLinkType = miraklSupport.isSellerSupportUser
        ? {
              label: formatMessage({
                  id: 'organizations.support.link.back',
              }),
              link: {
                  to: '/organizations',
              },
          }
        : undefined;

    const navigationItems: PageNavigationItemType[] | undefined =
        miraklSupport.isSellerSupportUser
            ? [
                  {
                      id: 'general-information-tab',
                      label: formatMessage({ id: 'general.information' }),
                      to: `/organizations/${organizationUuid}`,
                  },
                  {
                      id: 'store-account-tab',
                      label: formatMessage({ id: 'shops' }),
                      to: `/organizations/${organizationUuid}/shops`,
                  },
              ]
            : undefined;

    if (miraklSupport.isSellerSupportUser && warehouseEnable) {
        navigationItems?.push({
            id: 'warehouse-tab',
            label: formatMessage({
                id: 'organizations.warehouses.navigation.title',
            }),
            to: `/organizations/${organizationUuid}/warehouses`,
        });
    }

    if (miraklSupport.isSellerSupportUser) {
        navigationItems?.push({
            id: 'user-tab',
            label: formatMessage({ id: 'users' }),
            to: `/organizations/${organizationUuid}/users`,
        });
    }

    if (miraklSupport.isSellerSupportUser && sellerMonetizationEnable) {
        navigationItems?.push({
            id: 'subscription-tab',
            label: formatMessage({
                id: 'organizations.subscription.navigation.title',
            }),
            to: `/organizations/${organizationUuid}/subscription`,
        });
    }

    const unlinkAndRefresh = (orgUuid: string, shopUuids: string[]) => {
        return unlinkShopCallback(orgUuid, shopUuids).then(() => {
            refreshOrganizationContext();
            if (refreshPage) {
                refreshPage();
            }
        });
    };

    const deleteOrganizationCallback = (uuid: string): Promise<void> => {
        return apiDelete(`/private/organizations/${uuid}`, null).then(() => {
            navigate({ to: '/organizations' });
            return;
        });
    };

    const isUnlinkButtonDisabled = shopsCount === 0;

    const supportActions: PageTitleActionsType = [
        <Button
            disabled={isUnlinkButtonDisabled}
            key="unlink-action"
            label={formatMessage({ id: 'organizations.actions.unlink.label' })}
            tooltipText={
                isUnlinkButtonDisabled
                    ? formatMessage({
                          id: 'organizations.actions.unlink.tooltip',
                      })
                    : undefined
            }
            variant="secondary"
            onClick={() =>
                showModal(
                    <OrganizationShopUnlinkModal
                        getShopsCallback={fetchShopsCallback}
                        organizationUuid={organizationUuid}
                        unlinkShopCallback={unlinkAndRefresh}
                    />
                )
            }
        />,
        <ActionMenu
            id="organization-more-button"
            items={[
                {
                    id: DELETE_ORGANIZATION_ACTION_ID,
                    label: formatMessage({ id: 'button.delete' }),
                },
            ]}
            key="action-menu"
            trigger={
                <MenuButton
                    label={formatMessage({ id: 'button.more' })}
                    variant="secondary"
                    onClick={() => {}}
                />
            }
            onItemClick={() =>
                showModal(
                    <OrganizationDeleteModal
                        deleteOrganizationCallback={deleteOrganizationCallback}
                        organizationName={organizationName}
                        organizationUuid={organizationUuid}
                    />
                )
            }
        />,
    ];

    return (
        <AccountGlobalLayout>
            <PageTitle
                actions={[
                    miraklSupport.isSellerSupportReadWrite && supportActions,
                    customActions,
                ]}
                backLink={backLink}
                navigationItems={navigationItems}
                title={
                    miraklSupport.isSellerSupportUser
                        ? organizationName
                        : pageTitle
                }
            />
            <PageLayout size={size}>
                <LayoutContainer>
                    <LayoutItem>{children}</LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </AccountGlobalLayout>
    );
}

export default OrganizationRunPage;
