import { useI18n } from '@mirakl/i18n';
import { Button } from '@mirakl/roma';

const _ProfileInfoEditButton = ({ onClick }: { onClick: () => void }) => {
    const { formatMessage } = useI18n();
    return (
        <Button
            label={formatMessage({ id: 'button.edit' })}
            size="small"
            variant="secondary"
            onClick={onClick}
        />
    );
};

export default _ProfileInfoEditButton;
