import {
    GetQueryParamsValueFromHelperType,
    createQueryParamsHelper,
} from '@mirakl/roma';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import { StageValues } from '../../../types/stageTypes';
import useOrganizationContext from '../OrganizationContext';

type UserShopsAssociations = {
    id: string;
    isAdmin: boolean;
    profile: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    shopsByTenant: {
        shops: string[];
        stage: StageValues;
        tenantDisplayName: string;
    }[];
};

export type OrganizationUsersPayload = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: UserShopsAssociations[];
    nextPageToken?: string;
    previousPageToken?: string;
};

export const organizationUsersQueryParamsHelper = createQueryParamsHelper<{
    userType: {
        type: 'singleOption';
        value: string;
    };
}>({
    userType: 'singleOption',
})
    .withSeekPagination()
    .withSearch('email')
    .withSort('sort');

type OrganizationUsersQueryParams = GetQueryParamsValueFromHelperType<
    typeof organizationUsersQueryParamsHelper
>;

export const useOrganizationUsersApiClient = () => {
    const { apiGet } = useAuthenticatedFetch();
    const { uuid: organizationUuid } = useOrganizationContext();

    return (
        queryParams: OrganizationUsersQueryParams
    ): Promise<OrganizationUsersPayload> =>
        apiGet<OrganizationUsersPayload>(
            `/private/organizations/${organizationUuid}/users`,
            {
                params: queryParams,
            }
        ).then((payload) => payload.data);
};

export const useGrantAdministratorApiClient = () => {
    const { apiPost } = useAuthenticatedFetch();
    return (organizationUuid: string, userUuid: string) =>
        apiPost(
            `/private/organizations/${organizationUuid}/users/${userUuid}/roles/ADMINISTRATOR`,
            null
        );
};

export const useRemoveAdministratorApiClient = () => {
    const { apiDelete } = useAuthenticatedFetch();
    return (organizationUuid: string, userUuid: string) =>
        apiDelete(
            `/private/organizations/${organizationUuid}/users/${userUuid}/roles/ADMINISTRATOR`,
            null
        );
};
