import { useI18n } from '@mirakl/i18n';
import { LayoutContainer, Page, PageLayout, PageTitle } from '@mirakl/roma';

import ProfileInfoPanel from './components/ProfileInfoPanel';

const ProfileInfoPage = () => {
    const { formatMessage } = useI18n();

    return (
        <Page>
            <PageTitle
                title={formatMessage({ id: 'user.profile.information' })}
            />
            <PageLayout size="sm">
                <LayoutContainer>
                    <ProfileInfoPanel />
                </LayoutContainer>
            </PageLayout>
        </Page>
    );
};

export default ProfileInfoPage;
