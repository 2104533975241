import { Flex, Text } from '@mirakl/roma';

import { useCompact500FormatMessage } from '../../i18n/useCompact500FormatMessage';

type SignupOrganizationCardProps = {
    businessRegistrationNumber: string;
    inlinedAddress: string;
    taxIdNumber: string;
};
const SignupOrganizationCardText = ({
    businessRegistrationNumber,
    inlinedAddress,
    taxIdNumber,
}: SignupOrganizationCardProps) => {
    const { formatBMessage } = useCompact500FormatMessage();

    return (
        <Flex flexDirection="column">
            <Text>
                {formatBMessage(
                    {
                        id: 'organization.merge.page.form.organization.radio.helptext.brn',
                    },
                    {
                        0: businessRegistrationNumber,
                    }
                )}
            </Text>
            <Text>
                {formatBMessage(
                    {
                        id: 'organization.merge.page.form.organization.radio.helptext.taxid',
                    },
                    {
                        0: taxIdNumber,
                    }
                )}
            </Text>
            <Text>
                {formatBMessage(
                    {
                        id: 'organization.merge.page.form.organization.radio.helptext.address',
                    },
                    {
                        0: inlinedAddress,
                    }
                )}
            </Text>
        </Flex>
    );
};

export default SignupOrganizationCardText;
