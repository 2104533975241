import { useCallback } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    AsyncSelect,
    Form,
    Modal,
    SeekPaginationResultType,
    useForm,
    useSnackbar,
} from '@mirakl/roma';

import useTicketingLicenceErrorHandler from '../utils/useTicketingLicenceErrorHandler';

type UnlicensedUserType = {
    email: string;
};

type AddLicensedUserFormType = {
    email: string;
};

const TicketingLicenceAddUserModal = ({
    onAddLicensedUser,
    onSearchUnlicensedUsers,
}: {
    onAddLicensedUser: (user: AddLicensedUserFormType) => Promise<void>;
    onSearchUnlicensedUsers: (params: {
        limit: number;
        pageToken?: string;
        search: string;
    }) => Promise<SeekPaginationResultType<UnlicensedUserType>>;
}) => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const handleError = useTicketingLicenceErrorHandler();

    const formData = useForm<AddLicensedUserFormType>({
        defaultValues: { email: '' },
    });
    const {
        formState: { isDirty, isSubmitting },
        handleSubmit,
    } = formData;

    const onAddLicensedUserConfirmed = useCallback(
        (close: () => void) => async (user: AddLicensedUserFormType) => {
            try {
                await onAddLicensedUser(user);
                close();
                addSnackbar({
                    message: formatMessage({
                        id: 'operator.accounts.ticketing.licence.add.success',
                    }),
                    status: 'success',
                });
            } catch (error) {
                handleError(error);
            }
        },
        [addSnackbar, formatMessage, handleError, onAddLicensedUser]
    );

    const onSearchUnlicensedUser = useCallback(
        async (search: string, pageToken?: string) => {
            const { data: users, nextPageToken } =
                await onSearchUnlicensedUsers({
                    search,
                    limit: 10,
                    pageToken,
                });
            return {
                options:
                    users?.map((user) => ({
                        id: user.email,
                        label: user.email,
                        value: user.email,
                    })) ?? [],
                loadMore: nextPageToken
                    ? () => onSearchUnlicensedUser(search, nextPageToken)
                    : undefined,
            };
        },
        [onSearchUnlicensedUsers]
    );

    return (
        <Modal size="small">
            <Modal.Header
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.add.title',
                })}
            />
            <Modal.Content>
                <Form {...formData}>
                    <AsyncSelect
                        breakOutOfContainer
                        label={formatMessage({
                            id: 'operator.accounts.ticketing.licence.add.email.label',
                        })}
                        name="email"
                        placeholderOption={{
                            id: 'placeholder',
                            label: `-- ${formatMessage({ id: 'select.no.value' })} --`,
                            value: '',
                        }}
                        required
                        onSearchChange={onSearchUnlicensedUser}
                    />
                </Form>
            </Modal.Content>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'button.add',
                    }),
                    state: isSubmitting ? 'loading' : 'default',
                    onClick: (close, e) =>
                        handleSubmit(onAddLicensedUserConfirmed(close))(e),
                    disabled: !isDirty,
                }}
            />
        </Modal>
    );
};

export default TicketingLicenceAddUserModal;
