import { DateTime } from 'luxon';
import React from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Flex,
    FormattedDateTime,
    Icon,
    Separator,
    Text,
    Tooltip,
} from '@mirakl/roma';

import { CurrentPlanDetail } from './SubscriptionPage';

type SubscriptionDetailProps = {
    currentPlanDetail: CurrentPlanDetail;
    isOrganizationAdmin: boolean;
};

function SubscriptionPriceSection({
    currentPlanDetail,
}: SubscriptionDetailProps) {
    const { formatMessage } = useI18n();

    return (
        <>
            <Flex
                flexDirection="row"
                justifyContent="space-between"
                marginTop={1}
            >
                <Text variant="default-500">
                    {formatMessage({
                        id: `organization.subscription.price.plan.title.${currentPlanDetail.type.toLowerCase()}`,
                    })}
                </Text>
                <Flex>
                    <Text variant="default-500">
                        {formatMessage(
                            {
                                id: 'organization.subscription.price.plan.amount',
                            },
                            {
                                0: currentPlanDetail.displayPrice,
                            }
                        )}
                    </Text>
                </Flex>
            </Flex>

            <br />
            <Separator />

            <Flex flexDirection="column" marginTop={2}>
                <Flex flexDirection="row" justifyContent="space-between">
                    <Text variant="default-500">
                        {formatMessage({
                            id: 'organization.subscription.price.estimated.total',
                        })}
                        <Tooltip
                            content={formatMessage({
                                id: 'organization.subscription.price.estimated.total.tooltip',
                            })}
                            trigger={<Icon name="help-outline" />}
                        />
                    </Text>

                    <Flex>
                        <Text variant="default-500">
                            {currentPlanDetail.displayPrice}
                        </Text>
                    </Flex>
                </Flex>
                <Text variant="default-400">
                    {formatMessage(
                        {
                            id: 'organization.subscription.price.billing.date',
                        },
                        {
                            0: (
                                <FormattedDateTime
                                    date={DateTime.fromISO(
                                        currentPlanDetail.nextBillingDate
                                    )}
                                    options={DateTime.DATE_FULL}
                                    variant="default-400"
                                />
                            ),
                        }
                    )}
                </Text>
            </Flex>
        </>
    );
}

export default SubscriptionPriceSection;
