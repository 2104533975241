import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Modal, Paragraph, useSnackbar } from '@mirakl/roma';

type ConfirmUpdateUserRoleModalPropsType = {
    apiCall: (
        organizationUuid: string,
        userUuid: string
    ) => Promise<AxiosResponse<void>>;
    operation: string;
    organizationUuid: string;
    refetch: () => void;
    userEmail: string;
    userUuid: string;
};

function ConfirmUpdateUserRoleModal({
    apiCall,
    operation,
    organizationUuid,
    refetch,
    userEmail,
    userUuid,
}: ConfirmUpdateUserRoleModalPropsType) {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const [modalState, setModalState] = useState<'default' | 'loading'>(
        'default'
    );

    const onValid = useCallback(
        (close: () => void) => {
            setModalState('loading');
            return apiCall(organizationUuid, userUuid)
                .then(() => {
                    addSnackbar({
                        status: 'success',
                        message: formatMessage({
                            id: `${operation}.administrator.modal.snackbar.success.message`,
                        }),
                    });
                    close();
                    refetch();
                })
                .catch(() => {
                    addSnackbar({
                        status: 'error',
                        message: formatMessage({ id: 'snackbar.edit.fail' }),
                    });
                    setModalState('default');
                });
        },
        [
            addSnackbar,
            formatMessage,
            operation,
            organizationUuid,
            refetch,
            userUuid,
            apiCall,
        ]
    );

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: `${operation}.administrator.modal.title.label`,
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Paragraph>
                        {formatMessage(
                            {
                                id: `${operation}.administrator.modal.content.label`,
                            },
                            {
                                0: userEmail,
                                b: (chunks: string) => <b>{chunks}</b>,
                            }
                        )}
                    </Paragraph>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: `${operation}.administrator.modal.button.confirm.label`,
                    }),
                    state: modalState,
                    onClick: onValid,
                }}
            />
        </Modal>
    );
}

export default ConfirmUpdateUserRoleModal;
