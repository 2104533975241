import { useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    ConfirmModal,
    Form,
    Panel,
    Paragraph,
    SaveBar,
    Spinner,
    TextField,
    useForm,
    useModal,
    useSnackbar,
} from '@mirakl/roma';

import { useCountries } from '../../../common/address/CountriesProvider';
import AddressField from '../../../common/address/form/AddressField';
import useLoginContext from '../../../config/login/LoginProvider';
import useOrganizationContext from '../OrganizationContext';

import {
    FORM_BUSINESS_REGISTRATION_NUMBER,
    FORM_ORGANIZATION_NAME,
    FORM_TAX_ID_NUMBER,
    OrganizationFormType,
} from './organizationTypes';

type OrganizationFormProps = {
    defaultValues: OrganizationFormType;
    saveCallback(
        organizationFormType: OrganizationFormType
    ): Promise<OrganizationFormType>;
};

function OrganizationForm({
    defaultValues,
    saveCallback,
}: OrganizationFormProps) {
    const { formatMessage } = useI18n();
    const { isOrganizationAdmin, refresh } = useOrganizationContext();
    const { addSnackbar } = useSnackbar();
    const { showModal } = useModal();
    const { isLoading } = useCountries();
    const { miraklSupport } = useLoginContext();

    const formData = useForm<OrganizationFormType>({
        defaultValues: {
            [FORM_ORGANIZATION_NAME]: defaultValues[FORM_ORGANIZATION_NAME],
            [FORM_BUSINESS_REGISTRATION_NUMBER]:
                defaultValues[FORM_BUSINESS_REGISTRATION_NUMBER] ?? '',
            [FORM_TAX_ID_NUMBER]: defaultValues[FORM_TAX_ID_NUMBER] ?? '',
            address: defaultValues.address ?? '',
            addressContinued: defaultValues.addressContinued ?? '',
            city: defaultValues.city ?? '',
            postalCode: defaultValues.postalCode ?? '',
            state: defaultValues.state ?? '',
            countryCode: defaultValues.countryCode ?? '',
        },
    });

    const [businessRegistrationNumber, setBusinessRegistrationNumber] =
        useState(defaultValues[FORM_BUSINESS_REGISTRATION_NUMBER]);
    const [taxId, setTaxId] = useState(defaultValues[FORM_TAX_ID_NUMBER]);

    const {
        formState: { isDirty, isSubmitting },
        handleSubmit,
        reset,
    } = formData;

    const onSubmit = handleSubmit((formValues) => {
        return saveCallback(formValues).then((newData) => {
            refresh();
            addSnackbar({
                message: formatMessage({
                    id: 'snackbar.edit.success',
                }),
                status: 'success',
            });
            reset(newData);
            setBusinessRegistrationNumber(
                newData[FORM_BUSINESS_REGISTRATION_NUMBER]
            );
            setTaxId(newData[FORM_TAX_ID_NUMBER]);
        });
    });

    const onDiscard = () => {
        showModal(
            <ConfirmModal
                confirmButton={{
                    label: formatMessage({
                        id: 'button.discard',
                    }),
                    onClick: (close) => {
                        reset();
                        close();
                        return Promise.resolve();
                    },
                }}
                content={formatMessage({
                    id: 'modal.reset.description',
                })}
                dismissButton={{
                    label: formatMessage({
                        id: 'button.discard.cancel',
                    }),
                }}
                title={formatMessage({
                    id: 'modal.reset.title',
                })}
            />
        );
    };

    const disabled = !(
        miraklSupport.isSellerSupportReadWrite || isOrganizationAdmin
    );

    return isLoading ? (
        <Spinner />
    ) : (
        <Form {...formData}>
            <Panel>
                <Panel.Content>
                    <TextField
                        disabled={!miraklSupport.isSellerSupportReadWrite}
                        label={formatMessage({ id: 'organization.name' })}
                        maxLength={255}
                        name={FORM_ORGANIZATION_NAME}
                        required
                    />
                </Panel.Content>
                <Panel.Content
                    title={formatMessage({ id: 'billing.information' })}
                >
                    <Paragraph>
                        {formatMessage({
                            id: 'billing.information.description',
                        })}
                    </Paragraph>
                    <TextField
                        disabled={
                            disabled ||
                            (!!businessRegistrationNumber &&
                                !miraklSupport.isSellerSupportReadWrite)
                        }
                        label={formatMessage({
                            id: 'business.registration.number',
                        })}
                        maxLength={255}
                        name={FORM_BUSINESS_REGISTRATION_NUMBER}
                        required
                        tooltipText={formatMessage({
                            id: 'organization.form.brn.tooltip',
                        })}
                    />
                    <TextField
                        disabled={
                            disabled ||
                            (!!taxId && !miraklSupport.isSellerSupportReadWrite)
                        }
                        label={formatMessage({ id: 'tax.id.number' })}
                        maxLength={255}
                        name={FORM_TAX_ID_NUMBER}
                        required
                        tooltipText={formatMessage({
                            id: 'organization.form.tax.id.tooltip',
                        })}
                    />
                    <AddressField disabled={disabled} />
                </Panel.Content>
            </Panel>
            {isDirty && (
                <SaveBar
                    actions={[
                        <Button
                            disabled={disabled}
                            key="saveButton"
                            label={formatMessage({ id: 'button.save' })}
                            state={isSubmitting ? 'loading' : 'default'}
                            submit
                            onClick={onSubmit}
                        />,
                        <Button
                            key="discardButton"
                            label={formatMessage({ id: 'button.discard' })}
                            variant="secondary"
                            onClick={onDiscard}
                        />,
                    ]}
                />
            )}
        </Form>
    );
}

export default OrganizationForm;
