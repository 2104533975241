import SupportOperatorAccountDetailsLayout from '../../detail/support/SupportOperatorAccountDetailsLayout';

import SmtpDetailsPanel from './component/SmtpDetailsPanel';

const SupportOperatorSmtpDetailsPage = () => {
    return (
        <SupportOperatorAccountDetailsLayout size="sm">
            <SmtpDetailsPanel />
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportOperatorSmtpDetailsPage;
