import axios from 'axios';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import useQuery from '../../router/useQuery';
import useLogin from '../useLogin';
import useSignupRedirect from '../useSignupRedirect';

type RedirectPayload = {
    location: string;
};

const useExternalSignup = () => {
    const { isReady, logout, user } = useLogin();
    const handleSignupRedirect = useSignupRedirect();
    const { search } = useLocation();

    const param = useQuery({
        type: 'string',
        product_id: 'string',
        callback: 'string',
        create_organization: 'boolean',
        organization_selector: 'boolean',
        locale: 'optionalString',
    });

    const onSubmit = useCallback(
        async (email: string) => {
            const { data } = await axios.post<RedirectPayload>(
                `/private/signup/external${search}`,
                {
                    email,
                    type: param.type,
                    callbackUrl: param.callback,
                    productId: param.product_id,
                    customizations: {
                        createOrganization: param.create_organization,
                        organizationSelector: param.organization_selector,
                    },
                    locale:
                        user?.user?.locale ??
                        param.locale ??
                        navigator.language,
                }
            );
            return handleSignupRedirect(data);
        },
        [
            search,
            param.type,
            param.callback,
            param.create_organization,
            param.organization_selector,
            param.product_id,
            param.locale,
            user?.user?.locale,
            handleSignupRedirect,
        ]
    );

    return {
        isReady: isReady,
        user: user,
        onSubmit,
        logout,
        handleSignupRedirect,
    };
};

export default useExternalSignup;
