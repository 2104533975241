import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

type ProfileInfoPayload = {
    email: string;
    firstName?: string;
    language?: string;
    lastName?: string;
    timeZone?: string;
};

type ProfileInfoUpdatePayload = {
    firstName: string;
    language: string;
    lastName: string;
    timeZone: string;
};

const useProfileInfoApi = () => {
    const { apiGet, apiPut } = useAuthenticatedFetch();

    const fetchProfileInfo: () => Promise<ProfileInfoPayload> = useCallback(
        () =>
            apiGet<ProfileInfoPayload>('/private/user/info').then(
                ({ data }) => data
            ),
        [apiGet]
    );

    const updateProfileInfo: (
        update: ProfileInfoUpdatePayload
    ) => Promise<ProfileInfoPayload> = useCallback(
        (update) =>
            apiPut<ProfileInfoUpdatePayload, ProfileInfoPayload>(
                '/private/user/info',
                update
            ).then((response) => response.data),
        [apiPut]
    );

    return {
        fetchProfileInfo,
        updateProfileInfo,
    };
};

export default useProfileInfoApi;
