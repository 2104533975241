import { useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Panel } from '@mirakl/roma';

import OrganizationRunPage from '../OrganizationRunPage';

import OrganizationUsersDatatable, {
    COLUMN_LINKED_SHOPS_KEY,
    COLUMN_PROFILE_KEY,
    COLUMN_USER_TYPE_KEY,
} from './OrganizationUsersDatatable';
import {
    OrganizationUsersPayload,
    organizationUsersQueryParamsHelper,
    useOrganizationUsersApiClient,
} from './useOrganizationUsersApiClient';
import { ADMINISTRATOR, MEMBER } from './userTypeConstants';

const fromPayload = (payload: OrganizationUsersPayload) => ({
    ...payload,
    data: payload.data.map((shopUserLinkInfo) => {
        return {
            id: shopUserLinkInfo.id,
            [COLUMN_PROFILE_KEY]: shopUserLinkInfo.profile,
            [COLUMN_LINKED_SHOPS_KEY]: shopUserLinkInfo.shopsByTenant,
            [COLUMN_USER_TYPE_KEY]: shopUserLinkInfo.isAdmin
                ? ADMINISTRATOR.value
                : MEMBER.value,
        };
    }),
});

type OrganizationUsersState = ReturnType<typeof fromPayload>;

function OrganizationUsersPage() {
    const { formatMessage } = useI18n();

    const fetchUsers = useOrganizationUsersApiClient();
    const [organizationUsers, setOrganizationUsers] =
        useState<OrganizationUsersState>({
            count: {
                capped: false,
                counted: 0,
            },
            data: [],
        });

    const { loadingState, onQueryParamsChange, refetch } =
        organizationUsersQueryParamsHelper.useQueryParamsChange(
            (queryParams) => {
                fetchUsers(queryParams).then((payload) =>
                    setOrganizationUsers(fromPayload(payload))
                );
            }
        );

    return (
        <organizationUsersQueryParamsHelper.QueryParamsProvider
            onQueryParamsChange={onQueryParamsChange}
        >
            <OrganizationRunPage
                pageTitle={formatMessage({
                    id: 'organization.users.page.title',
                })}
                refreshPage={refetch}
                size="sm"
            >
                <Panel>
                    <Panel.Content fullWidth>
                        <OrganizationUsersDatatable
                            loading={loadingState}
                            organizationUsersData={organizationUsers}
                            refetch={refetch}
                        />
                    </Panel.Content>
                </Panel>
            </OrganizationRunPage>
        </organizationUsersQueryParamsHelper.QueryParamsProvider>
    );
}

export default OrganizationUsersPage;
