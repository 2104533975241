import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { PageLoader, useErrorHandler } from '@mirakl/roma';

import { basePath } from '../../Root';
import nullifyPayload from '../../common/nullifyPayload';
import useLoginContext from '../../config/login/LoginProvider';
import useErrorToast from '../../error/useErrorToast';
import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';
import useQuery from '../../router/useQuery';
import { mapFormToLinkShopPayload } from '../common/shop/linkShopMapper';
import { sortByLinkedThenTenantThenName } from '../common/shop/shopSort';
import useUsersShopsApiClient from '../common/shop/useUsersShopsApiClient';

import CreateOrganizationStepper, {
    CreateOrganizationFormType,
} from './form/CreateOrganizationStepper';
import { ShopType } from './form/LinkShopsField';

type CreatedOrganization = {
    id: string;
};

const LOADER_DURATION_MS = 2000;

const CreateOrganizationPage = () => {
    const { apiPost } = useAuthenticatedFetch();
    const handleError = useErrorHandler();
    const showErrorToast = useErrorToast();
    const fetchReadyToLinkShops = useUsersShopsApiClient();
    const { search, state: locationState } = useLocation();
    const { backUrl, skipIntro } = locationState || {};

    const [loading, setLoading] = useState<boolean>(true);
    const [shops, setShops] = useState<ShopType[]>([]);
    const { email: connectedUserEmail } = useLoginContext();
    const { callback, product_id, token } = useQuery({
        product_id: 'string',
        callback: 'string',
        token: 'optionalString',
    });

    useEffect(() => {
        fetchReadyToLinkShops()
            .then((result) => {
                setShops(
                    sortByLinkedThenTenantThenName(
                        result?.shops.map((shop) => {
                            return {
                                ...shop,
                                tenantDisplayName:
                                    shop.tenantName ?? shop.tenantId,
                                channelNames: shop.channels.map(
                                    (channel) => channel.text ?? channel.code
                                ),
                            };
                        })
                    )
                );
                setLoading(false);
            })
            .catch(handleError);
    }, [fetchReadyToLinkShops, handleError]);

    const createOrganization = useCallback(
        (organization: CreateOrganizationFormType) => {
            const { linkedShopUuidsByUser, ...cleanValues } = organization;

            const organizationPostPayload = nullifyPayload({
                shopsToLink: mapFormToLinkShopPayload(
                    connectedUserEmail,
                    linkedShopUuidsByUser
                ),
                ...cleanValues,
            });
            return apiPost('/private/organizations', organizationPostPayload, {
                params: {
                    product_id,
                    callback,
                    token,
                },
            })
                .then(
                    (
                        createdOrganization: AxiosResponse<CreatedOrganization>
                    ) => {
                        return new Promise<string>((resolve) => {
                            setTimeout(
                                () =>
                                    resolve(
                                        createdOrganization.headers.location
                                    ),
                                LOADER_DURATION_MS
                            );
                        });
                    }
                )
                .catch((error) => {
                    showErrorToast(error, [400]);
                    throw error;
                });
        },
        [
            apiPost,
            product_id,
            callback,
            token,
            showErrorToast,
            connectedUserEmail,
        ]
    );

    return loading ? (
        <PageLoader fullPage />
    ) : (
        <CreateOrganizationStepper
            backUrl={backUrl}
            connectedUserShops={shops}
            createOrganization={createOrganization}
            productId={product_id}
            skipIntro={skipIntro}
            successUrl={`${basePath}/organizations/create/after${search}`}
        />
    );
};

export default CreateOrganizationPage;
