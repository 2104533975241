import { useMemo } from 'react';

type ProfileInfoType = {
    email: string;
    firstName?: string;
    lastName?: string;
};

const useProfileInfoInitials = ({
    email,
    firstName,
    lastName,
}: ProfileInfoType): string =>
    useMemo(() => {
        if (!firstName || !lastName) {
            return email
                .replace(/[^a-zA-Z]/g, '')
                .substring(0, 2)
                .toUpperCase();
        }
        return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }, [email, firstName, lastName]);

export default useProfileInfoInitials;
