import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Select } from '@mirakl/roma';

import { useCountries } from '../CountriesProvider';

type CountrySelectFieldType = {
    disabled?: boolean;
    name: string;
    required?: boolean;
};

const CountrySelectField = ({
    disabled,
    name,
    required,
}: CountrySelectFieldType) => {
    const { formatMessage } = useI18n();
    const { countries } = useCountries();

    const countryOptions = useMemo(
        () =>
            countries.map((country) => ({
                id: country.code,
                label: country.label,
                value: country.code,
            })),
        [countries]
    );

    return (
        <Select
            disabled={disabled}
            label={formatMessage({ id: 'location.country' }) as string}
            name={name}
            options={countryOptions}
            placeholderOption={{
                id: 'noSelection',
                label: '',
                value: '',
            }}
            required={required}
        />
    );
};

export default CountrySelectField;
