import { useI18n } from '@mirakl/i18n';
import { useErrorHandler, useSnackbar } from '@mirakl/roma';

import { isTicketingLicenceError } from './ticketingLicenceError';

const useTicketingLicenceErrorHandler = () => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const handleErrorRoma = useErrorHandler();

    return (error: unknown) => {
        if (isTicketingLicenceError(error)) {
            const { code } = error.response.data;
            addSnackbar({
                message: formatMessage({
                    id: `operator.accounts.ticketing.licence.error.${code}.message`,
                }),
                status: 'error',
            });
            return;
        }
        if (error instanceof Error) {
            handleErrorRoma(error); // delegate to roma error handler
            return;
        }
    };
};

export default useTicketingLicenceErrorHandler;
