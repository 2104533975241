import { LinkedShopFormType } from '../../create/form/LinkShopsFields';

export type LinkShopRequest = {
    othersShopsToLinks: OthersShopsToLinks[];
    shopIds: string[];
};
type OthersShopsToLinks = {
    confirmationCodeToken: string;
    shopIds: string[];
};

type LinkFormType = {
    email: string;
    shopUuid: string;
    verificationToken?: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const groupBy = function (xs: any[], key: string) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const mapFormToLinkShopPayload = (
    connectedUserEmail: string,
    list: LinkFormType[]
): LinkShopRequest => {
    list = list ?? [];
    const otherShopsGroupByVerificationToken: Record<
        string,
        LinkedShopFormType[]
    > = groupBy(
        list.filter((value) => value.email !== connectedUserEmail),
        'verificationToken'
    );

    return {
        othersShopsToLinks: Object.entries(
            otherShopsGroupByVerificationToken
        ).map(([key, value]) => ({
            confirmationCodeToken: key,
            shopIds: value.map((formValue) => formValue.shopUuid),
        })),
        shopIds: list
            .filter((value) => value.email === connectedUserEmail)
            .map((value) => value.shopUuid),
    };
};
