import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { useModal, useNavigate, useSnackbar } from '@mirakl/roma';

import AccountGlobalLayout from '../common/layout/AccountGlobalLayout';
import useOrganizationContext from '../organization/run/OrganizationContext';
import useLinkedShops from '../organization/run/shop/useLinkedShops';

import { useWarehouseApiClient } from './api/useWarehouseApiClient';
import ConfirmCreateWarehouseModal from './components/ConfirmCreateWarehouseModal';
import { WarehouseFormType } from './components/WarehouseForm';
import WarehousePage, { toApiPayload } from './components/WarehousePage';
import { mapOrganizationShopToShopDatalistItem } from './components/WarehouseShopAssociationPanel';

export const WarehouseCreatePage = () => {
    const { postWarehouse: onSave } = useWarehouseApiClient();
    const { linkedShops, loading } = useLinkedShops();
    const { showModal } = useModal();
    const { addSnackbar } = useSnackbar();
    const { formatMessage } = useI18n();
    const { uuid: organizationUuid } = useOrganizationContext();
    const navigate = useNavigate();

    // Preselect all shops when creating a warehouse
    const selectedShops = useMemo(
        () => linkedShops?.map(mapOrganizationShopToShopDatalistItem),
        [linkedShops]
    );

    const onfulfilled = () => {
        addSnackbar({
            message: formatMessage({
                id: 'snackbar.edit.success',
            }),
            status: 'success',
        });
        navigate({
            to: `/organizations/${organizationUuid}/warehouses`,
            shouldNotShowConfirmModal: true,
        });
    };

    const handleSubmit = (
        defaultValues: WarehouseFormType,
        values: WarehouseFormType
    ) => {
        const createRequest = toApiPayload(values);
        return new Promise((resolve, reject) => {
            showModal(
                <ConfirmCreateWarehouseModal
                    warehouseCode={values.code}
                    onConfirm={() =>
                        onSave(createRequest)
                            .then(onfulfilled)
                            .then(resolve)
                            .catch(reject)
                    }
                />
            );
        });
    };

    return (
        <AccountGlobalLayout>
            <WarehousePage
                confirmModalOnSave
                initialValues={{
                    code: '',
                    name: '',
                    type: '',
                    address: '',
                    addressContinued: '',
                    city: '',
                    countryCode: '',
                    postalCode: '',
                    state: '',
                    shops: selectedShops,
                }}
                loading={loading}
                pageTitle="organizations.warehouses.form.create.title"
                onSubmit={handleSubmit}
            />
        </AccountGlobalLayout>
    );
};
