import { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';

import { AddressType } from '../../../common/address/form/AddressField';
import nullifyPayload from '../../../common/nullifyPayload';
import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import useOrganizationContext from '../OrganizationContext';
import OrganizationRunPage from '../OrganizationRunPage';

import OrganizationForm from './OrganizationForm';
import {
    FORM_BUSINESS_REGISTRATION_NUMBER,
    FORM_ORGANIZATION_NAME,
    FORM_TAX_ID_NUMBER,
    OrganizationFormType,
} from './organizationTypes';

type OrganizationPayload = AddressType & {
    [FORM_BUSINESS_REGISTRATION_NUMBER]: string;
    [FORM_ORGANIZATION_NAME]: string;
    [FORM_TAX_ID_NUMBER]: string;
};

function OrganizationGeneralInformationPage() {
    const { formatMessage } = useI18n();
    const { apiGet, apiPut } = useAuthenticatedFetch();

    const [organizationPayload, setOrganizationPayload] =
        useState<OrganizationPayload>();
    const [isLoading, setLoading] = useState<boolean>(true);

    const { uuid } = useOrganizationContext();

    useEffect(() => {
        apiGet<OrganizationPayload>(`/private/organizations/${uuid}`).then(
            (response) => {
                setOrganizationPayload(response.data);
                setLoading(false);
            }
        );
    }, [apiGet, uuid]);

    const saveOrganizationCallback = (
        organization: OrganizationFormType
    ): Promise<OrganizationFormType> => {
        const organizationPostPayload = nullifyPayload(organization);
        return apiPut(
            `/private/organizations/${uuid}`,
            organizationPostPayload
        ).then(() => organization);
    };

    const defaultValues = !isLoading ? organizationPayload : undefined;

    return (
        <OrganizationRunPage
            pageTitle={formatMessage({ id: 'general.information' })}
            size="sm"
        >
            {defaultValues && (
                <OrganizationForm
                    defaultValues={defaultValues}
                    saveCallback={saveOrganizationCallback}
                />
            )}
        </OrganizationRunPage>
    );
}

export default OrganizationGeneralInformationPage;
