import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Alert } from '@mirakl/roma';

import useOperatorAccountContext from '../../../../OperatorAccountContext';
import useTicketingLicenceApi from '../api/useTicketingLicenceApi';

const LoadedTicketingLicenceLimitReachedAlert = () => {
    const { formatMessage } = useI18n();
    const { id: operatorAccountId } = useOperatorAccountContext();
    const { fetchLicenceLimitQuery, fetchLicensedUsersQuery } =
        useTicketingLicenceApi(operatorAccountId);
    const { data: licensedUsers } = useSuspenseQuery(fetchLicensedUsersQuery());
    const { data: limit } = useSuspenseQuery(fetchLicenceLimitQuery());

    if (limit > licensedUsers.length) {
        return null;
    }

    return (
        <Alert
            status="info"
            title={formatMessage({
                id: 'operator.accounts.ticketing.licence.limit.reached',
            })}
        />
    );
};

const TicketingLicenceLimitReachedAlert = () => {
    return (
        <Suspense fallback={null}>
            <LoadedTicketingLicenceLimitReachedAlert />
        </Suspense>
    );
};

export default TicketingLicenceLimitReachedAlert;
