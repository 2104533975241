import { AxiosError } from 'axios';

type ValidationErrorDataType = {
    errors?: {
        messages?: {
            defaultMessage: string;
        }[];
    }[];
};

const sanitizeMessageKey = (messageKey: string) => {
    if (messageKey.startsWith('{') && messageKey.endsWith('}')) {
        return messageKey.substring(1, messageKey.length - 1);
    }
    return messageKey;
};

export const getErrorMessage = (
    error: Error,
    formatMessage: (m: object) => string
) => {
    if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        // display validation error if any
        if (axiosError.response && axiosError.response.status === 400) {
            const errorData = axiosError.response
                .data as ValidationErrorDataType;
            if (
                errorData.errors &&
                errorData.errors[0] &&
                errorData.errors[0].messages &&
                errorData.errors[0].messages[0]
            ) {
                return formatMessage({
                    id: sanitizeMessageKey(
                        errorData.errors[0].messages[0].defaultMessage
                    ),
                });
            }
        }
    }
    return error.message;
};

export const isDebugMode = () =>
    process.env.NODE_ENV === 'development' || process.env.DEPLOY_ENV === 'test';
