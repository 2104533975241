import { createQueryParamsHelper } from '@mirakl/roma';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import { StageValues } from '../../../types/stageTypes';

export const SHOP_NAME_FILTER = 'shopName';

export type ShopsPayload = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: ShopListPayload[];
    nextPageToken?: string;
    previousPageToken?: string;
};

export type ShopListPayload = {
    channels: Channel[];
    name: string;
    stage: StageValues;
    state: State;
    tenantId: string;
    tenantLogoUrl: string;
    tenantName?: string;
    uuid: string;
};
type Channel = {
    code: string;
    text?: string;
};

type State = {
    suspensionType?: string;
    value: string;
};

export const organizationShopQueryParamsHelper = createQueryParamsHelper<{
    tenant: {
        type: 'singleOption';
        value: string;
    };
}>({
    tenant: 'singleOption',
})
    .withSeekPagination()
    .withSearch(SHOP_NAME_FILTER)
    .withSort('sort');

type OrganizationShopQueryParams = Parameters<
    Parameters<
        (typeof organizationShopQueryParamsHelper)['useQueryParamsChange']
    >[0]
>[0];

const useOrganizationShopsApiClient: () => (
    organizationUuid: string,
    queryParams: OrganizationShopQueryParams
) => Promise<ShopsPayload> = () => {
    const { apiGet } = useAuthenticatedFetch();

    return (
        organizationUuid: string,
        queryParams: OrganizationShopQueryParams
    ): Promise<ShopsPayload> =>
        apiGet<ShopsPayload>(
            `/private/organizations/${organizationUuid}/shops`,
            {
                params: queryParams,
            }
        ).then((payload) => payload.data);
};

export default useOrganizationShopsApiClient;
