import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../../fetch/useAuthenticatedFetch';
import { StageValues } from '../../../../types/stageTypes';
import { LinkShopRequest } from '../../../common/shop/linkShopMapper';

export type ShopsPayload = {
    shops: Shops[];
};

type Shops = {
    alreadyInOrganization: boolean;
    channels: {
        code: string;
        text?: string;
    }[];
    name: string;
    stage: StageValues;
    state: {
        suspensionType?: string;
        value: string;
    };
    tenantId: string;
    tenantName?: string;
    uuid: string;
};

const useStoreSelectionApi = () => {
    const { apiGet, apiPut } = useAuthenticatedFetch();

    const fetchShopForEmail = useCallback(
        (token: string) => {
            return apiGet<ShopsPayload>('/private/users/shops/other', {
                params: { confirmationCodeToken: token },
            }).then((payload) => payload.data);
        },
        [apiGet]
    );

    const linkShopToOrganization = useCallback(
        (organizationUuid: string, payload: LinkShopRequest) => {
            return apiPut(
                `/private/organizations/${organizationUuid}/shops`,
                payload
            ).then();
        },
        [apiPut]
    );

    return {
        fetchShopForEmail,
        linkShopToOrganization,
    };
};

export default useStoreSelectionApi;
