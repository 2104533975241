import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
    LinkButton,
    OnboardingStepper,
    Paragraph,
    TextField,
    useNavigate,
    useStepperForm,
} from '@mirakl/roma';

import AddressField, {
    AddressType,
} from '../../../common/address/form/AddressField';
import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

const failedValidation = (fieldName: string, messageCode: string) => {
    return Promise.reject({
        response: {
            status: 400,
            data: {
                errors: [
                    {
                        field: fieldName,
                        messages: [
                            {
                                code: messageCode,
                            },
                        ],
                    },
                ],
            },
        },
    });
};

export type OrganizationStepFormType = AddressType & {
    businessRegistrationNumber: string;
    name: string;
    taxIdNumber: string;
};

type OrganizationStepProps = {
    backUrl: 'optionalString';
    browserNavigate: ReturnType<typeof useNavigate>;
};

const OrganizationStep = ({
    backUrl,
    browserNavigate,
}: OrganizationStepProps) => {
    const { formatMessage } = useIntl();
    const { apiGet } = useAuthenticatedFetch();

    const goBack = useCallback(() => {
        // Use the parent navigate and not the one of the stepper
        browserNavigate({
            to: `${backUrl}`,
            shouldNotShowConfirmModal: true,
        });
    }, [browserNavigate, backUrl]);

    const stepFormData = useStepperForm<OrganizationStepFormType>({
        defaultValues: {
            name: '',
            businessRegistrationNumber: '',
            taxIdNumber: '',
            address: '',
            addressContinued: '',
            city: '',
            postalCode: '',
            state: '',
            countryCode: '',
        },
    });

    return (
        <OnboardingStepper.Form
            {...stepFormData}
            onSubmit={({ name }) => {
                return apiGet<{ available: boolean }>(
                    '/private/organizations/name-available',
                    { params: { name } }
                ).then(({ data }) => {
                    if (!data.available) {
                        return failedValidation(
                            'name',
                            'validation.error.organization.exists'
                        );
                    }
                });
            }}
        >
            <TextField
                label={formatMessage({
                    id: 'organization.name',
                })}
                maxLength={255}
                name="name"
                required
            />
            <Paragraph noSpaceBottom={false}>
                {formatMessage({
                    id: 'billing.information.description',
                })}
            </Paragraph>
            <TextField
                label={formatMessage({
                    id: 'business.registration.number',
                })}
                maxLength={255}
                name="businessRegistrationNumber"
                required
                tooltipText={formatMessage({
                    id: 'organization.form.brn.tooltip',
                })}
            />
            <TextField
                label={formatMessage({
                    id: 'tax.id.number',
                })}
                maxLength={255}
                name="taxIdNumber"
                required
                tooltipText={formatMessage({
                    id: 'organization.form.tax.id.tooltip',
                })}
            />
            <AddressField />
            {backUrl && (
                <LinkButton
                    iconName="arrow-back"
                    iconPosition="start"
                    label={formatMessage({
                        id: 'organization.form.back.to.selection',
                    })}
                    onClick={goBack}
                />
            )}
        </OnboardingStepper.Form>
    );
};

export default OrganizationStep;
