import { useEffect } from 'react';

import { PageLoader, useNavigate } from '@mirakl/roma';

import useQuery from '../router/useQuery';

const DURATION = 250;
export const InvitationLoader = () => {
    const { token } = useQuery({ token: 'string' });
    const navigate = useNavigate();
    useEffect(() => {
        const intervalId = setInterval(() => {
            clearInterval(intervalId);
            // href to force going through the backend
            navigate({ href: `/invitation/verified?token=${token}` });
        }, DURATION);
    }, [navigate, token]);
    return <PageLoader fullPage />;
};
