const PREFIX = 'mirakl.account:';
export const SIGNUP_REDIRECT = `${PREFIX}signup-redirect`;

const SILENT_LOGIN = `${PREFIX}silent-login`;

class SignupLoginStateManager {
    get redirect() {
        return sessionStorage.getItem(SIGNUP_REDIRECT);
    }

    set redirect(value: string | null) {
        if (value) {
            sessionStorage.setItem(SIGNUP_REDIRECT, value);
        }
    }

    public deleteRedirect() {
        sessionStorage.removeItem(SIGNUP_REDIRECT);
    }

    set silentLogin(value: boolean) {
        sessionStorage.setItem(SILENT_LOGIN, `${value}`);
    }

    get silentLogin() {
        const value =
            (sessionStorage.getItem(SILENT_LOGIN) ?? 'true') === 'true';

        // once read the value should be removed from the session
        this.deleteSilentLogin();
        return value;
    }

    private deleteSilentLogin() {
        sessionStorage.removeItem(SILENT_LOGIN);
    }
}

export const signupLoginStateManager = new SignupLoginStateManager();
