import { ReactNode, useCallback } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Text } from '@mirakl/roma';

export const useCompact500FormatMessage = () => {
    const { formatMessage } = useI18n();

    const formatBMessage = useCallback(
        (descriptor: { id: string }, values: Record<string, ReactNode>) => {
            return formatMessage(descriptor, {
                ...values,
                b(chunks: string) {
                    return <Text variant="compact-500">{chunks}</Text>;
                },
            });
        },
        [formatMessage]
    );

    return { formatBMessage };
};
