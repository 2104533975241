import { useI18n } from '@mirakl/i18n';
import { LayoutContainer, PageLayout, PageTitle } from '@mirakl/roma';

import OperatorUserGlobalLayout from '../../../common/layout/OperatorUserGlobalLayout';

import OperatorSmtpListingPanel from './component/OperatorSmtpListingPanel';

const OperatorSmtpListingPage = () => {
    const { formatMessage } = useI18n();

    return (
        <OperatorUserGlobalLayout>
            <PageTitle title={formatMessage({ id: 'operator.smtp.title' })} />
            <PageLayout size="sm">
                <LayoutContainer>
                    <OperatorSmtpListingPanel />
                </LayoutContainer>
            </PageLayout>
        </OperatorUserGlobalLayout>
    );
};

export default OperatorSmtpListingPage;
