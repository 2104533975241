import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';

import { AnalyticsProvider } from '@mirakl/analytics';
import { PageLoader, useErrorHandler } from '@mirakl/roma';

type AnalyticsConfigPayload = {
    key: string;
};

type AccountAnalyticsProviderProps = {
    children: ReactNode;
};

const AccountAnalyticsProvider = ({
    children,
}: AccountAnalyticsProviderProps) => {
    const [analyticsConfig, setAnalyticsConfig] =
        useState<AnalyticsConfigPayload>();
    const handleError = useErrorHandler();

    useEffect(() => {
        axios
            .get<AnalyticsConfigPayload>('/analytics-config')
            .then((response) => {
                setAnalyticsConfig(response.data);
            })
            .catch(() => setAnalyticsConfig({} as AnalyticsConfigPayload));
    }, [handleError]);

    if (!analyticsConfig) {
        return <PageLoader fullPage />;
    }
    return (
        <AnalyticsProvider trackKey={analyticsConfig.key}>
            {children}
        </AnalyticsProvider>
    );
};

export default AccountAnalyticsProvider;
