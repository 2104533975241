export const createSmtpAsSupportQueryParam = 'support';

export type SmtpStatus =
    | 'CONFIGURED'
    | 'TO_VERIFY'
    | 'VERIFIED'
    | 'DISABLED'
    | 'ERROR';

export type SmtpType = 'SELF_MANAGED' | 'MIRAKL_SMTP';

export type OperatorSmtpType = {
    id: string;
    name: string;
    senderEmail: string;
    senderName: string;
    status: SmtpStatus;
    type: SmtpType;
};

export type SmtpFormType = {
    host: string;
    name: string;
    password: string;
    port: number;
    senderEmail: string;
    senderName: string;
    startTls: boolean;
    type: SmtpType;
    username: string;
    xvarHeaderPrefix: string;
};

export type ServerStepFormType = {
    name: string;
    type: SmtpType;
};
