import { useI18n } from '@mirakl/i18n';
import {
    Box,
    EmptyState,
    LayoutContainer,
    LayoutItem,
    Page,
    PageLayout,
} from '@mirakl/roma';

const InvitationExpiredPage = () => {
    const { formatMessage } = useI18n();
    return (
        <Page>
            <PageLayout size="sm">
                <LayoutContainer>
                    <LayoutItem>
                        <Box paddingTop={10}>
                            <EmptyState
                                illustrationName="error-500-2"
                                illustrationSize="large"
                                subtitle={formatMessage({
                                    id: 'signup.error.subtitle',
                                })}
                                title={formatMessage({
                                    id: 'signup.error.title',
                                })}
                            />
                        </Box>
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </Page>
    );
};

export default InvitationExpiredPage;
