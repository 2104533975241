import React, { useState } from 'react';

import {
    GetQueryParamsValueFromHelperType,
    createQueryParamsHelper,
} from '@mirakl/roma';

import TicketingLicenceUsersDatatable from './TicketingLicenceUsersDatatable';

const queryParamsHelper = createQueryParamsHelper()
    .withSearch('search')
    .withSort('sort');

type QueryParamsType = GetQueryParamsValueFromHelperType<
    typeof queryParamsHelper
>;

const TicketingLicenceUsersListing = () => {
    const { QueryParamsProvider, useQueryParamsChange } = queryParamsHelper;
    const [queryParams, setQueryParams] = useState<QueryParamsType>();
    const { onQueryParamsChange } = useQueryParamsChange(async (params) => {
        setQueryParams(params);
    });

    return (
        <QueryParamsProvider onQueryParamsChange={onQueryParamsChange}>
            <TicketingLicenceUsersDatatable {...queryParams} />
        </QueryParamsProvider>
    );
};

export default TicketingLicenceUsersListing;
