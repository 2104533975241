import { useI18n } from '@mirakl/i18n';
import { Box, Flex, Scrollable, Text, TextCellContent } from '@mirakl/roma';

import { useLocalizedCommaJoiner } from '../../i18n/useLocalizedCommaJoiner';
import { StageValues } from '../../types/stageTypes';

type ShopOnTenantCellContentType = {
    tenants: {
        shops: string[];
        stage: StageValues;
        tenantDisplayName: string;
    }[];
};

function ShopOnTenantCellContent({ tenants }: ShopOnTenantCellContentType) {
    const { formatMessage } = useI18n();
    const { joinWithCommas } = useLocalizedCommaJoiner();

    const shouldShowStage = tenants.some((element) => element.stage !== 'PROD');

    const shopsCount = tenants
        .map((tenant) => tenant.shops.length)
        .reduce((acc, cur) => acc + cur, 0);
    return (
        <TextCellContent
            renderExpandableContent={
                shopsCount
                    ? () => {
                          return (
                              <Scrollable hideScrollbars maxHeight="400px">
                                  <Box>
                                      {tenants.map((tenant) => {
                                          return (
                                              <Flex
                                                  flexDirection="column"
                                                  key={tenant.tenantDisplayName}
                                                  marginBottom={2}
                                              >
                                                  <Text variant="compact-400">
                                                      {shouldShowStage
                                                          ? formatMessage(
                                                                {
                                                                    id: 'tenant.stage.inline',
                                                                },
                                                                {
                                                                    0: tenant.tenantDisplayName,
                                                                    1: formatMessage(
                                                                        {
                                                                            id: `shop.stage.${tenant.stage}`,
                                                                        }
                                                                    ),
                                                                }
                                                            )
                                                          : tenant.tenantDisplayName}
                                                  </Text>
                                                  <Text variant="small-400">
                                                      {joinWithCommas(
                                                          tenant.shops
                                                      )}
                                                  </Text>
                                              </Flex>
                                          );
                                      })}
                                  </Box>
                              </Scrollable>
                          );
                      }
                    : undefined
            }
            text={formatMessage(
                { id: 'stores.count' },
                {
                    0: shopsCount,
                }
            )}
        />
    );
}

export default ShopOnTenantCellContent;
