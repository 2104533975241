import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { useI18n } from '@mirakl/i18n';
import { PageLoader, useErrorHandler } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import { useOperatorAccountClientApi } from './operatorAccountClientApi';
import { useOperatorAccountUserApiClient } from './users/listing/api/useOperatorAccountUserApiClient';

type OperatorAccountContextType = {
    id: string;
    isAdmin: boolean;
    isClientAccount: boolean;
    logoFile?: File;
    logoUrl?: string;
    name: string;
    reload: () => void;
};

const OperatorAccountContext = createContext<
    OperatorAccountContextType | undefined
>(undefined);

const useOperatorAccountContext = () => {
    const { formatMessage } = useI18n();

    const context = useContext(OperatorAccountContext);
    if (!context) {
        throw new Error(formatMessage({ id: 'error.403.title' }));
    }
    return context;
};

export const OperatorAccountProvider = ({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}) => {
    const { id: queryId } = useRequiredParams<{ id: string }>();
    const handleError = useErrorHandler();

    const { getOperatorAccountDetails, getOperatorAccountLogo } =
        useOperatorAccountClientApi();
    const { getUserRoles } = useOperatorAccountUserApiClient(queryId);

    const [contextIsLoading, setContextIsLoading] = useState<boolean>(true);
    const [operatorAccountName, setOperatorAccountName] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [logoFile, setLogoFile] = useState<File>();
    const [logoUrl, setLogoUrl] = useState<string>();
    const [userRoles, setUserRoles] = useState<Array<string>>([]);
    const [isClientAccount, setIsClientAccount] = useState<boolean>(false);

    const fetchOperatorAccountContext = useCallback(() => {
        Promise.all([
            getOperatorAccountDetails(queryId),
            getOperatorAccountLogo(queryId),
            getUserRoles(),
        ])
            .then(([{ isClientAccount: isClient, name }, logo, roles]) => {
                if (logo) {
                    const file = new File([logo.data], logo.filename, {
                        type: logo.contentType,
                    });
                    const fileUrl = URL.createObjectURL(file);
                    setLogoFile(file);
                    setLogoUrl(fileUrl);
                } else {
                    setLogoFile(undefined);
                    setLogoUrl(undefined);
                }
                setUserRoles(roles);
                setId(queryId);
                setOperatorAccountName(name);
                setIsClientAccount(isClient);
                setContextIsLoading(false);
            })
            .catch(handleError);
    }, [
        getOperatorAccountDetails,
        getOperatorAccountLogo,
        getUserRoles,
        handleError,
        queryId,
    ]);

    useEffect(() => {
        fetchOperatorAccountContext();
    }, [fetchOperatorAccountContext]);

    const reload = useCallback(() => {
        fetchOperatorAccountContext();
    }, [fetchOperatorAccountContext]);

    return (
        <>
            {contextIsLoading && <PageLoader fullPage />}
            {!contextIsLoading && (
                <OperatorAccountContext.Provider
                    value={{
                        id,
                        logoFile,
                        logoUrl,
                        name: operatorAccountName,
                        reload,
                        isClientAccount,
                        isAdmin: userRoles.includes('ADMINISTRATOR'),
                    }}
                >
                    {children}
                </OperatorAccountContext.Provider>
            )}
        </>
    );
};

export default useOperatorAccountContext;
