import React, { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useI18n } from '@mirakl/i18n';
import {
    AdvancedDatatableSelection,
    Button,
    Datalist,
    Flex,
    Grid,
    Media,
    Panel,
    Paragraph,
    Text,
    createQueryParamsHelper,
} from '@mirakl/roma';

import useLinkedShops from '../../organization/run/shop/useLinkedShops';
import { ShopListPayload } from '../../organization/run/shop/useListShopsApi';
import { WarehouseShopApiType } from '../api/useWarehouseApiClient';

import { WarehouseFormType } from './WarehouseForm';

const SHOPS_FIELD = 'shops';

type ShopDatalistItem = {
    id: string;
    shopName: string;
    tenantId: string;
    tenantLogoUrl: string;
    tenantName?: string;
};

export const mapOrganizationShopToShopDatalistItem = (
    organizationShop: ShopListPayload
): ShopDatalistItem => {
    return {
        id: organizationShop.uuid,
        shopName: organizationShop.name,
        tenantName: organizationShop.tenantName,
        tenantId: organizationShop.tenantId,
        tenantLogoUrl: organizationShop.tenantLogoUrl,
    };
};

const DATALIST_SEARCH_ID = 'search';
const DATATABLE_SEARCH_ID = 'modal-search';
const { QueryParamsProvider, useQueryParamsChange } =
    createQueryParamsHelper().withSearch(DATALIST_SEARCH_ID);

const ShopDataListRowContent = ({
    id,
    shopName,
    tenantId,
    tenantLogoUrl,
    tenantName,
}: ShopDatalistItem) => {
    return (
        <Grid columnsWidth={['flex', '80px', '80px']} fullWidth key={id}>
            <Flex gap={2}>
                <Media
                    alt={tenantName ?? tenantId}
                    size={7}
                    type="image"
                    url={tenantLogoUrl}
                />
                <Flex flexDirection="column">
                    <Text variant="default-500">{tenantName ?? tenantId}</Text>
                    <Text variant="small-400">{shopName}</Text>
                </Flex>
            </Flex>
        </Grid>
    );
};

const getFilterShops =
    (search: string | undefined) => (shop: WarehouseShopApiType) => {
        if (!search) {
            return true;
        }
        const formattedSearch = search.toLowerCase();
        return (
            shop.shopName.toLowerCase().includes(formattedSearch) ||
            shop.tenantId.toLowerCase().includes(formattedSearch) ||
            shop.tenantName?.toLowerCase().includes(formattedSearch)
        );
    };

const WarehouseShopAssociationPanel = () => {
    const { formatMessage } = useI18n();
    const [search, setSearch] = useState<string>();

    // List of all shops linked to the ORGANIZATION
    const { linkedShops: shops, loading: shopsLoading } = useLinkedShops();
    const organizationShops = useMemo(
        () => shops?.map(mapOrganizationShopToShopDatalistItem),
        [shops]
    );

    const [matchedShopsCount, setMatchedShopsCount] = useState<number>(0);

    // List of shops associated to the WAREHOUSE (sublist of linked to orga)
    const { control } = useFormContext<WarehouseFormType>();
    const selectedShops = useWatch({ control, name: SHOPS_FIELD });
    const datalistShops = useMemo(() => {
        if (Array.isArray(selectedShops)) {
            return selectedShops?.filter(getFilterShops(search));
        }
        return selectedShops;
    }, [selectedShops, search]);

    const headings = useMemo(
        () => [
            {
                columnKey: 'name',
                label: formatMessage({
                    id: 'organizations.warehouses.form.shops.datalist.manage.stores',
                }),
                render: (_name: string, shop: ShopDatalistItem) =>
                    ShopDataListRowContent(shop),
            },
        ],
        [formatMessage]
    );
    const { onQueryParamsChange } = useQueryParamsChange((queryParams) =>
        setSearch(queryParams[DATALIST_SEARCH_ID])
    );

    return (
        <Panel>
            <Panel.Header
                title={formatMessage({
                    id: 'organizations.warehouses.form.shops.title',
                })}
            />
            <Panel.Content>
                <Paragraph>
                    {formatMessage({
                        id: 'organizations.warehouses.form.shops.content',
                    })}
                </Paragraph>
            </Panel.Content>
            <AdvancedDatatableSelection<ShopDatalistItem, number>
                a11yLabel={formatMessage({
                    id: 'organizations.warehouses.form.shops.datalist.label',
                })}
                datatable={{
                    id: 'warehouse-shop-link-selection',
                    emptyState: null,
                    fetchData: ({ queryParams }) =>
                        new Promise((resolve) => {
                            const filteredShops = organizationShops?.filter(
                                getFilterShops(
                                    queryParams?.[DATATABLE_SEARCH_ID]
                                        ?.value as string
                                )
                            );
                            setMatchedShopsCount(filteredShops?.length ?? 0);
                            resolve({
                                data: filteredShops,
                            });
                        }),
                    headings,
                    resultNumberMessage: formatMessage(
                        { id: 'count.result' },
                        { 0: matchedShopsCount }
                    ),
                    bulkSelect: {
                        hideSelectAll: true,
                    },
                }}
                maxSelection={0}
                name={SHOPS_FIELD}
                renderField={({ open }) => (
                    <QueryParamsProvider
                        onQueryParamsChange={onQueryParamsChange}
                    >
                        <Datalist
                            data={datalistShops}
                            loading={shopsLoading ? 'loading' : 'done'}
                            toolbar={{
                                action: (
                                    <Button
                                        label={formatMessage({
                                            id: 'organizations.warehouses.form.shops.datalist.action',
                                        })}
                                        onClick={open}
                                    />
                                ),
                                resultNumberMessage: formatMessage(
                                    { id: 'count.result' },
                                    { 0: selectedShops.length }
                                ),
                            }}
                        >
                            {(data) => (
                                <Datalist.Rows>
                                    {data.map((item) => (
                                        <Datalist.Item
                                            id={item.id}
                                            key={item.id}
                                        >
                                            <Datalist.ItemContent>
                                                <ShopDataListRowContent
                                                    {...item}
                                                />
                                            </Datalist.ItemContent>
                                        </Datalist.Item>
                                    ))}
                                </Datalist.Rows>
                            )}
                        </Datalist>
                    </QueryParamsProvider>
                )}
                size="medium"
                title={formatMessage({
                    id: 'organizations.warehouses.form.shops.datalist.manage.title',
                })}
                toolbar={{
                    searchBar: {
                        id: DATATABLE_SEARCH_ID,
                        placeholder: formatMessage({
                            id: 'organizations.warehouses.form.shops.datalist.search.placeholder',
                        }),
                    },
                }}
            />
        </Panel>
    );
};

export default WarehouseShopAssociationPanel;
