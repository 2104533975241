import { DateTime } from 'luxon';
import { useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    DatatableProvider,
    LayoutContainer,
    Page,
    PageLayout,
    PageTitle,
    Panel,
} from '@mirakl/roma';

import AccountGlobalLayout from '../../common/layout/AccountGlobalLayout';
import {
    operatorAccountListingQueryParamsHelper,
    useOperatorAccountClientApi,
} from '../operatorAccountClientApi';

import OperatorAccountListingDatatable, {
    OperatorAccountsDatatableType,
} from './components/OperatorAccountListingDatatable';

function OperatorAccountListingPage() {
    const { formatMessage } = useI18n();
    const { getOperatorAccounts } = useOperatorAccountClientApi();

    const [operators, setOperators] = useState<OperatorAccountsDatatableType>({
        count: { counted: 0, capped: false },
        data: [],
    });

    const { loadingState, onQueryParamsChange } =
        operatorAccountListingQueryParamsHelper.useQueryParamsChange(
            (queryParams) => {
                getOperatorAccounts(queryParams).then((payload) => {
                    setOperators({
                        ...payload,
                        data: payload.data.map((operatorAccount) => ({
                            ...operatorAccount,
                            type: operatorAccount.isClientAccount
                                ? 'CUSTOMER'
                                : 'PARTNER',
                            dateCreated: DateTime.fromISO(
                                operatorAccount.dateCreated
                            ),
                        })),
                    });
                });
            }
        );

    return (
        <AccountGlobalLayout>
            <Page>
                <PageTitle
                    title={formatMessage({
                        id: 'operator.accounts',
                    })}
                />
                <PageLayout size="md">
                    <LayoutContainer>
                        <Panel>
                            <Panel.Content fullWidth>
                                <operatorAccountListingQueryParamsHelper.QueryParamsProvider
                                    onQueryParamsChange={onQueryParamsChange}
                                >
                                    <DatatableProvider>
                                        <OperatorAccountListingDatatable
                                            loadingState={loadingState}
                                            operators={operators}
                                        />
                                    </DatatableProvider>
                                </operatorAccountListingQueryParamsHelper.QueryParamsProvider>
                            </Panel.Content>
                        </Panel>
                    </LayoutContainer>
                </PageLayout>
            </Page>
        </AccountGlobalLayout>
    );
}

export default OperatorAccountListingPage;
