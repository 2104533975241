import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { PageLoader, useErrorHandler } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';

type OrganizationContextPayload = {
    availableConnectors: string[];
    isAdmin: boolean;
    isLegacySubscription: boolean;
    isSupport: boolean;
    organizationName: string;
    organizationShopsCount: number;
};

type OrganizationContextType = {
    availableConnectors: string[];
    isLegacySubscription: boolean;
    isOrganizationAdmin: boolean;
    name: string;
    refresh(): Promise<void>;
    shopsCount: number;
    uuid: string;
};

const OrganizationContext = createContext<OrganizationContextType | undefined>(
    undefined
);

const useOrganizationContext = () => {
    const context = useContext(OrganizationContext);
    if (!context) {
        throw new Error(
            'Cannot access organization outside of OrganizationProvider'
        );
    }
    return context;
};

export const OrganizationProvider = ({ children }: { children: ReactNode }) => {
    const handleError = useErrorHandler();
    const { apiGet } = useAuthenticatedFetch();
    const [isLoading, setIsLoading] = useState(true);

    const { uuid } = useRequiredParams<{ uuid: string }>();

    const [organizationName, setOrganizationName] = useState<string>('');
    const [organizationShopsCount, setOrganizationShopsCount] =
        useState<number>(0);
    const [isOrganizationAdmin, setIsOrganizationAdmin] =
        useState<boolean>(false);
    const [availableConnectors, setAvailableConnectors] = useState<string[]>(
        []
    );
    const [isLegacySubscription, setIsLegacySubscription] =
        useState<boolean>(false);

    const fetchOrganizationContext = useCallback(
        () =>
            apiGet<OrganizationContextPayload>(
                `/private/organizations/${uuid}/context`
            )
                .then((payload) => {
                    setOrganizationName(payload.data.organizationName);
                    setIsOrganizationAdmin(payload.data.isAdmin);
                    setOrganizationShopsCount(
                        payload.data.organizationShopsCount
                    );
                    if (payload.data.availableConnectors !== undefined) {
                        setAvailableConnectors(
                            payload.data.availableConnectors
                        );
                    }
                    setIsLegacySubscription(payload.data.isLegacySubscription);
                })
                .catch(handleError)
                .finally(() => setIsLoading(false)),
        [apiGet, handleError, uuid]
    );

    useEffect(() => {
        fetchOrganizationContext();
    }, [fetchOrganizationContext]);

    return isLoading ? (
        <PageLoader fullPage />
    ) : (
        <OrganizationContext.Provider
            value={{
                availableConnectors: availableConnectors,
                isOrganizationAdmin: isOrganizationAdmin,
                name: organizationName,
                refresh: fetchOrganizationContext,
                shopsCount: organizationShopsCount,
                uuid: uuid,
                isLegacySubscription,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

export default useOrganizationContext;
