import { useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    DatatableProvider,
    Panel,
    Paragraph,
    SeekPaginationResultType,
    useErrorHandler,
} from '@mirakl/roma';

import OrganizationRunPage from '../../organization/run/OrganizationRunPage';
import { StageValues } from '../../types/stageTypes';
import {
    WarehouseListingPayload,
    WarehousePayloadType,
    useWarehouseApiClient,
    warehouseQueryParamsHelper,
} from '../api/useWarehouseApiClient';

import { WarehouseListDatatable } from './components/WarehouseListDatatable';

export type WarehouseType = {
    code: string;
    id: string;
    linkedShops: {
        shops: string[];
        stage: StageValues;
        tenantDisplayName: string;
    }[];
    name: string;
    type: 'SELF_MANAGED' | 'EXTERNALLY_MANAGED';
};

export type WarehouseListing = SeekPaginationResultType<WarehouseType>;

const mapToWarehouseType = (payload: WarehousePayloadType): WarehouseType => {
    const shopsByTenant: {
        [key: string]: {
            shops: string[];
            stage: StageValues;
            tenantDisplayName: string;
        };
    } = {};

    payload.linkedShops.forEach((shop) => {
        const tenantDisplayName = shop.tenantName || shop.tenantId;
        if (!shopsByTenant[tenantDisplayName]) {
            shopsByTenant[tenantDisplayName] = {
                shops: [],
                stage: shop.stage,
                tenantDisplayName,
            };
        }
        shopsByTenant[tenantDisplayName].shops.push(shop.shopName);
    });

    const linkedShops = Object.values(shopsByTenant).map((tenant) => {
        tenant.shops.sort(); // Sort shops alphabetically
        return tenant;
    });

    linkedShops.sort((a, b) =>
        a.tenantDisplayName.localeCompare(b.tenantDisplayName)
    ); // Sort by tenantDisplayName

    return {
        code: payload.code,
        id: payload.id,
        linkedShops,
        name: payload.name,
        type: payload.type,
    };
};

const mapToWarehouseListing = (
    payload: WarehouseListingPayload
): WarehouseListing => {
    return {
        count: payload.count,
        data: payload.data.map(mapToWarehouseType),
        nextPageToken: payload.nextPageToken,
        previousPageToken: payload.previousPageToken,
    };
};

export const WarehouseListPage = () => {
    const { formatMessage } = useI18n();
    const { listWarehouses } = useWarehouseApiClient();
    const [warehouses, setWarehouses] = useState<WarehouseListing>({
        count: { counted: 0, capped: false },
        data: [],
    });
    const handleError = useErrorHandler();

    const { loadingState, onQueryParamsChange, refetch } =
        warehouseQueryParamsHelper.useQueryParamsChange((queryParams) =>
            listWarehouses(queryParams)
                .then((payload) => {
                    setWarehouses(mapToWarehouseListing(payload));
                })
                .catch((error) => {
                    handleError(error);
                })
        );

    return (
        <warehouseQueryParamsHelper.QueryParamsProvider
            onQueryParamsChange={onQueryParamsChange}
        >
            <OrganizationRunPage
                pageTitle={formatMessage({
                    id: 'organization.users.page.title',
                })}
                size="md"
            >
                <Panel>
                    <Panel.Header
                        title={formatMessage({
                            id: 'organizations.warehouses.listing.header.name.label',
                        })}
                    />
                    <Panel.Content>
                        <Paragraph>
                            {formatMessage({
                                id: 'organizations.warehouses.listing.description',
                            })}
                        </Paragraph>
                    </Panel.Content>
                    <Panel.Content fullWidth>
                        <DatatableProvider>
                            <WarehouseListDatatable
                                loading={loadingState}
                                refetch={refetch}
                                warehouses={warehouses}
                            />
                        </DatatableProvider>
                    </Panel.Content>
                </Panel>
            </OrganizationRunPage>
        </warehouseQueryParamsHelper.QueryParamsProvider>
    );
};
