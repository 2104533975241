import React, { type ReactNode, Suspense, useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Flex,
    Hyperlink,
    LayoutContainer,
    LayoutItem,
    Media,
    Page,
    PageLoader,
    PageTitle,
    Paragraph,
    Skeleton,
    Text,
    Title,
    useErrorHandler,
    useNavigate,
} from '@mirakl/roma';

import useOrganizationContext from '../../OrganizationContext';
import { CurrentPlanDetail } from '../SubscriptionPage';
import useSubscriptionApi, {
    Paywall,
    PaywallType,
} from '../useSubscriptionApi';

import PaywallCard from './components/paywallCard/PaywallCard';
import PaywallCardHeader from './components/paywallCardHeader/PaywallCardHeader';
import PaywallCardItem from './components/paywallCardItem/PaywallCardItem';
import PaywallCardSection from './components/paywallCardSection/PaywallCardSection';

const CenteredTitle = styled(Title)`
    text-align: center;
    text-wrap: balance;
`;

const CenteredText = styled(Paragraph)`
    text-align: center;
    text-wrap: balance;
`;

const CardContainer = styled(Flex).attrs({
    gap: 4,
    padding: 2,
})`
    scroll-snap-type: x mandatory;
    overflow-y: auto;
`;

const CardWrapper = ({ children }: { children: ReactNode }) => (
    <Flex justifyContent="center">
        <CardContainer
            paddingBottom={5}
            paddingHorizontal={{ sm: 5, default: 6 }}
        >
            {children}
        </CardContainer>
    </Flex>
);

interface CardsProps {
    isLegacy: boolean;
    onPlanSelected: (planId: PaywallType, isUpgrading: boolean) => void;
    plans: Paywall;
    subscription: SubscriptionInfos | undefined;
}

const isCurrentPlan = (
    subscription: SubscriptionInfos | undefined,
    plan: string
) => {
    if (!subscription) {
        return false;
    }
    return subscription.currentPlan.type === plan;
};

const canPickPlan = (
    isLegacy: boolean,
    subscription: SubscriptionInfos | undefined,
    plan: string
) => {
    if (isLegacy && plan === 'START') {
        return false;
    }
    if (!subscription) {
        return true;
    }
    const plans = ['START', 'GROWTH', 'PRO', 'BUSINESS'];
    const currentPlanIndex = plans.indexOf(subscription.currentPlan.type);
    const planIndex = plans.indexOf(plan);
    return planIndex > currentPlanIndex;
};

const Cards = ({
    isLegacy,
    onPlanSelected,
    plans,
    subscription,
}: CardsProps) => {
    const { formatMessage } = useI18n();
    const isUpgrading = !!subscription;

    return (
        <CardWrapper>
            <PaywallCard>
                <PaywallCardHeader
                    canPickPlan={canPickPlan(isLegacy, subscription, 'START')}
                    currentPlan={isCurrentPlan(subscription, 'START')}
                    heading={
                        <Text color="grey-500" variant="small-400">
                            {formatMessage({
                                id: 'organization.paywall.start.heading',
                            })}
                        </Text>
                    }
                    isUpgrading={isUpgrading}
                    plan="start"
                    price={formatMessage({
                        id: 'organization.paywall.card.price.free',
                    })}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="basic-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem disabled type="catalog-transformer" />
                    <PaywallCardItem
                        disabled
                        type="multi-channel-customer-care"
                    />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.start.addOns.products.displayPrice}
                        titleVar="50"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.start.addOns.gmv.displayPrice}
                        titleVar="$25"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-limited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem disabled type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    canPickPlan={canPickPlan(isLegacy, subscription, 'GROWTH')}
                    currentPlan={isCurrentPlan(subscription, 'GROWTH')}
                    isUpgrading={isUpgrading}
                    plan="growth"
                    price={plans.growth.displayPrice + '/mo'}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="advanced-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem
                        badge={{ label: 'AI', iconName: 'sparkle' }}
                        type="catalog-transformer"
                    />
                    <PaywallCardItem type="multi-channel-customer-care" />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.growth.addOns.products.displayPrice}
                        titleVar="50"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.growth.addOns.gmv.displayPrice}
                        titleVar="$25"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-unlimited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem disabled type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    canPickPlan={canPickPlan(isLegacy, subscription, 'PRO')}
                    currentPlan={isCurrentPlan(subscription, 'PRO')}
                    isUpgrading={isUpgrading}
                    plan="pro"
                    price={plans.pro.displayPrice + '/mo'}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="advanced-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem
                        badge={{ label: 'AI', iconName: 'sparkle' }}
                        type="catalog-transformer"
                    />
                    <PaywallCardItem type="multi-channel-customer-care" />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.pro.addOns.products.displayPrice}
                        titleVar="150"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.pro.addOns.gmv.displayPrice}
                        titleVar="$150"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-unlimited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    canPickPlan={canPickPlan(
                        isLegacy,
                        subscription,
                        'BUSINESS'
                    )}
                    currentPlan={isCurrentPlan(subscription, 'BUSINESS')}
                    isUpgrading={isUpgrading}
                    plan="business"
                    price={plans.business.displayPrice + '/mo'}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="advanced-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem
                        badge={{ label: 'AI', iconName: 'sparkle' }}
                        type="catalog-transformer"
                    />
                    <PaywallCardItem type="multi-channel-customer-care" />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={
                            plans.business.addOns.products.displayPrice
                        }
                        titleVar="250"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.business.addOns.gmv.displayPrice}
                        titleVar="$400"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-unlimited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    canPickPlan
                    currentPlan={false}
                    isUpgrading={isUpgrading}
                    plan="enterprise"
                    price={formatMessage({
                        id: 'organization.paywall.card.price.custom',
                    })}
                    onPlanSelected={onPlanSelected}
                />
                <Flex alignItems="center" flexDirection="column">
                    <Title tag="h3">
                        {formatMessage({
                            id: 'organization.paywall.enterprise.content.title',
                        })}
                    </Title>
                    <CenteredText>
                        {formatMessage({
                            id: 'organization.paywall.enterprise.content.subtitle',
                        })}
                    </CenteredText>
                    <Media name="work-team" type="illustration" />
                </Flex>
            </PaywallCard>
        </CardWrapper>
    );
};

const extractCallback = (url: string): string => {
    const urlParts = url.split('callback=');
    if (urlParts[1] === undefined) {
        throw new Error('URL param callback not found.');
    }
    return urlParts[1];
};

type SubscriptionInfos = {
    currentPlan: CurrentPlanDetail;
};

const PaywallPage = () => {
    const { formatMessage } = useI18n();
    const [plans, setPlans] = useState<Paywall>();
    const [isLoadingPlans, setIsLoadingPlans] = useState(true);
    const { isLegacySubscription, uuid } = useOrganizationContext();
    const { getPlans, subscribe, updateSubscription } = useSubscriptionApi();
    const navigate = useNavigate();
    const callback = extractCallback(window.location.href);
    const { getSubscription } = useSubscriptionApi();
    const [subscriptionInfos, setSubscriptionInfos] =
        useState<SubscriptionInfos>();
    const handleError = useErrorHandler();
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);

    useEffect(() => {
        getPlans(uuid).then((value) => {
            setPlans(value);
            setIsLoadingPlans(false);
        });
    }, [uuid, getPlans]);
    useEffect(() => {
        getSubscription(uuid)
            .then((data) => {
                if (data !== undefined) {
                    setSubscriptionInfos({
                        currentPlan: {
                            nextBillingDate: data.nextBillingDate,
                            displayPrice: data.displayPrice,
                            type: data.type,
                            endDate: data.endDate,
                        },
                    });
                }
            })
            .catch(handleError)
            .finally(() => setIsLoadingSubscription(false));
    }, [uuid, setIsLoadingSubscription, getSubscription, handleError]);

    return isLoadingPlans || isLoadingSubscription || plans === undefined ? (
        <PageLoader fullPage />
    ) : (
        <Page>
            <PageTitle title="" />
            {/*we do not use PageLayout to allow a horizontal scroll for the cards*/}
            <main>
                <LayoutContainer>
                    <LayoutItem>
                        <Flex
                            alignItems="center"
                            flexDirection="column"
                            paddingHorizontal={6}
                        >
                            <CenteredTitle tag="h1">
                                {formatMessage(
                                    {
                                        id: 'organization.paywall.mainTitle',
                                    },
                                    { br: <br /> }
                                )}
                            </CenteredTitle>
                        </Flex>

                        <Suspense
                            fallback={
                                <Skeleton
                                    a11yLabel={formatMessage({
                                        id: 'organization.loading',
                                    })}
                                >
                                    <CardWrapper>
                                        {Array.from(
                                            { length: 5 },
                                            (_, index) => (
                                                <Box flexShrink={0} key={index}>
                                                    <Skeleton.Item
                                                        height="1100px"
                                                        width="270px"
                                                    />
                                                </Box>
                                            )
                                        )}
                                    </CardWrapper>
                                </Skeleton>
                            }
                        >
                            <Cards
                                isLegacy={isLegacySubscription}
                                plans={plans}
                                subscription={subscriptionInfos}
                                onPlanSelected={(
                                    planType: PaywallType,
                                    isUpgrading: boolean
                                ) => {
                                    setIsLoadingPlans(true);
                                    if (isUpgrading) {
                                        updateSubscription(
                                            uuid,
                                            plans[planType].id,
                                            callback
                                        ).then((response) => {
                                            navigate({
                                                href: response.headers.location,
                                            });
                                        });
                                    } else {
                                        subscribe(
                                            uuid,
                                            plans[planType].id,
                                            callback
                                        )
                                            .then((response) => {
                                                navigate({
                                                    href: response.headers
                                                        .location,
                                                });
                                            })
                                            .catch(handleError);
                                    }
                                }}
                            />
                        </Suspense>
                        <Box paddingHorizontal={{ sm: 5, default: 6 }}>
                            <CenteredText>
                                {formatMessage(
                                    {
                                        id: 'organization.paywall.subtext',
                                    },
                                    {
                                        a: (chunk: string) => (
                                            <Hyperlink
                                                href="https://help.mirakl.net/bundle/sellers/page/topics/Mirakl/account/manage_subscriptions_mirakl_account.htm"
                                                variant="default-500"
                                            >
                                                {chunk}
                                            </Hyperlink>
                                        ),
                                    }
                                )}
                            </CenteredText>
                        </Box>
                    </LayoutItem>
                </LayoutContainer>
            </main>
        </Page>
    );
};

export default PaywallPage;
