import { ReactNode } from 'react';

import { Flex, Text, Title } from '@mirakl/roma';

import useProfileInfoContext from '../../ProfileInfoProvider';

const _ProfileInfoIdentity = ({ children }: { children?: ReactNode }) => {
    const { profileInfo } = useProfileInfoContext();
    const { email, fullName } = profileInfo;

    return (
        <Flex gap={3} justifyContent="space-between">
            <Flex flexDirection="column" overflow="hidden">
                <Title noSpaceBottom tag="h1" truncate>
                    {fullName}
                </Title>
                {fullName !== email && <Text truncate>{email}</Text>}
            </Flex>
            <Flex>{children}</Flex>
        </Flex>
    );
};

export default _ProfileInfoIdentity;
