import React, { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Checkbox,
    EmptyState,
    Panel,
    Stepper,
    useErrorHandler,
    useStepperForm,
} from '@mirakl/roma';

import { StageValues } from '../../../../types/stageTypes';
import ShopListingCheckBoxGroup, {
    OptionGroup,
} from '../../../common/shop/ShopListingCheckBoxGroup';

import useStoreSelectionApi from './linkingStoreApi';

type Shops = {
    alreadyInOrganization: boolean;
    channelNames: string[];
    name: string;
    stage: StageValues;
    state: {
        suspensionType?: string;
        value: string;
    };
    tenantDisplayName: string;
    tenantId: string;
    uuid: string;
};

function StoreSelectionPage({
    tenantId,
    userInformation,
}: {
    tenantId: string;
    userInformation: {
        email: string;
        token: string;
    };
}) {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { fetchShopForEmail } = useStoreSelectionApi();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [shops, setShops] = useState<Shops[]>([]);
    useEffect(() => {
        fetchShopForEmail(userInformation.token)
            .then((shopsPayload) => {
                setShops(
                    shopsPayload.shops.map((shop) => {
                        return {
                            ...shop,
                            tenantDisplayName: shop.tenantName ?? shop.tenantId,
                            channelNames: shop.channels.map(
                                (channel) => channel.text ?? channel.code
                            ),
                        };
                    })
                );
                setIsLoading(false);
            })
            .catch(handleError);
    }, [
        fetchShopForEmail,
        userInformation.token,
        setShops,
        setIsLoading,
        handleError,
    ]);

    const formData = useStepperForm({});

    const chosenTenantOptions = shops.filter((s) => s.tenantId === tenantId);

    const groupOptions: OptionGroup[] = [
        {
            title: chosenTenantOptions[0]
                ? chosenTenantOptions[0].tenantDisplayName
                : tenantId,
            options: chosenTenantOptions,
            token: userInformation.token,
        },
        {
            title: formatMessage({ id: 'organizations.step.link.store.other' }),
            options: shops.filter((s) => s.tenantId !== tenantId),
            token: userInformation.token,
        },
    ];

    if (shops.length === 0 && !isLoading) {
        return (
            <Panel>
                <Panel.Content>
                    <EmptyState
                        illustrationName="empty-shop"
                        illustrationSize="medium"
                        subtitle={formatMessage(
                            {
                                id: 'organizations.step.link.shop.no.store.found.subtitle',
                            },
                            { 0: tenantId, 1: userInformation.email }
                        )}
                        title={formatMessage({
                            id: 'organizations.step.link.shop.no.store.found',
                        })}
                    />
                </Panel.Content>
            </Panel>
        );
    } else {
        return (
            <Stepper.Form {...formData}>
                <Panel loading={isLoading}>
                    <Panel.Content
                        title={formatMessage({
                            id: 'organizations.step.link.store.title',
                        })}
                    >
                        <ShopListingCheckBoxGroup
                            displayAlreadyLinkedShop
                            email={userInformation.email}
                            label={formatMessage(
                                {
                                    id: 'organizations.step.link.store.subtitle',
                                },
                                { 0: userInformation.email }
                            )}
                            name="checkbox-group"
                            optionGroup={groupOptions}
                        />
                    </Panel.Content>
                </Panel>
                <Panel>
                    <Panel.Content>
                        <Checkbox
                            label={formatMessage({
                                id: 'organizations.step.link.store.checkbox.confirmation',
                            })}
                            name="confirm-checkbox"
                            required
                        />
                    </Panel.Content>
                </Panel>
            </Stepper.Form>
        );
    }
}

export default StoreSelectionPage;
