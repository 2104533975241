import { useCallback } from 'react';

import { useNavigate } from '@mirakl/roma';

type RedirectPayload = {
    location: string;
};

const useSignupRedirect = () => {
    const navigate = useNavigate();

    return useCallback(
        (data: RedirectPayload) => {
            if (
                data.location.startsWith('http://') ||
                data.location.startsWith('https://')
            ) {
                navigate({
                    href: data.location,
                    shouldNotShowConfirmModal: true,
                });
            } else {
                navigate({
                    to: data.location,
                    shouldNotShowConfirmModal: true,
                });
            }
        },
        [navigate]
    );
};

export default useSignupRedirect;
