import axios from 'axios';
import { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    ButtonGroup,
    Form,
    Panel,
    Paragraph,
    PasswordField,
    useErrorHandler,
    useForm,
    useNavigate,
} from '@mirakl/roma';

import { useLocaleContext } from '../config/i18n/I18nProvider';
import useQuery from '../router/useQuery';

import PasswordSpecsChecker, {
    PasswordValidityResults,
} from './PasswordSpecsChecker';
import { usePasswordValidation } from './usePasswordValidation';

type PasswordFormData = {
    password: string | undefined;
};

const PasswordForm = () => {
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const { formatMessage } = useI18n();
    const passwordValidation = usePasswordValidation();

    const query = useQuery({
        token: 'string',
    });

    const { setLocale } = useLocaleContext();
    const [email, setEmail] = useState('');
    const [product, setProduct] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [passwordSpecsValidity, setPasswordSpecsValidity] =
        useState<PasswordValidityResults>({
            global: false,
            specs: {
                length: false,
                digit: false,
                lower: false,
                upper: false,
                symbol: false,
            },
        });

    useEffect(() => {
        axios
            .get(`/private/invitation/${query.token}`)
            .then(({ data }) => {
                setEmail(data.email);
                setProduct(data.productName);
                setLoading(false);
                if (data.language) {
                    setLocale(data.language);
                }
            })
            .catch(handleError);
    }, [handleError, query.token, setLocale]);

    const formData = useForm<PasswordFormData>({
        defaultValues: {
            password: undefined,
        },
    });

    const {
        formState: { isDirty, isSubmitting, isValid },
        handleSubmit,
        setError,
    } = formData;

    const onSubmit = (values: PasswordFormData) => {
        return axios
            .post(
                '/private/invitation/setup-password',
                {
                    token: query.token,
                    password: values.password,
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                if (response.status === 200) {
                    navigate({
                        href: response.data.location,
                        shouldNotShowConfirmModal: true,
                    });
                }
            })
            .catch((reason) => {
                if (reason.response.status === 412) {
                    navigate({
                        to: reason.response.data.location,
                        shouldNotShowConfirmModal: true,
                    });
                } else if (reason.response.status === 400) {
                    setError('password', {
                        type: 'custom',
                        message: reason.response.data.message,
                    });
                } else {
                    handleError(reason);
                }
            });
    };

    return (
        <Form {...formData}>
            <Panel loading={isLoading} loadingVariant="form">
                <Panel.Header
                    title={
                        isLoading
                            ? undefined
                            : formatMessage(
                                  {
                                      id: 'user.account.confirmation.signup.to',
                                  },
                                  {
                                      0: formatMessage({
                                          id: `product.${product}`,
                                      }),
                                  }
                              )
                    }
                />
                <Panel.Content title={email}>
                    <Paragraph>
                        {formatMessage({
                            id: 'user.account.confirmation.password.set',
                        })}
                    </Paragraph>
                    <PasswordField
                        label={formatMessage({
                            id: 'user.account.confirmation.password',
                        })}
                        name="password"
                        required
                        validate={(value: string) =>
                            value.length === 0 ||
                            passwordValidation(value).global ||
                            formatMessage({
                                id: 'com.mirakl.domain.validation.passwordNotStrongEnough',
                            })
                        }
                        onChange={(value: string) =>
                            setPasswordSpecsValidity(passwordValidation(value))
                        }
                    />
                    <PasswordSpecsChecker
                        passwordSpecsValidity={passwordSpecsValidity}
                    />
                    <ButtonGroup alignment="end">
                        <Button
                            disabled={!isDirty || !isValid}
                            label={formatMessage({
                                id: 'user.account.confirmation.signup',
                            })}
                            state={isSubmitting ? 'loading' : 'default'}
                            submit
                            onClick={handleSubmit(onSubmit)}
                        />
                    </ButtonGroup>
                </Panel.Content>
            </Panel>
        </Form>
    );
};

export default PasswordForm;
