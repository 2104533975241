import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Datatable,
    DatatableLoadingType,
    DatatablePagination,
    DatatableSeekPaginateDataType,
    DatatableTrailingActionMenuType,
    DateCellContent,
    SimpleSelectionListFilter,
    TextCellContent,
    createHeadingsHelper,
    useModal,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import { UserProfileInfoCellContent } from '../../../../common/datatable/UserProfileInfoCellContent';
import useLoginContext from '../../../../config/login/LoginProvider';
import { useLocalizedCommaJoiner } from '../../../../i18n/useLocalizedCommaJoiner';
import useOperatorAccountContext from '../../../OperatorAccountContext';
import { useOperatorAccountClientApi } from '../../../operatorAccountClientApi';
import { operatorAccountUserQueryParamHelper } from '../operatorAccountUserQueryParamHelper';

import OperatorConfirmUpdateUserRoleModal from './OperatorConfirmUpdateUserRoleModal';

export type OperatorAccountUserType = {
    dateCreated: string;
    id: string;
    lastLogin?: string;
    profile: { email: string; firstName?: string; lastName?: string };
    roles: string[];
};

type OperatorAccountUserListingDatatableProps = {
    loadingState: DatatableLoadingType;
    operatorUsersPage: DatatableSeekPaginateDataType<OperatorAccountUserType>;
    refetch: () => void;
};

const headingsHelper = createHeadingsHelper<OperatorAccountUserType>();

const OperatorAccountUserListingDatatable = ({
    loadingState,
    operatorUsersPage,
    refetch,
}: OperatorAccountUserListingDatatableProps) => {
    const { formatMessage } = useI18n();
    const { showModal } = useModal();

    const { joinWithCommas } = useLocalizedCommaJoiner();
    const { updateUserRole } = useOperatorAccountClientApi();

    const { isAdmin } = useOperatorAccountContext();
    const { email: currentUserEmail, miraklSupport } = useLoginContext();
    const { id: accountId } = useRequiredParams<{ id: string }>();

    const headings = useMemo(() => {
        return [
            headingsHelper.accessor('profile', {
                label: formatMessage({
                    id: 'operator.accounts.users.listing.header.users',
                }),
                sortable: true,
                render: (profile) => (
                    <UserProfileInfoCellContent profileInfo={profile} />
                ),
            }),
            headingsHelper.accessor('roles', {
                label: formatMessage({
                    id: 'operator.accounts.users.listing.header.role',
                }),
                render(roles) {
                    return (
                        <TextCellContent
                            text={joinWithCommas(
                                roles.map((role) =>
                                    formatMessage({ id: `user.type.${role}` })
                                )
                            )}
                        />
                    );
                },
            }),
            headingsHelper.accessor('dateCreated', {
                label: formatMessage({
                    id: 'operator.accounts.users.listing.header.date.creation',
                }),
                initialSortDirection: 'DESC',
                sortable: true,
                render(date) {
                    return <DateCellContent date={DateTime.fromISO(date)} />;
                },
            }),
            headingsHelper.accessor('lastLogin', {
                label: formatMessage({
                    id: 'operator.accounts.users.listing.header.last.login',
                }),
                render(date) {
                    return (
                        <DateCellContent
                            date={date ? DateTime.fromISO(date) : undefined}
                            options={DateTime.DATETIME_SHORT}
                        />
                    );
                },
            }),
        ];
    }, [formatMessage, joinWithCommas]);

    const roleOptions = ['ADMINISTRATOR', 'MEMBER'].map((role) => ({
        label: formatMessage({ id: `user.type.${role}` }),
        value: role,
        id: role,
    }));

    const renderTrailingActions = useCallback(
        ({
            id,
            profile: { email },
            roles,
        }: OperatorAccountUserType): DatatableTrailingActionMenuType<OperatorAccountUserType> => {
            // User cannot edit their own roles
            if (email === currentUserEmail) {
                return {
                    a11yActionButtonLabel: formatMessage({
                        id: 'datatable.user.role.cannot.revoke',
                    }),
                    disabled: true,
                    items: [],
                };
            }

            const operation = roles.includes('ADMINISTRATOR')
                ? 'revoke'
                : 'grant';

            const item = [
                {
                    id: `${operation}-administrator-user`,
                    label: formatMessage({
                        id: `datatable.user.role.${operation}.administrator`,
                    }),
                },
            ];

            return {
                a11yActionButtonLabel: formatMessage({
                    id: `datatable.user.role.${operation}.administrator`,
                }),
                items: item,
                onItemClick: () => {
                    showModal(
                        <OperatorConfirmUpdateUserRoleModal
                            accountId={accountId}
                            operation={operation}
                            refetch={refetch}
                            userEmail={email}
                            userId={id}
                            onConfirm={updateUserRole}
                        />
                    );
                },
            };
        },
        [
            accountId,
            currentUserEmail,
            formatMessage,
            refetch,
            showModal,
            updateUserRole,
        ]
    );

    const hasWriteAccess = isAdmin || miraklSupport.isOperatorSupportReadWrite;

    return (
        <Datatable
            data={operatorUsersPage}
            emptyState={{
                title: formatMessage({
                    id: 'operator.accounts.users.listing.empty.title',
                }),
            }}
            headings={headings}
            id="operator-user-listing-datatable"
            loading={loadingState}
            pagination={<DatatablePagination.Seek />}
            renderTrailingActions={
                hasWriteAccess ? renderTrailingActions : undefined
            }
            resultNumberMessage={formatMessage(
                { id: 'count.result' },
                { 0: operatorUsersPage.count?.counted }
            )}
            toolbar={{
                searchBar: {
                    placeholder: formatMessage({
                        id: 'operator.accounts.users.listing.search.placeholder',
                    }),
                    id: 'search',
                },
                filters: operatorAccountUserQueryParamHelper.createFilters({
                    role: {
                        label: formatMessage({
                            id: 'operator.accounts.users.listing.filters.roles',
                        }),
                        component: (
                            <SimpleSelectionListFilter
                                id="role-filter"
                                options={roleOptions}
                            />
                        ),
                    },
                }),
            }}
        />
    );
};

export default OperatorAccountUserListingDatatable;
