import { useCallback, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Modal, Paragraph, Text, useSnackbar } from '@mirakl/roma';

import useTicketingLicenceErrorHandler from '../utils/useTicketingLicenceErrorHandler';

type LicensedUserType = {
    email: string;
};

const TicketingLicenceRemoveUserModal = ({
    onRemoveLicensedUser,
    user,
}: {
    onRemoveLicensedUser: (user: LicensedUserType) => Promise<void>;
    user: LicensedUserType;
}) => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const handleError = useTicketingLicenceErrorHandler();

    const [
        removeLicensedUserConfirmButtonState,
        setRemoveLicensedUserConfirmButtonState,
    ] = useState<'default' | 'loading' | 'success'>('default');

    const onRemoveLicensedUserConfirmed = useCallback(
        async (close: () => void) => {
            try {
                setRemoveLicensedUserConfirmButtonState('loading');
                await onRemoveLicensedUser(user);
                close();
                addSnackbar({
                    message: formatMessage({
                        id: 'operator.accounts.ticketing.licence.remove.success',
                    }),
                    status: 'success',
                });
            } catch (error) {
                handleError(error);
            } finally {
                setRemoveLicensedUserConfirmButtonState('default');
            }
        },
        [addSnackbar, formatMessage, onRemoveLicensedUser, handleError, user]
    );

    return (
        <Modal size="small">
            <Modal.Header
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.remove.title',
                })}
            />
            <Modal.Content>
                <Paragraph>
                    {formatMessage(
                        {
                            id: 'operator.accounts.ticketing.licence.remove.confirm',
                        },
                        {
                            email: user.email,
                            strong: (chunks: string) => (
                                <Text tag="strong" variant="default-500">
                                    {chunks}
                                </Text>
                            ),
                        }
                    )}
                </Paragraph>
            </Modal.Content>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'button.remove',
                    }),
                    state: removeLicensedUserConfirmButtonState,
                    onClick: onRemoveLicensedUserConfirmed,
                    destructive: true,
                }}
            />
        </Modal>
    );
};

export default TicketingLicenceRemoveUserModal;
