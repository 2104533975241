import { useI18n } from '@mirakl/i18n';
import { MultipleSelectionListFilter } from '@mirakl/roma';

function ProductFilter() {
    const { formatMessage } = useI18n();
    const buildOption = (id: string, value: string) => ({
        id: id,
        label: formatMessage({ id: `product.${value}` }),
        value: value,
    });

    const optionData = [
        { id: 'ads', value: 'ADS' },
        { id: 'connect', value: 'CONNECT' },
        { id: 'invoicing', value: 'INVOICING' },
        { id: 'mmp', value: 'MMP' },
        { id: 'mps', value: 'MPS' },
        { id: 'mcp', value: 'MCP' },
        { id: 'mcm', value: 'MCM' },
        { id: 'payout', value: 'PAYOUT' },
    ];

    return (
        <MultipleSelectionListFilter
            id="product-filter"
            options={optionData.map(({ id, value }) => buildOption(id, value))}
        />
    );
}

export default ProductFilter;
