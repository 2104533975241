import { queryOptions, useQueryClient } from '@tanstack/react-query';

import { SeekPaginationResultType } from '@mirakl/roma';

import useAuthenticatedFetch from '../../../../../fetch/useAuthenticatedFetch';

type LicensedUsersPayloadType = {
    users: {
        email: string;
        firstName?: string;
        lastName?: string;
    }[];
};

type LicenceLimitPayloadType = {
    limit: number;
};

type UnlicensedUserPayloadType = {
    email: string;
};

type AddLicensedUserPayloadType = {
    email: string;
};

type RemoveLicensedUserPayloadType = {
    email: string;
};

const useTicketingLicenceApi = (operatorAccountId: string) => {
    const { apiDelete, apiGet, apiPost, apiPut } = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    const fetchLicensedUsersQuery = () =>
        queryOptions({
            queryKey: [
                'operators',
                operatorAccountId,
                'ticketing',
                'licence',
                'users',
            ],
            queryFn: ({ signal }) =>
                apiGet<LicensedUsersPayloadType>(
                    `/private/operators/${operatorAccountId}/ticketing/licences/users/licensed`,
                    { signal }
                ).then(({ data }) => data.users),
        });

    const searchUnlicensedUsers = async (params: {
        limit: number;
        pageToken?: string;
        search: string;
    }) => {
        const { data } = await apiGet<
            SeekPaginationResultType<UnlicensedUserPayloadType>
        >(
            `/private/operators/${operatorAccountId}/ticketing/licences/users/unlicensed`,
            {
                params,
            }
        );
        return data;
    };

    const addLicensedUser = async (user: { email: string }) => {
        const {
            data: { users },
        } = await apiPost<AddLicensedUserPayloadType, LicensedUsersPayloadType>(
            `/private/operators/${operatorAccountId}/ticketing/licences/users/licensed`,
            {
                email: user.email,
            }
        );
        queryClient.setQueryData(
            ['operators', operatorAccountId, 'ticketing', 'licence', 'users'],
            users
        );
    };

    const removeLicensedUser = async (user: { email: string }) => {
        const {
            data: { users },
        } = await apiDelete<RemoveLicensedUserPayloadType>(
            `/private/operators/${operatorAccountId}/ticketing/licences/users/licensed`,
            {
                email: user.email,
            }
        );
        queryClient.setQueryData(
            ['operators', operatorAccountId, 'ticketing', 'licence', 'users'],
            users
        );
    };

    const fetchLicenceLimitQuery = () =>
        queryOptions({
            queryKey: [
                'operators',
                operatorAccountId,
                'ticketing',
                'licence',
                'limit',
            ],
            queryFn: ({ signal }) =>
                apiGet<LicenceLimitPayloadType>(
                    `/private/operators/${operatorAccountId}/ticketing/licences/limit`,
                    { signal }
                ).then(({ data }) => data.limit),
        });

    const updateLicenceLimit = (limit: number) =>
        apiPut<LicenceLimitPayloadType>(
            `/private/operators/${operatorAccountId}/ticketing/licences/limit`,
            { limit }
        ).then(() =>
            queryClient.setQueryData(
                [
                    'operators',
                    operatorAccountId,
                    'ticketing',
                    'licence',
                    'limit',
                ],
                limit
            )
        );

    return {
        fetchLicensedUsersQuery,
        searchUnlicensedUsers,
        fetchLicenceLimitQuery,
        updateLicenceLimit,
        addLicensedUser,
        removeLicensedUser,
    };
};

export default useTicketingLicenceApi;
