import { ReactNode } from 'react';

import { useI18n } from '@mirakl/i18n';
import { ErrorBoundary, PageError } from '@mirakl/roma';

import { isTicketingLicenceError } from '../utils/ticketingLicenceError';

const TicketingLicenceErrorPage = ({ error }: { error: Error }) => {
    const { formatMessage } = useI18n();

    if (isTicketingLicenceError(error)) {
        const { code } = error.response.data;
        const message = formatMessage({
            id: `operator.accounts.ticketing.licence.error.${code}.message`,
        });
        const action = formatMessage({
            id: `operator.accounts.ticketing.licence.error.${code}.action`,
        });
        switch (code) {
            case 'TICKETING_LICENCE_ZENDESK_INTEGRATION':
                return (
                    <PageError
                        illustrationName="empty-data-1"
                        subtitle={action}
                        title={message}
                    />
                );
            case 'TICKETING_LICENCE_RATE_LIMIT_REACHED':
                return (
                    <PageError
                        illustrationName="error-429"
                        subtitle={action}
                        title={message}
                    />
                );
            default:
                break;
        }
    }

    throw error; // delegate to the parent error boundary
};

const TicketingLicenceErrorBoundary = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <ErrorBoundary FallbackComponent={TicketingLicenceErrorPage}>
            {children}
        </ErrorBoundary>
    );
};

export default TicketingLicenceErrorBoundary;
