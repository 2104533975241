import { useMemo } from 'react';

type ProfileInfoType = {
    email: string;
};

const profileInfoColors = [
    'blue-light',
    'blue-regular',
    'blue-dark',
    'red-light',
    'red-regular',
    'red-dark',
    'green-light',
    'green-regular',
    'green-dark',
    'purple-light',
    'purple-regular',
    'purple-dark',
    'yellow-light',
    'yellow-regular',
    'yellow-dark',
] as const;

export type ProfileInfoColorType = (typeof profileInfoColors)[number];

/**
 * Implementation of djb2 hash algorithm
 */
const hashString = (str: string) => {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) + hash + char; // hash * 33 + char
    }
    return hash >>> 0; // Ensure the hash is a positive number
};

const useProfileInfoColor = ({
    email,
}: ProfileInfoType): ProfileInfoColorType => {
    return useMemo(() => {
        const hash = hashString(email);
        return profileInfoColors[hash % profileInfoColors.length]!;
    }, [email]);
};

export default useProfileInfoColor;
