import React from 'react';

import { useI18n } from '@mirakl/i18n';
import { Paragraph } from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import { StageValues } from '../../../types/stageTypes';
import ShopListingCheckBoxGroup, {
    OptionGroup,
} from '../../common/shop/ShopListingCheckBoxGroup';
import { ShopWithToken } from '../../common/shop/useUserShopsForLink';

import { LINKED_SHOPS_FIELD_NAME } from './LinkShopsFields';

export type ShopType = {
    alreadyInOrganization: boolean;
    channelNames: string[];
    name: string;
    stage: StageValues;
    state: {
        suspensionType?: string;
        value: string;
    };
    tenantDisplayName: string;
    tenantId: string;
    tenantName?: string;
    uuid: string;
};

type LinkShopsFieldProps = {
    hasAnyAvailableShop?: boolean;
    isEdition?: boolean;
    shopsByUser: Record<string, ShopWithToken>;
    tenantByUser: Record<string, string>;
};

const LinkShopsField = ({ shopsByUser, tenantByUser }: LinkShopsFieldProps) => {
    const { formatMessage } = useI18n();
    const { email: connectedUserEmail } = useLoginContext();
    const availableShops =
        shopsByUser[connectedUserEmail]?.shops.filter(
            (shop) => !shop.alreadyInOrganization
        ) || [];

    const groupOptions: OptionGroup[] = [
        {
            options: Object.entries(shopsByUser)
                .filter(
                    ([email, shopWithToken]) =>
                        shopWithToken.shops.length > 0 &&
                        connectedUserEmail === email
                )
                .flatMap(([, shopWithToken]) =>
                    shopWithToken.shops.filter(
                        (shop) => !shop.alreadyInOrganization
                    )
                ),
        },
    ];

    return (
        <>
            <Paragraph>
                {formatMessage(
                    { id: 'signup.organization.listing.shops.subtitle' },
                    {
                        shopCount: availableShops.length,
                        email: connectedUserEmail,
                        b: (chunks: string) => <b>{chunks}</b>,
                    }
                )}
            </Paragraph>
            {availableShops.length > 0 && (
                <ShopListingCheckBoxGroup
                    email={connectedUserEmail}
                    label={formatMessage(
                        {
                            id: 'organizations.step.link.store.subtitle',
                        },
                        { 0: connectedUserEmail }
                    )}
                    name={LINKED_SHOPS_FIELD_NAME}
                    optionGroup={groupOptions}
                />
            )}

            {Object.entries(shopsByUser)
                .filter(
                    ([email, shopWithToken]) =>
                        shopWithToken.shops?.length > 0 &&
                        connectedUserEmail !== email &&
                        tenantByUser[email]
                )
                .map(([email, shopWithToken], index) => {
                    const filteredShopsForTenant = shopWithToken.shops.filter(
                        (shop) => shop.tenantId === tenantByUser[email]
                    );
                    return (
                        <ShopListingCheckBoxGroup
                            email={email}
                            key={`${email}-${index}`}
                            label={formatMessage(
                                {
                                    id: 'organizations.step.link.store.subtitle',
                                },
                                { 0: email }
                            )}
                            name={LINKED_SHOPS_FIELD_NAME}
                            optionGroup={[
                                {
                                    title: filteredShopsForTenant[0]
                                        ? filteredShopsForTenant[0]
                                              .tenantDisplayName
                                        : tenantByUser[email],
                                    options: filteredShopsForTenant,
                                    token: shopWithToken.verificationToken,
                                },
                                {
                                    title: formatMessage({
                                        id: 'organizations.step.link.store.other',
                                    }),
                                    options: shopWithToken.shops.filter(
                                        (s) =>
                                            s.tenantId !== tenantByUser[email]
                                    ),
                                    token: shopWithToken.verificationToken,
                                },
                            ]}
                        />
                    );
                })}
        </>
    );
};

export default LinkShopsField;
