import { ReactNode, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { GlobalLayout, GlobalLayoutSidebarMenuItemType } from '@mirakl/roma';

import useProfileInfoContext from '../information/ProfileInfoProvider';

function ProfileGlobalLayout({ children }: { children: ReactNode }) {
    const { formatMessage } = useI18n();
    const {
        profileInfo: { color, email, fullName, initials },
    } = useProfileInfoContext();

    const menuItems = useMemo<GlobalLayoutSidebarMenuItemType[]>(
        () => [
            {
                id: 'menu-item-information',
                label: formatMessage({
                    id: 'user.profile.information',
                }),
                iconName: 'person',
                to: '/profile/information',
            },
            {
                id: 'menu-item-security',
                label: formatMessage({
                    id: 'user.profile.security.password',
                }),
                iconName: 'lock',
                to: '/profile/security',
            },
        ],
        [formatMessage]
    );

    return (
        <GlobalLayout
            sidebar={{
                menuItems: menuItems,
                title: fullName,
                subtitle: email !== fullName ? email : undefined,
                media: {
                    color,
                    initials,
                    type: 'avatar',
                },
            }}
            topBar={{
                buttons: undefined, // Nothing in the topBar
            }}
        >
            {children}
        </GlobalLayout>
    );
}

export default ProfileGlobalLayout;
