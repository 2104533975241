import React, { ReactNode } from 'react';

import { useI18n } from '@mirakl/i18n';
import { CheckboxGroup, Flex, Text, Tooltip } from '@mirakl/roma';

import { ShopType } from '../../create/form/LinkShopsField';

import ShopStateComponent from './ShopStateComponent';

export type OptionGroup = {
    options: ShopType[];
    title?: string;
    token?: string;
};
type Option = {
    disabled: boolean;
    id: string;
    text: ReactNode;
    value: OptionValue;
};

type OptionValue = {
    email: string;
    shopUuid: string;
    verificationToken?: string;
};

function ShopListingCheckBoxGroup({
    displayAlreadyLinkedShop = false,
    email,
    label,
    name,
    optionGroup,
}: {
    displayAlreadyLinkedShop?: boolean;
    email: string;
    label: string;
    name: string;
    optionGroup: OptionGroup[];
}) {
    const { formatMessage } = useI18n();

    const mapShopToOption = (shop: ShopType, token?: string): Option => {
        const tenantDisplayName = shop.tenantName ?? shop.tenantId;
        return {
            id: shop.uuid,
            value: {
                email: email,
                shopUuid: shop.uuid,
                verificationToken: token,
            },
            disabled: shop.alreadyInOrganization,
            text: (
                <Flex alignItems="start" flexDirection="column">
                    <Text variant="default-500">
                        {tenantDisplayName + ' - ' + shop.name}{' '}
                        <ShopStateComponent
                            shopState={shop.state}
                            size="small"
                        />
                    </Text>
                    <Text variant="default-400">
                        {shop.channelNames.join(', ')}
                    </Text>
                </Flex>
            ),
        };
    };

    const sortByLinkedOrTenantOrShopName = (a: ShopType, b: ShopType) => {
        return (
            Number(a.alreadyInOrganization) - Number(b.alreadyInOrganization) ||
            a.tenantDisplayName.localeCompare(b.tenantDisplayName) ||
            a.name.localeCompare(b.name)
        );
    };

    const defaultValues = optionGroup[0]
        ? optionGroup[0].options
              .filter((s) => !s.alreadyInOrganization)
              .map((value) => ({
                  email: email,
                  shopUuid: value.uuid,
                  verificationToken: optionGroup[0]?.token,
              }))
        : [];

    return (
        <CheckboxGroup
            defaultValue={defaultValues}
            isOptionSelected={(option: OptionValue, values: OptionValue[]) =>
                values.some((value) => value.shopUuid === option.shopUuid)
            }
            label={label}
            name={name}
            required
        >
            {optionGroup.map(
                (optGrp, index) =>
                    optGrp.options.length > 0 && (
                        <CheckboxGroup.Group
                            column={1}
                            key={`${email}-${index}`}
                            title={optGrp.title ? optGrp.title : ''}
                        >
                            {optGrp.options
                                .filter((shop) =>
                                    displayAlreadyLinkedShop
                                        ? true
                                        : !shop.alreadyInOrganization
                                )
                                .sort(sortByLinkedOrTenantOrShopName)
                                .map((shop) =>
                                    mapShopToOption(shop, optGrp.token)
                                )
                                .map((opt) => (
                                    <CheckboxGroup.Slot
                                        disabled={opt.disabled}
                                        key={opt.id}
                                        value={opt.value}
                                    >
                                        {opt.disabled ? (
                                            <Tooltip
                                                breakOutOfContainer
                                                content={formatMessage({
                                                    id: 'organizations.step.link.shop.already.linked',
                                                })}
                                                fullWidth
                                                placement="top-start"
                                                trigger={opt.text}
                                            />
                                        ) : (
                                            opt.text
                                        )}
                                    </CheckboxGroup.Slot>
                                ))}
                        </CheckboxGroup.Group>
                    )
            )}
        </CheckboxGroup>
    );
}

export default ShopListingCheckBoxGroup;
