import { useI18n } from '@mirakl/i18n';
import { BadgeStatusType, IconNameType, StatusCellContent } from '@mirakl/roma';

import { SmtpStatus } from '../../OperatorSmtpClientModelApi';

type OperatorSmtpValidationCellContentProps = {
    status: SmtpStatus;
};

const cellData: (
    operatorSmtpStatus: SmtpStatus,
    formatMessage: ReturnType<typeof useI18n>['formatMessage']
) => {
    iconName?: IconNameType;
    label: string;
    status: BadgeStatusType;
    tooltipText?: string;
} = (operatorSmtpStatus, formatMessage) => {
    switch (operatorSmtpStatus) {
        case 'CONFIGURED':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.CONFIGURED.label',
                }),
                status: 'success',
                tooltipText: formatMessage({
                    id: 'operator.smtp.status.CONFIGURED.tooltip',
                }),
            };
        case 'TO_VERIFY':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.TO_VERIFY.label',
                }),
                status: 'pending',
            };
        case 'VERIFIED':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.VERIFIED.label',
                }),
                status: 'success',
            };
        case 'DISABLED':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.DISABLED.label',
                }),
                tooltipText: formatMessage({
                    id: 'operator.smtp.status.DISABLED.tooltip',
                }),
                status: 'warning',
            };
        case 'ERROR':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.ERROR.label',
                }),
                status: 'error',
            };
    }
};

const OperatorSmtpValidationCellContent = ({
    status,
}: OperatorSmtpValidationCellContentProps) => {
    const { formatMessage } = useI18n();

    return <StatusCellContent {...cellData(status, formatMessage)} />;
};

export default OperatorSmtpValidationCellContent;
