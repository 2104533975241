import { FormattedMessage } from 'react-intl';

import { useI18n } from '@mirakl/i18n';
import {
    Hyperlink,
    Panel,
    Paragraph,
    RadioGroup,
    Stepper,
    TextField,
    useForm,
    useStepperValues,
} from '@mirakl/roma';

import { ServerStepFormType } from '../../OperatorSmtpClientModelApi';
import { useOperatorSmtpClientApi } from '../../operatorSmtpClientApi';

import Summary from './Summary';

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 100;

type ServerStepProps = {
    operatorAccountId: string;
};

const ServerStep = ({ operatorAccountId }: ServerStepProps) => {
    const { formatMessage } = useI18n();

    const {
        createOperatorSmtpValidation: { validateServerStep },
    } = useOperatorSmtpClientApi();

    // Used for when user goes back to this step
    const savedValues = useStepperValues<ServerStepFormType>();
    const formData = useForm<ServerStepFormType>({
        defaultValues: {
            name: savedValues.name ?? '',
            type: savedValues.type ?? 'SELF_MANAGED',
        },
    });

    return (
        <Stepper.FormWithSummary
            {...formData}
            renderSummary={(values) => <Summary {...values} />}
            onSubmit={(values) =>
                validateServerStep(operatorAccountId, values.name)
            }
        >
            <Panel>
                <Panel.Header
                    title={formatMessage({
                        id: 'operator.smtp.create.server.title',
                    })}
                />

                <Panel.Content>
                    <Paragraph>
                        <FormattedMessage
                            id="operator.smtp.configuration.description"
                            values={{
                                a(chunk) {
                                    // TODO ACCOUNT-1167: add the correct link
                                    return (
                                        <Hyperlink
                                            href="https://example.com"
                                            target="_blank"
                                        >
                                            {chunk}
                                        </Hyperlink>
                                    );
                                },
                            }}
                        />
                    </Paragraph>
                </Panel.Content>
                <Panel.Content>
                    <TextField
                        label={formatMessage({
                            id: 'operator.smtp.field.name',
                        })}
                        maxLength={MAX_NAME_LENGTH}
                        minLength={MIN_NAME_LENGTH}
                        name="name"
                        required
                    />
                    <RadioGroup
                        column={1}
                        helpText={formatMessage({
                            id: 'operator.smtp.field.type.description',
                        })}
                        label={formatMessage({
                            id: 'operator.smtp.field.type',
                        })}
                        name="type"
                    >
                        <RadioGroup.Card
                            helpText={formatMessage({
                                id: 'operator.smtp.field.type.SELF_MANAGED.description',
                            })}
                            label={formatMessage({
                                id: 'operator.smtp.field.type.SELF_MANAGED',
                            })}
                            value="SELF_MANAGED"
                        />
                        <RadioGroup.Card
                            helpText={formatMessage({
                                id: 'operator.smtp.field.type.MIRAKL_SMTP.description',
                            })}
                            label={formatMessage({
                                id: 'operator.smtp.field.type.MIRAKL_SMTP',
                            })}
                            value="MIRAKL_SMTP"
                        />
                    </RadioGroup>
                </Panel.Content>
            </Panel>
        </Stepper.FormWithSummary>
    );
};

export default ServerStep;
