import { useI18n } from '@mirakl/i18n';
import {
    Button,
    EmailField,
    Flex,
    Form,
    Paragraph,
    useForm,
} from '@mirakl/roma';

import useErrorToast from '../../error/useErrorToast';

type SignupEmailContentProps = {
    onSubmit: (email: string) => Promise<void>;
    product: string;
    signupHint?: string;
};

const SignupEmailContent = ({
    onSubmit,
    product,
    signupHint,
}: SignupEmailContentProps) => {
    const showErrorToast = useErrorToast();
    const { formatMessage } = useI18n();
    const submit = (email: string) =>
        onSubmit(email).catch((error) => showErrorToast(error, [400]));

    const formData = useForm({
        defaultValues: {
            email: signupHint ?? '',
        },
    });
    const {
        formState: { isSubmitting, isValid },
        handleSubmit,
    } = formData;

    return (
        <Form {...formData}>
            {!signupHint && (
                <Paragraph>
                    {formatMessage(
                        { id: 'signup.subtitle' },
                        {
                            product: product,
                        }
                    )}
                </Paragraph>
            )}
            <EmailField
                disabled={!!signupHint}
                label={formatMessage({ id: 'signup.email.label' })}
                name="email"
                required
            />
            <Flex justifyContent="end">
                <Button
                    data-testid="signup-email-submit-button"
                    disabled={!isValid}
                    label={formatMessage({ id: 'signup.continue' })}
                    state={isSubmitting ? 'loading' : 'default'}
                    submit
                    onClick={handleSubmit(({ email }) => submit(email))}
                />
            </Flex>
        </Form>
    );
};

export default SignupEmailContent;
