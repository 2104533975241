import {
    Box,
    Flex,
    LayoutContainer,
    LayoutItem,
    Page,
    PageLayout,
} from '@mirakl/roma';

import PasswordForm from './PasswordForm';

const InvitationSetupPassword = () => {
    return (
        <Page fullPage>
            <PageLayout>
                <LayoutContainer fullHeight>
                    <LayoutItem>
                        <Flex center style={{ height: '100%', width: '100%' }}>
                            <Box style={{ width: 400 }}>
                                <PasswordForm />
                            </Box>
                        </Flex>
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </Page>
    );
};

export default InvitationSetupPassword;
