import { useI18n } from '@mirakl/i18n';
import {
    Form,
    Modal,
    TextField,
    UploadField,
    useForm,
    useSnackbar,
} from '@mirakl/roma';

type CompanyInformationEditFormType = {
    id: string;
    logo: File[];
    name: string;
};

export type LogoConfigType = {
    allowedContentTypes: string[];
    maxHeightPx: number;
    maxSizeBytes: number;
    maxWidthPx: number;
};

type CompanyInformationEditModalProps = {
    defaultValues: CompanyInformationEditFormType;
    isOperatorSupportUser: boolean;
    logoConfig: LogoConfigType;
    reloadContext: () => void;
    updateCompanyInformation: (
        id: string,
        values: CompanyInformationEditFormType,
        shouldUpdateLogo: boolean
    ) => Promise<void>;
};

const getImageDimensions = (
    file: File
): Promise<{
    height: number;
    width: number;
}> => {
    const fileUrl = URL.createObjectURL(file);
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            const imageDimensions = { width: img.width, height: img.height };
            resolve(imageDimensions);
        };
        img.src = fileUrl;
    });
};

const CompanyInformationEditModal = ({
    defaultValues,
    isOperatorSupportUser,
    logoConfig,
    reloadContext,
    updateCompanyInformation,
}: CompanyInformationEditModalProps) => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();

    const formData = useForm<CompanyInformationEditFormType>({
        defaultValues: defaultValues,
    });

    const {
        formState: { isSubmitting, touchedFields },
        handleSubmit,
    } = formData;

    const onValid =
        (close: () => void) => (values: CompanyInformationEditFormType) => {
            const isLogoDirty = touchedFields.logo;
            return updateCompanyInformation(
                values.id,
                values,
                !!isLogoDirty
            ).then(() => {
                addSnackbar({
                    message: formatMessage({ id: 'snackbar.edit.success' }),
                    status: 'success',
                });
                reloadContext();
                close();
            });
        };

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'operator.accounts.detail.company.edit.modal.title',
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Form {...formData}>
                        <TextField
                            label={formatMessage({
                                id: 'operator.accounts.detail.company.name',
                            })}
                            maxLength={100}
                            minLength={2}
                            name="name"
                            required
                        />
                        <UploadField
                            accept={logoConfig.allowedContentTypes.join(',')}
                            disabled={isOperatorSupportUser}
                            fileMaxSize={logoConfig.maxSizeBytes}
                            label={formatMessage({
                                id: 'operator.accounts.detail.company.logo',
                            })}
                            name="logo"
                            noDropZone
                            validate={async (value) => {
                                const file = value[0];
                                if (!file) {
                                    return true;
                                }
                                const imageDimensions =
                                    await getImageDimensions(file);
                                if (
                                    imageDimensions.width >
                                        logoConfig.maxWidthPx ||
                                    imageDimensions.height >
                                        logoConfig.maxHeightPx
                                ) {
                                    return formatMessage(
                                        {
                                            id: 'operator.accounts.detail.company.logo.error.dimension',
                                        },
                                        {
                                            0: logoConfig.maxWidthPx,
                                            1: logoConfig.maxHeightPx,
                                        }
                                    );
                                }
                                return true;
                            }}
                        />
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({ id: 'button.save' }),
                    state: isSubmitting ? 'loading' : 'default',
                    onClick: (close, e) => handleSubmit(onValid(close))(e),
                }}
            />
        </Modal>
    );
};

export default CompanyInformationEditModal;
