import { ReactNode } from 'react';

import { Flex } from '@mirakl/roma';

const _ProfileInfoItemList = ({ children }: { children: ReactNode }) => {
    return (
        <Flex flexDirection="column" gap={2} paddingBottom={4}>
            {children}
        </Flex>
    );
};

export default _ProfileInfoItemList;
