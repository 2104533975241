import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { OnboardingStepper, useNavigate } from '@mirakl/roma';

import { Step } from '../../../types/romaTypes';
import useUserShopsForLink from '../../common/shop/useUserShopsForLink';

import { ShopType } from './LinkShopsField';
import LinkShopsStep, { LinkShopsStepFormType } from './LinkShopsStep';
import OrganizationBeforeStepper from './OrganizationBeforeStepper';
import OrganizationStep, { OrganizationStepFormType } from './OrganizationStep';

export type CreateOrganizationFormType = OrganizationStepFormType &
    LinkShopsStepFormType;

type CreateOrganizationStepperProps = {
    backUrl: 'optionalString';
    connectedUserShops: ShopType[];
    createOrganization: (
        data: CreateOrganizationFormType
    ) => Promise<string | void>;
    productId: string;
    skipIntro: 'optionalBoolean';
    successUrl: string;
};

const CreateOrganizationStepper = ({
    backUrl,
    connectedUserShops,
    createOrganization,
    productId,
    skipIntro,
    successUrl,
}: CreateOrganizationStepperProps) => {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();

    /* Note: we need to call this hook at this level (or higher) so that the state is not erased when the stepper
    unmounts its steps. This lets the user keep shops added after a backend error occurred in the form for example. */
    const userShopsForLink = useUserShopsForLink(connectedUserShops);

    const formSteps = useMemo(() => {
        const commonStep: Pick<Step, 'media' | 'illustration'> = {
            media: {
                url:
                    '/static/images/' +
                    formatMessage({
                        id: `product.${productId}.logo`,
                    }),
                alt: formatMessage({
                    id: `product.${productId}.logo.alt`,
                }),
                type: 'image',
            },
            illustration: 'onboarding-stepper',
        };

        const steps = [
            {
                ...commonStep,
                content: (
                    <OrganizationStep
                        backUrl={backUrl}
                        browserNavigate={navigate}
                    />
                ),
                title: formatMessage({
                    id: 'organization.stepper.organization.step.title',
                }),
            },
        ];

        // When we are coming from the organization chooser for instance.
        if (!skipIntro) {
            steps.unshift({
                ...commonStep,
                content: <OrganizationBeforeStepper />,
                title: formatMessage(
                    {
                        id: 'organization.stepper.organization.step.before.title',
                    },
                    { 0: formatMessage({ id: `product.${productId}` }) }
                ),
            });
        }

        if (connectedUserShops.some((shop) => !shop.alreadyInOrganization)) {
            steps.push({
                ...commonStep,
                title: formatMessage({
                    id: 'organization.stepper.stores.linking.step.title',
                }),
                content: <LinkShopsStep userShopsForLink={userShopsForLink} />,
            });
        }

        return steps;
    }, [
        backUrl,
        skipIntro,
        connectedUserShops,
        userShopsForLink,
        productId,
        formatMessage,
        navigate,
    ]);

    return (
        <OnboardingStepper<CreateOrganizationFormType>
            buttonLabels={{
                last: formatMessage({
                    id: 'organization.stepper.button.create.label',
                }),
                next: formatMessage({
                    id: 'button.continue',
                }),
                previous: formatMessage({
                    id: 'button.back',
                }),
            }}
            steps={formSteps}
            successUrl={(callback) => {
                navigate({
                    to: successUrl,
                    state: {
                        callback,
                    },
                });
            }}
            onGlobalSubmit={createOrganization}
        />
    );
};

export default CreateOrganizationStepper;
