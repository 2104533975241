import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { useI18n } from '@mirakl/i18n';
import {
    Flex,
    Hyperlink,
    LayoutContainer,
    PageLayout,
    Panel,
    Paragraph,
    SpacingWrapper,
    Text,
    Title,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import { useOperatorSmtpClientApi } from '../../operatorSmtpClientApi';

import SmtpDetailsEmailAuthenticationPanel from './SmtpDetailsEmailAuthenticationPanel';

const FieldInfo = ({ nameKey, value }: { nameKey: string; value?: string }) => {
    const printedValue = (str?: string) => {
        if (str && str.length > 0) {
            return str;
        }
        return '-';
    };

    return (
        <SpacingWrapper>
            <Flex flexDirection="column">
                <Text variant="compact-500">
                    <FormattedMessage id={nameKey} />
                </Text>
                <Text>{printedValue(value)}</Text>
            </Flex>
        </SpacingWrapper>
    );
};

const StringInfo = ({
    nameKey,
    value,
}: {
    nameKey: string;
    value?: string;
}) => {
    return <FieldInfo nameKey={nameKey} value={value} />;
};

const BooleanInfo = ({
    nameKey,
    value,
}: {
    nameKey: string;
    value?: boolean;
}) => {
    const { formatMessage } = useI18n();
    return (
        <FieldInfo
            nameKey={nameKey}
            value={
                value
                    ? formatMessage({
                          id: 'generic.yes',
                      })
                    : formatMessage({
                          id: 'generic.no',
                      })
            }
        />
    );
};

const TypeInfo = ({ nameKey, value }: { nameKey: string; value: string }) => {
    const { formatMessage } = useI18n();
    return (
        <FieldInfo
            nameKey={nameKey}
            value={formatMessage({
                id: `operator.smtp.field.type.${value}`,
            })}
        />
    );
};

const SmtpDetailsLoadedPanel = () => {
    const { getOperatorSmtpDetails } = useOperatorSmtpClientApi();

    const { id: operatorAccountId, smtpId } = useRequiredParams<{
        id: string;
        smtpId: string;
    }>();

    const { data } = useSuspenseQuery(
        getOperatorSmtpDetails(operatorAccountId, smtpId)
    );

    // Get the domain including the `@`
    const domain = data.senderEmail.substring(data.senderEmail.indexOf('@'));

    const isSelfManaged = data.type === 'SELF_MANAGED';

    return (
        <PageLayout size="sm">
            <LayoutContainer>
                <Panel noSpaceBottom>
                    <Panel.Content>
                        <Paragraph>
                            <FormattedMessage
                                id="operator.smtp.details.description"
                                values={{
                                    a(chunk) {
                                        // TODO ACCOUNT-1167: add the correct link
                                        return (
                                            <Hyperlink
                                                href="https://example.com"
                                                target="_blank"
                                            >
                                                {chunk}
                                            </Hyperlink>
                                        );
                                    },
                                }}
                            />
                        </Paragraph>
                    </Panel.Content>
                    <Panel.Separator fullWidth />
                    <Panel.Content>
                        <Title tag="h3">
                            <FormattedMessage id="operator.smtp.details.summary" />
                        </Title>
                        <StringInfo
                            nameKey="operator.smtp.field.name"
                            value={data.name}
                        />
                        <TypeInfo
                            nameKey="operator.smtp.field.type"
                            value={data.type}
                        />
                        <StringInfo
                            nameKey="operator.smtp.field.sender_name"
                            value={data.senderName}
                        />
                        <StringInfo
                            nameKey="operator.smtp.field.sender_email"
                            value={data.senderEmail}
                        />
                        <StringInfo
                            nameKey="operator.smtp.field.xvar_header_prefix"
                            value={data.xvarHeaderPrefix}
                        />

                        {isSelfManaged && (
                            <>
                                <StringInfo
                                    nameKey="operator.smtp.field.host"
                                    value={data.host}
                                />
                                <StringInfo
                                    nameKey="operator.smtp.field.port"
                                    value={data.port?.toString()}
                                />
                                <StringInfo
                                    nameKey="operator.smtp.field.username"
                                    value={data.username}
                                />
                                <BooleanInfo
                                    nameKey="operator.smtp.field.start_tls"
                                    value={data.startTls}
                                />
                            </>
                        )}
                    </Panel.Content>
                </Panel>
                {!isSelfManaged && data.dnsSmtpAuthenticationRecords && (
                    <SmtpDetailsEmailAuthenticationPanel
                        dnsSmtpAuthenticationRecords={
                            data.dnsSmtpAuthenticationRecords
                        }
                        domain={domain}
                    />
                )}
            </LayoutContainer>
        </PageLayout>
    );
};

const SmtpDetailsLoadingPanel = () => {
    return (
        <PageLayout size="sm">
            <Panel loading />
        </PageLayout>
    );
};

const SmtpDetailsPanel = () => {
    return (
        <Suspense fallback={<SmtpDetailsLoadingPanel />}>
            <SmtpDetailsLoadedPanel />
        </Suspense>
    );
};

export default SmtpDetailsPanel;
