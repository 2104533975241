import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { OnboardingStepper, Paragraph, useStepperForm } from '@mirakl/roma';

import { useLocaleContext } from '../../../config/i18n/I18nProvider';
import { useDefaultLocale } from '../../../config/i18n/useDefaultLocale';
import ProfileInfoLanguageField from '../../../profile/information/components/fields/ProfileInfoLanguageField';
import ProfileInfoNameField from '../../../profile/information/components/fields/ProfileInfoNameField';
import ProfileInfoTimeZoneField from '../../../profile/information/components/fields/ProfileInfoTimeZoneField';

type InvitationType = {
    language: string;
    productName: string;
};

type LanguageType = {
    label: string;
    locale: string;
};

type ProfileInformationStepProps = {
    invitation: InvitationType;
    languages: LanguageType[];
    timeZones: string[];
};

const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ProfileInformationStep = ({
    invitation,
    languages,
    timeZones,
}: ProfileInformationStepProps) => {
    const { formatMessage } = useI18n();
    const { setLocale } = useLocaleContext();
    const defaultLocale = useDefaultLocale();

    const defaultLanguage = useMemo(
        () =>
            languages.find(
                (language) => language.locale === invitation.language
            )?.locale ?? defaultLocale,
        [invitation, languages, defaultLocale]
    );

    const formData = useStepperForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            language: defaultLanguage,
            timeZone: defaultTimeZone,
        },
    });

    const descriptionId =
        invitation.productName === 'MMP'
            ? 'user.profile.confirmation.MMP.description'
            : 'user.profile.confirmation.description';

    // ACCOUNT-956: When the user has selected a language, we change the language for the whole onboarding flow
    const onSubmit = () => {
        setLocale(formData.getValues('language'));
        return Promise.resolve();
    };

    return (
        <OnboardingStepper.Form {...formData} onSubmit={onSubmit}>
            <Paragraph>{formatMessage({ id: descriptionId })}</Paragraph>
            <ProfileInfoNameField
                label={formatMessage({
                    id: 'user.profile.confirmation.firstname',
                })}
                name="firstName"
                required
            />
            <ProfileInfoNameField
                label={formatMessage({
                    id: 'user.profile.confirmation.lastname',
                })}
                name="lastName"
                required
            />
            <ProfileInfoLanguageField
                label={formatMessage({
                    id: 'user.profile.confirmation.language',
                })}
                languages={languages}
                name="language"
                required
            />
            <ProfileInfoTimeZoneField
                label={formatMessage({
                    id: 'user.profile.confirmation.timezone',
                })}
                name="timeZone"
                required
                timeZones={timeZones}
            />
        </OnboardingStepper.Form>
    );
};

export default ProfileInformationStep;
