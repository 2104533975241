import React from 'react';
import { Navigate, Route, useResolvedPath } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import RestrictedRoute from '../../common/RestrictedRoute';
import WarehousePages from '../../warehouse/WarehousePages';

import { OrganizationProvider } from './OrganizationContext';
import OrganizationGeneralInformationPage from './organization/OrganizationGeneralInformationPage';
import EnsureShopsManagingTabId from './shop/ShopsManagingTabIds';
import LinkingShopsByEmailPage from './shop/linking/LinkingShopsByEmailPage';
import SubscriptionPage from './subscription/SubscriptionPage';
import PaywallPage from './subscription/paywall/PaywallPage';
import OrganizationUsersPage from './users/OrganizationUsersPage';

const OrganizationRunPages = () => {
    const baseUrl = useResolvedPath('').pathname;
    return (
        <OrganizationProvider>
            <Routes>
                <Route
                    element={<Navigate to={`${baseUrl}/shops/linked-shops`} />}
                    path="/shops"
                />
                <Route
                    element={<LinkingShopsByEmailPage />}
                    path="/shops/link-with-different-email-address"
                />
                <Route
                    element={<EnsureShopsManagingTabId />}
                    path="/shops/:tab"
                />
                <Route
                    element={
                        <RestrictedRoute
                            allowAdminSellerOnly
                            allowSupport={false}
                            featureEnabled="seller-monetization"
                        />
                    }
                >
                    <Route element={<PaywallPage />} path="/paywall" />
                </Route>
                <Route
                    element={
                        <RestrictedRoute featureEnabled="seller-monetization" />
                    }
                >
                    <Route
                        element={<SubscriptionPage />}
                        path="/subscription"
                    />
                </Route>
                <Route element={<OrganizationUsersPage />} path="/users" />
                <Route element={<RestrictedRoute featureEnabled="warehouse" />}>
                    <Route element={<WarehousePages />} path="/warehouses/*" />
                </Route>
                <Route element={<OrganizationGeneralInformationPage />} index />
            </Routes>
        </OrganizationProvider>
    );
};

export default OrganizationRunPages;
