import { useCallback } from 'react';

import { useSnackbar } from '@mirakl/roma';

const useClipboard = () => {
    const { addSnackbar } = useSnackbar();

    const copy = useCallback(
        async (content: string, successMessage: string) => {
            await navigator.clipboard.writeText(content);
            return addSnackbar({
                message: successMessage,
                status: 'success',
            });
        },
        [addSnackbar]
    );

    return { copy };
};

export default useClipboard;
