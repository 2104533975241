import axios from 'axios';

type TimeZonePayloadType = {
    timeZones: string[];
};

type LanguagesPayloadType = {
    languages: {
        label: string;
        locale: string;
    }[];
};

export const fetchAvailableLanguages = () => {
    return axios
        .get<LanguagesPayloadType>('/private/profile/settings/languages')
        .then(({ data }) => data.languages);
};

export const fetchTimeZones = () => {
    return axios
        .get<TimeZonePayloadType>('/private/profile/settings/timezones')
        .then(({ data }) => data.timeZones);
};
