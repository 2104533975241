import {
    GetQueryParamsValueFromHelperType,
    createQueryParamsHelper,
} from '@mirakl/roma';

import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';
import useOrganizationContext from '../../organization/run/OrganizationContext';
import { StageValues } from '../../types/stageTypes';

export type WarehouseApiType = {
    address: {
        city: string;
        country_iso_code: string;
        state: string;
        street_1: string;
        street_2: string;
        zip_code: string;
    };
    code: string;
    name: string;
    shops: WarehouseShopApiType[];
    type: string;
};

export type WarehouseShopApiType = {
    id: string;
    shopName: string;
    tenantId: string;
    tenantLogoUrl: string;
    tenantName?: string;
};

export type WarehouseListingPayload = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: WarehousePayloadType[];
    nextPageToken?: string;
    previousPageToken?: string;
};

export type WarehousePayloadType = {
    code: string;
    id: string;
    linkedShops: {
        id: string;
        shopName: string;
        stage: StageValues;
        tenantId: string;
        tenantName?: string;
    }[];
    name: string;
    type: 'SELF_MANAGED' | 'EXTERNALLY_MANAGED';
};

export type WarehouseUpdateRequestApiType = {
    address: {
        city: string;
        country_iso_code: string;
        state: string;
        street_1: string;
        street_2: string;
        zip_code: string;
    };
    code: string;
    name: string;
    shopsToLink: string[];
    shopsToUnlink: string[];
    type: string;
};

export type WarehouseCreateRequestApiType = {
    address: {
        city: string;
        country_iso_code: string;
        state: string;
        street_1: string;
        street_2: string;
        zip_code: string;
    };
    code: string;
    name: string;
    shopIds: string[];
    type: string;
};

type WarehouseReorderPayloadType = {
    warehouseIds: string[];
};

const toWarehouseReorderPayloadType = (
    ids: string[]
): WarehouseReorderPayloadType => {
    return {
        warehouseIds: ids,
    };
};

export const warehouseQueryParamsHelper = createQueryParamsHelper(
    {}
).withSeekPagination();

export const useWarehouseApiClient = () => {
    const { uuid: organizationUuid } = useOrganizationContext();
    const { apiGet, apiPost, apiPut } = useAuthenticatedFetch();

    type WarehouseQueryParams = GetQueryParamsValueFromHelperType<
        typeof warehouseQueryParamsHelper
    >;

    return {
        getWarehouse: async (warehouseUuid: string) =>
            apiGet<WarehouseApiType>(
                `/private/organizations/${organizationUuid}/warehouses/${warehouseUuid}`
            ).then((response) => response.data),
        postWarehouse: async (
            requestValues: WarehouseCreateRequestApiType
        ): Promise<unknown> => {
            return apiPost(
                `/private/organizations/${organizationUuid}/warehouses`,
                requestValues
            );
        },
        putWarehouse:
            (warehouseUuid: string) =>
            async (
                requestValues: WarehouseUpdateRequestApiType
            ): Promise<unknown> => {
                return apiPut(
                    `/private/organizations/${organizationUuid}/warehouses/${warehouseUuid}`,
                    requestValues
                );
            },
        listWarehouses: (
            queryParams: WarehouseQueryParams
        ): Promise<WarehouseListingPayload> =>
            apiGet<WarehouseListingPayload>(
                `/private/organizations/${organizationUuid}/warehouses`,
                {
                    params: queryParams,
                }
            ).then((payload) => payload.data),
        reorder: async (warehouseIds: string[]): Promise<void> => {
            return apiPut(
                `/private/organizations/${organizationUuid}/warehouses/reorder`,
                toWarehouseReorderPayloadType(warehouseIds)
            ).then(() => {});
        },
    };
};
