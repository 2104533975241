import React, { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Datatable,
    DatatableHeadingCellType,
    DatatableProvider,
    Flex,
    Media,
    Text,
    TextCellContent,
    createHeadingsHelper,
    useErrorHandler,
    useModal,
    useSnackbar,
} from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import ShopStateComponent from '../../common/shop/ShopStateComponent';
import { mapFormToLinkShopPayload } from '../../common/shop/linkShopMapper';
import {
    SHOP_NAME_FILTER,
    UsersShopsQueryParams,
    usersShopsQueryParamsHelper,
} from '../../common/shop/useUsersShopsApiClient';
import useOrganizationContext from '../OrganizationContext';

import OwnershipModal from './OwnershipModal';
import { ReadyToLinkShopsData } from './ShopsManagingPage';
import useStoreSelectionApi from './linking/linkingStoreApi';

type ShopDetail = {
    channelNames: string[];
    id: string;
    store: string;
    storeAction: ShopState;
    tenant: TenantInfo;
};
type TenantInfo = {
    logoUrl: string;
    name: string;
};

type ShopState = {
    suspensionType?:
        | 'MANUAL'
        | 'SHOP_CREATION'
        | 'PROFILE_INCOMPLETE'
        | 'QUALITY_ISSUE';
    value: string;
};

export type ReadyToLinkProps = {
    fetchReadyToLinkShops: (queryParams?: UsersShopsQueryParams) => void;
    readyToLinkShops: ReadyToLinkShopsData;
    readyToLinkShopsCount: number;
};

function ReadyToLinkShops({
    fetchReadyToLinkShops,
    readyToLinkShops,
    readyToLinkShopsCount,
}: ReadyToLinkProps) {
    const { formatMessage } = useI18n();
    const { showModal } = useModal();
    const { addSnackbar } = useSnackbar();
    const handleError = useErrorHandler();
    const { uuid } = useOrganizationContext();
    const { email } = useLoginContext();
    const { linkShopToOrganization } = useStoreSelectionApi();

    const { loadingState, onQueryParamsChange } =
        usersShopsQueryParamsHelper.useQueryParamsChange((queryParams) => {
            return fetchReadyToLinkShops(queryParams);
        });

    const headingHelper = createHeadingsHelper<ShopDetail>();
    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            headingHelper.accessor('tenant', {
                label: formatMessage({
                    id: 'datatable.organizations.marketplace.label',
                }),
                sortable: true,
                initialSortDirection: 'ASC',
                maxWidth: '250px',
                render: (cellData, { channelNames, tenant }: ShopDetail) => (
                    <Flex flexDirection="row">
                        <Flex margin={2}>
                            <Media
                                alt={tenant.name}
                                size={7}
                                type="image"
                                url={tenant.logoUrl}
                            />
                        </Flex>
                        <Flex
                            alignContent="center"
                            fullWidth
                            margin={2}
                            marginTop={!channelNames.join(', ') ? 4 : 2}
                        >
                            <TextCellContent
                                subtext={channelNames.join(', ')}
                                text={tenant.name}
                            />
                        </Flex>
                    </Flex>
                ),
            }),
            headingHelper.accessor('store', {
                label: formatMessage({
                    id: 'datatable.organizations.store.label',
                }),
                maxWidth: '200px',
            }),
            headingHelper.accessor('storeAction', {
                label: formatMessage({
                    id: 'datatable.organizations.store.status.label',
                }),
                tooltipText: formatMessage({
                    id: 'datatable.organizations.store.status.tooltip',
                }),
                render: (data: ShopState) => (
                    <ShopStateComponent shopState={data} />
                ),
            }),
        ],
        [formatMessage, headingHelper]
    );

    const linkShops = (
        emailUserToLink: string,
        shopUuidsToLink: string[] | 'all'
    ) => {
        function getShopUuids() {
            return shopUuidsToLink === 'all'
                ? readyToLinkShops.shops.map((shop) => shop.id)
                : shopUuidsToLink;
        }

        const shops: { email: string; shopUuid: string }[] = getShopUuids().map(
            (shopId) => ({ email: emailUserToLink, shopUuid: shopId })
        );
        return linkShopToOrganization(
            uuid,
            mapFormToLinkShopPayload(emailUserToLink, shops)
        )
            .then(() => {
                addSnackbar({
                    status: 'success',
                    message: formatMessage({
                        id: 'snackbar.edit.success',
                    }),
                });
                fetchReadyToLinkShops();
            })
            .catch(handleError);
    };

    return (
        <usersShopsQueryParamsHelper.QueryParamsProvider
            onQueryParamsChange={onQueryParamsChange}
        >
            {readyToLinkShopsCount > 0 && (
                <Flex inline margin={5}>
                    <Text>
                        {formatMessage({
                            id: 'datatable.organizations.shop.page.readyToLink.info.message',
                        })}
                    </Text>
                </Flex>
            )}
            <DatatableProvider>
                <Datatable
                    data={readyToLinkShops.shops}
                    emptyState={{
                        illustrationName: 'empty-shop',
                        title: formatMessage({
                            id: 'datatable.organizations.shop.page.readyToLink.emptyState.title',
                        }),
                        subtitle: formatMessage(
                            {
                                id: 'datatable.organizations.shop.page.readyToLink.emptyState.subtitle',
                            },
                            {
                                0: formatMessage({
                                    id: 'organizations.actions.add.store',
                                }),
                            }
                        ),
                    }}
                    headings={headings}
                    id="list-ready-to-link-shop-table"
                    loading={loadingState}
                    maxVisibleLines={10}
                    renderTrailingActions={(rowData) => (
                        <Button
                            label={formatMessage({
                                id: 'datatable.organizations.shop.page.readyToLink.button.link',
                            })}
                            onClick={() => {
                                showModal(
                                    <OwnershipModal
                                        email={email}
                                        linkShops={linkShops}
                                        organizationUuid={uuid}
                                        shopUuidsToLink={[rowData.id]}
                                    />
                                );
                            }}
                        />
                    )}
                    resultNumberMessage={formatMessage(
                        { id: 'count.result' },
                        { 0: readyToLinkShops.shops.length }
                    )}
                    rowSelectionRenderActions={(selectedRows) => [
                        <Button
                            key="link-shop-button"
                            label={formatMessage({
                                id: 'datatable.organizations.shop.page.readyToLink.button.link',
                            })}
                            onClick={() => {
                                showModal(
                                    <OwnershipModal
                                        email={email}
                                        linkShops={linkShops}
                                        organizationUuid={uuid}
                                        shopUuidsToLink={selectedRows}
                                    />
                                );
                            }}
                        />,
                    ]}
                    toolbar={{
                        searchBar: {
                            id: SHOP_NAME_FILTER,
                            placeholder: formatMessage({
                                id: 'datatable.organizations.search.store.name',
                            }),
                        },
                    }}
                />
            </DatatableProvider>
        </usersShopsQueryParamsHelper.QueryParamsProvider>
    );
}

export default ReadyToLinkShops;
