import { ReactNode, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { GlobalLayout } from '@mirakl/roma';

import useLoginContext from '../../config/login/LoginProvider';
import useOrganizationContext from '../../organization/run/OrganizationContext';
import { GlobalLayoutSidebarType } from '../../types/romaTypes';
import { useFeatureToggle } from '../toggle/FeatureToggleProvider';

import { HELP_PAGE_URL } from './AccountGlobalLayout';

function ShopUserGlobalLayout({
    children,
}: {
    children: ReactNode | ReactNode[];
}) {
    const { formatMessage } = useI18n();

    const { email } = useLoginContext();

    const { name, uuid } = useOrganizationContext();

    const { isFeatureEnable } = useFeatureToggle();
    const sellerMonetizationEnable = isFeatureEnable('seller-monetization');
    const warehouseEnabled = isFeatureEnable('warehouse');

    const getShopUserSidebarMenuItems = useMemo(() => {
        const menuItems: GlobalLayoutSidebarType = [
            {
                id: 'menu-item-general-info',
                label: formatMessage({ id: 'general.information' }),
                iconName: 'info',
                to: `/organizations/${uuid}`,
            },
            {
                id: 'menu-item-shops',
                label: formatMessage({ id: 'shops' }),
                iconName: 'store',
                to: `/organizations/${uuid}/shops`,
            },
        ];

        if (warehouseEnabled) {
            menuItems.push({
                id: 'menu-item-warehouses',
                label: formatMessage({
                    id: 'organizations.warehouses.page.title',
                }),
                iconName: 'store-front',
                to: `/organizations/${uuid}/warehouses`,
            });
        }

        menuItems.push({
            id: 'menu-item-users',
            label: formatMessage({ id: 'users' }),
            iconName: 'person',
            to: `/organizations/${uuid}/users`,
        });

        if (sellerMonetizationEnable) {
            menuItems.push({
                id: 'menu-item-subscription',
                label: formatMessage({
                    id: 'organizations.subscription.navigation.title',
                }),
                iconName: 'monetization-on',
                to: `/organizations/${uuid}/subscription`,
            });
        }

        return menuItems;
    }, [formatMessage, uuid, sellerMonetizationEnable, warehouseEnabled]);

    return (
        <GlobalLayout
            sidebar={{
                footerItems: [
                    {
                        id: 'menu-item-help-center',
                        label: formatMessage({ id: 'help.center' }),
                        iconName: 'support',
                        href: HELP_PAGE_URL,
                        openInNewTab: true,
                    },
                ],
                media: {
                    url: '/static/images/mirakl-logo.png',
                    alt: 'Mirakl',
                    type: 'image',
                },
                menuItems: getShopUserSidebarMenuItems,
                subtitle: email,
                title: name,
            }}
            topBar={{
                buttons: undefined, // Nothing in the topBar
            }}
        >
            {children}
        </GlobalLayout>
    );
}

export default ShopUserGlobalLayout;
