import { useI18n } from '@mirakl/i18n';
import { Form, Modal, useForm, useSnackbar } from '@mirakl/roma';

import ProfileInfoLanguageField from '../fields/ProfileInfoLanguageField';
import ProfileInfoNameField from '../fields/ProfileInfoNameField';
import ProfileInfoTimeZoneField from '../fields/ProfileInfoTimeZoneField';

type ProfileInfoType = {
    email: string;
    firstName?: string;
    language?: string;
    lastName?: string;
    timeZone?: string;
};

type ProfileInfoFormType = {
    firstName: string;
    language: string;
    lastName: string;
    timeZone: string;
};

type LanguageType = {
    label: string;
    locale: string;
};

const _ProfileInfoEditModal = ({
    languages,
    onEdit,
    profileInfo,
    timeZones,
}: {
    languages: LanguageType[];
    onEdit: (profileInfo: ProfileInfoFormType) => Promise<void>;
    profileInfo: ProfileInfoType;
    timeZones: string[];
}) => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const { firstName, language, lastName, timeZone } = profileInfo;

    const formData = useForm<ProfileInfoFormType>({
        defaultValues: {
            firstName: firstName || '',
            language: language || '',
            lastName: lastName || '',
            timeZone: timeZone || '',
        },
    });

    const {
        formState: { isDirty, isSubmitting },
        handleSubmit,
    } = formData;

    const onSubmit = (close: () => void) => (values: ProfileInfoFormType) =>
        onEdit(values).then(() => {
            addSnackbar({
                message: formatMessage({
                    id: 'user.profile.information.edit.success',
                }),
                status: 'success',
            });
            close();
        });

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'user.profile.information.edit',
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Form {...formData}>
                        <ProfileInfoNameField
                            label={formatMessage({
                                id: 'user.profile.confirmation.firstname',
                            })}
                            name="firstName"
                            required
                        />
                        <ProfileInfoNameField
                            label={formatMessage({
                                id: 'user.profile.confirmation.lastname',
                            })}
                            name="lastName"
                            required
                        />
                        <ProfileInfoLanguageField
                            breakOutOfContainer
                            label={formatMessage({
                                id: 'user.profile.confirmation.language',
                            })}
                            languages={languages}
                            name="language"
                            required
                        />
                        <ProfileInfoTimeZoneField
                            breakOutOfContainer
                            label={formatMessage({
                                id: 'user.profile.confirmation.timezone',
                            })}
                            name="timeZone"
                            required
                            timeZones={timeZones}
                        />
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'button.save',
                    }),
                    state: isSubmitting ? 'loading' : 'default',
                    disabled: !isDirty,
                    onClick: (close, e) => handleSubmit(onSubmit(close))(e),
                }}
            />
        </Modal>
    );
};

export default _ProfileInfoEditModal;
