import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Panel, Paragraph } from '@mirakl/roma';

import useOperatorAccountContext from '../../../../OperatorAccountContext';
import useTicketingLicenceApi from '../api/useTicketingLicenceApi';

import TicketingLicenceUsersListing from './TicketingLicenceUsersListing';

const LoadedTicketingLicenceUsersPanel = () => {
    const { formatMessage } = useI18n();

    const { id: operatorAccountId } = useOperatorAccountContext();

    const { fetchLicenceLimitQuery, fetchLicensedUsersQuery } =
        useTicketingLicenceApi(operatorAccountId);
    const { data: licensedUsers } = useSuspenseQuery(fetchLicensedUsersQuery());
    const { data: limit } = useSuspenseQuery(fetchLicenceLimitQuery());

    return (
        <Panel>
            <Panel.Header
                badges={
                    limit != null
                        ? {
                              id: 'limit',
                              label: formatMessage(
                                  {
                                      id: 'operator.accounts.ticketing.licence.limit.display',
                                  },
                                  {
                                      0: licensedUsers.length,
                                      1: limit,
                                  }
                              ),
                              status:
                                  limit <= licensedUsers.length
                                      ? 'warning'
                                      : 'info',
                          }
                        : undefined
                }
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.listing.title',
                })}
            />
            <Panel.Content>
                <Paragraph>
                    {formatMessage({
                        id: 'operator.accounts.ticketing.licence.users.listing.hint',
                    })}
                </Paragraph>
            </Panel.Content>
            <Panel.Content fullWidth>
                <TicketingLicenceUsersListing />
            </Panel.Content>
        </Panel>
    );
};

const TicketingLicenceUsersPanel = () => {
    return (
        <Suspense fallback={<Panel loading />}>
            <LoadedTicketingLicenceUsersPanel />
        </Suspense>
    );
};

export default TicketingLicenceUsersPanel;
