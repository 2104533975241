import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

import { useErrorHandler } from '@mirakl/roma';

import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';

type CountriesProviderProps = {
    children: ReactNode;
};

type CountryType = {
    code: string;
    label: string;
};

type CountryPayload = CountryType & {
    optionalZipcode: boolean;
};

type CountriesContextType = {
    countries: CountryType[];
    countriesWithOptionalZipcode: CountryType[];
    isLoading: boolean;
};

const CountriesContext = createContext<CountriesContextType>({
    countries: [],
    countriesWithOptionalZipcode: [],
    isLoading: true,
});

export const useCountries = () => useContext(CountriesContext);

const CountriesProvider = ({ children }: CountriesProviderProps) => {
    const { apiGet } = useAuthenticatedFetch();
    const handleError = useErrorHandler();

    const [countries, setCountries] = useState<CountryType[]>([]);
    const [countriesWithOptionalZipcode, setCountriesWithOptionalZipcode] =
        useState<CountryType[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        apiGet<CountryPayload[]>('/private/countries')
            .then((result) => {
                setCountries(
                    result.data.map(
                        ({ code, label }): CountryType => ({ code, label })
                    )
                );
                setCountriesWithOptionalZipcode(
                    result.data
                        .filter((country) => country.optionalZipcode)
                        .map(
                            ({ code, label }): CountryType => ({ code, label })
                        )
                );
                setLoading(false);
            })
            .catch(handleError);
    }, [apiGet, handleError]);

    return (
        <CountriesContext.Provider
            value={{
                countries: countries,
                countriesWithOptionalZipcode: countriesWithOptionalZipcode,
                isLoading,
            }}
        >
            {children}
        </CountriesContext.Provider>
    );
};

export default CountriesProvider;
