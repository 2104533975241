import { ReactNode, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { GlobalLayout } from '@mirakl/roma';

import useLoginContext from '../../config/login/LoginProvider';
import useOperatorAccountContext from '../../operators/OperatorAccountContext';
import useTicketingLicenceEnabled from '../../operators/users/ticketing/licence/utils/useTicketingLicenceEnabled';
import { GlobalLayoutSidebarType } from '../../types/romaTypes';
import { useFeatureToggle } from '../toggle/FeatureToggleProvider';

import { HELP_PAGE_URL } from './AccountGlobalLayout';

type OperatorUserGlobalLayoutProps = {
    children: ReactNode;
};

const OperatorUserGlobalLayout = ({
    children,
}: OperatorUserGlobalLayoutProps) => {
    const { formatMessage } = useI18n();
    const { isFeatureEnable } = useFeatureToggle();

    const { email } = useLoginContext();
    const { id, logoUrl, name } = useOperatorAccountContext();

    const isSmtpFeatureEnabled = isFeatureEnable('smtp');
    const { isEnabled: isTicketingLicenceEnabled } =
        useTicketingLicenceEnabled();
    const { isAdmin } = useOperatorAccountContext();
    const {
        miraklSupport: { isOperatorSupportUser },
    } = useLoginContext();

    const usersMenuItems = useMemo(() => {
        const subItems = [
            {
                id: 'menu-item-user-listing',
                label: formatMessage({
                    id: 'operator.accounts.users.listing.title',
                }),
                to: `/operators/${id}/users`,
            },
        ];
        if (isTicketingLicenceEnabled) {
            subItems.push({
                id: 'menu-item-users-ticketing-licence',
                label: formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.title',
                }),
                to: `/operators/${id}/users/ticketing/licences`,
            });
        }
        return subItems;
    }, [formatMessage, id, isTicketingLicenceEnabled]);

    const menuItems = useMemo(() => {
        const items: GlobalLayoutSidebarType = [
            {
                id: 'menu-item-company-information',
                label: formatMessage({
                    id: 'operator.accounts.detail.company.information',
                }),
                iconName: 'store',
                to: `/operators/${id}`,
            },
            {
                id: 'menu-item-users',
                label: formatMessage({ id: 'operator.accounts.users.title' }),
                iconName: 'person',
                subItems: usersMenuItems,
            },
        ];

        if (isSmtpFeatureEnabled && (isAdmin || isOperatorSupportUser)) {
            items.push({
                id: 'menu-item-smtp-listing',
                label: formatMessage({ id: 'operator.smtp.title' }),
                iconName: 'configuration',
                to: `/operators/${id}/smtp-configurations`,
            });
        }

        return items;
    }, [
        formatMessage,
        id,
        usersMenuItems,
        isSmtpFeatureEnabled,
        isAdmin,
        isOperatorSupportUser,
    ]);

    return (
        <GlobalLayout
            sidebar={{
                footerItems: [
                    {
                        id: 'menu-item-help-center',
                        label: formatMessage({ id: 'help.center' }),
                        iconName: 'support',
                        href: HELP_PAGE_URL,
                        openInNewTab: true,
                    },
                ],
                menuItems: menuItems,
                media: logoUrl
                    ? {
                          url: logoUrl,
                          alt: formatMessage({
                              id: 'operator.accounts.layout.logo.alt',
                          }),
                          type: 'image',
                      }
                    : undefined,
                title: name,
                subtitle: email,
            }}
            topBar={{
                buttons: undefined, // Nothing in the topBar
            }}
        >
            {children}
        </GlobalLayout>
    );
};

export default OperatorUserGlobalLayout;
