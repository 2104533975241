import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { PageLoader, useErrorHandler } from '@mirakl/roma';

import { withAuthorizationHeader } from '../../fetch/headerUtils';
import useQuery from '../../router/useQuery';

import { MiraklSupportType, buildMiraklSupport } from './MiraklSupportBuilder';
import { isLoginRequired } from './auth0Utils';

export type Role =
    | 'ROLE_SUPPORT_OPERATOR'
    | 'ROLE_SUPPORT_OPERATOR_READONLY'
    | 'ROLE_SUPPORT_SELLER'
    | 'ROLE_SUPPORT_SELLER_READONLY'
    | 'ROLE_OPERATOR';

type LoginUserType = {
    email: string;
    getAccessToken(): Promise<string>;
    hasAnyRole: (roles: Role[]) => boolean;
    logout(): void;
    miraklSupport: MiraklSupportType;
    roles: string[];
};

type LoginUserPayload = {
    roles: string[];
};

export const LoginContext = createContext<LoginUserType | null>(null);

const useLoginContext = () => {
    const context = useContext(LoginContext);
    if (!context) {
        throw new Error(
            'Trying to access login context outside of the LoginProvider.'
        );
    }
    return context;
};

export const LoginProvider = ({
    children,
}: {
    children: ReactNode | ReactNode[];
}) => {
    const {
        error,
        getAccessTokenSilently,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout: logoutWithRedirect,
        user: auth0User,
    } = useAuth0();

    const handleError = useErrorHandler();
    const [loginUser, setLoginUser] = useState<LoginUserPayload>();
    const { login_hint } = useQuery({ login_hint: 'optionalString' });

    const login = useCallback(() => {
        return loginWithRedirect({
            appState: {
                returnTo: `${window.location.pathname}${window.location.search}`,
            },
            authorizationParams: {
                login_hint,
            },
        }).catch(handleError);
    }, [loginWithRedirect, login_hint, handleError]);

    const logout = useCallback(() => {
        return logoutWithRedirect({
            async openUrl(url) {
                window.location.replace(url);
            },
        });
    }, [logoutWithRedirect]);

    const getAccessToken = useCallback((): Promise<string> => {
        return getAccessTokenSilently({
            detailedResponse: true,
            /* Cache duration is set according to the access_token lifetime.
             * Thanks to this cache, getting the access token will only trigger
             * a refresh if the lifetime of the access_token has expired. */
            cacheMode: 'on',
        })
            .then((response) => response.access_token)
            .catch((errorResponse) => {
                console.warn(errorResponse);
                logout();
            }) as Promise<string>;
    }, [getAccessTokenSilently, logout]);

    const hasAnyRole = useCallback(
        (roles: Role[]) =>
            !!loginUser?.roles &&
            roles.some((role) => loginUser.roles.includes(role)),
        [loginUser?.roles]
    );

    useEffect(() => {
        /* note: if there is an error we need to check if it's the "login_required" error because we definitely want to
         * trigger the login in this case. This can happen when you goes through the external signup, you are not
         * logged in but your account already exists, so the login state will be in error, and we redirect the user
         * using the router to the create organization page. */
        if (!isLoading && (!error || isLoginRequired(error))) {
            if (!isAuthenticated) {
                // user is not logged in, redirect to the login page
                login();
            }
        } else if (!isLoading && error) {
            handleError(new Error(`Authentication failed: ${error}`));
        }
    }, [isLoading, isAuthenticated, error, handleError, login]);

    useEffect(() => {
        if (isAuthenticated) {
            getAccessToken()
                .then((accessToken) => {
                    axios
                        .get<LoginUserPayload>(
                            '/private/user',
                            withAuthorizationHeader({}, accessToken)
                        )
                        .then((response) => response.data)
                        .then(setLoginUser)
                        .catch(handleError);
                })
                .catch(handleError);
        }
    }, [getAccessToken, isAuthenticated, handleError]);

    return (
        <>
            {(!auth0User || !loginUser) && <PageLoader fullPage />}
            {auth0User && loginUser && (
                <LoginContext.Provider
                    value={{
                        email: auth0User.email!,
                        getAccessToken,
                        hasAnyRole,
                        logout,
                        miraklSupport: buildMiraklSupport(loginUser.roles),
                        roles: loginUser.roles,
                    }}
                >
                    {children}
                </LoginContext.Provider>
            )}
        </>
    );
};

export default useLoginContext;
