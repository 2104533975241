import {
    GetQueryParamsValueFromHelperType,
    createQueryParamsHelper,
} from '@mirakl/roma';

export const operatorAccountUserQueryParamHelper = createQueryParamsHelper<{
    role: {
        type: 'singleOption';
        value: string;
    };
}>({
    role: 'singleOption',
})
    .withSort()
    .withSearch('search')
    .withSeekPagination();

export type OperatorAccountUserQueryParamType =
    GetQueryParamsValueFromHelperType<
        typeof operatorAccountUserQueryParamHelper
    >;
