import axios from 'axios';
import { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    LinkButton,
    OnboardingStepperLayout,
    PageLoader,
    Paragraph,
    useErrorHandler,
} from '@mirakl/roma';

type InvitationData = {
    email: string;
    productId: string;
};

const INVITATION_SPAM_DELAY_MS = 60_000;

const SignupEmailSentPage = () => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();

    const [invitedEmail, setInvitedEmail] = useState<string | undefined>();
    const [productId, setProductId] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [resendButtonAvailable, setResendButtonAvailable] =
        useState<boolean>(true);

    useEffect(() => {
        axios
            .get<InvitationData>('/private/signup/info')
            .then(({ data }) => {
                setInvitedEmail(data.email);
                setProductId(data.productId);
                setLoading(false);
            })
            .catch(handleError);
    }, [handleError]);

    return loading ? (
        <PageLoader fullPage />
    ) : (
        <OnboardingStepperLayout
            illustration="onboarding-stepper"
            media={{
                url:
                    '/static/images/' +
                    formatMessage({
                        id: `product.${productId}.logo`,
                    }),
                alt: formatMessage({
                    id: `product.${productId}.logo.alt`,
                }),
                type: 'image',
            }}
            title={formatMessage({ id: 'signup.email.sent.title' })}
        >
            <Paragraph>
                {formatMessage(
                    { id: 'signup.email.sent.subtitle' },
                    { 0: invitedEmail, b: (chunks: string) => <b>{chunks}</b> }
                )}
            </Paragraph>
            <LinkButton
                disabled={!resendButtonAvailable}
                iconName="mail"
                label={formatMessage({ id: 'signup.email.resend' })}
                tooltipText={
                    resendButtonAvailable
                        ? undefined
                        : formatMessage({ id: 'signup.email.resend.tooltip' })
                }
                onClick={() => {
                    axios.post('/private/signup/re-send').then(() => {
                        setResendButtonAvailable(false);
                        setTimeout(
                            () => setResendButtonAvailable(true),
                            INVITATION_SPAM_DELAY_MS
                        );
                    });
                }}
            />
        </OnboardingStepperLayout>
    );
};

export default SignupEmailSentPage;
