import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useLocalizedCommaJoiner = () => {
    const intl = useIntl();

    const joinWithCommas = useCallback(
        (list: string[]) =>
            intl.formatList(list, {
                style: 'narrow', // To have a result like "aa, bb, cc, dd"
                type: 'conjunction',
            }),
        [intl]
    );

    return {
        joinWithCommas,
    };
};
