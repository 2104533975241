import { useWatch } from 'react-hook-form';

import { useI18n } from '@mirakl/i18n';
import { Flex, TextField } from '@mirakl/roma';

import { useCountries } from '../CountriesProvider';

import CountrySelectField from './CountrySelectField';

export type AddressType = {
    address: string;
    addressContinued: string;
    city: string;
    countryCode: string;
    postalCode: string;
    state: string;
};

// on name : one would expect such a component to have a 'name' prop, allowing
// to change where it is used in the form. At the moment, the field is always
// "inlined" in the form, with all it's element at the root level, so we don't
// need a name. However, if needed, it could be added (leaving it optional
// probably), to change the base name.
type AddressFieldProps = {
    disabled?: boolean;
};

const AddressField = ({ disabled }: AddressFieldProps) => {
    const { formatMessage } = useI18n();
    const { countriesWithOptionalZipcode } = useCountries();

    const formValues = useWatch<AddressType>();

    return (
        <>
            <TextField
                disabled={disabled}
                label={formatMessage({
                    id: 'location.address',
                })}
                maxLength={255}
                name="address"
                required
            />
            <TextField
                disabled={disabled}
                label={formatMessage({
                    id: 'location.address.continued',
                })}
                maxLength={255}
                name="addressContinued"
            />
            <Flex gap={4}>
                <CountrySelectField
                    disabled={disabled}
                    name="countryCode"
                    required
                />
                <TextField
                    disabled={disabled}
                    label={formatMessage({
                        id: 'location.state',
                    })}
                    maxLength={255}
                    name="state"
                />
            </Flex>
            <Flex gap={4}>
                <TextField
                    disabled={disabled}
                    label={formatMessage({
                        id: 'location.city',
                    })}
                    maxLength={255}
                    name="city"
                    required
                />
                <TextField
                    disabled={disabled}
                    label={formatMessage({
                        id: 'location.postal.code',
                    })}
                    maxLength={255}
                    name="postalCode"
                    required={
                        !countriesWithOptionalZipcode.some(
                            (country) => country.code === formValues.countryCode
                        )
                    }
                />
            </Flex>
        </>
    );
};

export default AddressField;
