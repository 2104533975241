import { ReactNode } from 'react';

import { Flex } from '@mirakl/roma';

const _ProfileInfoContent = ({ children }: { children: ReactNode }) => {
    return (
        <Flex flex="auto" flexDirection="column" gap={3} overflow="hidden">
            {children}
        </Flex>
    );
};

export default _ProfileInfoContent;
