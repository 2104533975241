import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Flex, Icon, IconNameType, Text } from '@mirakl/roma';

const _ProfileInfoItem = ({
    icon,
    value,
}: {
    icon: IconNameType;
    value?: string | null;
}) => {
    const { formatMessage } = useI18n();

    const fallback = useMemo(
        () => formatMessage({ id: 'user.profile.information.missing' }),
        [formatMessage]
    );

    return (
        <Flex alignItems="center" gap={2}>
            <Icon name={icon} />
            <Text>{value ?? fallback}</Text>
        </Flex>
    );
};

export default _ProfileInfoItem;
