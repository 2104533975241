import { Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import RestrictedRoleRoute from '../common/RestrictedRoleRoute';

import OrganizationMergePage from './merge/OrganizationMergePage';
import OrganizationRunPages from './run/OrganizationRunPages';
import OrganizationListingPage from './run/listing/OrganizationListingPage';

const OrganizationPages = () => {
    return (
        <Routes>
            <Route
                element={
                    <RestrictedRoleRoute
                        roles={[
                            'ROLE_SUPPORT_SELLER',
                            'ROLE_SUPPORT_SELLER_READONLY',
                        ]}
                    />
                }
            >
                <Route element={<OrganizationListingPage />} index />
            </Route>
            <Route
                element={
                    <RestrictedRoleRoute roles={['ROLE_SUPPORT_SELLER']} />
                }
            >
                <Route element={<OrganizationMergePage />} path="merge" />
            </Route>
            <Route element={<OrganizationRunPages />} path=":uuid/*" />
        </Routes>
    );
};

export default OrganizationPages;
