import { AppState, Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';

import { PageLoader, useErrorHandler, useNavigate } from '@mirakl/roma';

type Auth0ConfigPayload = {
    clientId: string;
    domain: string;
};

type Auth0ConfigurationProviderProps = {
    children: ReactElement;
};

const Auth0ConfigurationProvider = ({
    children,
}: Auth0ConfigurationProviderProps) => {
    const [auth0Config, setAuth0Config] = useState<Auth0ConfigPayload>();
    const navigate = useNavigate();
    const handleError = useErrorHandler();

    useEffect(() => {
        axios
            .get<Auth0ConfigPayload>('/auth0-config')
            .then((response) => {
                setAuth0Config(response.data);
            })
            .catch(handleError);
    }, [handleError]);

    const redirect = (appState: AppState | undefined) => {
        if (appState?.returnTo) {
            navigate({ to: appState.returnTo });
        }
    };

    if (!auth0Config) {
        return <PageLoader fullPage />;
    }
    return (
        <Auth0Provider
            authorizationParams={{
                audience: 'account',
                redirect_uri: `${window.location.origin}/login/callback`,
            }}
            clientId={auth0Config.clientId}
            domain={auth0Config.domain}
            /* once logged in use the refresh_token grant type */
            useRefreshTokens
            /* try to fetch a new token if the refresh token expires */
            useRefreshTokensFallback
            onRedirectCallback={redirect}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ConfigurationProvider;
