import { Outlet } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import { useErrorHandler } from '@mirakl/roma';

import useLoginContext from '../config/login/LoginProvider';

import useOperatorAccountContext from './OperatorAccountContext';

const OperatorAdminOrSupportOnlyRoute = () => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { isAdmin } = useOperatorAccountContext();
    const {
        miraklSupport: { isOperatorSupportUser },
    } = useLoginContext();

    if (isAdmin || isOperatorSupportUser) {
        return <Outlet />;
    }

    handleError(new Error(formatMessage({ id: 'error.403.title' })));
};

export default OperatorAdminOrSupportOnlyRoute;
