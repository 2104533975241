import { PropsWithChildren } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Paragraph, Text } from '@mirakl/roma';

import { SmtpFormType } from '../../OperatorSmtpClientModelApi';

type SummaryProps = {
    currentFormValues: Partial<SmtpFormType>;
    validatedValues: Partial<SmtpFormType>;
};

const FieldValue = ({
    children,
    label,
}: PropsWithChildren<{ label: string }>) => (
    <>
        <Paragraph noSpaceBottom variant="default">
            <Text variant="default-500">{label}</Text>
        </Paragraph>
        <Paragraph>{children || '-'}</Paragraph>
    </>
);

const Summary = ({ currentFormValues, validatedValues }: SummaryProps) => {
    const { formatMessage } = useI18n();

    // Keep currentFormValues last so that it overrides validatedValues when user clicks on "Back"
    const allValues = { ...validatedValues, ...currentFormValues };

    const showSelfManagedFields = allValues.type === 'SELF_MANAGED';
    let startTlsValue = undefined;

    // Need to differenciate between false and undefined
    if (allValues.startTls !== undefined) {
        startTlsValue = allValues.startTls
            ? formatMessage({ id: 'generic.yes' })
            : formatMessage({ id: 'generic.no' });
    }

    return (
        <>
            <FieldValue
                label={formatMessage({ id: 'operator.smtp.field.name' })}
            >
                {allValues.name}
            </FieldValue>
            <FieldValue
                label={formatMessage({ id: 'operator.smtp.field.type' })}
            >
                {formatMessage({
                    id: `operator.smtp.field.type.${allValues.type}`,
                })}
            </FieldValue>
            <FieldValue
                label={formatMessage({
                    id: 'operator.smtp.field.sender_name',
                })}
            >
                {allValues.senderName}
            </FieldValue>
            <FieldValue
                label={formatMessage({
                    id: 'operator.smtp.field.sender_email',
                })}
            >
                {allValues.senderEmail}
            </FieldValue>
            <FieldValue
                label={formatMessage({
                    id: 'operator.smtp.field.xvar_header_prefix',
                })}
            >
                {allValues.xvarHeaderPrefix}
            </FieldValue>
            {showSelfManagedFields ? (
                <>
                    <FieldValue
                        label={formatMessage({
                            id: 'operator.smtp.field.host',
                        })}
                    >
                        {allValues.host}
                    </FieldValue>
                    <FieldValue
                        label={formatMessage({
                            id: 'operator.smtp.field.port',
                        })}
                    >
                        {allValues.port}
                    </FieldValue>
                    <FieldValue
                        label={formatMessage({
                            id: 'operator.smtp.field.username',
                        })}
                    >
                        {allValues.username}
                    </FieldValue>
                    <FieldValue
                        label={formatMessage({
                            id: 'operator.smtp.field.start_tls',
                        })}
                    >
                        {startTlsValue}
                    </FieldValue>
                </>
            ) : undefined}
        </>
    );
};

export default Summary;
