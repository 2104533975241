import React from 'react';

import { useI18n } from '@mirakl/i18n';
import { Badge } from '@mirakl/roma';

type ShopState = {
    suspensionType?: string;
    value: string;
};

function ShopStateComponent({
    shopState,
    size = 'default',
}: {
    shopState: ShopState;
    size?: 'small' | 'default';
}) {
    const { formatMessage } = useI18n();

    const { suspensionType, value: shopStateValue } = shopState;
    const labelKey = suspensionType
        ? `shop.state.${shopStateValue}_${suspensionType}`
        : `shop.state.${shopStateValue}`;
    const statusKey = `${labelKey}.status`;

    return shopStateValue === 'UNKNOWN' ? (
        ''
    ) : (
        <Badge
            label={formatMessage({ id: labelKey })}
            size={size}
            status={formatMessage({ id: statusKey })}
        />
    );
}

export default ShopStateComponent;
