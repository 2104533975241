import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import useOrganizationShopsApiClient, {
    ShopListPayload,
    ShopsPayload,
} from '../shop/useListShopsApi';

const useOrganizationShopUnlink = () => {
    const { apiDelete } = useAuthenticatedFetch();
    const fetchShops = useOrganizationShopsApiClient();
    const fetchShopsCallback = useCallback(
        (organizationUuid: string) => {
            const getAllPages = async (): Promise<ShopListPayload[]> => {
                let nextPageToken;
                const result: ShopListPayload[] = [];

                do {
                    const response: ShopsPayload = await fetchShops(
                        organizationUuid,
                        {
                            pageToken: nextPageToken,
                            tenant: undefined,
                            sort: 'tenant,ASC',
                            limit: 150,
                            'select-shopName': undefined,
                            shopName: undefined,
                        }
                    );
                    nextPageToken = response.nextPageToken;
                    result.push(...response.data);
                } while (nextPageToken);
                return result;
            };

            return new Promise<ShopListPayload[]>((resolve) => {
                getAllPages().then((result) => {
                    resolve(result);
                });
            });
        },
        [fetchShops]
    );

    const unlinkShopCallback = useCallback(
        (organizationUuid: string, shopUuids: string[]) =>
            apiDelete(`/private/organizations/${organizationUuid}/shops`, {
                shopUuids,
            }),
        [apiDelete]
    );

    return {
        fetchShopsCallback,
        unlinkShopCallback,
    };
};

export default useOrganizationShopUnlink;
