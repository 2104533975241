import React, { useMemo, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Datatable,
    DatatableHeadingCellType,
    DatatablePagination,
    DatatableProvider,
    Flex,
    Hyperlink,
    Media,
    Text,
    TextCellContent,
    createHeadingsHelper,
} from '@mirakl/roma';

import ShopStateComponent from '../../common/shop/ShopStateComponent';
import useOrganizationContext from '../OrganizationContext';

import useOrganizationShopsApiClient, {
    SHOP_NAME_FILTER,
    ShopsPayload,
    organizationShopQueryParamsHelper,
} from './useListShopsApi';

export const fromPayload = (
    payload: ShopsPayload
): OrganizationLinkedShopData => ({
    ...payload,
    data: payload.data.map((shop: ShopList) => {
        return {
            channelNames: shop.channels.map(
                (channel) => channel.text ?? channel.code
            ),
            id: shop.uuid,
            tenant: {
                name: shop.tenantName ?? shop.tenantId,
                logoUrl: shop.tenantLogoUrl,
                id: shop.tenantId,
            },
            store: shop.name,
            storeAction: { ...shop.state },
        };
    }),
});
type OrganizationLinkedShopData = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: ShopDetail[];
    nextPageToken?: string;
    previousPageToken?: string;
};
type ShopDetail = {
    channelNames: string[];
    id: string;
    store: string;
    storeAction: ShopState;
    tenant: TenantInfo;
};

type TenantInfo = {
    id: string;
    logoUrl: string;
    name?: string;
};

type ShopList = {
    channels: Channel[];
    name: string;
    state: ShopState;
    tenantId: string;
    tenantLogoUrl: string;
    tenantName?: string;
    uuid: string;
};
type Channel = {
    code: string;
    text?: string;
};
type ShopState = {
    suspensionType?: string;
    value: string;
};

function ListLinkedShop() {
    const { formatMessage } = useI18n();
    const { uuid: organizationUuid } = useOrganizationContext();

    const [isLoading, setIsLoading] = useState(true);
    const [linkedShopPagination, setLinkedShopWithPagination] =
        useState<OrganizationLinkedShopData>({
            count: {
                capped: false,
                counted: 0,
            },
            data: [],
        });

    const fetchShops = useOrganizationShopsApiClient();
    const { onQueryParamsChange } =
        organizationShopQueryParamsHelper.useQueryParamsChange(
            (queryParams) => {
                fetchShops(organizationUuid, queryParams).then((payload) => {
                    setLinkedShopWithPagination(fromPayload(payload));
                    setIsLoading(false);
                });
            }
        );
    const headingHelper = createHeadingsHelper<ShopDetail>();
    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            headingHelper.accessor('tenant', {
                label: formatMessage({
                    id: 'datatable.organizations.marketplace.label',
                }),
                sortable: true,
                initialSortDirection: 'ASC',
                maxWidth: '250px',
                render: (cellData, { channelNames, tenant }: ShopDetail) => {
                    return (
                        <Flex flexDirection="row">
                            <Flex margin={2}>
                                <Media
                                    alt={cellData.id}
                                    size={7}
                                    type="image"
                                    url={tenant.logoUrl}
                                />
                            </Flex>
                            <Flex
                                alignContent="center"
                                fullWidth
                                margin={2}
                                marginTop={!channelNames.join(', ') ? 4 : 2}
                            >
                                <TextCellContent
                                    subtext={channelNames.join(', ')}
                                    text={cellData.name}
                                />
                            </Flex>
                        </Flex>
                    );
                },
            }),

            headingHelper.accessor('store', {
                label: formatMessage({
                    id: 'datatable.organizations.store.label',
                }),

                maxWidth: '200px',
            }),
            headingHelper.accessor('storeAction', {
                label: formatMessage({
                    id: 'datatable.organizations.store.status.label',
                }),
                tooltipText: formatMessage({
                    id: 'datatable.organizations.store.status.tooltip',
                }),
                render: (data: ShopState) => (
                    <ShopStateComponent shopState={data} />
                ),
            }),
        ],
        [formatMessage, headingHelper]
    );

    return (
        <organizationShopQueryParamsHelper.QueryParamsProvider
            onQueryParamsChange={onQueryParamsChange}
        >
            <Flex inline margin={5}>
                <Text>
                    {formatMessage({
                        id: 'datatable.organizations.shop.info.message',
                    })}{' '}
                    <Hyperlink
                        href={formatMessage({ id: 'shop.liked.store.doc.url' })}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {formatMessage({
                            id: 'datatable.organizations.shop.info.message.external',
                        })}
                    </Hyperlink>
                </Text>
            </Flex>
            <DatatableProvider>
                <Datatable
                    data={linkedShopPagination}
                    emptyState={{
                        title: formatMessage({
                            id: 'datatable.organizations.no.store',
                        }),
                        subtitle: formatMessage({
                            id: 'datatable.organizations.no.store.subtitle',
                        }),
                        illustrationName: 'empty-shop',
                    }}
                    headings={headings}
                    id="list-shop-table"
                    loading={isLoading ? 'loading' : 'done'}
                    pagination={<DatatablePagination.Seek />}
                    toolbar={{
                        searchBar: {
                            id: SHOP_NAME_FILTER,
                            placeholder: formatMessage({
                                id: 'datatable.organizations.search.store.name',
                            }),
                        },
                    }}
                />
            </DatatableProvider>
        </organizationShopQueryParamsHelper.QueryParamsProvider>
    );
}

export default ListLinkedShop;
