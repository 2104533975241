import { useEffect, useMemo, useState } from 'react';

import {
    Panel,
    useErrorHandler,
    useIsUnderBreakpoint,
    useModal,
} from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import {
    fetchAvailableLanguages,
    fetchTimeZones,
} from '../../settings/api/profileSettingsApi';
import useProfileInfoContext from '../ProfileInfoProvider';

import _ProfileInfoAvatar from './internal/_ProfileInfoAvatar';
import _ProfileInfoContent from './internal/_ProfileInfoContent';
import _ProfileInfoEditButton from './internal/_ProfileInfoEditButton';
import _ProfileInfoEditModal from './internal/_ProfileInfoEditModal';
import _ProfileInfoIdentity from './internal/_ProfileInfoIdentity';
import _ProfileInfoItem from './internal/_ProfileInfoItem';
import _ProfileInfoItemList from './internal/_ProfileInfoItemList';
import _ProfileInfoPanelContent from './internal/_ProfileInfoPanelContent';

type LanguageType = {
    label: string;
    locale: string;
};

const ProfileInfoPanel = () => {
    const handleError = useErrorHandler();
    const { miraklSupport } = useLoginContext();
    const { profileInfo, updateProfileInfo } = useProfileInfoContext();
    const { showModal } = useModal();
    const [languages, setLanguages] = useState<LanguageType[]>([]);
    const [languagesLoading, setLanguagesLoading] = useState(true);
    const [timeZones, setTimeZones] = useState<string[]>([]);
    const [timeZonesLoading, setTimeZonesLoading] = useState(true);
    const isSmallScreen = useIsUnderBreakpoint('sm');

    useEffect(() => {
        fetchAvailableLanguages()
            .then((result) => {
                setLanguages(result);
                setLanguagesLoading(false);
            })
            .catch(handleError);
    }, [handleError]);

    useEffect(() => {
        fetchTimeZones()
            .then((result) => {
                setTimeZones(result);
                setTimeZonesLoading(false);
            })
            .catch(handleError);
    }, [handleError]);

    const isLoading = useMemo(
        () => languagesLoading || timeZonesLoading,
        [languagesLoading, timeZonesLoading]
    );

    const profileInfoLanguage = useMemo(
        () => languages.find((lang) => lang.locale === profileInfo?.language),
        [languages, profileInfo]
    );

    if (isLoading) {
        return <Panel loading />;
    }

    return (
        profileInfo && (
            <Panel>
                <_ProfileInfoPanelContent isSmallScreen={isSmallScreen}>
                    <_ProfileInfoAvatar />
                    <_ProfileInfoContent>
                        <_ProfileInfoIdentity>
                            {!miraklSupport.isSupportUser && (
                                <_ProfileInfoEditButton
                                    onClick={() =>
                                        showModal(
                                            <_ProfileInfoEditModal
                                                languages={languages}
                                                profileInfo={profileInfo}
                                                timeZones={timeZones}
                                                onEdit={updateProfileInfo}
                                            />
                                        )
                                    }
                                />
                            )}
                        </_ProfileInfoIdentity>
                        <_ProfileInfoItemList>
                            <_ProfileInfoItem
                                icon="language"
                                value={profileInfoLanguage?.label}
                            />
                            <_ProfileInfoItem
                                icon="watch-later"
                                value={profileInfo.timeZone}
                            />
                        </_ProfileInfoItemList>
                    </_ProfileInfoContent>
                </_ProfileInfoPanelContent>
            </Panel>
        )
    );
};

export default ProfileInfoPanel;
