import { useCallback, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Datatable,
    DatatableHeadingCellType,
    DatatablePagination,
    DatatableProvider,
    DatatableTrailingActionMenuItemType,
    DatatableTrailingActionMenuType,
    TextCellContent,
    useModal,
} from '@mirakl/roma';

import ShopOnTenantCellContent from '../../../common/datatable/ShopOnTenantCellContent';
import { UserProfileInfoCellContent } from '../../../common/datatable/UserProfileInfoCellContent';
import useLoginContext from '../../../config/login/LoginProvider';
import { DatatableLoadingType } from '../../../types/romaTypes';
import { StageValues } from '../../../types/stageTypes';
import useOrganizationContext from '../OrganizationContext';

import ConfirmUpdateUserRoleModal from './ConfirmUpdateUserRoleModal';
import UserTypeFilter from './UserTypeFilter';
import {
    useGrantAdministratorApiClient,
    useRemoveAdministratorApiClient,
} from './useOrganizationUsersApiClient';

export const COLUMN_PROFILE_KEY = 'profile';
export const COLUMN_LINKED_SHOPS_KEY = 'linkedStores';
export const COLUMN_USER_TYPE_KEY = 'userType';

export const USER_TYPE_FILTER = COLUMN_USER_TYPE_KEY;
export const USER_EMAIL_FILTER = 'email';

type UserShopsAssociations = {
    id: string;
    linkedStores: {
        shops: string[];
        stage: StageValues;
        tenantDisplayName: string;
    }[];
    profile: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    userType: 'ADMINISTRATOR' | 'MEMBER';
};

type OrganizationUsers = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: UserShopsAssociations[];
};

type OrganizationUsersDatatableProps = {
    loading: DatatableLoadingType;
    organizationUsersData: OrganizationUsers;
    refetch: () => void;
};

function OrganizationUsersDatatable({
    loading,
    organizationUsersData,
    refetch,
}: OrganizationUsersDatatableProps) {
    const { isOrganizationAdmin, uuid } = useOrganizationContext();
    const { email, miraklSupport } = useLoginContext();
    const { formatMessage } = useI18n();
    const { showModal } = useModal();
    const removeAdministratorApiClient = useRemoveAdministratorApiClient();
    const grantAdministratorApiClient = useGrantAdministratorApiClient();

    const renderTrailingActions = useCallback(
        (
            data: UserShopsAssociations
        ): DatatableTrailingActionMenuType<UserShopsAssociations> => {
            const items: DatatableTrailingActionMenuItemType[] = [];
            if (data.profile.email === email) {
                return {
                    a11yActionButtonLabel: formatMessage({
                        id: 'datatable.user.role.cannot.revoke',
                    }),
                    disabled: true,
                    items: [],
                };
            }

            const action =
                data.userType === 'ADMINISTRATOR'
                    ? {
                          operation: 'revoke',
                          apiClient: removeAdministratorApiClient,
                      }
                    : {
                          operation: 'grant',
                          apiClient: grantAdministratorApiClient,
                      };

            items.push({
                id: `${action.operation}-administrator-user`,
                label: formatMessage({
                    id: `datatable.user.role.${action.operation}.administrator`,
                }),
            });

            return {
                a11yActionButtonLabel: formatMessage({
                    id: `datatable.user.role.${action.operation}.administrator`,
                }),
                items,
                onItemClick: (rowData: UserShopsAssociations) => {
                    showModal(
                        <ConfirmUpdateUserRoleModal
                            apiCall={action.apiClient}
                            operation={action.operation}
                            organizationUuid={uuid}
                            refetch={refetch}
                            userEmail={rowData.profile.email}
                            userUuid={rowData.id}
                        />
                    );
                },
            };
        },
        [
            email,
            formatMessage,
            refetch,
            showModal,
            uuid,
            grantAdministratorApiClient,
            removeAdministratorApiClient,
        ]
    );

    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            {
                columnKey: COLUMN_PROFILE_KEY,
                label: formatMessage({
                    id: 'organization.users.listing.header.users',
                }),
                sortable: false,
                render: (profile: {
                    email: string;
                    firstName?: string;
                    lastName?: string;
                }) => <UserProfileInfoCellContent profileInfo={profile} />,
            },
            {
                columnKey: COLUMN_LINKED_SHOPS_KEY,
                label: formatMessage({ id: 'stores.linked' }),
                sortable: false,
                render: (
                    cellValue: {
                        shops: string[];
                        stage: StageValues;
                        tenantDisplayName: string;
                    }[]
                ) => <ShopOnTenantCellContent tenants={cellValue} />,
            },
            {
                columnKey: COLUMN_USER_TYPE_KEY,
                label: formatMessage({ id: 'user.type' }),
                sortable: true,
                initialSortDirection: 'ASC',
                render: (userType: string) => (
                    <TextCellContent
                        text={formatMessage({ id: `user.type.${userType}` })}
                    />
                ),
            },
        ],
        [formatMessage]
    );

    return (
        <DatatableProvider>
            <Datatable
                data={organizationUsersData}
                emptyState={null} // Cannot be empty
                headings={headings}
                id="organization-users-datatable"
                loading={loading}
                pagination={<DatatablePagination.Seek />}
                renderTrailingActions={
                    isOrganizationAdmin ||
                    miraklSupport.isSellerSupportReadWrite
                        ? renderTrailingActions
                        : undefined
                }
                resultNumberMessage={formatMessage(
                    { id: 'count.result' },
                    { 0: organizationUsersData.count.counted }
                )}
                toolbar={{
                    filters: {
                        [USER_TYPE_FILTER]: {
                            label: formatMessage({ id: 'user.type' }),
                            type: 'singleOption',
                            component: <UserTypeFilter />,
                        },
                    },
                    searchBar: {
                        id: USER_EMAIL_FILTER,
                        placeholder: formatMessage({
                            id: 'datatable.user.email.search',
                        }),
                    },
                }}
            />
        </DatatableProvider>
    );
}

export default OrganizationUsersDatatable;
