import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense } from 'react';

import { useI18n } from '@mirakl/i18n';
import { PageTitle, PageTitleBadgeType } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import OperatorUserGlobalLayout from '../../../common/layout/OperatorUserGlobalLayout';
import { SmtpStatus } from '../OperatorSmtpClientModelApi';
import {
    baseSmtpUrlAsOperator,
    useOperatorSmtpClientApi,
} from '../operatorSmtpClientApi';

import SmtpDetailsPanel from './component/SmtpDetailsPanel';

const getBadgeProps = (
    status: SmtpStatus,
    formatMessage: (value: { id: string }) => string
): PageTitleBadgeType => {
    switch (status) {
        case 'CONFIGURED':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.CONFIGURED.label',
                }),
                status: 'success',
            };
        case 'VERIFIED':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.VERIFIED.label',
                }),
                status: 'success',
            };
        case 'TO_VERIFY':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.TO_VERIFY.label',
                }),
                status: 'pending',
            };
        case 'DISABLED':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.DISABLED.label',
                }),
                status: 'warning',
            };
        case 'ERROR':
            return {
                label: formatMessage({
                    id: 'operator.smtp.status.ERROR.label',
                }),
                status: 'error',
            };
    }
};

const OperatorSmtpTitle = () => {
    const { formatMessage } = useI18n();
    const { getOperatorSmtpDetails } = useOperatorSmtpClientApi();

    const { id: operatorAccountId, smtpId } = useRequiredParams<{
        id: string;
        smtpId: string;
    }>();
    const { data } = useSuspenseQuery(
        getOperatorSmtpDetails(operatorAccountId, smtpId)
    );
    return (
        <PageTitle
            backLink={{
                label: formatMessage({
                    id: 'operator.smtp.details.back.label',
                }),
                link: {
                    to: baseSmtpUrlAsOperator(operatorAccountId),
                },
            }}
            badge={getBadgeProps(data.status, formatMessage)}
            title={data.name}
        />
    );
};

const OperatorSmtpDetailsPage = () => {
    return (
        <OperatorUserGlobalLayout>
            <Suspense fallback={<PageTitle loading />}>
                <OperatorSmtpTitle />
            </Suspense>
            <SmtpDetailsPanel />
        </OperatorUserGlobalLayout>
    );
};

export default OperatorSmtpDetailsPage;
