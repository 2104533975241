import { useEffect, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { OnboardingStepperLayout, PageLoader } from '@mirakl/roma';

import useQuery from '../../router/useQuery';

import SignupEmailContent from './SignupEmailContent';
import SignupLoggedInContent from './SignupLoggedInContent';
import useExternalSignup from './useExternalSignup';

/**
 * This signup page is meant to be used for a user that want to access mirakl from an outside page with no authentication.
 * - If the user is already logged in mirakl eco-system, we display a page to confirm the account he will
 *   access the mirakl product with
 * - If the user is not logged in, with which email he wants to access the mirakl product. It can be an existing auth0
 *   account, or not (in this case the user will have to create his account)
 */
const SignupExternalPage = () => {
    const { formatMessage } = useI18n();
    const { isReady, logout, onSubmit, user } = useExternalSignup();

    const param = useQuery({
        product_id: 'string',
        signup_hint: 'optionalString',
    });

    const product = useMemo(
        () =>
            formatMessage({
                id: `product.${param.product_id}`,
            }),
        [formatMessage, param.product_id]
    );

    const forceLogout = useMemo(
        () =>
            param.signup_hint &&
            user.isLoggedIn &&
            user.user.email !== param.signup_hint,
        [param.signup_hint, user.isLoggedIn, user.user?.email]
    );

    useEffect(() => {
        if (forceLogout) {
            // force logout if the user is logged in with the wrong email
            logout();
        }
    }, [logout, forceLogout]);

    if (!isReady || forceLogout) {
        return <PageLoader fullPage />;
    }
    return (
        <OnboardingStepperLayout
            illustration="onboarding-stepper"
            media={{
                url:
                    '/static/images/' +
                    formatMessage({
                        id: `product.${param.product_id}.logo`,
                    }),
                alt: formatMessage({
                    id: `product.${param.product_id}.logo.alt`,
                }),
                type: 'image',
            }}
            title={formatMessage(
                { id: 'signup.title' },
                {
                    product: product,
                }
            )}
        >
            {(user.isLoggedIn && (
                <SignupLoggedInContent
                    logout={logout}
                    product={product}
                    signupHint={param.signup_hint}
                    user={user.user}
                    onSubmit={onSubmit}
                />
            )) || (
                <SignupEmailContent
                    product={product}
                    signupHint={param.signup_hint}
                    onSubmit={onSubmit}
                />
            )}
        </OnboardingStepperLayout>
    );
};

export default SignupExternalPage;
