import { useI18n } from '@mirakl/i18n';
import { Select, SelectionListOptionGroupType } from '@mirakl/roma';

type Tenant = {
    id: string;
    logoUrl: string;
    name?: string;
};

type SelectMarketplaceComponentProps = {
    breakOutOfContainer?: boolean;
    connectorList: string[];
    handleChange?: (tenantId: string) => void;
    helpText?: string;
    name: string;
    placement: 'top-center' | 'bottom-center';
    required?: boolean;
    tenantList: Tenant[];
};

function SelectMarketplaceField({
    breakOutOfContainer = false,
    connectorList,
    handleChange,
    helpText,
    name,
    placement,
    required = false,
    tenantList,
}: SelectMarketplaceComponentProps) {
    const { formatMessage } = useI18n();
    const options: SelectionListOptionGroupType<string>[] = [
        {
            id: 'connectorMarketplaces',
            title: formatMessage({
                id: 'datatable.organizations.marketplaces.connector.label',
            }),
            options: connectorList.map((connector) => ({
                id: connector,
                label: formatMessage({ id: `connector.${connector}` }),
                value: connector,
                media: {
                    url:
                        '/static/images/' +
                        formatMessage({ id: `connector.${connector}.logo` }),
                    type: 'image',
                    alt: formatMessage({ id: `connector.${connector}.alt` }),
                },
            })),
        },
        {
            id: 'miraklMarketplaces',
            title: formatMessage({
                id: 'datatable.organizations.marketplaces.mirakl.label',
            }),
            options: tenantList.map((tenant) => ({
                id: tenant.id,
                label: tenant.name ?? tenant.id,
                value: tenant.id,
                media: {
                    url: tenant.logoUrl,
                    type: 'image',
                    alt: tenant.id,
                },
            })),
        },
    ];
    const placeholder = {
        id: 'placeholder',
        value: '',
        label: `-- ${formatMessage({ id: 'select.no.value' })} --`,
    };

    return (
        <Select
            breakOutOfContainer={breakOutOfContainer}
            helpText={helpText}
            label={
                formatMessage({
                    id: 'datatable.organizations.marketplace.label',
                }) as string
            }
            name={name}
            optionFilterPlaceholder={formatMessage({
                id: 'datatable.filter.placeholder',
            })}
            options={options}
            placeholderOption={placeholder}
            placement={placement}
            required={required}
            onChange={handleChange}
        />
    );
}

export default SelectMarketplaceField;
