import { useI18n } from '@mirakl/i18n';
import {
    Checkbox,
    Form,
    Modal,
    Paragraph,
    useForm,
    useNavigate,
} from '@mirakl/roma';

type OwnershipFormType = {
    ['ownership']: boolean;
};

type OwnershipModalProps = {
    email: string;
    linkShops: (
        email: string,
        shopUuidsToLink: string[] | 'all'
    ) => Promise<void>;
    organizationUuid: string;
    shopUuidsToLink: string[] | 'all';
};

// TODO Will replace OwnershipModal with this ticket ACCOUNT-899
const OwnershipModal = ({
    email,
    linkShops,
    organizationUuid,
    shopUuidsToLink,
}: OwnershipModalProps) => {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();

    const formData = useForm<OwnershipFormType>({
        defaultValues: { ownership: false },
    });

    const {
        formState: { isSubmitting },
        handleSubmit,
    } = formData;

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'organization.stepper.stores.linking.step.title',
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Form {...formData}>
                        <Checkbox
                            label={formatMessage({
                                id: 'organization.link.stores.confirm.ownership',
                            })}
                            name="ownership"
                            required
                        />
                        <Paragraph noSpaceBottom={false}>
                            {formatMessage({
                                id: 'organization.link.stores.notification',
                            })}
                        </Paragraph>
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'organization.link.store.accounts',
                    }),
                    onClick: (close, event) => {
                        return handleSubmit(() => {
                            linkShops(email, shopUuidsToLink).then(() => {
                                close();
                                return navigate({
                                    to: `/organizations/${organizationUuid}/shops/linked-shops`,
                                });
                            });
                        })(event);
                    },
                    state: isSubmitting ? 'loading' : undefined,
                }}
                dismissButton={{
                    label: formatMessage({
                        id: 'button.cancel',
                    }),
                }}
            />
        </Modal>
    );
};

export default OwnershipModal;
