import { Navigate, Route, useResolvedPath } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import ProfileInfoPage from './information/ProfileInfoPage';
import ProfileGlobalLayout from './layout/ProfileGlobalLayout';
import ProfileSecurityPage from './security/ProfileSecurityPage';

const ProfilePages = () => {
    const baseUrl = useResolvedPath('').pathname;

    return (
        <ProfileGlobalLayout>
            <Routes>
                <Route element={<ProfileSecurityPage />} path="/security" />
                <Route element={<ProfileInfoPage />} path="/information" />
                <Route
                    element={<Navigate to={`${baseUrl}/information`} />}
                    index
                />
            </Routes>
        </ProfileGlobalLayout>
    );
};

export default ProfilePages;
