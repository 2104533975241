import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    CheckboxGroup,
    Form,
    Modal,
    Paragraph,
    useForm,
    useNavigate,
    useSnackbar,
} from '@mirakl/roma';

import { StageValues } from '../../../types/stageTypes';
import { ShopListPayload } from '../shop/useListShopsApi';

const SHOPS_FIELD = 'shops-field';

type ShopUnlinkCheckboxOptionsType = {
    helpText: string;
    id: string;
    label: string;
    value: string;
}[];

type UnlinkShopsFormType = {
    [SHOPS_FIELD]: string[];
};

function OrganizationShopUnlinkModal({
    getShopsCallback,
    organizationUuid,
    unlinkShopCallback,
}: {
    getShopsCallback: (organizationUuid: string) => Promise<ShopListPayload[]>;
    organizationUuid: string;
    unlinkShopCallback: (
        organizationUuid: string,
        shopUuids: string[]
    ) => Promise<AxiosResponse | void>;
}) {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [shopData, setShopData] = useState<ShopListPayload[]>([]);

    useEffect(() => {
        getShopsCallback(organizationUuid)
            .then((data) => {
                setShopData(data);
                setIsLoading(false);
            })
            .catch(() =>
                addSnackbar({
                    status: 'error',
                    message: formatMessage({ id: 'snackbar.load.fail' }),
                })
            );
    }, [addSnackbar, formatMessage, getShopsCallback, organizationUuid]);

    const formData = useForm<UnlinkShopsFormType>({
        defaultValues: {
            [SHOPS_FIELD]: [],
        },
    });

    const {
        formState: { isSubmitting },
        handleSubmit,
        watch,
    } = formData;

    const shouldShowStage = shopData.some(
        (element) => element.stage !== 'PROD'
    );

    const generateLabel = (tenantDisplayName: string, stage: StageValues) => {
        if (shouldShowStage) {
            return formatMessage(
                { id: 'tenant.stage.inline' },
                {
                    0: tenantDisplayName,
                    1: formatMessage({ id: `shop.stage.${stage}` }),
                }
            );
        }
        return tenantDisplayName;
    };

    const formattedOptions: ShopUnlinkCheckboxOptionsType = shopData.map(
        (shop) => ({
            id: shop.uuid,
            label: generateLabel(shop.tenantName ?? shop.tenantId, shop.stage),
            helpText: shop.name,
            value: shop.uuid,
        })
    );

    const onValid = (close: () => void) => (values: UnlinkShopsFormType) => {
        return unlinkShopCallback(organizationUuid, values[SHOPS_FIELD])
            .then(() =>
                addSnackbar({
                    status: 'success',
                    message: formatMessage({
                        id: 'organization.shop.unlink.modal.snackbar.success.message',
                    }),
                })
            )
            .then(close)
            .then(() =>
                navigate({
                    to: `/organizations/${organizationUuid}/shops/linked-shops`,
                })
            )
            .catch(() => {
                addSnackbar({
                    status: 'error',
                    message: formatMessage({ id: 'snackbar.edit.fail' }),
                });
            });
    };

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'organization.shop.unlink.modal.title.label',
                })}
            />
            <Modal.Scrollable loading={isLoading}>
                <Modal.Content>
                    <Form {...formData}>
                        <Paragraph>
                            {formatMessage({
                                id: 'organization.shop.unlink.modal.content.label',
                            })}
                        </Paragraph>
                        <CheckboxGroup
                            column={1}
                            label={formatMessage({
                                id: 'organization.shop.unlink.modal.field.shops.label',
                            })}
                            name={SHOPS_FIELD}
                            required
                        >
                            {formattedOptions.map(
                                ({ helpText, id, label, value }) => (
                                    <CheckboxGroup.Checkbox
                                        helpText={helpText}
                                        key={id}
                                        label={label}
                                        value={value}
                                    />
                                )
                            )}
                        </CheckboxGroup>
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'organization.shop.unlink.modal.button.unlink.label',
                    }),
                    state: isSubmitting ? 'loading' : 'default',
                    onClick: (close, event) =>
                        handleSubmit(onValid(close))(event),
                    disabled: watch(SHOPS_FIELD).length === 0,
                }}
            />
        </Modal>
    );
}

export default OrganizationShopUnlinkModal;
