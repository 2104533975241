import axios from 'axios';
import { ReactNode, createContext, useContext, useState } from 'react';

import { I18nProvider as MiraklI18nProvider } from '@mirakl/i18n';

import { useDefaultLocale } from './useDefaultLocale';

type LocalContextType = {
    locale: string;
    setLocale(locale: string): void;
};

const LocaleContext = createContext<null | LocalContextType>(null);

type I18nProviderProps = {
    children: ReactNode;
};

export const useLocaleContext = () => {
    const context = useContext(LocaleContext);
    if (!context) {
        throw new Error('LocaleContext must be used inside I18nProvider');
    }
    return context;
};

const importMessagesForLocale = ({
    prefixedJavaLocale,
}: {
    prefixedJavaLocale: string;
}) => {
    return import(
        /* webpackMode: "lazy", webpackChunkName: "intl-[request]" */ `../../../temp/i18n/messages${prefixedJavaLocale}.json`
    );
};

const fetchTranslationServerMessages = (currentLocale: string) =>
    axios
        .get(`/translations/${currentLocale}`)
        .then((response) => response.data);

const I18nProvider = ({ children }: I18nProviderProps) => {
    const defaultLocale = useDefaultLocale();
    const [locale, setLocale] = useState(defaultLocale);

    return (
        /**
         * This I18nFetcherContext is needed because we can fetch either unauthenticated or
         * authenticated translations depending on the pages. However, we can't know at this point if
         * the user is authenticated nor his locale since we must keep the following provider's hierarchy:
         * I18nProvider > RomaProvider > Auth0ConfigurationProvider
         *
         * Thanks to this context, when the user logs in it will fetch again the authenticated translations
         * by using the real user's locale. On unauthenticated page, unauthenticated translations will be fetched
         * by using the browser's locale.
         */
        <LocaleContext.Provider
            value={{
                locale,
                setLocale,
            }}
        >
            <MiraklI18nProvider
                fetchTranslationServerMessages={fetchTranslationServerMessages}
                importMessagesForLocale={importMessagesForLocale}
                locale={locale}
            >
                {children}
            </MiraklI18nProvider>
        </LocaleContext.Provider>
    );
};

export default I18nProvider;
