import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Button,
    Flex,
    Form,
    LinkButton,
    Panel,
    RadioGroup,
    Scrollable,
    SpacingWrapper,
    useErrorHandler,
    useForm,
    useNavigate,
} from '@mirakl/roma';

import { basePath } from '../../Root';
import useLoginContext from '../../config/login/LoginProvider';
import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';
import useQuery from '../../router/useQuery';
import useSignupRedirect from '../useSignupRedirect';

import SignupOrganizationCardText from './SignupOrganizationCardText';

type OrganizationChosenFormData = {
    organizationRadioGroup: string | undefined;
};

type OrganizationDetailsType = {
    address: string;
    businessRegistrationNumber: string;
    id: string;
    inlinedAddress: string;
    name: string;
    taxIdNumber: string;
};

type OrganizationsList = {
    organizations: OrganizationDetailsType[];
};

type SignupOrganizationSelectorFormProps = {
    callback: string;
    loginHint: string;
    product: string;
    type: string;
};

const SignupOrganizationSelectorForm = ({
    callback,
    loginHint,
    product,
    type,
}: SignupOrganizationSelectorFormProps) => {
    const { apiGet, apiPost } = useAuthenticatedFetch();
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const { formatMessage, locale } = useI18n();

    const { email } = useLoginContext();
    const handleSignupRedirect = useSignupRedirect();
    const { search } = useLocation();
    const param = useQuery({
        create_organization: 'boolean',
        organization_selector: 'boolean',
    });

    const [isLoading, setLoading] = useState<boolean>(true);

    const formData = useForm<OrganizationChosenFormData>({
        defaultValues: { organizationRadioGroup: undefined },
    });

    const {
        formState: { isDirty, isSubmitting, isValid },
        handleSubmit,
    } = formData;

    const [organizationOptions, setOrganizationOptions] =
        useState<OrganizationDetailsType[]>();

    const restartSignupFlow = useCallback(() => {
        apiPost(`/private/signup/restart${search}`, {
            email,
            type: type,
            callbackUrl: callback,
            productId: product,
            customizations: {
                createOrganization: param.create_organization,
                organizationSelector: param.organization_selector,
            },
            locale: locale,
        })
            .then(({ data }) =>
                navigate({
                    to: `${data.location}`,
                    shouldNotShowConfirmModal: true,
                })
            )
            .catch(handleError);
    }, [
        apiPost,
        callback,
        email,
        handleError,
        locale,
        navigate,
        param.create_organization,
        param.organization_selector,
        product,
        search,
        type,
    ]);

    const validateOrganizationChoice = useCallback(
        (organizationUuid: string) => {
            return apiPost(`/private/signup/organization/link${search}`, {
                type: type,
                callbackUrl: callback,
                productId: product,
                organizationUuid: organizationUuid,
            }).then(({ data }) => handleSignupRedirect(data));
        },
        [apiPost, callback, handleSignupRedirect, product, search, type]
    );

    useEffect(() => {
        setLoading(true);
        // If the logged in user is not the same as the initial user, we force the user to begin a new flow
        if (email !== loginHint) {
            restartSignupFlow();
        }
        apiGet<OrganizationsList>('/private/organizations', {
            params: { email },
        })
            .then(({ data }) => {
                setOrganizationOptions(data.organizations);
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(handleError);
    }, [apiGet, email, loginHint, handleError, restartSignupFlow]);

    const onSubmit = (values: OrganizationChosenFormData) => {
        const organizationUuidToBeLinked = values.organizationRadioGroup;
        if (organizationUuidToBeLinked === undefined) {
            throw Error('There must be one organization selected');
        }

        return validateOrganizationChoice(organizationUuidToBeLinked);
    };

    const goToOrganizationCreatePage = useCallback(() => {
        navigate({
            to: `${basePath}/organizations/create${search}`,
            shouldNotShowConfirmModal: true,
            state: {
                backUrl: `${location.pathname}${search}`,
                skipIntro: true,
            },
        });
    }, [search, navigate]);

    return isLoading || organizationOptions === undefined ? (
        <Panel loading loadingVariant="form" />
    ) : (
        <Box>
            <Form {...formData}>
                <Scrollable fullWidth maxHeight="50vh">
                    <Box fullWidth>
                        <RadioGroup
                            a11yLabel={formatMessage({
                                id: 'signup.organization.choice.form.radio.a11y.label',
                            })}
                            column={1}
                            name="organizationRadioGroup"
                        >
                            {organizationOptions.map((organization) => (
                                <RadioGroup.Card
                                    helpText={
                                        <SignupOrganizationCardText
                                            businessRegistrationNumber={
                                                organization.businessRegistrationNumber
                                            }
                                            inlinedAddress={
                                                organization.inlinedAddress
                                            }
                                            taxIdNumber={
                                                organization.taxIdNumber
                                            }
                                        />
                                    }
                                    key={organization.id}
                                    label={organization.name}
                                    value={organization.id}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </Scrollable>
                <SpacingWrapper>
                    <LinkButton
                        iconName="loading"
                        iconPosition="start"
                        label={formatMessage({
                            id: 'signup.organization.choice.create.organization',
                        })}
                        onClick={goToOrganizationCreatePage}
                    />
                </SpacingWrapper>
                <Flex justifyContent="end">
                    <Button
                        disabled={!isDirty || !isValid}
                        label={formatMessage({ id: 'signup.continue' })}
                        state={isSubmitting ? 'loading' : 'default'}
                        submit
                        onClick={handleSubmit(onSubmit)}
                    />
                </Flex>
            </Form>
        </Box>
    );
};

export default SignupOrganizationSelectorForm;
