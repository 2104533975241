import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

type PasswordInfoPayload = {
    lastPasswordReset?: string;
};

const useProfileSecurityApi = () => {
    const { apiGet, apiPost } = useAuthenticatedFetch();

    const fetchPasswordInfo = useCallback(
        () => apiGet<PasswordInfoPayload>('/private/user/password'),
        [apiGet]
    );

    const performPasswordReset = useCallback(
        () => apiPost('/private/user/password/reset', {}),
        [apiPost]
    );

    return {
        fetchPasswordInfo,
        performPasswordReset,
    };
};

export default useProfileSecurityApi;
