import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { PageLoader, useErrorHandler } from '@mirakl/roma';

import { useLocaleContext } from '../../config/i18n/I18nProvider';
import { useDefaultLocale } from '../../config/i18n/useDefaultLocale';
import useLoginContext from '../../config/login/LoginProvider';

import useProfileInfoApi from './api/useProfileInfoApi';
import useProfileInfoColor, {
    ProfileInfoColorType,
} from './hooks/useProfileInfoColor';
import useProfileInfoFullName from './hooks/useProfileInfoFullName';
import useProfileInfoInitials from './hooks/useProfileInfoInitials';

type ProfileInfoType = {
    email: string;
    firstName?: string;
    language?: string;
    lastName?: string;
    timeZone?: string;
};

type ProfileInfoUpdateType = {
    firstName: string;
    language: string;
    lastName: string;
    timeZone: string;
};

type ProfileInfoContextType = {
    profileInfo: ProfileInfoType & {
        color: ProfileInfoColorType;
        fullName: string;
        initials: string;
    };
    updateProfileInfo: (
        profileInfoUpdate: ProfileInfoUpdateType
    ) => Promise<void>;
};

const ProfileInfoContext = createContext<ProfileInfoContextType | null>(null);

const useProfileInfoContext = () => {
    const context = useContext(ProfileInfoContext);
    if (!context) {
        throw new Error(
            'Cannot access user profile info outside of ProfileInfoProvider'
        );
    }
    return context;
};

export const ProfileInfoProvider = ({ children }: { children: ReactNode }) => {
    const { email: userLoginEmail, miraklSupport } = useLoginContext();
    const defaultLocale = useDefaultLocale();
    const { setLocale } = useLocaleContext();
    const {
        fetchProfileInfo: fetchProfileInfoApi,
        updateProfileInfo: updateProfileInfoApi,
    } = useProfileInfoApi();
    const defaultProfileInfo = useMemo(
        () => ({
            email: userLoginEmail,
            language: defaultLocale,
        }),
        [userLoginEmail, defaultLocale]
    );
    const [profileInfo, setProfileInfo] =
        useState<ProfileInfoType>(defaultProfileInfo);
    const [profileInfoLoading, setProfileInfoLoading] = useState(true);
    const handleError = useErrorHandler();
    const fullName = useProfileInfoFullName(profileInfo);
    const initials = useProfileInfoInitials(profileInfo);
    const color = useProfileInfoColor(profileInfo);

    const updateProfileInfo = useCallback(
        (profileInfoUpdate: ProfileInfoUpdateType) =>
            updateProfileInfoApi(profileInfoUpdate)
                .then((result) => {
                    setProfileInfo(result);
                    if (result.language) {
                        setLocale(result.language);
                    }
                })
                .catch(handleError),
        [updateProfileInfoApi, handleError, setLocale]
    );

    useEffect(() => {
        if (miraklSupport.isSupportUser) {
            setProfileInfoLoading(false);
            return;
        }
        fetchProfileInfoApi()
            .then((result) => {
                setProfileInfo(result);
                if (result.language) {
                    setLocale(result.language);
                }
            })
            .catch(handleError)
            .finally(() => setProfileInfoLoading(false));
    }, [
        miraklSupport,
        defaultProfileInfo,
        fetchProfileInfoApi,
        handleError,
        setLocale,
    ]);

    return (
        <>
            {profileInfoLoading && <PageLoader fullPage />}
            {!profileInfoLoading && (
                <ProfileInfoContext.Provider
                    value={{
                        profileInfo: {
                            ...profileInfo,
                            fullName,
                            initials,
                            color,
                        },
                        updateProfileInfo,
                    }}
                >
                    {children}
                </ProfileInfoContext.Provider>
            )}
        </>
    );
};

export default useProfileInfoContext;
