import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useErrorHandler } from '@mirakl/roma';

import { isConsentRequired, isLoginRequired } from '../config/login/auth0Utils';
import { Auth0User } from '../types/auth0Types';

import { signupLoginStateManager } from './signupLoginStateManager';
import { useSilentLogin } from './useSilentLogin';

const useLogin = () => {
    const {
        error,
        isAuthenticated,
        isLoading,
        logout: logoutWithRedirect,
        user,
    } = useAuth0();
    const { shouldPerformLogin, silentLogin } = useSilentLogin({
        redirectUri: `${window.location.origin}/signup/callback`,
    });
    const { pathname, search } = useLocation();
    const handleError = useErrorHandler();

    // Set/Get location after authentication flow end
    const redirectTo = useMemo(() => {
        const originalRedirect = signupLoginStateManager.redirect;
        if (!originalRedirect) {
            const redirect = `${pathname}${search}`;
            signupLoginStateManager.redirect = redirect;
            return redirect;
        }
        return originalRedirect;
    }, [pathname, search]);

    const logout = useCallback(() => {
        // redirect to the signup page with the right param and signature after logout
        logoutWithRedirect({
            logoutParams: {
                returnTo: `${window.location.origin}${redirectTo}`,
            },
        });
    }, [logoutWithRedirect, redirectTo]);

    useEffect(() => {
        if (!isLoading && !error) {
            // user is not logged in, try to login silently
            if (shouldPerformLogin && !isAuthenticated) {
                silentLogin();
            } else {
                signupLoginStateManager.deleteRedirect();
            }
        } else if (!isLoading && isLoginRequired(error)) {
            // this is not an error, the user is just definitely not authenticated
            signupLoginStateManager.deleteRedirect();
        } else if (!isLoading && isConsentRequired(error)) {
            handleError(
                new Error(
                    'Consent failed. This error only happens on localhost. To get rid of it, see the troubleshooting part of the account-web-front README'
                )
            );
        } else if (!isLoading && error) {
            handleError(
                new Error(`Authentication failed: ${JSON.stringify(error)}`)
            );
        }
    }, [
        error,
        handleError,
        isAuthenticated,
        isLoading,
        shouldPerformLogin,
        silentLogin,
    ]);

    return {
        /* be careful before touching the isReady definition, this is quite sensitive */
        isReady: !isLoading && (isAuthenticated || isLoginRequired(error)),
        user: {
            isLoggedIn: isAuthenticated && !!user,
            user: user as Auth0User,
        },
        logout,
    };
};

export default useLogin;
