import { useI18n } from '@mirakl/i18n';
import {
    LayoutContainer,
    LayoutItem,
    PageLayout,
    PageTitle,
} from '@mirakl/roma';

import nullifyPayload from '../../common/nullifyPayload';
import useOrganizationContext from '../../organization/run/OrganizationContext';
import { BackLinkType } from '../../types/romaTypes';
import {
    WarehouseApiType,
    WarehouseCreateRequestApiType,
} from '../api/useWarehouseApiClient';

import WarehouseForm, { WarehouseFormType } from './WarehouseForm';

export const toFormValues = (
    warehouseApiPayload: WarehouseApiType
): WarehouseFormType => ({
    code: warehouseApiPayload.code,
    name: warehouseApiPayload.name,
    type: warehouseApiPayload.type,
    city: warehouseApiPayload.address.city,
    state: warehouseApiPayload.address.state,
    address: warehouseApiPayload.address.street_1,
    addressContinued: warehouseApiPayload.address.street_2,
    countryCode: warehouseApiPayload.address.country_iso_code,
    postalCode: warehouseApiPayload.address.zip_code,
    shops: warehouseApiPayload.shops,
});
export const toApiPayload = (
    warehouseFormValues: WarehouseFormType
): WarehouseCreateRequestApiType =>
    nullifyPayload({
        code: warehouseFormValues.code,
        name: warehouseFormValues.name,
        type: warehouseFormValues.type,
        address: {
            city: warehouseFormValues.city,
            state: warehouseFormValues.state,
            street_1: warehouseFormValues.address,
            street_2: warehouseFormValues.addressContinued,
            country_iso_code: warehouseFormValues.countryCode,
            zip_code: warehouseFormValues.postalCode,
        },
        shopIds: warehouseFormValues.shops.map((shop) => shop.id),
    });

export type UpsertWarehousePagePropsType = {
    confirmModalOnSave?: boolean;
    disableCode?: boolean;
    initialValues?: WarehouseFormType | undefined;
    loading?: boolean;
    onSubmit(
        defaultValues: WarehouseFormType,
        values: WarehouseFormType
    ): Promise<unknown>;
    pageTitle: string;
};

const WarehousePage = ({
    confirmModalOnSave = false,
    disableCode = false,
    initialValues,
    loading = false,
    onSubmit,
    pageTitle,
}: UpsertWarehousePagePropsType) => {
    const { uuid: organizationUuid } = useOrganizationContext();
    const { formatMessage } = useI18n();

    const backLink: BackLinkType = {
        label: formatMessage({ id: 'organizations.warehouses.form.link.back' }),
        link: {
            to: `/organizations/${organizationUuid}/warehouses`,
        },
    };

    return (
        !loading && (
            <>
                <PageTitle
                    backLink={backLink}
                    title={formatMessage({ id: pageTitle })}
                />
                <PageLayout size="sm">
                    <LayoutContainer>
                        <LayoutItem>
                            <WarehouseForm
                                confirmModalOnSave={confirmModalOnSave}
                                defaultValues={initialValues}
                                disableCode={disableCode}
                                onSubmit={onSubmit}
                            />
                        </LayoutItem>
                    </LayoutContainer>
                </PageLayout>
            </>
        )
    );
};

export default WarehousePage;
