import { FormattedMessage } from 'react-intl';

import { useI18n } from '@mirakl/i18n';
import {
    Grid,
    Label,
    Panel,
    Paragraph,
    SpacingWrapper,
    Title,
} from '@mirakl/roma';

import ValueFieldWithCopy from './ValueFieldWithCopy';

type SmtpDetailsEmailAuthenticationPanelProps = {
    dnsSmtpAuthenticationRecords: Map<string, string>;
    domain: string;
};

const SmtpDetailsEmailAuthenticationPanel = ({
    dnsSmtpAuthenticationRecords,
    domain,
}: SmtpDetailsEmailAuthenticationPanelProps) => {
    const { formatMessage } = useI18n();

    return (
        <Panel>
            <Panel.Header
                title={formatMessage({
                    id: 'operator.smtp.details.email.authentication',
                })}
            />
            <Panel.Content>
                <Title tag="h3">
                    <FormattedMessage
                        id="operator.smtp.details.email.authentication.domain.title"
                        values={{ domain: domain }}
                    />
                </Title>
                <Paragraph>
                    <FormattedMessage id="operator.smtp.details.email.authentication.domain.description" />
                </Paragraph>
                <SpacingWrapper>
                    <Grid columnsWidth={['flex', 'flex']} rowGap={3}>
                        <Label
                            id="host"
                            label={formatMessage({
                                id: 'operator.smtp.details.email.authentication.domain.host',
                            })}
                            noSpaceBottom
                        />
                        <Label
                            id="value"
                            label={formatMessage({
                                id: 'operator.smtp.details.email.authentication.domain.value',
                            })}
                            noSpaceBottom
                        />
                        {Array.from(dnsSmtpAuthenticationRecords.entries()).map(
                            ([key, value]) => [
                                <ValueFieldWithCopy
                                    a11yLabel={formatMessage(
                                        {
                                            id: 'operator.smtp.details.email.authentication.domain.host.a11y',
                                        },
                                        { host: key }
                                    )}
                                    key={`grid-${key}`}
                                    name={`host-${key}`}
                                    value={key}
                                />,
                                <ValueFieldWithCopy
                                    a11yLabel={formatMessage(
                                        {
                                            id: 'operator.smtp.details.email.authentication.domain.value.a11y',
                                        },
                                        {
                                            host: key,
                                            value: value,
                                        }
                                    )}
                                    key={`grid-${value}`}
                                    name={`value-${value}`}
                                    value={value}
                                />,
                            ]
                        )}
                    </Grid>
                </SpacingWrapper>
            </Panel.Content>
        </Panel>
    );
};

export default SmtpDetailsEmailAuthenticationPanel;
