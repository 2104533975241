import { TextCellContent } from '@mirakl/roma';

import useProfileInfoColor from '../../profile/information/hooks/useProfileInfoColor';
import useProfileInfoFullName from '../../profile/information/hooks/useProfileInfoFullName';
import useProfileInfoInitials from '../../profile/information/hooks/useProfileInfoInitials';

export const UserProfileInfoCellContent = ({
    profileInfo,
}: {
    profileInfo: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
}) => {
    const { email } = profileInfo;
    const fullName = useProfileInfoFullName(profileInfo);
    const initials = useProfileInfoInitials(profileInfo);
    const color = useProfileInfoColor(profileInfo);
    return (
        <TextCellContent
            media={{
                type: 'avatar',
                initials: initials,
                color: color,
            }}
            subtext={email !== fullName ? email : undefined}
            text={fullName}
        />
    );
};
