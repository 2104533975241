import { useI18n } from '@mirakl/i18n';
import { OnboardingStepperLayout, Paragraph } from '@mirakl/roma';

import useQuery from '../../router/useQuery';

import SignupOrganizationSelectorForm from './SignupOrganizationSelectorForm';

const SignupOrganizationSelectorPage = () => {
    const { formatMessage } = useI18n();

    const param = useQuery({
        login_hint: 'string',
        type: 'string',
        product_id: 'string',
        callback: 'string',
        create_organization: 'boolean',
        organization_selector: 'boolean',
    });
    const product = formatMessage({ id: `product.${param.product_id}` });

    return (
        <OnboardingStepperLayout
            illustration="onboarding-stepper"
            media={{
                url:
                    '/static/images/' +
                    formatMessage({
                        id: `product.${param.product_id}.logo`,
                    }),
                alt: formatMessage({
                    id: `product.${param.product_id}.logo.alt`,
                }),
                type: 'image',
            }}
            title={formatMessage(
                { id: 'signup.title' },
                {
                    product: product,
                }
            )}
        >
            <Paragraph>
                {formatMessage(
                    {
                        id: 'signup.organization.choice.subtitle',
                    },
                    {
                        product: product,
                    }
                )}
            </Paragraph>
            <SignupOrganizationSelectorForm
                callback={param.callback}
                loginHint={param.login_hint}
                product={param.product_id}
                type={param.type}
            />
        </OnboardingStepperLayout>
    );
};

export default SignupOrganizationSelectorPage;
