import { useFeatureToggle } from '../../../../../common/toggle/FeatureToggleProvider';
import useOperatorAccountContext from '../../../../OperatorAccountContext';

const useTicketingLicenceEnabled = () => {
    const { isFeatureEnable } = useFeatureToggle();
    const { isClientAccount } = useOperatorAccountContext();
    return {
        isEnabled: isClientAccount && isFeatureEnable('ticketing-licence'),
    };
};

export default useTicketingLicenceEnabled;
