import React, { ReactNode, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { LayoutContainer, Page, PageLayout, PageTitle } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import { basePath } from '../../../Root';
import AccountGlobalLayout from '../../../common/layout/AccountGlobalLayout';
import { useFeatureToggle } from '../../../common/toggle/FeatureToggleProvider';
import useOperatorAccountContext from '../../OperatorAccountContext';
import useOperatorAccountsUrls from '../../useOperatorAccountsUrls';
import useTicketingLicenceEnabled from '../../users/ticketing/licence/utils/useTicketingLicenceEnabled';

type OperatorAccountDetailsLayoutPropsType = {
    children: ReactNode | ReactNode[];
    size: 'sm' | 'md' | 'lg';
};

const SupportOperatorAccountDetailsLayout = ({
    children,
    size,
}: OperatorAccountDetailsLayoutPropsType) => {
    const { formatMessage } = useI18n();
    const { id } = useRequiredParams<{ id: string }>();
    const { name } = useOperatorAccountContext();
    const { defaultListingUrl } = useOperatorAccountsUrls();
    const { isFeatureEnable } = useFeatureToggle();
    const isSmtpFeatureEnabled = isFeatureEnable('smtp');
    const { isEnabled: isTicketingLicenceEnabled } =
        useTicketingLicenceEnabled();

    const navigationItems = useMemo(() => {
        const items = [
            {
                id: 'company-information-tab',
                label: formatMessage({
                    id: 'operator.accounts.detail.company.information',
                }),
                to: `${basePath}/operators/${id}/company`,
            },
            {
                id: 'company-users-tab',
                label: formatMessage({
                    id: 'operator.accounts.users.listing.title',
                }),
                to: `${basePath}/operators/${id}/company/users`,
            },
        ];
        if (isSmtpFeatureEnabled) {
            items.push({
                id: 'company-smtp-tab',
                label: formatMessage({
                    id: 'operator.smtp.title',
                }),
                to: `${basePath}/operators/${id}/company/smtp-configurations`,
            });
        }
        if (isTicketingLicenceEnabled) {
            items.push({
                id: 'company-users-ticketing-licence-tab',
                label: formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.title',
                }),
                to: `${basePath}/operators/${id}/company/users/ticketing/licences`,
            });
        }
        return items;
    }, [formatMessage, id, isSmtpFeatureEnabled, isTicketingLicenceEnabled]);

    return (
        <AccountGlobalLayout>
            <Page>
                <PageTitle
                    backLink={{
                        link: {
                            to: defaultListingUrl,
                        },
                        label: formatMessage({
                            id: 'operator.accounts.detail.back.link',
                        }),
                    }}
                    loading={!name}
                    navigationItems={navigationItems}
                    title={name}
                />
                <PageLayout size={size}>
                    <LayoutContainer>{children}</LayoutContainer>
                </PageLayout>
            </Page>
        </AccountGlobalLayout>
    );
};

export default SupportOperatorAccountDetailsLayout;
