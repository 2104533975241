import { ReactNode, useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Flex,
    IconButton,
    Media,
    Panel,
    Title,
    themeColor,
    themeElevation,
    themeRadius,
    themeSpace,
    useErrorHandler,
    useModal,
} from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import useOperatorAccountContext from '../../OperatorAccountContext';
import { useOperatorAccountClientApi } from '../../operatorAccountClientApi';

import CompanyInformationEditModal, {
    LogoConfigType,
} from './CompanyInformationEditModal';

const PersonalInfoPanelContainer = styled(Flex).attrs(() => ({
    flexDirection: 'column',
    width: '100%',
    paddingBottom: 4,
}))`
    overflow-wrap: anywhere;
    background-color: ${themeColor('white', 0)};
    border-radius: ${themeRadius(1)};
    box-shadow: ${themeElevation(300)};
    padding-top: 0;
`;

const PersonalInfoPanel = ({
    children,
    loading,
}: {
    children: ReactNode;
    loading?: boolean;
}) => {
    if (loading) {
        return <Panel loading />;
    }
    return <PersonalInfoPanelContainer>{children}</PersonalInfoPanelContainer>;
};

const PersonalInfoBanner = styled.div`
    background-color: ${themeColor('info-light')};
    height: 120px;
    width: 100%;
`;

const CompanyDetailsLogoContainer = styled(Box)`
    height: ${themeSpace(11)};
    width: ${themeSpace(11)};
    margin-top: calc(-${themeSpace(11)} / 2);
    margin-left: calc(50% - ${themeSpace(11)} / 2);
`;

const CompanyInformationLogo = () => {
    const { logoUrl } = useOperatorAccountContext();
    return (
        <CompanyDetailsLogoContainer>
            {logoUrl ? (
                <Media alt="logo" size={11} type="image" url={logoUrl} />
            ) : (
                <Media size={11} type="image" />
            )}
        </CompanyDetailsLogoContainer>
    );
};

const CompanyInformation = () => {
    const { formatMessage } = useI18n();
    const { showModal } = useModal();
    const handleError = useErrorHandler();

    const [logoConfig, setLogoConfig] = useState<LogoConfigType>({
        allowedContentTypes: [],
        maxHeightPx: 0,
        maxSizeBytes: 0,
        maxWidthPx: 0,
    });
    const [loading, setLoading] = useState<boolean>(true);

    const { miraklSupport } = useLoginContext();
    const {
        id,
        isAdmin,
        logoFile,
        name,
        reload: reloadContext,
    } = useOperatorAccountContext();

    const { getOperatorAccountLogoConfig, updateCompanyInformation } =
        useOperatorAccountClientApi();

    useEffect(() => {
        getOperatorAccountLogoConfig()
            .then((config) => setLogoConfig(config))
            .catch(handleError)
            .finally(() => setLoading(false));
    }, [getOperatorAccountLogoConfig, handleError]);

    const hasWriteAccess = isAdmin || miraklSupport.isOperatorSupportReadWrite;

    return (
        <Flex center={{ default: true }} gap={2}>
            <Title tag="h1">{name}</Title>
            {/* Ugly trick to make this leveled with the name, not html complient because of the double h1 */}
            <Title tag="h1">
                {hasWriteAccess && (
                    <IconButton
                        a11yLabel={formatMessage({
                            id: 'operator.accounts.detail.company.edit.button.a11y',
                        })}
                        disabled={loading}
                        iconName="create"
                        variant="ghost"
                        onClick={() => {
                            const defaultValues = {
                                name: name,
                                logo: logoFile ? [logoFile] : [],
                                id: id,
                            };

                            showModal(
                                <CompanyInformationEditModal
                                    defaultValues={defaultValues}
                                    isOperatorSupportUser={
                                        miraklSupport.isOperatorSupportUser
                                    }
                                    logoConfig={logoConfig}
                                    reloadContext={reloadContext}
                                    updateCompanyInformation={
                                        updateCompanyInformation
                                    }
                                />
                            );
                        }}
                    />
                )}
            </Title>
        </Flex>
    );
};

const PersonalInfoContent = () => {
    return (
        <Panel.Content>
            <Flex flexDirection="column" gap={5}>
                <CompanyInformationLogo />
                <CompanyInformation />
            </Flex>
        </Panel.Content>
    );
};

const CompanyInformationPanel = () => {
    return (
        <PersonalInfoPanel>
            <PersonalInfoBanner />
            <PersonalInfoContent />
        </PersonalInfoPanel>
    );
};

export default CompanyInformationPanel;
