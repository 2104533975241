import SupportOperatorAccountDetailsLayout from '../../detail/support/SupportOperatorAccountDetailsLayout';

import OperatorSmtpListingPanel from './component/OperatorSmtpListingPanel';
const SupportOperatorSmtpListingPage = () => {
    return (
        <SupportOperatorAccountDetailsLayout size="sm">
            <OperatorSmtpListingPanel isFromSupportPage />
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportOperatorSmtpListingPage;
