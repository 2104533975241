import axios, { AxiosError, AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { useLocaleContext } from '../config/i18n/I18nProvider';
import useLoginContext from '../config/login/LoginProvider';

import {
    withAcceptLanguageHeader,
    withAuthorizationHeader,
} from './headerUtils';

/**
 * Wrapper around axios that will get and put the auth token into the request Authorization header.
 * You must use this custom hook to make api calls to private endpoints, otherwise you will get a 401.
 */
const useAuthenticatedFetch = () => {
    const { getAccessToken, logout } = useLoginContext();
    const { locale } = useLocaleContext();

    const handleAuthorizationError = useCallback(
        (error: AxiosError) => {
            if (error?.response?.status === 401) {
                logout();
            }
            throw error;
        },
        [logout]
    );

    const populateHeaders = useCallback(
        (requestConfig: RawAxiosRequestConfig, accessToken: string) => {
            const request = withAuthorizationHeader(requestConfig, accessToken);
            return withAcceptLanguageHeader(request, locale);
        },
        [locale]
    );

    const apiGet = useCallback(
        async <T>(url: string, requestConfig: RawAxiosRequestConfig = {}) => {
            const accessToken = await getAccessToken();
            return axios
                .get<T>(url, populateHeaders(requestConfig, accessToken))
                .catch(handleAuthorizationError);
        },
        [getAccessToken, populateHeaders, handleAuthorizationError]
    );

    const apiPost = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async <T, R = any>(
            url: string,
            data: T,
            requestConfig: RawAxiosRequestConfig = {}
        ) => {
            const accessToken = await getAccessToken();
            return axios
                .post<
                    R,
                    AxiosResponse<R>,
                    T
                >(url, data, populateHeaders(requestConfig, accessToken))
                .catch(handleAuthorizationError);
        },
        [getAccessToken, populateHeaders, handleAuthorizationError]
    );

    const apiPut = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async <T, R = any>(
            url: string,
            data: T,
            requestConfig: RawAxiosRequestConfig = {}
        ) => {
            const accessToken = await getAccessToken();
            return axios
                .put<
                    R,
                    AxiosResponse<R>,
                    T
                >(url, data, populateHeaders(requestConfig, accessToken))
                .catch(handleAuthorizationError);
        },
        [getAccessToken, populateHeaders, handleAuthorizationError]
    );

    const apiDelete = useCallback(
        async <T>(
            url: string,
            data: T,
            requestConfig: RawAxiosRequestConfig = {}
        ) => {
            const accessToken = await getAccessToken();
            return axios
                .delete(url, {
                    ...populateHeaders(requestConfig, accessToken),
                    data,
                })
                .catch(handleAuthorizationError);
        },
        [getAccessToken, populateHeaders, handleAuthorizationError]
    );

    return {
        apiGet,
        apiPost,
        apiPut,
        apiDelete,
    };
};

export default useAuthenticatedFetch;
