import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { useI18n } from '@mirakl/i18n';
import { Stepper, useSnackbar } from '@mirakl/roma';

import useLoginContext from '../../../../config/login/LoginProvider';
import useQuery from '../../../../router/useQuery';
import { mapFormToLinkShopPayload } from '../../../common/shop/linkShopMapper';
import useOrganizationContext from '../../OrganizationContext';
import OrganizationRunPage from '../../OrganizationRunPage';

import AccountVerificationPage from './AccountVerificationPage';
import StoreSelectionPage from './StoreSelectionPage';
import useStoreSelectionApi from './linkingStoreApi';

type UserInformation = {
    email: string;
    token: string;
};

function LinkingShopsByEmail() {
    const { tenant } = useQuery({
        tenant: 'string',
    });
    const { formatMessage } = useI18n();
    const { uuid: organizationUuid } = useOrganizationContext();
    const [userInformation, setUserInformation] = useState<UserInformation>({
        token: '',
        email: '',
    });
    const onSubmitAccountValidation = (username: string, token: string) => {
        setUserInformation({ email: username, token: token });
    };
    const { linkShopToOrganization } = useStoreSelectionApi();
    const { addSnackbar } = useSnackbar();
    const { email: connectedUserEmail } = useLoginContext();

    const AccountVerificationStep = () => {
        return (
            <AccountVerificationPage
                onSubmitAccountValidation={onSubmitAccountValidation}
            />
        );
    };

    const StoreSelectionStep = () => {
        return (
            <StoreSelectionPage
                tenantId={tenant}
                userInformation={userInformation}
            />
        );
    };

    const handleGlobalSubmit = (values: FieldValues) => {
        return linkShopToOrganization(
            organizationUuid,
            mapFormToLinkShopPayload(
                connectedUserEmail,
                values['checkbox-group']
            )
        )
            .then(() => {
                addSnackbar({
                    status: 'success',
                    message: formatMessage({
                        id: 'organizations.step.link.shop.snackbar.store.linked',
                    }),
                });
                return Promise.resolve();
            })
            .catch(() => {
                addSnackbar({
                    message: formatMessage({
                        id: 'snackbar.edit.fail',
                    }),
                    status: 'error',
                });
                return Promise.reject();
            });
    };

    return (
        <OrganizationRunPage
            pageTitle={formatMessage({
                id: 'datatable.organizations.shop.page.title',
            })}
            size="sm"
        >
            <Stepper
                exitLink={{
                    to: `/organizations/${organizationUuid}/shops/linked-shops`,
                }}
                layoutSize="sm"
                stepSaveBarButtonLabels={{
                    lastButtonLabel: formatMessage({ id: 'button.link' }),
                    previousButtonLabel: formatMessage({ id: 'button.back' }),
                    nextButtonLabel: formatMessage({ id: 'button.continue' }),
                }}
                steps={[
                    {
                        content: <AccountVerificationStep />,
                        title: formatMessage({
                            id: 'organizations.step.account.verification.title',
                        }),
                    },
                    {
                        content: <StoreSelectionStep />,
                        title: formatMessage({
                            id: 'organizations.step.link.store.title',
                        }),
                    },
                ]}
                successLink={{
                    to: `/organizations/${organizationUuid}/shops/linked-shops`,
                }}
                title={formatMessage({
                    id: 'organization.link.store.accounts',
                })}
                onGlobalSubmit={handleGlobalSubmit}
            />
        </OrganizationRunPage>
    );
}

export default LinkingShopsByEmail;
