import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Badge,
    Button,
    Datatable,
    DatatableHeadingCellType,
    DatatablePagination,
    DatatableProvider,
    DatatableRowSelectionType,
    DateCellContent,
    Flex,
    createHeadingsHelper,
    createQueryParamsHelper,
    useModal,
    useNavigate,
} from '@mirakl/roma';

import ShopOnTenantCellContent from '../../../common/datatable/ShopOnTenantCellContent';
import useLoginContext from '../../../config/login/LoginProvider';
import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import { StageValues } from '../../../types/stageTypes';
import OrganizationShopUnlinkModal from '../shared/OrganizationShopUnlinkModal';
import useOrganizationShopUnlink from '../shared/useOrganizationShopUnlink';

import OrganizationAdminAsyncFilter from './OrganizationAdminAsyncFilter';
import OrganizationAdminsCellContent from './OrganizationAdminsCellContent';
import ShopAsyncFilter from './ShopAsyncFilter';
import {
    MERGE_MAX_LIMIT,
    MERGE_MIN_LIMIT,
    isMergeBelowMinLimit,
    isMergeButtonDeactivated,
} from './mergeHelper';

type OrganizationType = {
    administrators: string[];
    dateCreated: string;
    growthSolutions: string[];
    id: string;
    linkedShopsByTenant: {
        shops: string[];
        stage: StageValues;
        tenantDisplayName: string;
    }[];
    organizationName: string;
};

type OrganizationListingPayload = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: OrganizationType[];
    nextPageToken?: string;
    previousPageToken?: string;
};

const headingsHelper = createHeadingsHelper<OrganizationType>();

const queryParamsHelper = createQueryParamsHelper<{
    administrators: {
        type: 'multipleOptions';
        value: string;
    };
    linkedShops: {
        type: 'multipleOptions';
        value: string;
    };
}>({
    administrators: 'multipleOptions',
    linkedShops: 'multipleOptions',
})
    .withSeekPagination()
    .withSearch('organizationName')
    .withSort('sort');

function OrganizationListingDatatable() {
    const { miraklSupport } = useLoginContext();

    const { formatMessage } = useI18n();
    const { apiGet } = useAuthenticatedFetch();
    const navigate = useNavigate();

    const { showModal } = useModal();
    const { fetchShopsCallback, unlinkShopCallback } =
        useOrganizationShopUnlink();

    const [organizations, setOrganizations] =
        useState<OrganizationListingPayload>({
            count: { counted: 0, capped: false },
            data: [],
        });

    const { loadingState, onQueryParamsChange, refetch } =
        queryParamsHelper.useQueryParamsChange(async (queryParams) => {
            const { data } = await apiGet<OrganizationListingPayload>(
                '/private/organizations/search',
                {
                    params: queryParams,
                }
            );

            setOrganizations(data);
        });

    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            headingsHelper.accessor('organizationName', {
                label: formatMessage({ id: 'organization' }),
            }),
            headingsHelper.accessor('linkedShopsByTenant', {
                label: formatMessage({ id: 'stores.linked' }),
                render: (tenants) => (
                    <ShopOnTenantCellContent tenants={tenants} />
                ),
            }),
            headingsHelper.accessor('growthSolutions', {
                label: formatMessage({
                    id: 'datatable.organizations.header.growth.solutions.label',
                }),
                render: (growthSolutions) => {
                    const data =
                        growthSolutions.length > 0
                            ? growthSolutions.map((product) => (
                                  <Flex key={product} margin={1}>
                                      <Badge
                                          label={formatMessage({
                                              id: `product.${product}`,
                                          })}
                                          status="info"
                                      />
                                  </Flex>
                              ))
                            : formatMessage({ id: 'growth.solution.none' });
                    return (
                        <Flex flexWrap="wrap" maxWidth="200px">
                            {data}
                        </Flex>
                    );
                },
            }),
            headingsHelper.accessor('administrators', {
                label: formatMessage({
                    id: 'datatable.organizations.header.administrator.label',
                }),
                render: (administrators) => (
                    <OrganizationAdminsCellContent
                        administrators={administrators}
                    />
                ),
            }),
            headingsHelper.accessor('dateCreated', {
                label: formatMessage({
                    id: 'datatable.organizations.header.date.created.label',
                }),
                sortable: true,
                initialSortDirection: 'DESC',
                render: (date) => {
                    return (
                        <DateCellContent
                            date={DateTime.fromISO(date)}
                            options={DateTime.DATE_SHORT}
                        />
                    );
                },
            }),
        ],
        [formatMessage]
    );

    const unlinkShopCallbackAndReload = (
        organizationUuid: string,
        shopUuids: string[]
    ) => unlinkShopCallback(organizationUuid, shopUuids).then(() => refetch());

    const isUnlinkButtonDeactivated = (
        linkedShopsByTenant: { shops: string[] }[]
    ) =>
        linkedShopsByTenant.reduce(
            (count, current) => count + current.shops.length,
            0
        ) === 0;

    const action = ({
        id,
        linkedShopsByTenant,
    }: {
        id: string;
        linkedShopsByTenant: { shops: string[] }[];
    }) => (
        <Button
            disabled={isUnlinkButtonDeactivated(linkedShopsByTenant)}
            label={formatMessage({
                id: 'organizations.actions.unlink.label',
            })}
            tooltipBreakOutOfContainer
            tooltipText={
                isUnlinkButtonDeactivated(linkedShopsByTenant)
                    ? formatMessage({
                          id: 'organizations.actions.unlink.tooltip',
                      })
                    : undefined
            }
            onClick={() =>
                showModal(
                    <OrganizationShopUnlinkModal
                        getShopsCallback={fetchShopsCallback}
                        organizationUuid={id}
                        unlinkShopCallback={unlinkShopCallbackAndReload}
                    />
                )
            }
        />
    );
    const mergeLimitMessage = (selectedRows: DatatableRowSelectionType) =>
        isMergeBelowMinLimit(selectedRows, organizations.count.counted)
            ? formatMessage(
                  {
                      id: 'datatable.organizations.selection.action.button.merge.one.organization.error.label',
                  },
                  {
                      0: MERGE_MIN_LIMIT,
                  }
              )
            : formatMessage(
                  {
                      id: 'datatable.organizations.selection.action.button.merge.excessive.organizations.error.label',
                  },
                  {
                      0: MERGE_MAX_LIMIT,
                  }
              );
    const rowSelection = (selectedRows: DatatableRowSelectionType) => [
        <Button
            disabled={isMergeButtonDeactivated(
                selectedRows,
                organizations.count.counted
            )}
            key="organization-merge-button"
            label={formatMessage({
                id: 'datatable.organizations.selection.action.button.merge.label',
            })}
            tooltipBreakOutOfContainer
            tooltipText={
                isMergeButtonDeactivated(
                    selectedRows,
                    organizations.count.counted
                )
                    ? mergeLimitMessage(selectedRows)
                    : undefined
            }
            onClick={() => {
                navigate({
                    to: '/organizations/merge',
                    state: {
                        organizationUuids:
                            selectedRows != 'all'
                                ? selectedRows
                                : organizations.data.map(
                                      (organization) => organization.id
                                  ),
                    },
                });
            }}
        />,
    ];
    return (
        <queryParamsHelper.QueryParamsProvider
            onQueryParamsChange={onQueryParamsChange}
        >
            <DatatableProvider>
                <Datatable
                    data={organizations}
                    emptyState={{
                        title: formatMessage({
                            id: 'datatable.organizations.empty.title.label',
                        }),
                        subtitle: '',
                    }}
                    headings={headings}
                    id="organization-listing-datatable"
                    loading={loadingState}
                    pagination={<DatatablePagination.Seek />}
                    renderTrailingActions={
                        miraklSupport.isSellerSupportReadWrite
                            ? action
                            : undefined
                    }
                    resultNumberMessage={formatMessage(
                        { id: 'count.result' },
                        { 0: organizations.count.counted }
                    )}
                    rowSelectionRenderActions={
                        miraklSupport.isSellerSupportReadWrite
                            ? rowSelection
                            : undefined
                    }
                    toolbar={{
                        filters: queryParamsHelper.createFilters({
                            linkedShops: {
                                label: formatMessage({
                                    id: 'stores.linked',
                                }),
                                component: <ShopAsyncFilter />,
                            },
                            administrators: {
                                label: formatMessage({
                                    id: 'datatable.organizations.filter.administrators.label',
                                }),
                                component: <OrganizationAdminAsyncFilter />,
                            },
                        }),
                        searchBar: {
                            id: 'organizationName',
                            placeholder: formatMessage({
                                id: 'datatable.organizations.filter.organization.name.placeholder.label',
                            }),
                        },
                    }}
                    onRowClick={{
                        buildUrl: (rowData) => `/organizations/${rowData.id}`,
                        onClick: ({ url }) => navigate({ to: url as string }),
                    }}
                />
            </DatatableProvider>
        </queryParamsHelper.QueryParamsProvider>
    );
}

export default OrganizationListingDatatable;
