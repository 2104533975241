import { useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { OnboardingStepper, PasswordField, useStepperForm } from '@mirakl/roma';

import PasswordSpecsChecker, {
    PasswordValidityResults,
} from '../../PasswordSpecsChecker';
import { usePasswordValidation } from '../../usePasswordValidation';

const PasswordStep = () => {
    const { formatMessage } = useI18n();

    const passwordValidation = usePasswordValidation();
    const [passwordSpecsValidity, setPasswordSpecsValidity] =
        useState<PasswordValidityResults>({
            global: false,
            specs: {
                length: false,
                digit: false,
                lower: false,
                upper: false,
                symbol: false,
            },
        });

    const formData = useStepperForm({
        defaultValues: {
            password: '',
        },
    });

    return (
        <OnboardingStepper.Form {...formData}>
            <PasswordField
                label={formatMessage({
                    id: 'user.account.confirmation.password',
                })}
                name="password"
                required
                validate={(value: string) =>
                    value.length === 0 ||
                    passwordValidation(value).global ||
                    formatMessage({
                        id: 'com.mirakl.domain.validation.passwordNotStrongEnough',
                    })
                }
                onChange={(value: string) =>
                    setPasswordSpecsValidity(passwordValidation(value))
                }
            />
            <PasswordSpecsChecker
                passwordSpecsValidity={passwordSpecsValidity}
            />
        </OnboardingStepper.Form>
    );
};

export default PasswordStep;
