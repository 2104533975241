import { AxiosError } from 'axios';

const ticketingLicenceErrorCodes = [
    'TICKETING_LICENCE_ZENDESK_INTEGRATION',
    'TICKETING_LICENCE_RATE_LIMIT_REACHED',
    'TICKETING_LICENCE_LIMIT_REACHED',
] as const;

type TicketingLicenceErrorCodeType =
    (typeof ticketingLicenceErrorCodes)[number];

export type TicketingLicenceErrorType = AxiosError & {
    response: {
        data: {
            code: TicketingLicenceErrorCodeType;
        };
    };
};

export const isTicketingLicenceError = (
    error: unknown
): error is TicketingLicenceErrorType => {
    return (
        error instanceof AxiosError &&
        error.response?.data &&
        ticketingLicenceErrorCodes.includes(error.response.data.code)
    );
};
