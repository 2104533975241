import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useI18n } from '@mirakl/i18n';
import { useErrorHandler, useSnackbar } from '@mirakl/roma';

import { UserShopsForLink } from '../../common/shop/useUserShopsForLink';

import LinkAdditionalShopsCard from './LinkAdditionalShopsCard';
import LinkShopsField from './LinkShopsField';

export type LinkedShopFormType = {
    email: string;
    shopUuid: string;
};

export const LINKED_SHOPS_FIELD_NAME = 'linkedShopUuidsByUser';
export type LinkedShopsFormType = LinkedShopFormType[];

type LinkShopsFieldsProps = {
    isEdition?: boolean;
    userShopsForLink: UserShopsForLink;
};

const LinkShopsFields = ({
    isEdition = false,
    userShopsForLink,
}: LinkShopsFieldsProps) => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { addSnackbar } = useSnackbar();

    const { clearErrors } = useFormContext();
    const [tenantByUser, setTenantByUser] = useState<Record<string, string>>(
        {}
    );

    const addAvailableShops = (
        otherEmail: string,
        tenant: string,
        token: string
    ) => {
        userShopsForLink
            .fetchAdditionnalShops(otherEmail, token)
            .then(({ allShops }) => {
                setTenantByUser((prevState) => ({
                    ...prevState,
                    [otherEmail]: tenant,
                }));

                // Reset form errors if any
                clearErrors(LINKED_SHOPS_FIELD_NAME);

                if (allShops.length > 0) {
                    addSnackbar({
                        message: formatMessage(
                            {
                                id: 'organization.link.other.stores.found',
                            },
                            { 0: allShops.length }
                        ),
                        status: 'success',
                    });
                } else {
                    addSnackbar({
                        message: formatMessage({
                            id: 'organization.link.no.store.found',
                        }),
                        status: 'error',
                    });
                }
            })
            .catch(handleError);
    };

    return (
        <>
            {userShopsForLink.userOwnsAtLeastOneShop && (
                <LinkShopsField
                    hasAnyAvailableShop={userShopsForLink.hasAnyAvailableShop}
                    isEdition={isEdition}
                    shopsByUser={userShopsForLink.shopsByUser}
                    tenantByUser={tenantByUser}
                />
            )}
            <LinkAdditionalShopsCard onConfirm={addAvailableShops} />
        </>
    );
};

export default LinkShopsFields;
