import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Button,
    Flex,
    FormattedDateTime,
    Media,
    Panel,
    Paragraph,
    Text,
    useErrorHandler,
} from '@mirakl/roma';

import useProfileSecurityApi from '../api/useProfileSecurityApi';

type PasswordInfoPayload = {
    lastPasswordReset?: string;
};

const THIRTY_SECONDS = Duration.fromMillis(30000);

const ProfileSecurityPasswordPanel = ({
    onPasswordResetError,
    onPasswordResetSuccess,
}: {
    onPasswordResetError: () => void;
    onPasswordResetSuccess: () => void;
}) => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { fetchPasswordInfo, performPasswordReset } = useProfileSecurityApi();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isPasswordResetOnCooldown, setIsPasswordResetOnCooldown] =
        useState<boolean>(false);
    const [passwordInfo, setPasswordInfo] = useState<PasswordInfoPayload>();

    const startPasswordResetCooldown = () => {
        setIsPasswordResetOnCooldown(true);
        setTimeout(() => {
            setIsPasswordResetOnCooldown(false);
        }, THIRTY_SECONDS.as('milliseconds'));
    };

    const resetPassword = useCallback(
        () =>
            performPasswordReset()
                .then(onPasswordResetSuccess)
                .catch(onPasswordResetError)
                .finally(() => {
                    startPasswordResetCooldown();
                }),
        [performPasswordReset, onPasswordResetError, onPasswordResetSuccess]
    );

    useEffect(() => {
        fetchPasswordInfo()
            .then((result) => {
                setPasswordInfo(result.data);
            })
            .catch(handleError)
            .finally(() => {
                setIsLoading(false);
            });
    }, [fetchPasswordInfo, handleError]);

    return (
        <Panel loading={isLoading}>
            <Panel.Header
                title={formatMessage({
                    id: 'user.profile.security.password',
                })}
            />
            <Panel.Content fullWidth>
                <Flex alignItems="center" gap={6} marginStart={5}>
                    <Flex
                        alignItems="flex-start"
                        flex="1"
                        flexDirection="column"
                    >
                        <Paragraph>
                            <Text>
                                {formatMessage({
                                    id: 'user.profile.security.password.change.hint',
                                })}
                            </Text>
                            <br />
                            <Text variant="default-500">
                                {formatMessage(
                                    {
                                        id: 'user.profile.security.password.change.last-changed',
                                    },
                                    {
                                        date: passwordInfo?.lastPasswordReset ? (
                                            <FormattedDateTime
                                                date={DateTime.fromISO(
                                                    passwordInfo.lastPasswordReset
                                                )}
                                                options={DateTime.DATE_SHORT}
                                                variant="default-500"
                                            />
                                        ) : (
                                            <Text variant="default-500">
                                                {formatMessage({
                                                    id: 'user.profile.security.password.change.never',
                                                })}
                                            </Text>
                                        ),
                                    }
                                )}
                            </Text>
                        </Paragraph>
                        <Button
                            disabled={isPasswordResetOnCooldown}
                            label={formatMessage({
                                id: 'user.profile.security.password.change.button',
                            })}
                            size="small"
                            tooltipText={
                                isPasswordResetOnCooldown
                                    ? formatMessage(
                                          {
                                              id: 'user.profile.security.password.change.cooldown',
                                          },
                                          {
                                              duration:
                                                  THIRTY_SECONDS.as('seconds'),
                                          }
                                      )
                                    : undefined
                            }
                            variant="secondary"
                            onClick={resetPassword}
                        />
                    </Flex>
                    <Box marginEnd={5} width="100px">
                        <Media name="vertical-security" type="illustration" />
                    </Box>
                </Flex>
            </Panel.Content>
        </Panel>
    );
};

export default ProfileSecurityPasswordPanel;
