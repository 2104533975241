import { useSuspenseQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Datatable,
    DatatableProvider,
    DatatableRowDataType,
    createHeadingsHelper,
    useModal,
} from '@mirakl/roma';

import { UserProfileInfoCellContent } from '../../../../../common/datatable/UserProfileInfoCellContent';
import useLoginContext from '../../../../../config/login/LoginProvider';
import useOperatorAccountContext from '../../../../OperatorAccountContext';
import useTicketingLicenceApi from '../api/useTicketingLicenceApi';

import TicketingLicenceAddUserModal from './TicketingLicenceAddUserModal';
import TicketingLicenceRemoveUserModal from './TicketingLicenceRemoveUserModal';

type TicketingLicenceUserType = {
    email: string;
    firstName?: string;
    lastName?: string;
};

type TicketingLicenceUserRowType = DatatableRowDataType & {
    user: TicketingLicenceUserType;
};

const headingsHelper = createHeadingsHelper<TicketingLicenceUserRowType>();

const applySortByUser = (
    licensedUsers: TicketingLicenceUserType[],
    direction: 'ASC' | 'DESC'
) => {
    const sorted = Array.from(licensedUsers).sort((a, b) =>
        a.email.localeCompare(b.email)
    );
    return direction === 'ASC' ? sorted : sorted.reverse();
};

const applySort = (
    licensedUsers: TicketingLicenceUserType[],
    sort?: string
) => {
    if (!sort) {
        return licensedUsers;
    }
    const [column, direction] = sort.split(',') as [string, 'ASC' | 'DESC'];
    if (column === 'user') {
        return applySortByUser(licensedUsers, direction);
    }
    return licensedUsers;
};

const applySearch = (
    licensedUsers: TicketingLicenceUserType[],
    search?: string
) => {
    if (!search || search === '') {
        return licensedUsers;
    }

    return licensedUsers.filter(({ email }) =>
        email.toLowerCase().includes(search.toLowerCase())
    );
};

const TicketingLicenceUsersDatatable = ({
    search,
    sort,
}: {
    search?: string;
    sort?: string;
}) => {
    const { formatMessage } = useI18n();
    const { showModal } = useModal();
    const {
        miraklSupport: { isOperatorSupportReadWrite },
    } = useLoginContext();
    const { id: operatorAccountId, isAdmin } = useOperatorAccountContext();
    const canManageLicences = isOperatorSupportReadWrite || isAdmin;

    const {
        addLicensedUser,
        fetchLicenceLimitQuery,
        fetchLicensedUsersQuery,
        removeLicensedUser,
        searchUnlicensedUsers,
    } = useTicketingLicenceApi(operatorAccountId);
    const { data: licensedUsers } = useSuspenseQuery(fetchLicensedUsersQuery());
    const { data: licensedUsersLimit } = useSuspenseQuery(
        fetchLicenceLimitQuery()
    );

    const rows = useMemo(() => {
        let filteredLicensedUsers = licensedUsers;
        filteredLicensedUsers = applySearch(filteredLicensedUsers, search);
        filteredLicensedUsers = applySort(filteredLicensedUsers, sort);
        return filteredLicensedUsers.map((user) => ({
            id: user.email,
            user,
        }));
    }, [licensedUsers, search, sort]);

    const headings = useMemo(() => {
        return [
            headingsHelper.accessor('user', {
                label: formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.listing.header.name',
                }),
                render: (user) => (
                    <UserProfileInfoCellContent profileInfo={user} />
                ),
                initialSortDirection: 'ASC',
                sortable: true,
            }),
        ];
    }, [formatMessage]);

    const renderTrailingActions = useCallback(
        (row: TicketingLicenceUserRowType) => (
            <Button
                label={formatMessage({
                    id: 'button.remove',
                })}
                onClick={() => {
                    showModal(
                        <TicketingLicenceRemoveUserModal
                            user={row.user}
                            onRemoveLicensedUser={removeLicensedUser}
                        />
                    );
                }}
            />
        ),
        [formatMessage, removeLicensedUser, showModal]
    );

    return (
        <DatatableProvider>
            <Datatable
                data={rows}
                emptyState={{
                    title: formatMessage({
                        id: 'operator.accounts.ticketing.licence.users.listing.empty.title',
                    }),
                }}
                headings={headings}
                id="ticketing-licence-users-listing-datatable"
                renderTrailingActions={
                    canManageLicences ? renderTrailingActions : undefined
                }
                resultNumberMessage={formatMessage(
                    { id: 'count.result' },
                    { 0: rows.length }
                )}
                toolbar={{
                    action: canManageLicences ? (
                        <Button
                            disabled={
                                licensedUsers.length >= licensedUsersLimit
                            }
                            label={formatMessage({
                                id: 'button.add',
                            })}
                            tooltipText={
                                licensedUsers.length === licensedUsersLimit
                                    ? formatMessage(
                                          {
                                              id: 'operator.accounts.ticketing.licence.users.listing.add.limit.reached',
                                          },
                                          {
                                              limit: licensedUsersLimit,
                                          }
                                      )
                                    : undefined
                            }
                            onClick={() => {
                                showModal(
                                    <TicketingLicenceAddUserModal
                                        onAddLicensedUser={addLicensedUser}
                                        onSearchUnlicensedUsers={
                                            searchUnlicensedUsers
                                        }
                                    />
                                );
                            }}
                        />
                    ) : undefined,
                    searchBar: {
                        placeholder: formatMessage({
                            id: 'operator.accounts.ticketing.licence.users.listing.search.placeholder',
                        }),
                        id: 'search',
                    },
                }}
            />
        </DatatableProvider>
    );
};

export default TicketingLicenceUsersDatatable;
