import React from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    EmptyState,
    Hyperlink,
    NavigationButton,
    Panel,
    Text,
} from '@mirakl/roma';

interface NoSubscriptionProps {
    isLegacySubscription: boolean;
    isOrganizationAdmin: boolean;
    organizationUuid: string;
}

function NoSubscription({
    isLegacySubscription,
    isOrganizationAdmin,
    organizationUuid,
}: NoSubscriptionProps) {
    const { formatMessage } = useI18n();

    return (
        <>
            {isLegacySubscription ? (
                <Panel>
                    <EmptyState
                        action={
                            <NavigationButton
                                disabled={!isOrganizationAdmin}
                                label={formatMessage({
                                    id: 'organization.subscription.page.upgrade-plan',
                                })}
                                to={`/organizations/${organizationUuid}/paywall?callback=${window.location.origin}/organizations/${organizationUuid}/subscription`}
                                tooltipText={
                                    !isOrganizationAdmin &&
                                    formatMessage({
                                        id: 'organization.subscription.page.manage-plan.disabled.tooltip',
                                    })
                                }
                            />
                        }
                        illustrationName="onboarding-msg"
                        illustrationSize="small"
                        spacing
                        subtitle={formatMessage({
                            id: 'organization.subscription.page.upgrade-plan.description',
                        })}
                        title={formatMessage({
                            id: 'organization.subscription.page.upgrade-plan.title',
                        })}
                    />
                </Panel>
            ) : (
                <Panel>
                    <Panel.Content>
                        <EmptyState
                            illustrationName="payment-credit"
                            illustrationSize="medium"
                            subtitle={
                                <Text>
                                    {formatMessage({
                                        id: 'organization.subscription.price.empty.state.subtitle',
                                    })}{' '}
                                    <Hyperlink
                                        href="https://help.mirakl.net/bundle/sellers/page/topics/Mirakl/account/manage_subscriptions_mirakl_account.htm"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        {formatMessage({
                                            id: 'organization.subscription.price.empty.state.subtitle.link',
                                        })}
                                    </Hyperlink>
                                </Text>
                            }
                        />
                    </Panel.Content>
                </Panel>
            )}
        </>
    );
}

export default NoSubscription;
