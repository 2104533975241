import { useI18n } from '@mirakl/i18n';
import {
    Form,
    Modal,
    Paragraph,
    TextField,
    useForm,
    useSnackbar,
} from '@mirakl/roma';

const EXPECTED_CONFIRM_VALUE = 'DELETE';

const USER_CONFIRM_ID = 'userConfirm';

type OrganizationDeleteModalType = {
    deleteOrganizationCallback: (uuid: string) => Promise<void>;
    organizationName: string;
    organizationUuid: string;
};

type DeleteConfirmFormType = {
    [USER_CONFIRM_ID]: string;
};

function OrganizationDeleteModal({
    deleteOrganizationCallback,
    organizationName,
    organizationUuid,
}: OrganizationDeleteModalType) {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();

    const formData = useForm<DeleteConfirmFormType>({
        defaultValues: {
            [USER_CONFIRM_ID]: '',
        },
    });

    const {
        formState: { isSubmitting },
        handleSubmit,
        watch,
    } = formData;

    const userConfirmInputValue = watch(USER_CONFIRM_ID);

    const isDeleteConfirmationValid =
        userConfirmInputValue === EXPECTED_CONFIRM_VALUE;

    const onValid = (close: () => void) => () => {
        return deleteOrganizationCallback(organizationUuid)
            .then(() => {
                addSnackbar({
                    status: 'success',
                    message: formatMessage({
                        id: 'organization.modal.delete.success.snackbar.label',
                    }),
                });
                close();
            })
            .catch(() =>
                addSnackbar({
                    status: 'error',
                    message: formatMessage({ id: 'snackbar.edit.fail' }),
                })
            );
    };

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'organization.modal.delete.title.label',
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Form {...formData}>
                        <Paragraph>
                            {formatMessage(
                                {
                                    id: 'organization.modal.delete.description.label',
                                },
                                { 0: organizationName }
                            )}
                        </Paragraph>
                        <TextField
                            label={formatMessage({
                                id: 'organization.modal.delete.form.confirm.label',
                            })}
                            name={USER_CONFIRM_ID}
                            required
                            validate={() =>
                                isDeleteConfirmationValid ||
                                formatMessage({
                                    id: 'organization.modal.delete.form.confirm.error.label',
                                })
                            }
                        />
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({ id: 'button.delete' }),
                    destructive: true,
                    state: isSubmitting ? 'loading' : 'default',
                    onClick: (close, event) =>
                        handleSubmit(onValid(close))(event),
                    disabled: !isDeleteConfirmationValid,
                }}
            />
        </Modal>
    );
}

export default OrganizationDeleteModal;
