import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { PageLoader, useErrorHandler, useNavigate } from '@mirakl/roma';

import useQuery from '../router/useQuery';

import { signupLoginStateManager } from './signupLoginStateManager';
import useLogin from './useLogin';

const DURATION = 250;
export const InvitationPage = () => {
    const { token } = useQuery({ token: 'string' });

    const [invitedEmail, setInvitedEmail] = useState();

    const [invitationLoaded, setInvitationLoaded] = useState(false);

    const navigate = useNavigate();

    const handleError = useErrorHandler();

    const { isReady, logout, user } = useLogin();

    const inviteUser = useCallback(() => {
        axios
            .post(`/private/invitation/${token}`)
            .then(({ data }) => {
                navigate({
                    href: data.location,
                });
            })
            .catch((reason) => {
                if (reason.response.status === 400) {
                    navigate({
                        to: reason.response.data.location,
                    });
                } else {
                    handleError(new Error(reason));
                }
            });
    }, [token, navigate, handleError]);

    // this could be changed for another endpoint that will check if invitation has the same email.
    const loadInvitationData = useCallback(() => {
        axios
            .get(`/private/invitation/${token}`)
            .then(({ data }) => {
                setInvitedEmail(data.email);
                setInvitationLoaded(true);
            })
            .catch(handleError);
    }, [handleError, token]);

    useEffect(() => {
        if (!isReady) {
            return;
        }

        if (user.isLoggedIn && !invitationLoaded) {
            loadInvitationData();
            return;
        }

        if (user.isLoggedIn && invitedEmail !== user.user.email) {
            signupLoginStateManager.silentLogin = false;
            logout();
            return;
        }

        // we don't want to validate the invitation right away because this might be an app performing a preview link.
        const intervalId = setInterval(() => {
            clearInterval(intervalId);
            inviteUser();
        }, DURATION);
    }, [
        navigate,
        token,
        isReady,
        user,
        logout,
        inviteUser,
        invitedEmail,
        loadInvitationData,
        invitationLoaded,
        handleError,
    ]);
    return <PageLoader fullPage />;
};
