import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import { useI18n } from '@mirakl/i18n';
import {
    Flex,
    FormattedDateTime,
    LinkButton,
    Text,
    useNavigate,
} from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';

import { CurrentPlanDetail } from './SubscriptionPage';

type SubscriptionDetailProps = {
    currentPlanDetail: CurrentPlanDetail;
    initPaymentHistorySession: (
        organizationId: string,
        returnUrl: string
    ) => Promise<AxiosResponse>;
    isOrganizationAdmin: boolean;
    organizationId: string;
};

function SubscriptionDetailsSection({
    currentPlanDetail,
    initPaymentHistorySession,
    isOrganizationAdmin,
    organizationId,
}: SubscriptionDetailProps) {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();
    const billingDay = DateTime.fromISO(currentPlanDetail.nextBillingDate).day;
    const { miraklSupport } = useLoginContext();

    return (
        <>
            <Flex flexDirection="column" marginTop={1}>
                <Text variant="default-500">
                    {formatMessage({
                        id: 'organization.subscription.details.type',
                    })}
                </Text>
                <Text variant="default-400">
                    {formatMessage({
                        id: `organization.plan.type.${currentPlanDetail.type.toLowerCase()}`,
                    })}
                </Text>
            </Flex>
            <Flex flexDirection="column" marginTop={2}>
                <Text variant="default-500">
                    {formatMessage({
                        id: 'organization.subscription.details.billing.date',
                    })}
                </Text>
                <Text variant="default-400">
                    {formatMessage(
                        {
                            id: 'organization.subscription.details.billing.date.scheduled',
                        },
                        { 0: billingDay }
                    )}
                </Text>
            </Flex>
            <Flex flexDirection="column" marginTop={2}>
                <Text variant="default-500">
                    {formatMessage({
                        id: 'organization.subscription.details.end.date',
                    })}
                </Text>
                <Text variant="default-400">
                    <FormattedDateTime
                        date={DateTime.fromISO(currentPlanDetail.endDate)}
                        options={DateTime.DATE_MED}
                        variant="default-400"
                    />
                </Text>
            </Flex>
            <Flex marginTop={2}>
                {(isOrganizationAdmin || miraklSupport.isSellerSupportUser) && (
                    <LinkButton
                        disabled={miraklSupport.isSellerSupportUser}
                        label={formatMessage({
                            id: 'organization.subscription.details.payment.history',
                        })}
                        onClick={() => {
                            initPaymentHistorySession(
                                organizationId,
                                `${window.location.origin}/organizations/${organizationId}/subscription`
                            ).then((response) => {
                                navigate({ href: response.headers.location });
                            });
                        }}
                    />
                )}
            </Flex>
        </>
    );
}

export default SubscriptionDetailsSection;
