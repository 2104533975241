import { useI18n } from '@mirakl/i18n';
import { PageError } from '@mirakl/roma';

const TicketingLicenceUnavailablePage = () => {
    const { formatMessage } = useI18n();

    return (
        <PageError
            illustrationName="error-403-1"
            subtitle={formatMessage({
                id: 'operator.accounts.ticketing.licence.unavailable.subtitle',
            })}
            title={formatMessage({
                id: 'operator.accounts.ticketing.licence.unavailable.title',
            })}
        />
    );
};

export default TicketingLicenceUnavailablePage;
