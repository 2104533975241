import { useI18n } from '@mirakl/i18n';
import { LayoutContainer, PageLayout, PageTitle, Panel } from '@mirakl/roma';

import AccountGlobalLayout from '../../../common/layout/AccountGlobalLayout';

import OrganizationListingDatatable from './OrganizationListingDatatable';

function OrganizationListingPage() {
    const { formatMessage } = useI18n();

    return (
        <AccountGlobalLayout>
            <PageTitle title={formatMessage({ id: 'organizations' })} />
            <PageLayout size="md">
                <LayoutContainer>
                    <Panel>
                        <Panel.Content fullWidth>
                            <OrganizationListingDatatable />
                        </Panel.Content>
                    </Panel>
                </LayoutContainer>
            </PageLayout>
        </AccountGlobalLayout>
    );
}

export default OrganizationListingPage;
