import { useI18n } from '@mirakl/i18n';
import { OnboardingStepper, Paragraph } from '@mirakl/roma';

function OrganizationBeforeStepper() {
    const { formatMessage } = useI18n();

    return (
        <OnboardingStepper.Info>
            <Paragraph>
                {formatMessage({
                    id: 'organization.stepper.before.description',
                })}
            </Paragraph>
        </OnboardingStepper.Info>
    );
}

export default OrganizationBeforeStepper;
