import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

const useVerifyAccountApi = () => {
    const { apiGet, apiPost, apiPut } = useAuthenticatedFetch();

    const verifyCode = ({
        code,
        username,
    }: {
        code: string;
        username: string;
    }): Promise<string> => {
        return apiPost('/private/organizations/confirmation/verify', {
            code,
            username,
        }).then((data) => Promise.resolve(data.data));
    };

    const sendCode = (email: string): Promise<void> =>
        apiPut(
            '/private/organizations/confirmation/code',
            {},
            { params: { username: email } }
        ).then(() => Promise.resolve());

    const getTimeout = (): Promise<{ timeout: number }> => {
        return apiGet<{ timeout: number }>(
            '/private/organizations/confirmation/time'
        ).then((payload) => payload.data);
    };

    return {
        verifyCode,
        sendCode,
        getTimeout,
    };
};

export default useVerifyAccountApi;
