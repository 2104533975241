import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { PageLoader, useErrorHandler } from '@mirakl/roma';

import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';

type FeatureToggleProviderProps = {
    children: ReactNode;
};
type FeatureToggleContextType = {
    features: Record<string, boolean>;
    isFeatureEnable: (featureName: string) => boolean;
};
const FeatureToggleContext = createContext<FeatureToggleContextType | null>(
    null
);

export const useFeatureToggle = () => {
    const context = useContext(FeatureToggleContext);
    if (!context) {
        throw new Error(
            'Cannot access feature toggle info outside of FeatureToggleProvider'
        );
    }
    return context;
};

const FeatureToggleProvider = ({ children }: FeatureToggleProviderProps) => {
    const { apiGet } = useAuthenticatedFetch();
    const handleError = useErrorHandler();

    const [loading, setLoading] = useState(true);
    const [features, setFeatures] = useState<Record<string, boolean>>();

    const isFeatureEnable = useCallback(
        (featureName: string): boolean => {
            if (features === undefined) {
                return false;
            }
            return !!(
                features &&
                Object.keys(features).includes(featureName) &&
                features[featureName]
            );
        },
        [features]
    );

    useEffect(() => {
        apiGet<Record<string, boolean>>('/private/toggles')
            .then((result) => {
                setFeatures(result.data);
            })
            .catch(handleError)
            .finally(() => setLoading(false));
    }, [apiGet, handleError]);

    return (
        <>
            {loading && <PageLoader fullPage />}
            {features && (
                <FeatureToggleContext.Provider
                    value={{
                        features,
                        isFeatureEnable,
                    }}
                >
                    {children}
                </FeatureToggleContext.Provider>
            )}
        </>
    );
};

export default FeatureToggleProvider;
