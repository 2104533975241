import React, { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Form, Modal, useErrorHandler, useForm } from '@mirakl/roma';

import SelectMarketplaceField from '../../common/shop/SelectMarketplaceField';

export type Tenant = {
    id: string;
    logoUrl: string;
    name?: string;
}[];
type MarketplaceSelectionForm = {
    selectMarketplace: string;
};

function SelectMarketplaceModal({
    connectorList,
    getTenantsCallBack,
    handleModalSubmit,
}: {
    connectorList: string[];
    getTenantsCallBack: () => Promise<Tenant>;
    handleModalSubmit: (close: () => void, value: string) => Promise<void>;
}) {
    const { formatMessage } = useI18n();
    const formData = useForm<MarketplaceSelectionForm>({
        defaultValues: {
            selectMarketplace: '',
        },
    });
    const [tenantList, setTenantList] = useState<Tenant>([]);
    const handleError = useErrorHandler();
    const {
        formState: { isSubmitting },
        handleSubmit,
    } = formData;

    useEffect(() => {
        getTenantsCallBack()
            .then((payload) => {
                setTenantList(payload);
            })
            .catch(handleError);
    }, [getTenantsCallBack, handleError]);

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'organizations.modal.add.store.title',
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Form {...formData}>
                        <SelectMarketplaceField
                            breakOutOfContainer
                            connectorList={connectorList}
                            helpText={formatMessage({
                                id: 'organizations.actions.add.store.tooltip',
                            })}
                            name="selectMarketplace"
                            placement="bottom-center"
                            required
                            tenantList={tenantList}
                        />
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'button.add',
                    }),
                    onClick: (close, event) => {
                        return handleSubmit(() => {
                            return handleModalSubmit(
                                close,
                                formData.getValues('selectMarketplace')
                            );
                        })(event);
                    },
                    state: isSubmitting ? 'loading' : undefined,
                }}
                dismissButton={{
                    label: formatMessage({
                        id: 'button.cancel',
                    }),
                }}
            />
        </Modal>
    );
}

export default SelectMarketplaceModal;
