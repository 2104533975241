import { useCallback, useMemo, useState } from 'react';

import useLoginContext from '../../../config/login/LoginProvider';
import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import { ShopType } from '../../create/form/LinkShopsField';
import { ShopsPayload } from '../../run/shop/linking/linkingStoreApi';

import { sortByLinkedThenTenantThenName } from './shopSort';

type AdditionalShopsResult = {
    /* all the shops retrieved from the added email */
    allShops: ShopType[];
    /* all the shops retrieved from the added email that do not already belong to an organization */
    availableShops: ShopType[];
    /* the added email */
    email: string;
};

export type UserShopsForLink = {
    connectedUserShops: ShopType[];
    fetchAdditionnalShops(
        otherEmail: string,
        token: string
    ): Promise<AdditionalShopsResult>;
    hasAnyAvailableShop: boolean;
    shopsByUser: Record<string, ShopWithToken>;
    userOwnsAtLeastOneShop: boolean;
};

export type ShopWithToken = {
    shops: ShopType[];
    verificationToken?: string;
};

/**
 * This hook handle the shops of a user for shop linking forms. For now it took the connectedUserShops as parameter,
 * but it would be better to handle it as well, but we need to refacto the code to be consistent between create and
 * edit organization pages first.
 *
 * @param connectedUserShops the shops linked to the connected user email
 * @param excludedShopUuids the shops already linked to the organization to edit (only for edition page)
 */
const useUserShopsForLink = (
    connectedUserShops: ShopType[],
    excludedShopUuids?: Set<string>
): UserShopsForLink => {
    const { email: connectedUserEmail } = useLoginContext();
    const [shopsByUser, setShopsByUser] = useState<
        Record<string, ShopWithToken>
    >({ [connectedUserEmail]: { shops: connectedUserShops } });

    const { apiGet } = useAuthenticatedFetch();

    const hasAnyAvailableShop = useMemo(
        () =>
            Object.values(shopsByUser).some((shops) =>
                shops.shops.some((shop) => !shop.alreadyInOrganization)
            ),
        [shopsByUser]
    );

    const userOwnsAtLeastOneShop = useMemo(
        () =>
            Object.values(shopsByUser).some((shops) => shops.shops.length > 0),
        [shopsByUser]
    );

    const fetchAdditionnalShops = useCallback(
        (otherEmail: string, token: string) => {
            return apiGet<ShopsPayload>('/private/users/shops/other', {
                params: { confirmationCodeToken: token },
            }).then((result) => {
                if (result.data.shops?.length > 0) {
                    // Get rid of duplicate shops from SSO user and previously linked email addresses
                    // If we receive an already linked address, we will update the related entry
                    const knownShopIds = new Set<string>(
                        Object.entries(shopsByUser)
                            .filter(([email]) => email !== otherEmail)
                            .flatMap(([, shops]) =>
                                shops.shops.map((shop) => shop.uuid)
                            )
                    );

                    // Exclude already linked shops as well for organization edition
                    excludedShopUuids?.forEach((id) => knownShopIds.add(id));

                    // Only keep shops that are not already linked to another email address
                    const newShops = sortByLinkedThenTenantThenName(
                        result.data.shops
                            .filter((shop) => !knownShopIds.has(shop.uuid))
                            .map((shop) => {
                                return {
                                    ...shop,
                                    channelNames: shop.channels.map(
                                        (channel) =>
                                            channel.text ?? channel.code
                                    ),
                                    tenantDisplayName:
                                        shop.tenantName ?? shop.tenantId,
                                };
                            })
                    );

                    setShopsByUser((prevState) => ({
                        ...prevState,
                        [otherEmail]: {
                            shops: newShops,
                            verificationToken: token,
                        },
                    }));

                    return {
                        email: otherEmail,
                        allShops: newShops,
                        availableShops: newShops.filter(
                            (shop) => !shop.alreadyInOrganization
                        ),
                    };
                }
                return {
                    email: otherEmail,
                    allShops: [],
                    availableShops: [],
                };
            });
        },
        [excludedShopUuids, shopsByUser, apiGet]
    );

    return {
        connectedUserShops,
        shopsByUser,
        hasAnyAvailableShop,
        userOwnsAtLeastOneShop,
        fetchAdditionnalShops,
    };
};

export default useUserShopsForLink;
