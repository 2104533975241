import { LayoutItem } from '@mirakl/roma';

import SupportOperatorAccountDetailsLayout from '../../../detail/support/SupportOperatorAccountDetailsLayout';

import TicketingLicenceUnavailablePage from './TicketingLicenceUnavailablePage';
import TicketingLicenceErrorBoundary from './components/TicketingLicenceErrorBoundary';
import TicketingLicenceLimitForm from './components/TicketingLicenceLimitForm';
import TicketingLicenceLimitReachedAlert from './components/TicketingLicenceLimitReachedAlert';
import TicketingLicenceUsersPanel from './components/TicketingLicenceUsersPanel';
import useTicketingLicenceEnabled from './utils/useTicketingLicenceEnabled';

const SupportTicketingLicenceUsersPage = () => {
    const { isEnabled } = useTicketingLicenceEnabled();

    if (!isEnabled) {
        return <TicketingLicenceUnavailablePage />;
    }

    return (
        <SupportOperatorAccountDetailsLayout size="sm">
            <TicketingLicenceErrorBoundary>
                <LayoutItem>
                    <TicketingLicenceLimitReachedAlert />
                    <TicketingLicenceLimitForm>
                        <TicketingLicenceUsersPanel />
                    </TicketingLicenceLimitForm>
                </LayoutItem>
            </TicketingLicenceErrorBoundary>
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportTicketingLicenceUsersPage;
