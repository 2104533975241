import { DatatableRowSelectionType } from '@mirakl/roma';

export const MERGE_MIN_LIMIT = 2;
export const MERGE_MAX_LIMIT = 5;

const isBelowMinLimit = (rowsCount: number) => {
    return rowsCount < MERGE_MIN_LIMIT;
};

const isAboveMaxLimit = (rowsCount: number) => {
    return rowsCount > MERGE_MAX_LIMIT;
};

const isRowsCountOutOfMergeBounds = (rowsCount: number) => {
    return isBelowMinLimit(rowsCount) || isAboveMaxLimit(rowsCount);
};

export const isMergeButtonDeactivated = (
    selectedRows: DatatableRowSelectionType,
    nbOrganizations: number
) => {
    return (
        (selectedRows === 'all' &&
            isRowsCountOutOfMergeBounds(nbOrganizations)) ||
        isRowsCountOutOfMergeBounds(selectedRows.length)
    );
};

export const isMergeBelowMinLimit = (
    selectedRows: DatatableRowSelectionType,
    nbOrganizations: number
) => {
    return (
        (selectedRows === 'all' && isBelowMinLimit(nbOrganizations)) ||
        isBelowMinLimit(selectedRows.length)
    );
};
