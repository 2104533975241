import { RawAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

const withHeader = <HeaderValue>(
    requestConfig: RawAxiosRequestConfig,
    header: string,
    value: HeaderValue
) => ({
    ...requestConfig,
    headers: {
        ...(requestConfig.headers as RawAxiosRequestHeaders),
        [header]: value,
    },
});

export const withAuthorizationHeader = (
    requestConfig: RawAxiosRequestConfig,
    idToken: string
) => withHeader(requestConfig, 'Authorization', `Bearer ${idToken}`);

export const withAcceptLanguageHeader = (
    requestConfig: RawAxiosRequestConfig,
    locale: string
) => withHeader(requestConfig, 'Accept-Language', locale);
