import React from 'react';
import { css, styled } from 'styled-components';

import { useI18n } from '@mirakl/i18n';
import {
    Badge,
    Flex,
    Icon,
    type IconNameType,
    Media,
    Text,
    Title,
    Tooltip,
    themeColor,
} from '@mirakl/roma';

type PaywallCardItemProps = {
    badge?: {
        iconName: IconNameType;
        label: string;
    };
    disabled?: boolean;
    subtitleVar?: string;
    titleVar?: string;
    tooltipText?: string;
    type:
        | 'networking'
        | 'fast-store-creation'
        | 'basic-connectors'
        | 'inventory-management'
        | 'multi-channel-customer-care'
        | 'advanced-connectors'
        | 'channels'
        | 'products'
        | 'gmv'
        | 'order-management'
        | 'helpdesk-limited'
        | 'helpdesk-unlimited'
        | 'catalog-transformer'
        | 'professional-services';
};

const ColoredTitle = styled(Title)<{ $disabled?: boolean }>`
    ${({ $disabled }) =>
        $disabled &&
        css`
            color: ${themeColor('grey-400')};
        `}
`;

const PaywallCardItem = ({
    badge,
    disabled = false,
    subtitleVar,
    titleVar,
    tooltipText,
    type,
}: PaywallCardItemProps) => {
    const { formatMessage } = useI18n();

    return (
        <Flex fullWidth gap={3}>
            <Media
                name={disabled ? 'remove' : 'check'}
                size={5}
                status={disabled ? 'disabled' : 'success'}
                type="background-icon"
            />
            <Flex flexDirection="column">
                <Flex alignItems="center" gap={1}>
                    <ColoredTitle $disabled={disabled} noSpaceBottom tag="h4">
                        {formatMessage(
                            {
                                id: `organization.paywall.card.item.${type}.title`,
                            },
                            { var: titleVar }
                        )}
                    </ColoredTitle>
                    {tooltipText && (
                        <Tooltip
                            content={tooltipText}
                            trigger={<Icon name="help-outline" />}
                        />
                    )}
                    {badge && (
                        <Badge
                            iconName={badge.iconName}
                            label={badge.label}
                            size="small"
                            status="info"
                        />
                    )}
                </Flex>
                {type !== 'channels' && (
                    <Text
                        color={disabled ? 'grey-400' : 'grey-500'}
                        variant="small-400"
                    >
                        {formatMessage(
                            {
                                id: `organization.paywall.card.item.${type}.subtitle`,
                            },
                            { var: subtitleVar }
                        )}
                    </Text>
                )}
            </Flex>
        </Flex>
    );
};

export default PaywallCardItem;
