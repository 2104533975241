import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import { Alert, Button, Panel, useModal, useNavigate } from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import { PageTitleActionsType } from '../../../types/romaTypes';
import useUsersShopsApiClient, {
    ReadyToLinkShopsPayload,
    UsersShopsQueryParams,
} from '../../common/shop/useUsersShopsApiClient';
import useConnectorUrl from '../../connectors/useConnectorUrl';
import useOrganizationContext from '../OrganizationContext';
import OrganizationRunPage from '../OrganizationRunPage';

import ListLinkedShop from './ListLinkedShopsPage';
import ReadyToLinkShops from './ReadyToLinkPage';
import SelectMarketplaceModal from './SelectMarketplaceModal';
import useOrganizationTenant from './useOrganizationTenant';

type ShopList = {
    channels: Channel[];
    name: string;
    state: ShopState;
    tenantId: string;
    tenantLogoUrl: string;
    tenantName?: string;
    uuid: string;
};
type Channel = {
    code: string;
    text?: string;
};
export type ReadyToLinkShopsData = {
    shops: ShopDetail[];
};
type ShopDetail = {
    channelNames: string[];
    id: string;
    store: string;
    storeAction: ShopState;
    tenant: {
        logoUrl: string;
        name: string;
    };
};
type ShopState = {
    suspensionType?: string;
    value: string;
};

const fromPayload = (
    payload: ReadyToLinkShopsPayload
): ReadyToLinkShopsData => ({
    ...payload,
    shops: payload.shops.map((shop: ShopList) => {
        return {
            id: shop.uuid,
            channelNames: shop.channels.map(
                (channel) => channel.text ?? channel.code
            ),
            tenant: {
                name: shop.tenantName ?? shop.tenantId,
                logoUrl: shop.tenantLogoUrl,
            },
            store: shop.name,
            storeAction: { ...shop.state },
        };
    }),
});

function ShopsManagingPage() {
    const { formatMessage } = useI18n();
    const fetchReadyToLinkShopsApi = useUsersShopsApiClient();
    const { availableConnectors, isOrganizationAdmin, uuid } =
        useOrganizationContext();
    const { miraklSupport } = useLoginContext();
    const { showModal } = useModal();
    const navigate = useNavigate();
    const { getTenants } = useOrganizationTenant();
    const { tab } = useParams<{
        tab: 'linked-shops' | 'ready-to-link';
    }>();
    const [readyToLinkShopsCount, setReadyToLinkShopsCount] =
        useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [readyToLinkShops, setReadyToLinkShops] =
        useState<ReadyToLinkShopsData>({
            shops: [],
        });
    const hasReadyToLinkTab =
        !miraklSupport.isSellerSupportReadWrite && isOrganizationAdmin;

    const { search } = useLocation();
    const refreshParam = 'refresh';
    const hasRefreshParam = useMemo(() => {
        return new URLSearchParams(search).has(refreshParam);
    }, [search, refreshParam]);
    const [showConnectorShopsImportAlert, setShowConnectorShopsImportAlert] =
        useState<boolean>(hasRefreshParam);
    const { fetchConnectorUrl: fetchConnectorUrl } = useConnectorUrl();

    const sellerActions: PageTitleActionsType = [
        isOrganizationAdmin && (
            <Button
                key="add-store"
                label={formatMessage({ id: 'organizations.actions.add.store' })}
                variant="primary"
                onClick={() =>
                    showModal(
                        <SelectMarketplaceModal
                            connectorList={availableConnectors}
                            getTenantsCallBack={getTenants}
                            handleModalSubmit={(
                                close,
                                value
                            ): Promise<void> => {
                                if (availableConnectors.includes(value)) {
                                    const callback = `${window.location.origin}/organizations/${uuid}/shops/ready-to-link?${refreshParam}`;
                                    fetchConnectorUrl(value, callback).then(
                                        (connectorUrl) => {
                                            navigate({
                                                href: connectorUrl,
                                                shouldNotShowConfirmModal: true,
                                            });
                                        }
                                    );
                                } else {
                                    navigate({
                                        to: `/organizations/${uuid}/shops/link-with-different-email-address?tenant=${value}`,
                                    });
                                }
                                close();
                                return Promise.resolve();
                            }}
                        />
                    )
                }
            />
        ),
    ];

    const fetchReadyToLinkShops = useCallback(
        (queryParams?: UsersShopsQueryParams) => {
            setShowConnectorShopsImportAlert(hasRefreshParam);
            return fetchReadyToLinkShopsApi(queryParams).then((payload) => {
                setReadyToLinkShops(fromPayload(payload));
                // We need to check the search param shopName to avoid updating the ready to link shops based on search. We want the real shops number, not the one from the search.
                if (!queryParams?.shopName) {
                    setReadyToLinkShopsCount(payload.shops.length);
                }
            });
        },
        [fetchReadyToLinkShopsApi, hasRefreshParam]
    );

    useEffect(() => {
        if (isOrganizationAdmin) {
            fetchReadyToLinkShops().then(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [fetchReadyToLinkShops, isOrganizationAdmin]);

    const tabItems = useMemo(() => {
        const linkedShopsTab = {
            id: 'linked-shops-tab',
            label: formatMessage({
                id: 'datatable.organizations.shop.page.linkedShops.tab',
            }),
            to: `/organizations/${uuid}/shops/linked-shops`,
        };
        const readyToLinkTab = {
            id: 'ready-to-link-tab',
            label: formatMessage({
                id: 'datatable.organizations.shop.page.readyToLink.tab',
            }),
            counter: `${readyToLinkShopsCount}`,
            to: `/organizations/${uuid}/shops/ready-to-link`,
        };
        return hasReadyToLinkTab
            ? [linkedShopsTab, readyToLinkTab]
            : [linkedShopsTab];
    }, [formatMessage, hasReadyToLinkTab, readyToLinkShopsCount, uuid]);

    return (
        <OrganizationRunPage
            customActions={sellerActions}
            pageTitle={formatMessage({
                id: 'datatable.organizations.shop.page.title',
            })}
            size="sm"
        >
            {showConnectorShopsImportAlert && (
                <Alert
                    action={{
                        label: formatMessage({
                            id: 'datatable.organizations.shop.page.connector.import.refresh',
                        }),
                        onClick: () => {
                            setShowConnectorShopsImportAlert(false);
                            navigate({
                                to: `/organizations/${uuid}/shops/ready-to-link`,
                            });
                        },
                    }}
                    status="loading"
                    title={formatMessage({
                        id: 'datatable.organizations.shop.page.connector.import.title',
                    })}
                >
                    {formatMessage({
                        id: 'datatable.organizations.shop.page.connector.import.description',
                    })}
                </Alert>
            )}
            <Panel loading={isLoading}>
                <Panel.NavigationTabs items={tabItems} />
                <Panel.Content fullWidth>
                    {tab === 'linked-shops' ? (
                        <ListLinkedShop />
                    ) : (
                        <ReadyToLinkShops
                            fetchReadyToLinkShops={fetchReadyToLinkShops}
                            readyToLinkShops={readyToLinkShops}
                            readyToLinkShopsCount={readyToLinkShopsCount}
                        />
                    )}
                </Panel.Content>
            </Panel>
        </OrganizationRunPage>
    );
}

export default ShopsManagingPage;
