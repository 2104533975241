import { styled } from 'styled-components';

import { Box, Media, themeElevation, themeSpace } from '@mirakl/roma';

import useProfileInfoContext from '../../ProfileInfoProvider';

const profileInfoAvatarSize = 11;

const _ProfileInfoAvatarShadowBox = styled(Box)`
    box-shadow: ${themeElevation(700)};
    border-radius: 50%;
    height: ${themeSpace(profileInfoAvatarSize)};
    width: ${themeSpace(profileInfoAvatarSize)};
`;

const _ProfileInfoAvatar = () => {
    const {
        profileInfo: { color, initials },
    } = useProfileInfoContext();
    return (
        <_ProfileInfoAvatarShadowBox>
            <Media
                color={color}
                initials={initials}
                size={profileInfoAvatarSize}
                type="avatar"
            />
        </_ProfileInfoAvatarShadowBox>
    );
};

export default _ProfileInfoAvatar;
