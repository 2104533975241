import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import {
    EmptyState,
    Flex,
    LayoutContainer,
    LayoutItem,
    Page,
    PageLayout,
    useNavigate,
} from '@mirakl/roma';

const AfterCreateTransition = () => {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();

    const [showEmptyState, setShowEmptyState] = useState<boolean>(false);

    const {
        state: { callback },
    } = useLocation();

    useEffect(() => {
        // If there is no callback, then we just show an empty state
        if (!callback) {
            setShowEmptyState(true);
            return;
        }

        navigate({
            href: callback,
        });
    }, [callback, navigate]);

    return (
        <Page fullPage>
            <PageLayout>
                <LayoutContainer>
                    <LayoutItem>
                        <Flex paddingTop={10}>
                            {showEmptyState && (
                                <EmptyState
                                    illustrationName="order-shopping"
                                    illustrationSize="large"
                                    title={formatMessage({
                                        id: 'transition.after.create.title',
                                    })}
                                />
                            )}
                        </Flex>
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
            <Page.Footer />
        </Page>
    );
};

export default AfterCreateTransition;
