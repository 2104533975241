export type Role =
    | 'ROLE_SUPPORT_OPERATOR'
    | 'ROLE_SUPPORT_OPERATOR_READONLY'
    | 'ROLE_SUPPORT_SELLER'
    | 'ROLE_SUPPORT_SELLER_READONLY'
    | 'ROLE_OPERATOR';

export type MiraklSupportType = {
    isOperatorSupportReadOnly: boolean;
    isOperatorSupportReadWrite: boolean;
    isOperatorSupportUser: boolean;
    isSellerSupportReadOnly: boolean;
    isSellerSupportReadWrite: boolean;
    isSellerSupportUser: boolean;
    isSupportUser: boolean;
};

const OPERATOR_READWRITE_ROLE = 'ROLE_SUPPORT_OPERATOR';
const OPERATOR_READONLY_ROLE = 'ROLE_SUPPORT_OPERATOR_READONLY';
const OPERATOR_ROLES: Role[] = [
    OPERATOR_READWRITE_ROLE,
    OPERATOR_READONLY_ROLE,
];
const SELLER_READWRITE_ROLE = 'ROLE_SUPPORT_SELLER';
const SELLER_READONLY_ROLE = 'ROLE_SUPPORT_SELLER_READONLY';
const SELLER_ROLES: Role[] = [SELLER_READWRITE_ROLE, SELLER_READONLY_ROLE];

const hasRole = (role: Role, userRoles: string[]) => {
    return userRoles.includes(role);
};

const hasAnyRole = (roles: Role[], userRoles: string[]) => {
    return roles.some((role) => hasRole(role, userRoles));
};

export function buildMiraklSupport(userRoles: string[]) {
    return {
        isOperatorSupportReadOnly: hasRole(OPERATOR_READONLY_ROLE, userRoles),
        isOperatorSupportReadWrite: hasRole(OPERATOR_READWRITE_ROLE, userRoles),
        isOperatorSupportUser: hasAnyRole(OPERATOR_ROLES, userRoles),
        isSellerSupportReadOnly: hasRole(SELLER_READONLY_ROLE, userRoles),
        isSellerSupportReadWrite: hasRole(SELLER_READWRITE_ROLE, userRoles),
        isSellerSupportUser: hasAnyRole(SELLER_ROLES, userRoles),
        isSupportUser:
            hasAnyRole(OPERATOR_ROLES, userRoles) ||
            hasAnyRole(SELLER_ROLES, userRoles),
    };
}
