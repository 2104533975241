import React from 'react';

import { useErrorHandler } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import ShopsManagingPage from './ShopsManagingPage';

const SHOPS_MANAGING_TAB_IDS = ['linked-shops', 'ready-to-link'];

class HttpNotFound extends Error {
    constructor() {
        super('Http not found error');
    }

    private readonly response = {
        status: 404,
    };
}

const EnsureShopsManagingTabId = () => {
    const { tab } = useRequiredParams<{ tab: string }>();
    const handleError = useErrorHandler();

    if (!SHOPS_MANAGING_TAB_IDS.includes(tab)) {
        handleError(new HttpNotFound());
    }

    return <ShopsManagingPage />;
};

export default EnsureShopsManagingTabId;
