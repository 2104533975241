import 'core-js/stable';

import { createRoot } from 'react-dom/client';

import './global.css';

import { initSentry } from '@mirakl/react-scripts-mirakl/sentry';

import Root from './Root';
import './config/axios/axiosConfig';

initSentry();

const root = createRoot(document.querySelector('.mui-app') as HTMLDivElement);

root.render(<Root />);
