import { useCallback } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';
import useQuery from '../../router/useQuery';
import useLogin from '../useLogin';
import useSignupRedirect from '../useSignupRedirect';

export const useInternalSignup = () => {
    const { isReady, user } = useLogin();
    const handleSignupRedirect = useSignupRedirect();
    const { search } = useLocation();
    const { apiPost } = useAuthenticatedFetch();

    const param = useQuery({
        type: 'string',
        product_id: 'string',
        callback: 'string',
        email: 'string',
        create_organization: 'boolean',
        organization_selector: 'boolean',
        locale: 'optionalString',
        organization_id: 'optionalString',
        tenant_id: 'optionalString',
    });

    const onSubmit = useCallback(async () => {
        const { data } = await apiPost(`/private/signup/internal${search}`, {
            email: param.email,
            type: param.type,
            callbackUrl: param.callback,
            productId: param.product_id,
            tenantId: param.tenant_id,
            customizations: {
                createOrganization: param.create_organization,
                organizationSelector: param.organization_selector,
                organizationUuid: param.organization_id,
            },
            locale: user?.user?.locale ?? param.locale ?? navigator.language,
        });
        return handleSignupRedirect(data);
    }, [
        apiPost,
        search,
        param.email,
        param.type,
        param.callback,
        param.tenant_id,
        param.product_id,
        param.create_organization,
        param.organization_selector,
        param.organization_id,
        param.locale,
        user?.user?.locale,
        handleSignupRedirect,
    ]);

    return {
        isReady,
        onSubmit,
        user,
    };
};
