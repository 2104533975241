import { useCallback, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { Select, SelectionListOptionType } from '@mirakl/roma';

const ProfileInfoTimeZoneField = ({
    breakOutOfContainer,
    label,
    name,
    required,
    timeZones,
}: {
    breakOutOfContainer?: boolean;
    label: string;
    name: string;
    required?: boolean;
    timeZones: string[];
}) => {
    const { formatMessage } = useI18n();

    const options = useMemo(
        () =>
            timeZones
                .sort((a, b) => a.localeCompare(b))
                .map((tz) => ({
                    id: tz.replaceAll(/\W/g, '-'),
                    label: tz,
                    value: tz,
                })),
        [timeZones]
    );

    const placeholderOption = useMemo(() => {
        return {
            id: 'placeholder',
            value: null,
            label: `-- ${formatMessage({ id: 'select.no.value' })} --`,
        };
    }, [formatMessage]);

    const optionsByValue = useMemo(() => {
        const map = new Map<string, SelectionListOptionType<string>>();
        options.forEach((option) => {
            map.set(option.value, option);
        });
        return map;
    }, [options]);

    const findOption = useCallback(
        (value: string | null | undefined) => {
            if (!value) {
                return undefined;
            }
            return optionsByValue.get(value);
        },
        [optionsByValue]
    );

    // This will allow us to fall back to placeholder option
    // if the value is not included within available options
    const isOptionSelected = useCallback(
        (
            option: SelectionListOptionType<string | null>,
            value?: string | null
        ) => {
            const matchingOptionForValue = findOption(value);
            if (!matchingOptionForValue && option.id === placeholderOption.id) {
                return true;
            }
            return option.value === value;
        },
        [findOption, placeholderOption]
    );

    return (
        <Select
            breakOutOfContainer={breakOutOfContainer}
            isOptionSelected={isOptionSelected}
            label={label}
            name={name}
            options={options}
            placeholderOption={placeholderOption}
            required={required}
        />
    );
};

export default ProfileInfoTimeZoneField;
