import { useCallback } from 'react';

import { createQueryParamsHelper } from '@mirakl/roma';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import { StageValues } from '../../../types/stageTypes';

export const SHOP_NAME_FILTER = 'shopName';
export type ReadyToLinkShopsPayload = {
    shops: ShopPayload[];
};

export type ShopPayload = {
    alreadyInOrganization: boolean;
    channels: Channel[];
    name: string;
    stage: StageValues;
    state: {
        suspensionType?:
            | 'MANUAL'
            | 'SHOP_CREATION'
            | 'PROFILE_INCOMPLETE'
            | 'QUALITY_ISSUE';
        value: string;
    };
    tenantId: string;
    tenantLogoUrl: string;
    tenantName?: string;
    uuid: string;
};

type Channel = {
    code: string;
    text?: string;
};

export const usersShopsQueryParamsHelper = createQueryParamsHelper()
    .withSearch(SHOP_NAME_FILTER)
    .withSort('sort');

export type UsersShopsQueryParams = Parameters<
    Parameters<(typeof usersShopsQueryParamsHelper)['useQueryParamsChange']>[0]
>[0];

const useUsersShopsApiClient = () => {
    const { apiGet } = useAuthenticatedFetch();

    return useCallback(
        (
            queryParams?: UsersShopsQueryParams
        ): Promise<ReadyToLinkShopsPayload> =>
            apiGet<ReadyToLinkShopsPayload>(
                '/private/users/shops?inOrganization=false',
                {
                    params: queryParams,
                }
            ).then((payload) => payload.data),
        [apiGet]
    );
};

export default useUsersShopsApiClient;
