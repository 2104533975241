import { ReactNode } from 'react';

import useLoginContext from '../../config/login/LoginProvider';

import MiraklSupportGlobalLayout from './MiraklSupportGlobalLayout';
import ShopUserGlobalLayout from './ShopUserGlobalLayout';

export const HELP_PAGE_URL = 'https://help.mirakl.net/';

const AccountGlobalLayout = ({
    children,
}: {
    children: ReactNode | ReactNode[];
}) => {
    const { miraklSupport } = useLoginContext();

    return miraklSupport.isSupportUser ? (
        <MiraklSupportGlobalLayout>{children}</MiraklSupportGlobalLayout>
    ) : (
        <ShopUserGlobalLayout>{children}</ShopUserGlobalLayout>
    );
};

export default AccountGlobalLayout;
