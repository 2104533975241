import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useI18n } from '@mirakl/i18n';
import {
    DatatableProvider,
    DatatableSeekPaginateDataType,
    Hyperlink,
    Panel,
    Paragraph,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import { OperatorSmtpType } from '../../OperatorSmtpClientModelApi';
import {
    operatorSmtpListQueryParamsHelper,
    useOperatorSmtpClientApi,
} from '../../operatorSmtpClientApi';

import OperatorSmtpListingDatatable from './OperatorSmtpListingDatatable';

type OperatorSmtpListingProps = {
    isFromSupportPage?: boolean;
};

const OperatorSmtpListingPanel = ({
    isFromSupportPage,
}: OperatorSmtpListingProps) => {
    const { formatMessage } = useI18n();

    const { id } = useRequiredParams<{ id: string }>();

    const { getOperatorSmtps } = useOperatorSmtpClientApi();

    const [smtps, setSmtps] = useState<
        DatatableSeekPaginateDataType<OperatorSmtpType>
    >({});

    const { loadingState, onQueryParamsChange } =
        operatorSmtpListQueryParamsHelper.useQueryParamsChange(
            (queryParams) => {
                return getOperatorSmtps(id, queryParams).then((data) =>
                    setSmtps(data)
                );
            }
        );

    return (
        <Panel>
            <Panel.Header
                title={formatMessage({
                    id: 'operator.smtp.configuration',
                })}
            />
            <Panel.Content>
                <Paragraph>
                    <FormattedMessage
                        id="operator.smtp.configuration.description"
                        values={{
                            a(chunk) {
                                // TODO ACCOUNT-1167: add the correct link
                                return (
                                    <Hyperlink
                                        href="https://example.com"
                                        target="_blank"
                                    >
                                        {chunk}
                                    </Hyperlink>
                                );
                            },
                        }}
                    />
                </Paragraph>
            </Panel.Content>
            <Panel.Content fullWidth>
                <operatorSmtpListQueryParamsHelper.QueryParamsProvider
                    onQueryParamsChange={onQueryParamsChange}
                >
                    <DatatableProvider>
                        <OperatorSmtpListingDatatable
                            isFromSupportPage={isFromSupportPage}
                            loadingState={loadingState}
                            smtps={smtps}
                        />
                    </DatatableProvider>
                </operatorSmtpListQueryParamsHelper.QueryParamsProvider>
            </Panel.Content>
        </Panel>
    );
};

export default OperatorSmtpListingPanel;
