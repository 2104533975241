import { useI18n } from '@mirakl/i18n';
import { ConfirmModal } from '@mirakl/roma';

function ConfirmCreateWarehouseModal({
    onConfirm,
    warehouseCode,
}: {
    onConfirm(): Promise<unknown>;
    warehouseCode: string;
}) {
    const { formatMessage } = useI18n();
    const onClickConfirm = (close: () => void) => onConfirm().finally(close);

    return (
        <ConfirmModal
            confirmButton={{
                label: formatMessage({
                    id: 'button.confirm',
                }),
                onClick: onClickConfirm,
            }}
            content={formatMessage(
                { id: 'organizations.warehouses.form.modal.content' },
                { code: warehouseCode }
            )}
            size="medium"
            title={formatMessage({
                id: 'organizations.warehouses.form.modal.title',
            })}
        />
    );
}

export default ConfirmCreateWarehouseModal;
