import { Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import { OperatorAccountProvider } from './OperatorAccountContext';
import OperatorAdminOrSupportOnlyRoute from './OperatorAdminOrSupportOnlyRoute';
import OperatorSupportOnlyRoute from './OperatorSupportOnlyRoute';
import OperatorCompanyInformationPage from './detail/operator/OperatorCompanyInformationPage';
import SupportCompanyInformationPage from './detail/support/SupportCompanyInformationPage';
import OperatorAccountListingPage from './listing/OperatorAccountListingPage';
import OperatorSmtpPages from './smtp/OperatorSmtpPages';
import SupportOperatorSmtpPages from './smtp/SupportOperatorSmtpPages';
import OperatorAccountUserListingPage from './users/listing/OperatorAccountUserListingPage';
import SupportOperatorAccountUserListingPage from './users/listing/SupportOperatorAccountUserListingPage';
import OperatorAccountTicketingLicenceUsersPage from './users/ticketing/licence/OperatorAccountTicketingLicenceUsersPage';
import SupportTicketingLicenceUsersPage from './users/ticketing/licence/SupportTicketingLicenceUsersPage';

const OperatorAccountPages = () => {
    return (
        <Routes>
            <Route element={<OperatorSupportOnlyRoute />}>
                <Route element={<OperatorAccountListingPage />} index />
            </Route>
            <Route
                element={
                    <OperatorAccountProvider>
                        <Routes>
                            <Route
                                element={<OperatorAdminOrSupportOnlyRoute />}
                            >
                                <Route
                                    element={<OperatorAccountUserListingPage />}
                                    path="/users"
                                />
                                <Route
                                    element={
                                        <OperatorAccountTicketingLicenceUsersPage />
                                    }
                                    path="/users/ticketing/licences"
                                />
                                <Route
                                    element={<OperatorCompanyInformationPage />}
                                    index
                                />
                                <Route
                                    element={<OperatorSmtpPages />}
                                    path="/smtp-configurations/*"
                                />
                                <Route element={<OperatorSupportOnlyRoute />}>
                                    <Route
                                        element={
                                            <SupportCompanyInformationPage />
                                        }
                                        path="/company"
                                    />
                                    <Route
                                        element={
                                            <SupportOperatorAccountUserListingPage />
                                        }
                                        path="/company/users"
                                    />
                                    <Route
                                        element={<SupportOperatorSmtpPages />}
                                        path="/company/smtp-configurations/*"
                                    />
                                    <Route
                                        element={
                                            <SupportTicketingLicenceUsersPage />
                                        }
                                        path="/company/users/ticketing/licences"
                                    />
                                </Route>
                            </Route>
                        </Routes>
                    </OperatorAccountProvider>
                }
                path="/:id/*"
            />
        </Routes>
    );
};

export default OperatorAccountPages;
