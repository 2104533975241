import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Card,
    Flex,
    LinkButton,
    Paragraph,
    SpacingWrapper,
    Text,
    Title,
} from '@mirakl/roma';

import useErrorToast from '../../error/useErrorToast';
import { Auth0User } from '../../types/auth0Types';

type IdCardProps = {
    email: string;
    firstName?: string;
    lastName?: string;
};

const IdCard = ({ email, firstName, lastName }: IdCardProps) => {
    return (
        <Card alignContent="center" alignItems="center" gap={2}>
            <Flex flexDirection="column">
                <Title noSpaceBottom tag="h3">
                    {email}
                </Title>
                {firstName && lastName && firstName !== email && (
                    <Text variant="default-400">
                        {firstName} {lastName}
                    </Text>
                )}
            </Flex>
        </Card>
    );
};

type EmailSelectionProps = {
    canChangeAccount: boolean;
    logout: () => void;
    product: string;
    user: Auth0User;
};

const EmailSelection = ({
    canChangeAccount,
    logout,
    product,
    user,
}: EmailSelectionProps) => {
    const { formatMessage } = useI18n();

    return (
        <>
            <Paragraph>
                {formatMessage(
                    { id: 'signup.use.account.label' },
                    { product: product }
                )}
            </Paragraph>
            <IdCard
                email={user.email}
                firstName={user.name}
                lastName={user.family_name}
            />
            {canChangeAccount && (
                <SpacingWrapper>
                    <LinkButton
                        data-testid="change-account-button"
                        iconName="loading"
                        iconPosition="start"
                        label={formatMessage({ id: 'signup.change.account' })}
                        onClick={logout}
                    />
                </SpacingWrapper>
            )}
        </>
    );
};

type SignupLoggedInContentProps = {
    logout: () => void;
    onSubmit: (email: string) => Promise<void>;
    product: string;
    signupHint?: string;
    user: Auth0User;
};

const SignupLoggedInContent = ({
    logout,
    onSubmit,
    product,
    signupHint,
    user,
}: SignupLoggedInContentProps) => {
    const showErrorToast = useErrorToast();
    const { formatMessage } = useI18n();

    const submit = () =>
        onSubmit(user.email).catch((error) => showErrorToast(error, [400]));

    return (
        <>
            <EmailSelection
                canChangeAccount={!signupHint}
                logout={logout}
                product={product}
                user={user}
            />
            <Flex justifyContent="end">
                <Button
                    data-testid="continue-button"
                    label={formatMessage({ id: 'signup.continue' })}
                    onClick={submit}
                />
            </Flex>
        </>
    );
};

export default SignupLoggedInContent;
