import { useCallback } from 'react';

import { useI18n } from '@mirakl/i18n';
import { TextField } from '@mirakl/roma';

const isAlpha = (value: string) =>
    value.match(/^(?!.*(-{2,}|'{2,}| {2,}))[\p{L}\-' ]+$/u)
        ? true
        : 'com.mirakl.validation.profile.valid.char';

const ProfileInfoNameField = ({
    label,
    name,
    required,
}: {
    label: string;
    name: string;
    required?: boolean;
}) => {
    const { formatMessage } = useI18n();

    const validate = useCallback(
        (value: string) => {
            const result = isAlpha(value);
            if (typeof result === 'string') {
                return formatMessage({ id: result });
            }
            return result;
        },
        [formatMessage]
    );

    return (
        <TextField
            label={label}
            maxLength={30}
            minLength={2}
            name={name}
            required={required}
            validate={validate}
        />
    );
};

export default ProfileInfoNameField;
