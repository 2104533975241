import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { isEmail } from 'validator';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Button,
    Flex,
    Form,
    Modal,
    Paragraph,
    TextField,
    useForm,
    useSnackbar,
} from '@mirakl/roma';

const EMAIL_FIELD = 'username';
const CODE_FIELD = 'code';
const CONFIRMATION_CODE_TIMEOUT_MS = 60_000;
const CONFIRMATION_CODE_CONFIRM_RATE_LIMIT_TIMEOUT_MS = 60_000;

export type ConfirmationCodeFormType = {
    [CODE_FIELD]: string;
    [EMAIL_FIELD]: string;
};

type LinkShopsModalProps = {
    connectedUserEmail: string;
    onModalConfirm: (
        data: ConfirmationCodeFormType,
        close: () => void
    ) => Promise<void>;
    sendCode: (inputEmail: string) => Promise<void>;
    timeout: number;
};

const LinkShopsModal = ({
    connectedUserEmail,
    onModalConfirm,
    sendCode,
    timeout,
}: LinkShopsModalProps) => {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();

    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [sendCodeAvailable, setSendCodeAvailable] = useState<boolean>(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const [confirmationButtonEnabled, setConfirmationButtonEnabled] =
        useState<boolean>(true);

    const defaultValues = {
        [EMAIL_FIELD]: '',
        [CODE_FIELD]: '',
    };

    const formData = useForm<ConfirmationCodeFormType>({
        defaultValues: defaultValues,
    });

    const {
        formState: { isSubmitting },
        getFieldState,
        handleSubmit,
        setError,
        trigger,
        watch,
    } = formData;

    const { invalid: invalidEmail, isDirty: filledEmail } =
        getFieldState(EMAIL_FIELD);
    const isEmailReady = !invalidEmail && filledEmail;

    const inputEmail: string = watch(EMAIL_FIELD);

    // Closing this modal will delete the timer, but the 'Send code' button must stay disabled for one minute regardless.
    // We need to reload it each time we open the modal.
    useEffect(() => {
        const initialTimer = setTimeout(
            () => setSendCodeAvailable(true),
            timeout
        );
        setTimer(initialTimer);
        return () => clearTimeout(initialTimer);
    }, [timeout]);

    const sendCodeAndResetTimer = () => {
        clearTimeout(timer);
        return sendCode(inputEmail)
            .then(() => {
                setSendCodeAvailable(false);
                setCodeSent(true);
                setTimer(
                    setTimeout(
                        () => setSendCodeAvailable(true),
                        CONFIRMATION_CODE_TIMEOUT_MS
                    )
                );
                setConfirmationButtonEnabled(true);
            })
            .catch(() =>
                addSnackbar({
                    message: formatMessage({
                        id: 'snackbar.edit.fail',
                    }),
                    status: 'error',
                })
            );
    };

    function handleConfirmationVerificationErrors(error: AxiosError) {
        // Note: 400 validation error handling is built-in the roma form component, so no need to handle it here.
        if (error.response && error.response.status === 429) {
            setConfirmationButtonEnabled(false);
            setTimer(
                setTimeout(
                    () => setConfirmationButtonEnabled(true),
                    CONFIRMATION_CODE_CONFIRM_RATE_LIMIT_TIMEOUT_MS
                )
            );
            setError('code', {
                type: 'custom',
                message: formatMessage({ id: 'invalid.code.ratelimit' }),
            });
        } else if (error.response && error.response.status === 410) {
            setError('code', {
                type: 'custom',
                message: formatMessage({ id: 'invalid.code.resend' }),
            });
            setConfirmationButtonEnabled(false);
        }
    }

    return (
        <Modal size="medium">
            <Modal.Header
                title={formatMessage({
                    id: 'organization.link.store.accounts',
                })}
            />
            <Modal.Scrollable>
                <Modal.Content>
                    <Form {...formData}>
                        <Paragraph>
                            {formatMessage({
                                id: 'organization.link.other.stores.description',
                            })}
                        </Paragraph>
                        <Flex
                            alignItems={
                                codeSent || invalidEmail ? 'center' : 'end'
                            }
                            gap={2}
                            marginBottom={4}
                        >
                            <TextField
                                helpText={
                                    codeSent
                                        ? formatMessage({ id: 'code.sent' })
                                        : undefined
                                }
                                label={formatMessage({ id: 'email.address' })}
                                maxLength={255}
                                name={EMAIL_FIELD}
                                noSpaceBottom
                                required
                                status="success"
                                validate={(value: string) =>
                                    // EmailField uses the RFC 5322 norm, but we want to check for TLD as well
                                    isEmail(value)
                                        ? value !== connectedUserEmail ||
                                          formatMessage({
                                              id: 'email.already.linked',
                                          })
                                        : formatMessage({
                                              id: 'roma.form.email.invalid',
                                          })
                                }
                                onChange={() => {
                                    // We trigger validation at the first input (before the field is touched),
                                    // so that the 'Send code' button stays disabled until the email address is valid.
                                    if (!filledEmail) {
                                        trigger(EMAIL_FIELD);
                                    }
                                    if (codeSent) {
                                        setCodeSent(false);
                                    }
                                }}
                            />
                            <Box flexShrink={0}>
                                <Button
                                    disabled={
                                        !isEmailReady || !sendCodeAvailable
                                    }
                                    label={formatMessage({ id: 'send.code' })}
                                    tooltipBreakOutOfContainer
                                    tooltipText={
                                        !sendCodeAvailable
                                            ? formatMessage({
                                                  id: 'confirmation.code.tooltip',
                                              })
                                            : undefined
                                    }
                                    variant="secondary"
                                    onClick={sendCodeAndResetTimer}
                                />
                            </Box>
                        </Flex>
                        <TextField
                            label={formatMessage({ id: 'confirmation.code' })}
                            name={CODE_FIELD}
                            required
                            validate={(code: string) =>
                                code.length === 0 ||
                                code.match(/^\d{6}$/) !== null ||
                                formatMessage({ id: 'code.must.be.digits' })
                            }
                        />
                    </Form>
                </Modal.Content>
            </Modal.Scrollable>
            <Modal.Footer
                confirmButton={{
                    label: formatMessage({
                        id: 'button.confirm',
                    }),
                    onClick: (close, event) =>
                        handleSubmit((data) => onModalConfirm(data, close))(
                            event
                        ).catch((error) =>
                            handleConfirmationVerificationErrors(error)
                        ),
                    disabled: !confirmationButtonEnabled,
                    state: isSubmitting ? 'loading' : 'default',
                }}
                dismissButton={{
                    label: formatMessage({
                        id: 'button.cancel',
                    }),
                }}
            />
        </Modal>
    );
};

export default LinkShopsModal;
