import { PasswordValidityResults } from './PasswordSpecsChecker';

const regex = (value: RegExp) => {
    return (password: string) => value.test(password);
};

const PASSWORD_SPECS = {
    digit: regex(/.*\d.*/),
    lower: regex(/.*[a-z].*/u),
    upper: regex(/.*[A-Z].*/u),
    // This matches Auth0's special characters
    symbol: regex(/.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~].*/u),
};

const MIN_SIZE = 12;
const MIN_VALID_SPECS = 3;

export const usePasswordValidation = (): ((
    password: string
) => PasswordValidityResults) => {
    return (password: string): PasswordValidityResults => {
        const results: PasswordValidityResults = {
            specs: {
                length: false,
                digit: false,
                lower: false,
                upper: false,
                symbol: false,
            },
            global: false,
        };
        Object.entries(PASSWORD_SPECS).map(([name, spec]) => {
            (results.specs as Record<string, boolean>)[name] = spec(password);
        });
        const lengthValidity: boolean = (password?.length ?? 0) >= MIN_SIZE;
        results.global =
            Object.values(results.specs).filter((v) => v).length >=
                MIN_VALID_SPECS && lengthValidity;
        results.specs.length = lengthValidity;
        return results;
    };
};
