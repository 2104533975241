import { Outlet, Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';
import { RomaProvider } from '@mirakl/roma';
import { QueryClientProvider } from '@mirakl/roma/react-query';
import { BrowserRouter } from '@mirakl/roma/router';

import LandingPage from './LandingPage';
import AccountAnalyticsProvider from './analytics/AccountAnalyticsProvider';
import CountriesProvider from './common/address/CountriesProvider';
import FeatureToggleProvider from './common/toggle/FeatureToggleProvider';
import I18nProvider from './config/i18n/I18nProvider';
import Auth0ConfigurationProvider from './config/login/Auth0ConfigurationProvider';
import { LoginProvider } from './config/login/LoginProvider';
import queryClient from './config/query/queryClient';
import DebugErrorBoundary from './error/DebugErrorBoundary';
import InvitationExpiredPage from './invitations/InvitationExpiredPage';
import { InvitationLoader } from './invitations/InvitationLoader';
import { InvitationPage } from './invitations/InvitationPage';
import InvitationSetupPassword from './invitations/InvitationSetupPassword';
import SignupEmailSentPage from './invitations/SignupEmailSentPage';
import { SignupPageCallback } from './invitations/SignupPageCallback';
import SignupExternalPage from './invitations/external/SignupExternalPage';
import SignupOrganizationSelectorPage from './invitations/external/SignupOrganizationSelectorPage';
import SignupInternalPage from './invitations/internal/SignupInternalPage';
import ProfileOnboardingPage from './invitations/profile/ProfileOnboardingPage';
import OperatorAccountPages from './operators/OperatorAccountPages';
import OrganizationPages from './organization/OrganizationPages';
import CreateOrganizationPages from './organization/create/CreateOrganizationPages';
import ProfilePages from './profile/ProfilePages';
import { ProfileInfoProvider } from './profile/information/ProfileInfoProvider';

export const basePath = '';

const Root = () => {
    return (
        <BrowserRouter>
            <I18nProvider>
                <RomaProvider>
                    <QueryClientProvider client={queryClient}>
                        <DebugErrorBoundary>
                            <AccountAnalyticsProvider>
                                <Auth0ConfigurationProvider>
                                    <Routes>
                                        <Route
                                            element={<SignupPageCallback />}
                                            path="/signup/callback"
                                        />
                                        <Route
                                            element={<SignupExternalPage />}
                                            path="/signup/external"
                                        />
                                        <Route
                                            element={<SignupEmailSentPage />}
                                            path="/signup/sent"
                                        />
                                        <Route
                                            element={
                                                <InvitationSetupPassword />
                                            }
                                            path="/invitation/setup"
                                        />
                                        <Route
                                            element={<InvitationLoader />}
                                            path="/invitation"
                                        />
                                        <Route
                                            element={<InvitationPage />}
                                            path="/invitation/verified"
                                        />
                                        <Route
                                            element={<ProfileOnboardingPage />}
                                            path="/invitation/create-profile"
                                        />
                                        <Route
                                            element={<InvitationExpiredPage />}
                                            path="/invitation/error"
                                        />
                                        <Route
                                            element={
                                                <LoginProvider>
                                                    <FeatureToggleProvider>
                                                        <ProfileInfoProvider>
                                                            <Outlet />
                                                        </ProfileInfoProvider>
                                                    </FeatureToggleProvider>
                                                </LoginProvider>
                                            }
                                        >
                                            <Route
                                                element={<SignupInternalPage />}
                                                path="/signup/internal"
                                            />
                                            <Route
                                                element={
                                                    <CountriesProvider>
                                                        <Outlet />
                                                    </CountriesProvider>
                                                }
                                            >
                                                <Route
                                                    element={
                                                        <CreateOrganizationPages />
                                                    }
                                                    path="/organizations/create/*"
                                                />
                                                <Route
                                                    element={
                                                        <OrganizationPages />
                                                    }
                                                    path="/organizations/*"
                                                />
                                                <Route
                                                    element={
                                                        <SignupOrganizationSelectorPage />
                                                    }
                                                    path="/signup/organizations"
                                                />
                                                <Route
                                                    element={
                                                        <OperatorAccountPages />
                                                    }
                                                    path="/operators/*"
                                                />
                                                <Route
                                                    element={<ProfilePages />}
                                                    path="/profile/*"
                                                />
                                                <Route
                                                    element={<LandingPage />}
                                                    index
                                                />
                                            </Route>
                                        </Route>
                                    </Routes>
                                </Auth0ConfigurationProvider>
                            </AccountAnalyticsProvider>
                        </DebugErrorBoundary>
                    </QueryClientProvider>
                </RomaProvider>
            </I18nProvider>
        </BrowserRouter>
    );
};

export default Root;
