import { ReactElement } from 'react';

import { ErrorBoundary, useErrorHandler } from '@mirakl/roma';

import DebugErrorPage from './DebugErrorPage';
import { isDebugMode } from './errorUtils';

type DebugErrorBoundaryProps = {
    children: ReactElement;
};

/**
 * This custom error boundary will display the content of the Error directly on the page itself,
 * instead of the generic roma error pages, in order to make life of mirakl workers easier.
 */
const DebugErrorBoundary = ({ children }: DebugErrorBoundaryProps) => {
    const handleError = useErrorHandler();

    const onError = (error: Error) => {
        if (!isDebugMode) {
            // fallback to roma default error boundary
            handleError(error);
        }
    };

    return (
        <ErrorBoundary FallbackComponent={DebugErrorPage} onError={onError}>
            {children}
        </ErrorBoundary>
    );
};

export default DebugErrorBoundary;
