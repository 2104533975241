import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import {
    Alert,
    AlertCompact,
    Button,
    Flex,
    Form,
    LayoutContainer,
    LayoutItem,
    List,
    PageLayout,
    PageTitle,
    Panel,
    Paragraph,
    RadioGroup,
    SaveBar,
    Spinner,
    Text,
    Title,
    useErrorHandler,
    useForm,
    useModal,
    useNavigate,
    useSnackbar,
} from '@mirakl/roma';

import MiraklSupportGlobalLayout from '../../common/layout/MiraklSupportGlobalLayout';
import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';
import { useCompact500FormatMessage } from '../../i18n/useCompact500FormatMessage';
import { useLocalizedCommaJoiner } from '../../i18n/useLocalizedCommaJoiner';
import { StageValues } from '../../types/stageTypes';
import LinkedShopGrid from '../run/shared/LinkedShopGrid';

import OrganizationMergeModal from './OrganizationMergeModal';

const ORGANIZATION_RADIO_GROUP = 'organization-radio-group';

type OrganizationMergeFormData = {
    [ORGANIZATION_RADIO_GROUP]: string | undefined;
};

type OrganizationDetailsType = {
    address: string;
    brn: string;
    growthSolutions: string[];
    id: string;
    name: string;
    taxId: string;
};

type LinkedShopType = {
    name: string;
    stage: StageValues;
    state: {
        suspensionType?: string;
        value: string;
    };
    tenantDisplayName: string;
    uuid: string;
};

type MergeInfoPayload = {
    linkedShops: LinkedShopType[];
    organizations: OrganizationDetailsType[];
};

function OrganizationMergePage() {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();
    const { apiGet, apiPost } = useAuthenticatedFetch();
    const { showModal } = useModal();
    const { addSnackbar } = useSnackbar();
    const handleError = useErrorHandler();
    const { joinWithCommas } = useLocalizedCommaJoiner();

    const { formatBMessage } = useCompact500FormatMessage();
    const formData = useForm<OrganizationMergeFormData>({
        defaultValues: { [ORGANIZATION_RADIO_GROUP]: undefined },
    });

    const location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formattedOptions, setFormattedOptions] = useState<
        {
            helpText: JSX.Element;
            id: string;
            label: string;
            products: string;
            value: string;
        }[]
    >();
    const [linkedShops, setLinkedShops] = useState<LinkedShopType[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const formatToHelpText = useCallback(
        (organization: OrganizationDetailsType) => (
            <Flex flexDirection="column">
                <Text wrap="anywhere">
                    {formatBMessage(
                        {
                            id: 'organization.merge.page.form.organization.radio.helptext.brn',
                        },
                        {
                            0: organization.brn,
                        }
                    )}
                </Text>
                <Text wrap="anywhere">
                    {formatBMessage(
                        {
                            id: 'organization.merge.page.form.organization.radio.helptext.taxid',
                        },
                        {
                            0: organization.taxId,
                        }
                    )}
                </Text>
                <Text wrap="anywhere">
                    {formatBMessage(
                        {
                            id: 'organization.merge.page.form.organization.radio.helptext.address',
                        },
                        {
                            0: organization.address,
                        }
                    )}
                </Text>
            </Flex>
        ),
        [formatBMessage]
    );

    const formatToOption = useCallback(
        (organization: OrganizationDetailsType) => ({
            id: organization.id,
            helpText: formatToHelpText(organization),
            label: organization.name,
            products: organization.growthSolutions.length
                ? joinWithCommas(
                      organization.growthSolutions.map((id) =>
                          formatMessage({ id: `product.${id}` })
                      )
                  )
                : formatMessage({
                      id: 'organization.merge.growth.solution.empty',
                  }),
            value: organization.id,
        }),
        [formatMessage, formatToHelpText, joinWithCommas]
    );

    useEffect(() => {
        if (location?.state?.organizationUuids) {
            apiGet<MergeInfoPayload>('/private/organizations/merge', {
                params: {
                    organizationUuids: location?.state?.organizationUuids,
                },
            })
                .then((payload) => payload.data)
                .then((data) => {
                    setFormattedOptions(
                        data.organizations.map((option) =>
                            formatToOption(option)
                        )
                    );
                    setLinkedShops(data.linkedShops);
                    setIsLoading(false);
                })
                .catch(handleError);
        }
    }, [apiGet, formatToOption, handleError, location]);

    const {
        formState: { isDirty, isSubmitting, isValid },
        handleSubmit,
    } = formData;

    const informationAlert = (
        <>
            <Alert
                status="warning"
                title={formatMessage({
                    id: 'organization.merge.warning.title',
                })}
            >
                <Paragraph>
                    {formatMessage({
                        id: 'organization.merge.warning.description',
                    })}
                </Paragraph>
                <List ordered>
                    <List.Item>
                        {formatMessage(
                            {
                                id: 'organization.merge.warning.line1.description',
                            },
                            { b: (chunks: string) => <b>{chunks}</b> }
                        )}
                    </List.Item>
                    <List.Item>
                        {formatMessage(
                            {
                                id: 'organization.merge.warning.line2.description',
                            },
                            { b: (chunks: string) => <b>{chunks}</b> }
                        )}
                    </List.Item>
                </List>
            </Alert>
            <AlertCompact
                status="info"
                title={formatMessage({
                    id: 'organization.merge.information.warehouse',
                })}
            />
        </>
    );

    const mergeOrganizationsInto: (
        organizationUuid: string,
        allOrganizationsUuids: string[]
    ) => Promise<void> = (mainOrganizationUuid, allOrganizationUuids) => {
        return apiPost('/private/organizations/merge', {
            mainOrganizationUuid,
            allOrganizationUuids,
        })
            .then(() => {
                addSnackbar({
                    message: formatMessage({
                        id: 'organization.merge.snackbar.success.label',
                    }),
                    status: 'success',
                });
                navigate({
                    to: '/organizations',
                    shouldNotShowConfirmModal: true,
                });
            })
            .catch((error) => {
                const errorResponseData = error.response.data;
                if (
                    errorResponseData.errors !== undefined &&
                    errorResponseData.errors[0].messages[0].code ===
                        'WAREHOUSE_CODE_DUPLICATED'
                ) {
                    setShowAlert(true);
                } else {
                    addSnackbar({
                        message: formatMessage({
                            id: 'snackbar.edit.fail',
                        }),
                        status: 'error',
                    });
                }
            });
    };

    const onSubmit = (values: OrganizationMergeFormData) => {
        const mainOrganizationUuid = values[ORGANIZATION_RADIO_GROUP];
        const allOrganizationUuids = location?.state?.organizationUuids;

        if (mainOrganizationUuid === undefined) {
            throw Error('There must be one organization selected');
        }

        showModal(
            <OrganizationMergeModal
                mainOrganization={mainOrganizationUuid}
                mergeCallback={mergeOrganizationsInto}
                organizations={allOrganizationUuids}
            />
        );
        return Promise.resolve();
    };

    return (
        <MiraklSupportGlobalLayout>
            <PageTitle
                backLink={{
                    label: formatMessage({
                        id: 'organizations.support.link.back',
                    }),
                    link: {
                        to: '/organizations',
                    },
                }}
                title={formatMessage({ id: 'organization.merge.page.title' })}
            />

            <PageLayout size="md">
                {showAlert && (
                    <Alert
                        status="error"
                        title={formatMessage({
                            id: 'organization.merge.error.title',
                        })}
                        onClose={() => {
                            setShowAlert(false);
                        }}
                    >
                        {formatMessage({
                            id: 'organization.merge.error.description',
                        })}
                    </Alert>
                )}
                <LayoutContainer>
                    <LayoutItem>
                        <Panel loading={isLoading}>
                            <Panel.Content>
                                <Title tag="h2">
                                    {formatMessage({
                                        id: 'organization.merge.page.billing.information.title',
                                    })}
                                </Title>
                                <Paragraph>
                                    {formatMessage({
                                        id: 'organization.merge.page.billing.information.description',
                                    })}
                                </Paragraph>
                                {isLoading || formattedOptions === undefined ? (
                                    <Spinner />
                                ) : (
                                    <Form
                                        {...formData}
                                        alert={informationAlert}
                                    >
                                        <RadioGroup
                                            a11yLabel={formatMessage({
                                                id: 'organization.merge.page.form.organization.radio.a11y.label',
                                            })}
                                            column={1}
                                            name={ORGANIZATION_RADIO_GROUP}
                                        >
                                            {formattedOptions.map(
                                                ({
                                                    helpText,
                                                    id,
                                                    label,
                                                    products,
                                                    value,
                                                }) => (
                                                    <RadioGroup.Card
                                                        helpText={helpText}
                                                        key={id}
                                                        label={label}
                                                        tooltipText={products}
                                                        value={value}
                                                    />
                                                )
                                            )}
                                        </RadioGroup>
                                    </Form>
                                )}
                            </Panel.Content>
                        </Panel>
                        {linkedShops.length > 0 && (
                            <Panel loading={isLoading}>
                                <Panel.Content>
                                    <Title tag="h2">
                                        {formatMessage({ id: 'stores.linked' })}
                                    </Title>
                                    <LinkedShopGrid shops={linkedShops} />
                                </Panel.Content>
                            </Panel>
                        )}
                        <SaveBar
                            actions={[
                                <Button
                                    disabled={!isDirty || !isValid}
                                    key="button-merge"
                                    label={formatMessage({
                                        id: 'organization.merge.page.form.button.validate.label',
                                    })}
                                    state={isSubmitting ? 'loading' : 'default'}
                                    submit
                                    onClick={handleSubmit(onSubmit)}
                                />,
                                <Button
                                    key="button-cancel"
                                    label={formatMessage({
                                        id: 'button.cancel',
                                    })}
                                    variant="secondary"
                                    onClick={() =>
                                        navigate({ to: '/organizations' })
                                    }
                                />,
                            ]}
                        />
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </MiraklSupportGlobalLayout>
    );
}

export default OrganizationMergePage;
