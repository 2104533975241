import { OnboardingStepper, useStepperForm } from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import { UserShopsForLink } from '../../common/shop/useUserShopsForLink';

import LinkShopsFields, {
    LINKED_SHOPS_FIELD_NAME,
    LinkedShopsFormType,
} from './LinkShopsFields';

export type LinkShopsStepFormType = {
    linkedShopUuidsByUser: LinkedShopsFormType;
    ownership: boolean;
    selectMarketplace: string;
};

type LinkShopsStepProps = {
    userShopsForLink: UserShopsForLink;
};

const LinkShopsStep = ({ userShopsForLink }: LinkShopsStepProps) => {
    const { email: connectedUserEmail } = useLoginContext();

    const stepFormData = useStepperForm<LinkShopsStepFormType>({
        defaultValues: {
            [LINKED_SHOPS_FIELD_NAME]: userShopsForLink.connectedUserShops
                .filter((shop) => !shop.alreadyInOrganization)
                .map((shop) => ({
                    email: connectedUserEmail,
                    shopUuid: shop.uuid,
                })),
            ownership: false,
            selectMarketplace: '',
        },
    });

    return (
        <OnboardingStepper.Form {...stepFormData}>
            <LinkShopsFields userShopsForLink={userShopsForLink} />
        </OnboardingStepper.Form>
    );
};

export default LinkShopsStep;
