import { Outlet } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import { useErrorHandler } from '@mirakl/roma';

import useLoginContext from '../config/login/LoginProvider';
import useOrganizationContext from '../organization/run/OrganizationContext';

import { useFeatureToggle } from './toggle/FeatureToggleProvider';

type RestrictedRouteProps = {
    allowAdminSellerOnly?: boolean;
    allowSupport?: boolean;
    featureEnabled?: string;
};

const RestrictedRoute = (props: RestrictedRouteProps) => {
    const { formatMessage } = useI18n();

    const handleError = useErrorHandler();

    const {
        miraklSupport: { isSupportUser },
    } = useLoginContext();
    const { isOrganizationAdmin } = useOrganizationContext();
    const { isFeatureEnable } = useFeatureToggle();

    const adminSellerCheck =
        props.allowAdminSellerOnly !== undefined &&
        props.allowAdminSellerOnly !== isOrganizationAdmin;
    const featureEnabledCheck =
        props.featureEnabled !== undefined &&
        !isFeatureEnable(props.featureEnabled);
    const supportUserCheck =
        props.allowSupport !== undefined &&
        isSupportUser !== props.allowSupport;

    if (adminSellerCheck || featureEnabledCheck || supportUserCheck) {
        handleError(new Error(formatMessage({ id: 'error.403.title' })));
    }

    return <Outlet />;
};

export default RestrictedRoute;
