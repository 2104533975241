import { Outlet } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import { useErrorHandler } from '@mirakl/roma';

import useLoginContext, { Role } from '../config/login/LoginProvider';

type RestrictedRoleRouteProps = {
    roles: Role[];
};

const RestrictedRoleRoute = (props: RestrictedRoleRouteProps) => {
    const { formatMessage } = useI18n();

    const handleError = useErrorHandler();

    const { hasAnyRole } = useLoginContext();

    if (!hasAnyRole(props.roles)) {
        handleError(new Error(formatMessage({ id: 'error.403.title' })));
    }
    return <Outlet />;
};

export default RestrictedRoleRoute;
