import type { ReactNode } from 'react';
import { css, styled } from 'styled-components';

import { Flex, themeColor, themeElevation } from '@mirakl/roma';

const Wrapper = styled(Flex).attrs<{ $highlighted?: boolean }>({
    flexDirection: 'column',
    paddingHorizontal: 4,
    paddingVertical: 6,
})`
    scroll-snap-align: center;

    width: 270px;
    flex-shrink: 0;

    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    box-shadow: ${themeElevation(300)};

    ${({ $highlighted }) =>
        $highlighted
            ? css`
                  background: ${themeColor('primary-100')};
                  border-color: ${themeColor('primary-300')};
              `
            : css`
                  background: ${themeColor('white-0')};
                  border-color: transparent;
              `};
`;

type PaywallCardProps = {
    children: ReactNode;
    highlighted?: boolean;
};

const PaywallCard = ({ children, highlighted = false }: PaywallCardProps) => (
    <Wrapper $highlighted={highlighted}>{children}</Wrapper>
);

export default PaywallCard;
