type SortableShop = {
    alreadyInOrganization: boolean;
    name: string;
    tenantDisplayName: string;
};
export const sortByLinkedThenTenantThenName = <S extends SortableShop>(
    shops: S[]
): S[] =>
    [...shops].sort((a, b) =>
        a.alreadyInOrganization
            ? b.alreadyInOrganization
                ? 0
                : 1
            : -1 ||
              a.tenantDisplayName.localeCompare(b.tenantDisplayName) ||
              a.name.localeCompare(b.name)
    );
