import { useCallback, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Alert,
    LayoutContainer,
    Page,
    PageLayout,
    PageTitle,
    Text,
} from '@mirakl/roma';

import useLoginContext from '../../config/login/LoginProvider';

import ProfileSecurityPasswordPanel from './components/ProfileSecurityPasswordPanel';

const ProfileSecurityPage = () => {
    const { formatMessage } = useI18n();
    const { email } = useLoginContext();
    const [alert, setAlert] = useState<{
        message: string;
        status: 'info' | 'warning' | 'success' | 'error';
        title: string;
    } | null>(null);

    const dismissAlert = () => setAlert(null);

    const onPasswordResetSuccess = useCallback(() => {
        setAlert({
            status: 'success',
            title: formatMessage({
                id: 'user.profile.security.password.change.success.title',
            }),
            message: formatMessage(
                {
                    id: 'user.profile.security.password.change.success.message',
                },
                {
                    email: <Text variant="default-500">{email}</Text>,
                }
            ),
        });
    }, [email, formatMessage]);

    const onPasswordResetError = useCallback(() => {
        setAlert({
            status: 'error',
            title: formatMessage({
                id: 'user.profile.security.error.title',
            }),
            message: formatMessage({
                id: 'user.profile.security.error.message',
            }),
        });
    }, [formatMessage]);

    return (
        <Page>
            <PageTitle
                title={formatMessage({ id: 'user.profile.security.password' })}
            />
            <PageLayout size="sm">
                {alert && (
                    <Alert
                        status={alert.status}
                        title={alert.title}
                        onClose={dismissAlert}
                    >
                        {alert.message}
                    </Alert>
                )}
                <LayoutContainer>
                    <ProfileSecurityPasswordPanel
                        onPasswordResetError={onPasswordResetError}
                        onPasswordResetSuccess={onPasswordResetSuccess}
                    />
                </LayoutContainer>
            </PageLayout>
        </Page>
    );
};

export default ProfileSecurityPage;
