import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { useErrorHandler } from '@mirakl/roma';

import { signupLoginStateManager } from './signupLoginStateManager';

type SilentLoginParameters = {
    redirectUri: string;
};

export const useSilentLogin = ({ redirectUri }: SilentLoginParameters) => {
    const { loginWithRedirect } = useAuth0();
    const handleError = useErrorHandler();

    const silentLogin = useCallback(() => {
        // redirect to auth callback for signup that doesn't require signature
        // and will perform redirection to redirectTo
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: redirectUri,
                prompt: 'none',
            },
        }).catch(handleError);
    }, [loginWithRedirect, redirectUri, handleError]);

    const shouldPerformLogin = signupLoginStateManager.silentLogin;

    return { shouldPerformLogin, silentLogin };
};
