import { FormattedMessage } from 'react-intl';

import { useI18n } from '@mirakl/i18n';
import {
    Checkbox,
    EmailField,
    Flex,
    Hyperlink,
    IntegerNumberField,
    Panel,
    Paragraph,
    PasswordField,
    Stepper,
    TextField,
    useStepperForm,
    useStepperValues,
} from '@mirakl/roma';

import { ServerStepFormType } from '../../OperatorSmtpClientModelApi';

import Summary from './Summary';

const SENDER_NAME_MAX_LENGTH = 150;
const SENDER_EMAIL_MAX_LENGTH = 150;
const XVAR_HEADER_MAX_LENGTH = 998;
const HOST_MAX_LENGTH = 500;
const VALID_LOCAL_PART_REGEX = /^[a-zA-Z0-9\s"_!#$%&'*+/=?`{|}~^.-]{1,64}$/;
const VALID_DOMAIN_PART_REGEX = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]+$/;

type ConfigurationStepFormType = {
    host: string;
    password: string;
    port?: number;
    senderEmail: string;
    senderName: string;
    startTls: boolean;
    username: string;
    xvarHeaderPrefix: string;
};

type ConfigurationStepProps = {
    forbiddenDomains: {
        name: string;
        regex: RegExp;
    }[];
};

const ConfigurationStep = ({ forbiddenDomains }: ConfigurationStepProps) => {
    const { formatMessage } = useI18n();

    const { type } = useStepperValues<ServerStepFormType>();

    const formData = useStepperForm<ConfigurationStepFormType>({
        defaultValues: {
            senderName: '',
            senderEmail: '',
            xvarHeaderPrefix: '',
            host: '',
            port: undefined,
            username: '',
            password: '',
            startTls: false,
        },
    });

    const { watch } = formData;

    const isUsernameSet = !!watch('username');
    const isPasswordSet = !!watch('password');
    const isCredentialRequired = isUsernameSet || isPasswordSet;

    return (
        <Stepper.FormWithSummary
            {...formData}
            renderSummary={(values) => <Summary {...values} />}
        >
            <Panel>
                <Panel.Header
                    badges={{
                        status: 'info',
                        id: 'badge',
                        label: formatMessage({
                            id: `operator.smtp.type.${type}.label`,
                        }),
                    }}
                    title={formatMessage({
                        id: 'operator.smtp.create.configuration.title',
                    })}
                />

                <Panel.Content>
                    <Paragraph>
                        <FormattedMessage
                            id="operator.smtp.create.configuration.description.SELF_MANAGED"
                            values={{
                                a(chunk) {
                                    // TODO ACCOUNT-1167: add the correct link
                                    return (
                                        <Hyperlink
                                            href="https://example.com"
                                            target="_blank"
                                        >
                                            {chunk}
                                        </Hyperlink>
                                    );
                                },
                            }}
                        />
                    </Paragraph>
                </Panel.Content>
                <Panel.Content>
                    <TextField
                        helpText={formatMessage({
                            id: 'operator.smtp.field.sender_name.description',
                        })}
                        label={formatMessage({
                            id: 'operator.smtp.field.sender_name',
                        })}
                        maxLength={SENDER_NAME_MAX_LENGTH}
                        name="senderName"
                        required
                    />
                    <EmailField
                        label={formatMessage({
                            id: 'operator.smtp.field.sender_email',
                        })}
                        maxLength={SENDER_EMAIL_MAX_LENGTH}
                        name="senderEmail"
                        required
                        validate={(value) => {
                            if (!value) {
                                return true;
                            }

                            const splittedEmail = value.split('@');
                            if (
                                splittedEmail.length < 2 ||
                                splittedEmail.length > 2 ||
                                !splittedEmail[0] ||
                                !splittedEmail[1]
                            ) {
                                return true; // Let the EmailField handle the validation error
                            }

                            if (
                                !VALID_LOCAL_PART_REGEX.test(
                                    splittedEmail[0]
                                ) ||
                                !VALID_DOMAIN_PART_REGEX.test(splittedEmail[1])
                            ) {
                                return formatMessage({
                                    id: 'roma.form.email.invalid',
                                });
                            }

                            for (const forbiddenDomain of forbiddenDomains) {
                                if (forbiddenDomain.regex.test(value)) {
                                    return formatMessage(
                                        {
                                            id: 'operator.smtp.field.sender_email.domain.invalid',
                                        },
                                        { domain: forbiddenDomain.name }
                                    );
                                }
                            }
                        }}
                    />
                    <TextField
                        helpText={formatMessage({
                            id: 'operator.smtp.field.xvar_header_prefix.description',
                        })}
                        label={formatMessage({
                            id: 'operator.smtp.field.xvar_header_prefix',
                        })}
                        maxLength={XVAR_HEADER_MAX_LENGTH}
                        name="xvarHeaderPrefix"
                    />
                    {type === 'SELF_MANAGED' && (
                        <>
                            <Flex flexDirection="row" gap={4}>
                                <TextField
                                    label={formatMessage({
                                        id: 'operator.smtp.field.host',
                                    })}
                                    maxLength={HOST_MAX_LENGTH}
                                    name="host"
                                    required
                                />
                                <IntegerNumberField
                                    label={formatMessage({
                                        id: 'operator.smtp.field.port',
                                    })}
                                    max={65535}
                                    min={0}
                                    name="port"
                                    required
                                />
                            </Flex>
                            <TextField
                                label={formatMessage({
                                    id: 'operator.smtp.field.username',
                                })}
                                name="username"
                                required={isCredentialRequired}
                            />
                            <PasswordField
                                label={formatMessage({
                                    id: 'operator.smtp.field.password',
                                })}
                                name="password"
                                required={isCredentialRequired}
                            />
                            <Checkbox
                                helpText={formatMessage({
                                    id: 'operator.smtp.field.start_tls.description',
                                })}
                                label={formatMessage({
                                    id: 'operator.smtp.field.start_tls',
                                })}
                                name="startTls"
                            />
                        </>
                    )}
                </Panel.Content>
            </Panel>
        </Stepper.FormWithSummary>
    );
};

export default ConfigurationStep;
