import React, { type ReactNode } from 'react';
import { styled } from 'styled-components';

import { useI18n } from '@mirakl/i18n';
import { Flex, Text, themeColor } from '@mirakl/roma';

type PaywallCardSectionProps = {
    children: ReactNode;
    type:
        | 'networking'
        | 'channel-manager'
        | 'base-limits-overages'
        | 'services';
};

const Hr = styled.hr`
    width: 100%;
    height: 1px;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    background-color: ${themeColor('grey', 500)};
`;

const PaywallCardSection = ({ children, type }: PaywallCardSectionProps) => {
    const { formatMessage } = useI18n();

    return (
        <Flex flexDirection="column" paddingBottom={5}>
            <Flex center fullWidth marginBottom={4}>
                <Hr />
                <Flex
                    center
                    flexShrink={0}
                    maxWidth="90%"
                    overflow="hidden"
                    paddingHorizontal={2}
                >
                    <Text color="grey-500" truncate variant="separator">
                        {formatMessage({
                            id: `organization.paywall.card.section.${type}`,
                        })}
                    </Text>
                </Flex>
                <Hr />
            </Flex>
            <Flex flexDirection="column" gap={4}>
                {children}
            </Flex>
        </Flex>
    );
};

export default PaywallCardSection;
