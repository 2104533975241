import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

/**
 * Type to map query param type definition to actual type.
 */
type QueryParamTypeMap = QueryParamTypeRequiredMap & QueryParamTypeOptionalMap;

type QueryParamTypeRequiredMap = {
    array: unknown[];
    boolean: boolean;
    date: DateTime;
    number: number;
    object: Record<string, unknown>;
    string: string;
};

type QueryParamTypeOptionalMap = {
    optionalArray: unknown[] | undefined;
    optionalBoolean: boolean | undefined;
    optionalDate: DateTime | undefined;
    optionalNumber: number | undefined;
    optionalObject: Record<string, unknown> | undefined;
    optionalString: string | undefined;
};

/**
 * Type that can be used to define a query parameter in {@link QueryParamsDefinition}
 */
type QueryParamType = keyof QueryParamTypeMap;

/**
 * Type that contains all the strongly typed query parameters requested using {@link useQuery}
 */
type QueryParams<T> =
    T extends Record<string, QueryParamType>
        ? {
              [A in keyof T]: QueryParamTypeMap[T[A]];
          }
        : never;

/**
 * Type to define query parameters and their types to retrieve using {@link useQuery}
 */
export type QueryParamsDefinition = Record<string, QueryParamType>;

function getOrThrow(key: string, value: string | null): string {
    if (value) {
        return value;
    }
    throw new Error(`URL param '${key}' not found.`);
}

/**
 * Function to convert a query parameter value to it's actual type
 * @param value value to convert
 * @param type type to convert to
 */
function convert(key: string, value: string | null, type: QueryParamType) {
    switch (type) {
        case 'array':
            return JSON.parse(getOrThrow(key, value));
        case 'boolean':
            return getOrThrow(key, value) === 'true';
        case 'date':
            return DateTime.fromMillis(Number(getOrThrow(key, value)));
        case 'number':
            return Number(getOrThrow(key, value));
        case 'object':
            return JSON.parse(getOrThrow(key, value));
        case 'string':
            return getOrThrow(key, value);
    }

    // We are looking for an optional URL parameter
    if (value === null) {
        return undefined;
    }
    switch (type) {
        case 'optionalArray':
            return JSON.parse(getOrThrow(key, value));
        case 'optionalBoolean':
            return value === 'true';
        case 'optionalDate':
            return DateTime.fromMillis(Number(value));
        case 'optionalNumber':
            return Number(value);
        case 'optionalObject':
            return JSON.parse(value);
        case 'optionalString':
            return value;
    }
    throw new Error(`Unsupported URL param type '${type}'`);
}

/**
 * Extract query parameters from a given query part of a URL as string.
 * @param queryTypesObject query parameters to get
 * @param search query part of a URL
 */
function getValues<P extends QueryParamsDefinition>(
    queryTypesObject: P,
    search: string
): QueryParams<P> {
    const urlSearchParams = new URLSearchParams(search);
    return Object.fromEntries(
        Object.entries(queryTypesObject).map(([key, type]) => [
            key,
            convert(key, urlSearchParams.get(key), type),
        ])
    ) as QueryParams<P>;
}

/**
 * Return strongly type query parameter from the current location.
 * @param queryTypesObject parameters to retrieve with their types.
 */

function useQuery<P extends QueryParamsDefinition>(
    queryTypesObject: P
): QueryParams<P> {
    const { search } = useLocation();

    return useMemo(
        () => getValues(queryTypesObject, search),
        [queryTypesObject, search]
    );
}
export default useQuery;
