import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Card,
    Flex,
    Text,
    Title,
    useErrorHandler,
    useModal,
} from '@mirakl/roma';

import useLoginContext from '../../../config/login/LoginProvider';
import LinkShopsModal, {
    ConfirmationCodeFormType,
} from '../../common/shop/LinkShopsModal';
import SelectMarketplaceField from '../../common/shop/SelectMarketplaceField';
import useVerifyAccountApi from '../../common/shop/verifyAccountApi';
import { Tenant } from '../../run/shop/SelectMarketplaceModal';
import useOrganizationTenant from '../../run/shop/useOrganizationTenant';

type LinkShopsCardProps = {
    onConfirm: (email: string, tenant: string, token: string) => void;
};

const LinkAdditionalShopsCard = ({ onConfirm }: LinkShopsCardProps) => {
    const [activateLinkShopButton, setActivateLinkShopButton] =
        useState<boolean>(false);
    const { email } = useLoginContext();
    const { formatMessage } = useI18n();
    const { showModal } = useModal();
    const { getTimeout, sendCode, verifyCode } = useVerifyAccountApi();
    const { getValues, resetField } = useFormContext();
    const { getTenants } = useOrganizationTenant();
    const [tenantList, setTenantList] = useState<Tenant>([]);
    const handleError = useErrorHandler();

    useEffect(() => {
        getTenants()
            .then((payload) => {
                setTenantList(payload);
            })
            .catch(handleError);
    }, [getTenants, handleError]);

    const checkCode = (
        { code, username }: ConfirmationCodeFormType,
        tenant: string
    ): Promise<void> => {
        return verifyCode({ code: code, username: username }).then((token) =>
            onConfirm(username, tenant, token)
        );
    };

    const onModalConfirm = (
        data: ConfirmationCodeFormType,
        tenant: string,
        close: () => void
    ): Promise<void> =>
        checkCode(data, tenant).then(() => {
            close();
            resetField('selectMarketplace');
            setActivateLinkShopButton(false);
        });

    return (
        <Card flexDirection="column">
            <Flex flexDirection="column" marginBottom={2}>
                <Title tag="h4">
                    {formatMessage({
                        id: 'organization.link.other.stores.title',
                    })}
                </Title>
                <Flex marginTop={2}>
                    <Text>
                        {formatMessage({
                            id: 'organization.link.other.stores',
                        })}
                    </Text>
                </Flex>
            </Flex>
            <SelectMarketplaceField
                connectorList={[]}
                handleChange={(tenantId) =>
                    setActivateLinkShopButton(!!tenantId)
                }
                name="selectMarketplace"
                placement="top-center"
                tenantList={tenantList}
            />
            <Flex alignItems="flex-end" flexDirection="column" marginTop={2}>
                <Button
                    disabled={!activateLinkShopButton}
                    label={formatMessage({
                        id: 'button.add',
                    })}
                    size="small"
                    onClick={() => {
                        getTimeout().then((result) => {
                            showModal(
                                <LinkShopsModal
                                    connectedUserEmail={email}
                                    sendCode={sendCode}
                                    timeout={result.timeout}
                                    onModalConfirm={(
                                        data,
                                        closeLinkShopModal
                                    ) =>
                                        onModalConfirm(
                                            data,
                                            getValues('selectMarketplace'),
                                            closeLinkShopModal
                                        )
                                    }
                                />
                            );
                            return Promise.resolve();
                        });
                    }}
                />
            </Flex>
        </Card>
    );
};

export default LinkAdditionalShopsCard;
