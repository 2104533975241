import { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import { useErrorHandler, useNavigate, useSnackbar } from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import AccountGlobalLayout from '../common/layout/AccountGlobalLayout';
import useOrganizationContext from '../organization/run/OrganizationContext';

import {
    WarehouseUpdateRequestApiType,
    useWarehouseApiClient,
} from './api/useWarehouseApiClient';
import { WarehouseFormType } from './components/WarehouseForm';
import WarehousePage, { toFormValues } from './components/WarehousePage';

type Identifiable = { id: string };

const arrayContains = (
    array: Identifiable[],
    testedElement: Identifiable
): boolean => {
    return !!array.find((element) => element.id === testedElement.id);
};

const shopToLink = (
    selectedShop: Identifiable,
    alreadyLinkedShops: Identifiable[]
) => !arrayContains(alreadyLinkedShops, selectedShop);

const shopToUnlink = (
    alreadyLinkedShop: Identifiable,
    shopSelection: Identifiable[]
) => !arrayContains(shopSelection, alreadyLinkedShop);

export const WarehouseEditPage = () => {
    const [loading, setLoading] = useState(true);
    const { warehouseUuid } = useRequiredParams<{ warehouseUuid: string }>();
    const handleError = useErrorHandler();
    const [initialValues, setInitialValues] = useState<WarehouseFormType>();
    const { getWarehouse, putWarehouse: onSave } = useWarehouseApiClient();
    const { addSnackbar } = useSnackbar();
    const { formatMessage } = useI18n();
    const { uuid: organizationUuid } = useOrganizationContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!warehouseUuid) {
            setLoading(false);
        }
        getWarehouse(warehouseUuid)
            .then(toFormValues)
            .then(setInitialValues)
            .then(() => setLoading(false))
            .catch(handleError);
    }, []); // eslint-disable-line -- empty deps array is a valid syntax, it allows useEffect to run only once

    const onfulfilled = () => {
        addSnackbar({
            message: formatMessage({
                id: 'snackbar.edit.success',
            }),
            status: 'success',
        });
        navigate({
            to: `/organizations/${organizationUuid}/warehouses`,
            shouldNotShowConfirmModal: true,
        });
    };

    const handleSubmit = (
        defaultValues: WarehouseFormType,
        values: WarehouseFormType
    ) => {
        const request: WarehouseUpdateRequestApiType = {
            code: values.code,
            name: values.name,
            type: values.type,
            address: {
                city: values.city,
                state: values.state,
                street_1: values.address,
                street_2: values.addressContinued,
                country_iso_code: values.countryCode,
                zip_code: values.postalCode,
            },
            shopsToLink:
                values.shops
                    .filter((shop) => shopToLink(shop, defaultValues.shops))
                    .map((shop) => shop.id) || [],
            shopsToUnlink:
                defaultValues?.shops
                    .filter((dvShop) => shopToUnlink(dvShop, values.shops))
                    .map((shop) => shop.id) || [],
        };

        return onSave(warehouseUuid)(request).then(onfulfilled);
    };

    return (
        <AccountGlobalLayout>
            {initialValues && (
                <WarehousePage
                    disableCode
                    initialValues={initialValues}
                    loading={loading}
                    pageTitle="organizations.warehouses.form.edit.title"
                    onSubmit={handleSubmit}
                />
            )}
        </AccountGlobalLayout>
    );
};
