import { useI18n } from '@mirakl/i18n';
import { Flex, IconButton, TextField } from '@mirakl/roma';

import useClipboard from '../../../../common/hooks/useClipboard';

type ValueFieldWithCopyProps = {
    a11yLabel: string;
    name: string;
    value: string;
};

const ValueFieldWithCopy = ({
    a11yLabel,
    name,
    value,
}: ValueFieldWithCopyProps) => {
    const { copy } = useClipboard();
    const { formatMessage } = useI18n();
    return (
        <Flex gap={0}>
            <TextField.Uncontrolled
                a11yLabel={a11yLabel}
                disabled
                name={name}
                noSpaceBottom
                size="small"
                value={value}
                onChange={() => {
                    /* onChange required but nothing to do */
                }}
            />
            {/* Do not use the `endSlot` of the TextField for this button because it has some unwanted padding*/}
            <IconButton
                a11yLabel={formatMessage({
                    id: 'operator.smtp.details.email.authentication.domain.copy.tooltip',
                })}
                iconName="copy-content"
                size="small"
                variant="ghost"
                onClick={() => {
                    copy(value, formatMessage({ id: 'snackbar.copied' }));
                }}
            />
        </Flex>
    );
};

export default ValueFieldWithCopy;
