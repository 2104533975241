import { useI18n } from '@mirakl/i18n';
import { SimpleSelectionListFilter } from '@mirakl/roma';

import { ADMINISTRATOR, MEMBER } from './userTypeConstants';

function UserTypeFilter() {
    const { formatMessage } = useI18n();

    const options = [
        {
            id: 'admin',
            label: formatMessage({ id: ADMINISTRATOR.i18nLabel }),
            value: ADMINISTRATOR.value,
        },
        {
            id: 'member',
            label: formatMessage({ id: MEMBER.i18nLabel }),
            value: MEMBER.value,
        },
    ];

    return (
        <SimpleSelectionListFilter id="user-type-filter" options={options} />
    );
}

export default UserTypeFilter;
