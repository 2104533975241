import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { isEmail } from 'validator';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Button,
    EmailField,
    Flex,
    Panel,
    Paragraph,
    Stepper,
    TextField,
    useSnackbar,
    useStepperForm,
} from '@mirakl/roma';

import useLoginContext from '../../../../config/login/LoginProvider';
import useAuthenticatedFetch from '../../../../fetch/useAuthenticatedFetch';
import useVerifyAccountApi from '../../../common/shop/verifyAccountApi';

const CONFIRMATION_CODE_TIMEOUT_MS = 60_000;
const CONFIRMATION_CODE_CONFIRM_RATE_LIMIT_TIMEOUT_MS = 60_000;

export type ConfirmationCodeFormType = {
    code: string;
    username: string;
};

interface AccountVerificationStepperProps {
    onSubmitAccountValidation: (email: string, token: string) => void;
}

function AccountVerificationPage({
    onSubmitAccountValidation,
}: AccountVerificationStepperProps) {
    const { formatMessage } = useI18n();
    const { addSnackbar } = useSnackbar();
    const { email: connectedUserEmail } = useLoginContext();

    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [sendCodeAvailable, setSendCodeAvailable] = useState<boolean>(false);
    const [submittingCode, setSubmittingCode] = useState<boolean>(false);
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
    const [backTimeout, setBackTimeout] = useState<number>(0);
    const { apiGet } = useAuthenticatedFetch();
    const { sendCode, verifyCode } = useVerifyAccountApi();

    const formData = useStepperForm<ConfirmationCodeFormType>({
        defaultValues: {
            code: '',
            username: '',
        },
    });

    const { getFieldState, setError, trigger, watch } = formData;

    const { invalid: invalidEmail, isDirty: filledEmail } =
        getFieldState('username');

    const inputEmail: string = watch('username');

    useEffect(() => {
        apiGet<{ timeout: number }>(
            '/private/organizations/confirmation/time'
        ).then((result) => {
            setBackTimeout(result.data.timeout);
        });
    }, [apiGet, setBackTimeout]);

    useEffect(() => {
        const initialTimer = setTimeout(
            () => setSendCodeAvailable(true),
            backTimeout
        );
        setTimer(initialTimer);
        return () => clearTimeout(initialTimer);
    }, [backTimeout]);

    const sendCodeAndResetTimer = () => {
        setSubmittingCode(true);
        clearTimeout(timer);
        return sendCode(inputEmail)
            .then(() => {
                setSendCodeAvailable(false);
                setCodeSent(true);

                setTimer(
                    setTimeout(
                        () => setSendCodeAvailable(true),
                        CONFIRMATION_CODE_TIMEOUT_MS
                    )
                );
            })
            .catch(() =>
                addSnackbar({
                    message: formatMessage({
                        id: 'snackbar.edit.fail',
                    }),
                    status: 'error',
                })
            )
            .finally(() => setSubmittingCode(false));
    };

    function handleConfirmationVerificationErrors(error: AxiosError) {
        if (error.response && error.response.status === 429) {
            setTimer(
                setTimeout(
                    () => CONFIRMATION_CODE_CONFIRM_RATE_LIMIT_TIMEOUT_MS
                )
            );
            setError('code', {
                type: 'custom',
                message: formatMessage({ id: 'invalid.code.ratelimit' }),
            });
        } else if (error.response && error.response.status === 410) {
            setError('code', {
                type: 'custom',
                message: formatMessage({ id: 'invalid.code.resend' }),
            });
        } else if (error.response && error.response.status === 400) {
            setError('code', {
                type: 'custom',
                message: formatMessage({ id: 'invalid.code' }),
            });
        }
    }

    function handleSubmit(data: ConfirmationCodeFormType): Promise<void> {
        return verifyCode(data)
            .then((token) => {
                onSubmitAccountValidation(data.username, token);
                return Promise.resolve();
            })
            .catch((error) => {
                handleConfirmationVerificationErrors(error);
                return Promise.reject();
            });
    }

    return (
        <Stepper.Form {...formData} onSubmit={handleSubmit}>
            <Panel>
                <Panel.Content
                    title={formatMessage({
                        id: 'organizations.step.account.verification',
                    })}
                >
                    <Paragraph>
                        {formatMessage({
                            id: 'organizations.step.account.verification.text',
                        })}
                    </Paragraph>
                    <Flex
                        alignItems={codeSent || invalidEmail ? 'center' : 'end'}
                        gap={2}
                        marginBottom={4}
                    >
                        <EmailField
                            helpText={
                                codeSent
                                    ? formatMessage({ id: 'code.sent' })
                                    : undefined
                            }
                            label={formatMessage({ id: 'email.address' })}
                            maxLength={255}
                            name="username"
                            noSpaceBottom
                            required
                            status="success"
                            validate={(value: string) =>
                                // EmailField uses the RFC 5322 norm, but we want to check for TLD as well
                                isEmail(value)
                                    ? value !== connectedUserEmail ||
                                      formatMessage({
                                          id: 'email.already.linked',
                                      })
                                    : formatMessage({
                                          id: 'roma.form.email.invalid',
                                      })
                            }
                            onChange={() => {
                                // We trigger validation at the first input (before the field is touched),
                                // so that the 'Send code' button stays disabled until the email address is valid.
                                if (!filledEmail) {
                                    trigger('username');
                                }
                                if (codeSent) {
                                    setCodeSent(false);
                                }
                            }}
                        />
                        <Box flexShrink={0}>
                            <Button
                                disabled={
                                    !isEmail(inputEmail) || !sendCodeAvailable
                                }
                                label={formatMessage({ id: 'send.code' })}
                                state={submittingCode ? 'loading' : 'default'}
                                tooltipBreakOutOfContainer
                                tooltipText={
                                    !sendCodeAvailable
                                        ? formatMessage({
                                              id: 'organizations.step.account.verification.code.sent.button',
                                          })
                                        : undefined
                                }
                                variant="secondary"
                                onClick={sendCodeAndResetTimer}
                            />
                        </Box>
                    </Flex>
                    <TextField
                        label={formatMessage({ id: 'confirmation.code' })}
                        name="code"
                        required
                        validate={(code: string) =>
                            code.length === 0 ||
                            code.match(/^\d{6}$/) !== null ||
                            formatMessage({ id: 'code.must.be.digits' })
                        }
                    />
                </Panel.Content>
            </Panel>
        </Stepper.Form>
    );
}

export default AccountVerificationPage;
