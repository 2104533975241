import { Navigate, Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import useLoginContext from '../../config/login/LoginProvider';

import CreateOrganizationPage from './CreateOrganizationPage';
import AfterCreateTransition from './transition/AfterCreateTransition';

const CreateOrganizationPages = () => {
    const { miraklSupport } = useLoginContext();

    return !miraklSupport.isSellerSupportUser ? (
        <Routes>
            <Route element={<CreateOrganizationPage />} index />
            <Route element={<AfterCreateTransition />} path="/after" />
        </Routes>
    ) : (
        <Navigate to="/organizations" />
    );
};

export default CreateOrganizationPages;
