import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { PageLoader, useErrorHandler, useNavigate } from '@mirakl/roma';

import {
    SIGNUP_REDIRECT,
    signupLoginStateManager,
} from './signupLoginStateManager';

/**
 * This component is used as authorization flow callback url for silent login
 * It will navigate to the relative url found in sessionStorage (key `signup-redirect`)
 * and add a flag `silent-login` to false in sessionStorage.
 * This will avoid having to manage complex scenario when using oauth2 and sign url.
 * Signup page save the current url in `signup-redirect` then trigger a silent login this could cause a redirection to oauth2 authorization server (auth0).
 * The authorization server will then redirect to a callback (url that will use this component) with different parameters
 * depending on the outcome. Then in this component we redirect the user to the `signup-redirect` set by the signup page.
 *
 * User | ----------> | Signup ---------------------------------> | Authorization Server
 *      |<----------- | Signup <------------ | Signup Callback <- |
 * @constructor
 */
export const SignupPageCallback = () => {
    const { error, isAuthenticated, isLoading, user } = useAuth0();
    const navigate = useNavigate();
    const handleError = useErrorHandler();

    useEffect(() => {
        if (!isLoading && (error || user)) {
            signupLoginStateManager.silentLogin = false;

            const redirectUrl = signupLoginStateManager.redirect;
            if (!redirectUrl) {
                handleError(
                    new Error(
                        `Cookie ${SIGNUP_REDIRECT} not found: cannot redirect.`
                    )
                );
            } else {
                navigate({ to: redirectUrl as string });
            }
        }
    }, [user, error, isAuthenticated, isLoading, navigate, handleError]);

    return <PageLoader fullPage />;
};
