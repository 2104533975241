import { useCallback, useEffect, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Datatable,
    DatatableHeadingCellType,
    DatatablePagination,
    createHeadingsHelper,
    useDatatableContext,
    useNavigate,
    useSnackbar,
} from '@mirakl/roma';

import ShopOnTenantCellContent from '../../../common/datatable/ShopOnTenantCellContent';
import useLoginContext from '../../../config/login/LoginProvider';
import useOrganizationContext from '../../../organization/run/OrganizationContext';
import { DatatableLoadingType } from '../../../types/romaTypes';
import { useWarehouseApiClient } from '../../api/useWarehouseApiClient';
import { WarehouseListing, WarehouseType } from '../WarehouseListPage';

type WarehouseListDatatableProps = {
    loading: DatatableLoadingType;
    refetch: () => void;
    warehouses: WarehouseListing;
};

export const WarehouseListDatatable = ({
    loading,
    refetch,
    warehouses,
}: WarehouseListDatatableProps) => {
    const { formatMessage } = useI18n();
    const { isOrganizationAdmin, uuid } = useOrganizationContext();
    const navigate = useNavigate();
    const { miraklSupport } = useLoginContext();
    const {
        rowOrder: { get, reset },
    } = useDatatableContext();
    const { addSnackbar } = useSnackbar();
    const { reorder } = useWarehouseApiClient();

    const canEdit =
        isOrganizationAdmin || miraklSupport.isSellerSupportReadWrite;

    const rowOrder = get();

    const headingsHelper = createHeadingsHelper<WarehouseType>();

    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            headingsHelper.accessor('name', {
                label: formatMessage({
                    id: 'organizations.warehouses.form.name',
                }),
            }),
            headingsHelper.accessor('type', {
                label: formatMessage({
                    id: 'organizations.warehouses.listing.type',
                }),
                render: (type) => {
                    return formatMessage({
                        id: `organizations.warehouses.listing.type.${type}`,
                    });
                },
            }),
            headingsHelper.accessor('code', {
                label: formatMessage({
                    id: 'organizations.warehouses.listing.code',
                }),
            }),
            headingsHelper.accessor('linkedShops', {
                label: formatMessage({ id: 'stores.linked' }),
                render: (linkedShops) => (
                    <ShopOnTenantCellContent tenants={linkedShops} />
                ),
            }),
        ],
        [formatMessage, headingsHelper]
    );

    const goToWarehouseCreatePage = useCallback(() => {
        navigate({
            to: `/organizations/${uuid}/warehouses/create`,
        });
    }, [navigate, uuid]);

    const reorderWarehouses = useCallback(
        (newOrder: string[]) => {
            reorder(newOrder)
                .then(() => {
                    addSnackbar({
                        message: formatMessage({
                            id: 'organizations.warehouses.listing.reorder.success',
                        }),
                        status: 'success',
                    });
                })
                .catch((e) => {
                    addSnackbar({
                        message: 'Failed',
                        status: 'error',
                    });
                    throw e;
                })
                .finally(() => {
                    refetch();
                    reset();
                });
        },
        [addSnackbar, formatMessage, refetch, reorder, reset]
    );

    useEffect(() => {
        if (rowOrder) {
            reorderWarehouses(rowOrder);
        }
    }, [reorderWarehouses, rowOrder]);

    return (
        <Datatable
            data={warehouses}
            emptyState={{
                title: formatMessage({
                    id: 'organizations.warehouses.listing.empty.title.label',
                }),
                subtitle: '',
                illustrationName: 'empty-data-1',
            }}
            headings={headings}
            id="warehouse-listing-datatable"
            loading={loading}
            pagination={<DatatablePagination.Seek />}
            renderTrailingActions={(rowData) =>
                canEdit ? (
                    <Button
                        label={formatMessage({
                            id: 'button.edit',
                        })}
                        onClick={() =>
                            navigate({
                                to: `/organizations/${uuid}/warehouses/${rowData.id}`,
                            })
                        }
                    />
                ) : (
                    <></>
                )
            }
            reorderable={canEdit}
            resultNumberMessage={formatMessage(
                { id: 'count.result' },
                { 0: warehouses.count?.counted || 0 }
            )}
            toolbar={{
                action: canEdit ? (
                    <Button
                        label={formatMessage({
                            id: 'organizations.warehouses.listing.create.button',
                        })}
                        onClick={goToWarehouseCreatePage}
                    />
                ) : undefined,
            }}
        />
    );
};
