import { useI18n } from '@mirakl/i18n';
import {
    AsyncMultipleSelectionListFilter,
    CompletableOptionsType,
} from '@mirakl/roma';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import { StageValues } from '../../../types/stageTypes';

type ShopSuggestionPayload = {
    shops: {
        name: string;
        stage: StageValues;
        tenantId: string;
        tenantName: string;
        uuid: string;
    }[];
};

const FILTER_LIMIT = 25;

function ShopAsyncFilter() {
    const { formatMessage } = useI18n();
    const { apiGet } = useAuthenticatedFetch();

    const onSearchChange = (
        shopName: string,
        offset = 0
    ): Promise<CompletableOptionsType<string>> => {
        return apiGet<ShopSuggestionPayload>('/private/shops', {
            params: { shopName, limit: FILTER_LIMIT, offset },
        })
            .then((payload) => payload.data)
            .then((data) => {
                const shouldShowStage = data.shops.some(
                    (element) => element.stage !== 'PROD'
                );

                const generateTenantDisplay = (
                    tenantId: string,
                    tenantName: string,
                    stage: StageValues
                ) => {
                    const nameToDispay = tenantName ?? tenantId;

                    if (shouldShowStage) {
                        return formatMessage(
                            { id: 'tenant.stage.inline' },
                            {
                                0: nameToDispay,
                                1: formatMessage({ id: `shop.stage.${stage}` }),
                            }
                        );
                    }
                    return nameToDispay;
                };

                return {
                    loadMore:
                        data.shops.length === FILTER_LIMIT
                            ? () =>
                                  onSearchChange(
                                      shopName,
                                      offset + FILTER_LIMIT
                                  )
                            : undefined,
                    options: data.shops.map((shop) => ({
                        id: `${shop.uuid}`,
                        label: `${shop.name} (${generateTenantDisplay(
                            shop.tenantId,
                            shop.tenantName,
                            shop.stage
                        )})`,
                        value: `${shop.uuid}`,
                    })),
                };
            });
    };

    return (
        <AsyncMultipleSelectionListFilter
            a11ySearchLabel={formatMessage({
                id: 'datatable.organizations.filter.shop.name.filter.a11y.label',
            })}
            id="shop-name-filter"
            optionFilterPlaceholder={formatMessage({
                id: 'datatable.filter.placeholder',
            })}
            onSearchChange={onSearchChange}
        />
    );
}

export default ShopAsyncFilter;
