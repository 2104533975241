import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

type Tenant = {
    id: string;
    logoUrl: string;
    name?: string;
};

const useOrganizationTenants = () => {
    const { apiGet } = useAuthenticatedFetch();
    const getTenants: () => Promise<Tenant[]> = useCallback(
        () =>
            apiGet<Tenant[]>('/private/tenants').then(
                (payload) => payload.data
            ),
        [apiGet]
    );

    return {
        getTenants,
    };
};

export default useOrganizationTenants;
