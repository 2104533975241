import { useI18n } from '@mirakl/i18n';
import {
    LayoutContainer,
    LayoutItem,
    PageLayout,
    PageTitle,
} from '@mirakl/roma';

import OperatorUserGlobalLayout from '../../../../common/layout/OperatorUserGlobalLayout';

import TicketingLicenceUnavailablePage from './TicketingLicenceUnavailablePage';
import TicketingLicenceErrorBoundary from './components/TicketingLicenceErrorBoundary';
import TicketingLicenceLimitReachedAlert from './components/TicketingLicenceLimitReachedAlert';
import TicketingLicenceUsersPanel from './components/TicketingLicenceUsersPanel';
import useTicketingLicenceEnabled from './utils/useTicketingLicenceEnabled';

const OperatorAccountTicketingLicenceUsersPage = () => {
    const { formatMessage } = useI18n();
    const { isEnabled } = useTicketingLicenceEnabled();

    if (!isEnabled) {
        return <TicketingLicenceUnavailablePage />;
    }

    return (
        <OperatorUserGlobalLayout>
            <PageTitle
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.title',
                })}
            />
            <PageLayout size="sm">
                <LayoutContainer>
                    <TicketingLicenceErrorBoundary>
                        <LayoutItem>
                            <TicketingLicenceLimitReachedAlert />
                            <TicketingLicenceUsersPanel />
                        </LayoutItem>
                    </TicketingLicenceErrorBoundary>
                </LayoutContainer>
            </PageLayout>
        </OperatorUserGlobalLayout>
    );
};

export default OperatorAccountTicketingLicenceUsersPage;
