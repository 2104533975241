import { useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { EmptyState, Grid } from '@mirakl/roma';

import { StageValues } from '../../../types/stageTypes';

import LinkedShopCard from './LinkedShopCard';

type LinkedShopGridType = {
    name: string;
    stage: StageValues;
    state: {
        suspensionType?: string;
        value: string;
    };
    tenantDisplayName: string;
    uuid: string;
};

function LinkedShopGrid({ shops }: { shops: LinkedShopGridType[] }) {
    const { formatMessage } = useI18n();

    const hideBadges = useMemo(
        // if no staging other than prod has been found
        () => shops.every((shopLink) => shopLink.stage === 'PROD'),
        [shops]
    );

    return shops.length === 0 ? (
        <EmptyState
            illustrationName="empty-shop"
            illustrationSize="small"
            title={formatMessage({ id: 'shops.empty.title' })}
        />
    ) : (
        <Grid
            columnsWidth={{
                default: ['flex', 'flex'],
                sm: '100%',
            }}
        >
            {shops.map((linkedShop: LinkedShopGridType, index) => (
                <LinkedShopCard
                    hideBadge={hideBadges}
                    index={index}
                    key={linkedShop.uuid}
                    shop={linkedShop}
                />
            ))}
        </Grid>
    );
}

export default LinkedShopGrid;
