import { useI18n } from '@mirakl/i18n';
import { PageError } from '@mirakl/roma';

import { getErrorMessage } from './errorUtils';

type DebugErrorPageProps = {
    error: Error;
};

const DebugErrorPage = ({ error }: DebugErrorPageProps) => {
    const { formatMessage } = useI18n();

    return (
        <PageError
            action={{
                label: formatMessage({ id: 'roma.global-error.button.label' }),
            }}
            illustrationName="error-500-2"
            subtitle={getErrorMessage(error, formatMessage)}
            title={formatMessage({
                id: 'roma.global-error.title',
            })}
        />
    );
};

export default DebugErrorPage;
