import { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Alert,
    Button,
    Flex,
    Form,
    IconButton,
    Label,
    Media,
    Panel,
    SaveBar,
    Text,
    TextField,
    useForm,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import useClipboard from '../../../common/hooks/useClipboard';
import useLoginContext from '../../../config/login/LoginProvider';
import useOperatorAccountContext from '../../OperatorAccountContext';
import { useOperatorAccountClientApi } from '../../operatorAccountClientApi';

import SupportOperatorAccountDetailsLayout from './SupportOperatorAccountDetailsLayout';

type CompanyInformationFormType = {
    id: string;
    logo: File[];
    name: string;
};

type CompanyInformationFormProps = {
    companyExternalId: string;
    initialValues: CompanyInformationFormType;
    onSave: (values: CompanyInformationFormType) => Promise<void>;
};

const CompanyInformationForm = ({
    companyExternalId,
    initialValues,
    onSave,
}: CompanyInformationFormProps) => {
    const { formatMessage } = useI18n();
    const { copy } = useClipboard();

    const { miraklSupport } = useLoginContext();
    const { logoUrl } = useOperatorAccountContext();

    const formData = useForm<CompanyInformationFormType>({
        defaultValues: initialValues,
    });

    const {
        defaultValues,
        formState: { isDirty, isSubmitting },
        handleSubmit,
        reset,
    } = formData;

    return (
        <>
            <Panel>
                <Panel.Header
                    title={formatMessage({
                        id: 'operator.accounts.detail.company.information',
                    })}
                />
                <Panel.Content>
                    <Text variant="default-500">
                        {formatMessage({
                            id: 'operator.accounts.detail.company.id',
                        })}
                    </Text>
                    <Flex gap={2} marginBottom={4}>
                        <Text>{companyExternalId}</Text>
                        <IconButton
                            a11yLabel="operator.accounts.detail.company.id.copy"
                            iconName="copy-content"
                            size="tiny"
                            variant="ghost"
                            onClick={() =>
                                copy(
                                    companyExternalId,
                                    formatMessage({
                                        id: 'operator.accounts.detail.company.id.copied',
                                    })
                                )
                            }
                        />
                    </Flex>
                    <Alert
                        status="info"
                        title={formatMessage({
                            id: 'operator.accounts.detail.company.id.alert',
                        })}
                    />
                    <Form {...formData}>
                        <TextField
                            defaultValue={defaultValues.name}
                            disabled={!miraklSupport.isOperatorSupportReadWrite}
                            label={formatMessage({
                                id: 'operator.accounts.detail.company.name',
                            })}
                            maxLength={100}
                            minLength={2}
                            name="name"
                            required
                        />
                        <Label
                            id="logo"
                            label={formatMessage({
                                id: 'operator.accounts.detail.company.logo',
                            })}
                        />
                        {logoUrl ? (
                            <Media
                                alt="logo"
                                size={11}
                                type="image"
                                url={logoUrl}
                            />
                        ) : (
                            <Media size={11} type="image" />
                        )}
                    </Form>
                </Panel.Content>
            </Panel>
            {isDirty && (
                <SaveBar
                    actions={[
                        <Button
                            key="save"
                            label={formatMessage({ id: 'button.save' })}
                            state={isSubmitting ? 'loading' : 'default'}
                            onClick={(event) =>
                                handleSubmit((values) =>
                                    onSave(values).then(() => reset(values))
                                )(event)
                            }
                        />,
                        <Button
                            key="cancel"
                            label={formatMessage({
                                id: 'button.cancel',
                            })}
                            variant="secondary"
                            onClick={() => reset()}
                        />,
                    ]}
                />
            )}
        </>
    );
};

const SupportCompanyInformationPage = () => {
    const { id } = useRequiredParams<{ id: string }>();
    const { getOperatorAccountDetails, updateCompanyInformation } =
        useOperatorAccountClientApi();

    const [companyExternalId, setCompanyExternalId] = useState<string>();
    const [companyInformation, setCompanyInformation] =
        useState<CompanyInformationFormType>();

    useEffect(() => {
        getOperatorAccountDetails(id).then((data) => {
            setCompanyInformation({
                name: data.name,
                logo: [],
                id: id,
            });
            setCompanyExternalId(data.externalId);
        });
    }, [getOperatorAccountDetails, id]);

    const onSave = (values: CompanyInformationFormType) =>
        updateCompanyInformation(id, values).then(() =>
            setCompanyInformation(values)
        );

    return (
        <SupportOperatorAccountDetailsLayout size="sm">
            {companyExternalId && companyInformation ? (
                <CompanyInformationForm
                    companyExternalId={companyExternalId}
                    initialValues={companyInformation}
                    onSave={onSave}
                />
            ) : (
                <Panel loading />
            )}
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportCompanyInformationPage;
