import { useI18n } from '@mirakl/i18n';
import { Badge, Box, Card, Flex, Paragraph, Title } from '@mirakl/roma';

import { StageValues } from '../../../types/stageTypes';

type LinkedShopCardProps = {
    hideBadge: boolean;
    index: number;
    shop: {
        name: string;
        stage: StageValues;
        state: ShopStateProps;
        tenantDisplayName: string;
    };
};

type ShopStateProps = {
    suspensionType?: string;
    value: string;
};

function LinkedShopCard({ hideBadge, index, shop }: LinkedShopCardProps) {
    const { formatMessage } = useI18n();

    const stateFormat =
        shop.state.value +
        (shop.state.suspensionType ? '_' + shop.state.suspensionType : '');

    const labelKey = 'shop.state.' + stateFormat;
    const statusKey = 'shop.state.' + stateFormat + '.status';

    const stateMessage = formatMessage({
        id: labelKey,
        defaultMessage: 'UNKNOWN',
    });

    const showState = stateMessage !== 'UNKNOWN';

    return (
        <Card flexDirection="column">
            <Box data-testid={`shopcard-${index}`}>
                <Flex alignItems="center" gap={2}>
                    <Title noSpaceBottom tag="h3" truncate>
                        {shop.tenantDisplayName}
                        {!hideBadge && (
                            <>
                                {' - '}
                                {formatMessage({
                                    id: `shop.stage.${shop.stage}`,
                                })}
                            </>
                        )}
                    </Title>
                </Flex>
                <Paragraph noSpaceBottom>
                    {shop.name}{' '}
                    {showState && (
                        <Badge
                            label={stateMessage}
                            status={formatMessage({
                                id: statusKey,
                            })}
                        />
                    )}
                </Paragraph>
            </Box>
        </Card>
    );
}

export default LinkedShopCard;
