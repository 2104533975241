import { useI18n } from '@mirakl/i18n';
import {
    Button,
    Form,
    Panel,
    RadioGroup,
    SaveBar,
    TextField,
    useForm,
} from '@mirakl/roma';

import AddressField, {
    AddressType,
} from '../../common/address/form/AddressField';
import { WarehouseShopApiType } from '../api/useWarehouseApiClient';

import WarehouseShopAssociationPanel from './WarehouseShopAssociationPanel';

export type WarehouseFormType = AddressType & {
    code: string;
    name: string;
    shops: WarehouseShopApiType[];
    type: string;
};

type WarehouseFormPropsType = {
    confirmModalOnSave?: boolean;
    defaultValues: WarehouseFormType | undefined;
    disableCode?: boolean;
    onSubmit(
        defaultValues: WarehouseFormType | undefined,
        values: WarehouseFormType
    ): Promise<unknown>;
};

function WarehouseForm({
    defaultValues,
    disableCode,
    onSubmit,
}: WarehouseFormPropsType) {
    const { formatMessage } = useI18n();

    const formData = useForm<WarehouseFormType>({
        defaultValues,
    });
    const {
        formState: { isDirty, isSubmitting },
        handleSubmit,
        reset,
    } = formData;

    const onDiscard = () => {
        reset(defaultValues);
    };

    return (
        <Form {...formData}>
            <Panel>
                <Panel.Content
                    title={formatMessage({
                        id: 'organizations.warehouses.form.title',
                    })}
                >
                    <TextField
                        label={formatMessage({
                            id: 'organizations.warehouses.form.name',
                        })}
                        maxLength={255}
                        name="name"
                        required
                    />
                    <TextField
                        disabled={disableCode}
                        helpText={formatMessage({
                            id: 'organizations.warehouses.form.code.help',
                        })}
                        label={formatMessage({
                            id: 'organizations.warehouses.form.code',
                        })}
                        maxLength={255}
                        name="code"
                        required
                        tooltipText={formatMessage({
                            id: 'organizations.warehouses.form.code.tooltip',
                        })}
                    />
                    <AddressField />
                    <RadioGroup
                        a11yLabel={formatMessage({
                            id: 'signup.organization.choice.form.radio.a11y.label',
                        })}
                        column={1}
                        label={formatMessage({
                            id: 'organizations.warehouses.form.type',
                        })}
                        name="type"
                    >
                        <RadioGroup.Radio
                            label={formatMessage({
                                id: 'organizations.warehouses.form.type.SELF_MANAGED',
                            })}
                            value="SELF_MANAGED"
                        />
                        <RadioGroup.Radio
                            label={formatMessage({
                                id: 'organizations.warehouses.form.type.EXTERNALLY_MANAGED',
                            })}
                            value="EXTERNALLY_MANAGED"
                        />
                    </RadioGroup>
                </Panel.Content>
            </Panel>

            <WarehouseShopAssociationPanel />
            {isDirty && (
                <SaveBar
                    actions={[
                        <Button
                            key="saveButton"
                            label={formatMessage({ id: 'button.save' })}
                            state={isSubmitting ? 'loading' : 'default'}
                            submit
                            onClick={handleSubmit((values) =>
                                onSubmit(defaultValues, values)
                            )}
                        />,
                        <Button
                            key="discardButton"
                            label={formatMessage({ id: 'button.discard' })}
                            variant="secondary"
                            onClick={onDiscard}
                        />,
                    ]}
                />
            )}
        </Form>
    );
}

export default WarehouseForm;
