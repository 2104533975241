import { useI18n } from '@mirakl/i18n';
import { Flex, Icon, Label } from '@mirakl/roma';

type PasswordSpecCheckerProps = {
    label: string;
    success: boolean;
};

const PasswordSpecChecker = ({ label, success }: PasswordSpecCheckerProps) => {
    return (
        <Flex gap={1}>
            <span>{label}</span>{' '}
            {success && <Icon name="check" status="success" />}
        </Flex>
    );
};

export type PasswordValidityResults = {
    global: boolean;
    specs: {
        digit: boolean;
        length: boolean;
        lower: boolean;
        symbol: boolean;
        upper: boolean;
    };
};

type PasswordSpecsCheckerProps = {
    passwordSpecsValidity: PasswordValidityResults;
};

const PasswordSpecsChecker = ({
    passwordSpecsValidity,
}: PasswordSpecsCheckerProps) => {
    const { formatMessage } = useI18n();

    return (
        <>
            <Label
                id="password-spec"
                label={formatMessage({
                    id: 'com.mirakl.domain.validation.password.spec',
                })}
            />
            <ul>
                <li>
                    <PasswordSpecChecker
                        label={formatMessage({
                            id: 'com.mirakl.domain.validation.password.spec.digit',
                        })}
                        success={passwordSpecsValidity.specs.digit}
                    />
                </li>
                <li>
                    <PasswordSpecChecker
                        label={formatMessage({
                            id: 'com.mirakl.domain.validation.password.spec.upper',
                        })}
                        success={passwordSpecsValidity.specs.upper}
                    />
                </li>
                <li>
                    <PasswordSpecChecker
                        label={formatMessage({
                            id: 'com.mirakl.domain.validation.password.spec.lower',
                        })}
                        success={passwordSpecsValidity.specs.lower}
                    />
                </li>
                <li>
                    <PasswordSpecChecker
                        label={formatMessage({
                            id: 'com.mirakl.domain.validation.password.spec.special',
                        })}
                        success={passwordSpecsValidity.specs.symbol}
                    />
                </li>
            </ul>
        </>
    );
};

export default PasswordSpecsChecker;
