import { useCallback } from 'react';

import useAuthenticatedFetch from '../../fetch/useAuthenticatedFetch';

const useConnectorUrl = () => {
    const { apiGet } = useAuthenticatedFetch();

    const fetchConnectorUrl: (
        vendor: string,
        callback: string
    ) => Promise<string> = useCallback(
        (vendor, callback) =>
            apiGet<{ location: string }>(
                `/private/sellers/connectors/${vendor}?callback=${encodeURIComponent(callback)}`
            ).then(({ data }) => data.location),
        [apiGet]
    );

    return { fetchConnectorUrl };
};

export default useConnectorUrl;
