import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';
import useOrganizationContext from '../OrganizationContext';

import { ShopListPayload, ShopsPayload } from './useListShopsApi';

const useLinkedShops = () => {
    const { apiGet } = useAuthenticatedFetch();
    const { uuid: organizationUuid } = useOrganizationContext();
    const [loading, setLoading] = useState(true);
    const [linkedShops, setLinkedShops] = useState<ShopListPayload[]>([]);

    useEffect(() => {
        const getAllPages = async () => {
            let page;
            const result = [];
            do {
                const payload: AxiosResponse<ShopsPayload> =
                    await apiGet<ShopsPayload>(
                        `/private/organizations/${organizationUuid}/shops`,
                        {
                            params: {
                                pageToken: page?.nextPageToken,
                            },
                        }
                    );
                page = payload.data;
                result.push(...page.data);
            } while (page.nextPageToken);

            setLoading(false);
            setLinkedShops(result);
        };
        getAllPages();
    }, [apiGet, organizationUuid]);

    return { loading, linkedShops };
};

export default useLinkedShops;
