import { useI18n } from '@mirakl/i18n';
import {
    LayoutContainer,
    LayoutItem,
    PageLayout,
    PageTitle,
} from '@mirakl/roma';

import OperatorUserGlobalLayout from '../../../common/layout/OperatorUserGlobalLayout';

import OperatorAccountUserListingPanel from './OperatorAccountUserListingPanel';

const OperatorAccountUserListingPage = () => {
    const { formatMessage } = useI18n();

    return (
        <OperatorUserGlobalLayout>
            <PageTitle
                title={formatMessage({
                    id: 'operator.accounts.users.listing.title',
                })}
            />
            <PageLayout size="md">
                <LayoutContainer>
                    <LayoutItem>
                        <OperatorAccountUserListingPanel />
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </OperatorUserGlobalLayout>
    );
};

export default OperatorAccountUserListingPage;
