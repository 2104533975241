import { ReactNode } from 'react';

import { Flex, Panel } from '@mirakl/roma';

const _ProfileInfoPanelContent = ({
    children,
    isSmallScreen,
}: {
    children: ReactNode;
    isSmallScreen?: boolean;
}) => {
    return (
        <Panel.Content>
            <Flex flexDirection={isSmallScreen ? 'column' : 'row'} gap={5}>
                {children}
            </Flex>
        </Panel.Content>
    );
};

export default _ProfileInfoPanelContent;
