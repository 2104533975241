import { useState } from 'react';

import {
    DatatableProvider,
    DatatableSeekPaginateDataType,
    Panel,
    useErrorHandler,
} from '@mirakl/roma';
import { useRequiredParams } from '@mirakl/roma/router';

import { useOperatorAccountUserApiClient } from './api/useOperatorAccountUserApiClient';
import OperatorAccountUserListingDatatable, {
    OperatorAccountUserType,
} from './components/OperatorAccountUserListingDatatable';
import { operatorAccountUserQueryParamHelper } from './operatorAccountUserQueryParamHelper';

const OperatorAccountUserListingPanel = () => {
    const handleError = useErrorHandler();

    const [users, setUsers] = useState<
        DatatableSeekPaginateDataType<OperatorAccountUserType>
    >({});

    const { id } = useRequiredParams<{ id: string }>();
    const { fetchUsers } = useOperatorAccountUserApiClient(id);

    const { loadingState, onQueryParamsChange, refetch } =
        operatorAccountUserQueryParamHelper.useQueryParamsChange(
            (queryParams) =>
                fetchUsers(queryParams)
                    .then((fetchedUsers) => setUsers(fetchedUsers))
                    .catch(handleError)
        );

    return (
        <Panel>
            <Panel.Content fullWidth>
                <operatorAccountUserQueryParamHelper.QueryParamsProvider
                    onQueryParamsChange={onQueryParamsChange}
                >
                    <DatatableProvider>
                        <OperatorAccountUserListingDatatable
                            loadingState={loadingState}
                            operatorUsersPage={users}
                            refetch={refetch}
                        />
                    </DatatableProvider>
                </operatorAccountUserQueryParamHelper.QueryParamsProvider>
            </Panel.Content>
        </Panel>
    );
};

export default OperatorAccountUserListingPanel;
