import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    BadgesCellContent,
    Button,
    Datatable,
    DatatableHeadingCellType,
    DatatableLoadingType,
    DatatablePagination,
    DateCellContent,
    createHeadingsHelper,
    useNavigate,
} from '@mirakl/roma';

import { basePath } from '../../../Root';
import {
    OperatorAccountTypeEnum,
    operatorAccountListingQueryParamsHelper,
    useOperatorAccountClientApi,
} from '../../operatorAccountClientApi';
import ProductFilter from '../ProductFilter';
import TypeFilter from '../TypeFilter';

type OperatorAccountType = {
    dateCreated: DateTime;
    id: string;
    name: string;
    products: string[];
    type: OperatorAccountTypeEnum;
};

export type OperatorAccountsDatatableType = {
    count: {
        capped: boolean;
        counted: number;
    };
    data: OperatorAccountType[];
};

type OperatorAccountListingDatatableProps = {
    loadingState: DatatableLoadingType;
    operators: OperatorAccountsDatatableType;
};

const headingsHelper = createHeadingsHelper<OperatorAccountType>();

const OperatorAccountListingDatatable = ({
    loadingState,
    operators,
}: OperatorAccountListingDatatableProps) => {
    const { formatMessage } = useI18n();
    const navigate = useNavigate();

    const { exportOperatorAccounts } = useOperatorAccountClientApi();

    const formatType = useCallback(
        (type: OperatorAccountTypeEnum): string => {
            switch (type) {
                case 'CUSTOMER':
                    return formatMessage({
                        id: 'operator.accounts.listing.datatable.type.customer',
                    });
                case 'PARTNER':
                    return formatMessage({
                        id: 'operator.accounts.listing.datatable.type.partner',
                    });
            }
        },
        [formatMessage]
    );

    const formatProducts = useCallback(
        (products: string[]) => {
            return (
                <BadgesCellContent
                    badges={products.map((productId) => ({
                        label: formatMessage({ id: `product.${productId}` }),
                        status: 'info',
                    }))}
                />
            );
        },
        [formatMessage]
    );

    const headings: DatatableHeadingCellType[] = useMemo(
        () => [
            headingsHelper.accessor('name', {
                label: formatMessage({
                    id: 'operator.accounts.listing.datatable.name',
                }),
            }),
            headingsHelper.accessor('type', {
                label: formatMessage({
                    id: 'operator.accounts.listing.datatable.type',
                }),
                render: (type) => formatType(type),
            }),
            headingsHelper.accessor('products', {
                label: formatMessage({
                    id: 'datatable.organizations.header.growth.solutions.label',
                }),
                render: (products) => formatProducts(products),
            }),
            headingsHelper.accessor('dateCreated', {
                label: formatMessage({
                    id: 'operator.accounts.listing.datatable.dateCreated',
                }),
                render: (dateCreated) => (
                    <DateCellContent
                        date={dateCreated}
                        options={DateTime.DATE_SHORT}
                    />
                ),
                sortable: true,
                initialSortDirection: 'DESC',
            }),
        ],
        [formatMessage, formatType, formatProducts]
    );

    const { queryParams } =
        operatorAccountListingQueryParamsHelper.useQueryParams();

    const exportOperatorAccountsCallback = useCallback(() => {
        const typesFilterValues = queryParams.types?.map((type) => type.value);
        const exportParams = {
            products: queryParams.products?.map((product) => product.value),
            clientAccounts:
                typesFilterValues?.length === 1
                    ? typesFilterValues[0] === 'CUSTOMER'
                    : undefined,
            operatorName: queryParams.operatorName,
        };
        return exportOperatorAccounts(exportParams);
    }, [exportOperatorAccounts, queryParams]);

    return (
        <Datatable
            data={operators}
            emptyState={{
                title: formatMessage({
                    id: 'operator.accounts.listing.datatable.emptyState.title',
                }),
                subtitle: formatMessage({
                    id: 'operator.accounts.listing.datatable.emptyState.subtitle',
                }),
            }}
            headings={headings}
            id="operator-listing-datatable"
            loading={loadingState}
            pagination={<DatatablePagination.Seek />}
            renderTrailingActions={(rowData) => (
                <Button
                    label={formatMessage({
                        id: 'button.edit',
                    })}
                    onClick={() =>
                        navigate({
                            to: `${basePath}/operators/${rowData.id}/company`,
                        })
                    }
                />
            )}
            resultNumberMessage={formatMessage(
                { id: 'count.result' },
                { 0: operators.count.counted }
            )}
            toolbar={{
                exportPicker: {
                    errorMessage: formatMessage({
                        id: 'export.failed.message',
                    }),
                    options: [
                        {
                            label: 'CSV',
                            value: 'csv',
                        },
                    ],
                    title: formatMessage({
                        id: 'operator.accounts.listing.datatable.export.title',
                    }),
                    onConfirm: exportOperatorAccountsCallback,
                },
                filters: operatorAccountListingQueryParamsHelper.createFilters({
                    types: {
                        label: formatMessage({
                            id: 'operator.accounts.listing.datatable.filters.types',
                        }),
                        component: <TypeFilter />,
                    },
                    products: {
                        label: formatMessage({
                            id: 'operator.accounts.listing.datatable.filters.products',
                        }),
                        component: <ProductFilter />,
                    },
                }),
                searchBar: {
                    id: 'operatorName',
                    placeholder: formatMessage({
                        id: 'operator.accounts.listing.datatable.searchBar.placeholder',
                    }),
                },
            }}
            onRowClick={{
                onClick: ({ rowData }) =>
                    navigate({
                        to: `${basePath}/operators/${rowData.id}/company`,
                    }),
            }}
        />
    );
};

export default OperatorAccountListingDatatable;
