import { useCallback, useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    OnboardingStepper,
    PageLoader,
    PasswordFieldValue,
    TextFieldValue,
    useErrorHandler,
    useNavigate,
} from '@mirakl/roma';

import { useLocaleContext } from '../../config/i18n/I18nProvider';
import {
    fetchAvailableLanguages,
    fetchTimeZones,
} from '../../profile/settings/api/profileSettingsApi';
import useQuery from '../../router/useQuery';

import { createProfile, fetchInvitation } from './api/invitationApi';
import PasswordStep from './components/PasswordStep';
import ProfileInformationStep from './components/ProfileInformationStep';

type InvitationType = {
    email: string;
    language: string;
    productName: string;
    tenantId?: string;
};

type CreateProfileType = {
    firstName: TextFieldValue;
    language: string;
    lastName: TextFieldValue;
    password: PasswordFieldValue;
    timezone: string;
};

type LanguageType = {
    label: string;
    locale: string;
};

type LogoType = {
    alt: string;
    type: 'image';
    url: string;
};

type TemplateType = {
    logoAlt: string;
    logoUrl: string;
    stepperTitle: string;
};

const ProfileOnboardingPage = () => {
    const { formatMessage } = useI18n();

    const { setLocale } = useLocaleContext();
    const navigate = useNavigate();
    const handleError = useErrorHandler();

    const [invitation, setInvitation] = useState<InvitationType>();
    const [loadingInvitation, setLoadingInvitation] = useState(true);
    const [loadingLang, setLoadingLang] = useState(true);
    // We will load timeZones and availableLanguages here instead of using AsyncSelect in the LanguageSelect or TimeZoneSelect
    // Because we need to check if defaultValue is valid before setting it and this just doesn't work with AsyncSelect we encounter
    // a race condition.
    const [timeZoneLoading, setTimeZoneLoading] = useState(true);
    const [availableLanguages, setAvailableLanguage] = useState<LanguageType[]>(
        []
    );
    const [template, setTemplate] = useState<TemplateType>({
        logoAlt: '',
        logoUrl: '',
        stepperTitle: 'user.profile.confirmation.title',
    });

    const [availableTimeZones, setAvailableTimeZones] = useState<string[]>([]);
    const [logo, setLogo] = useState<LogoType>({
        url: '/static/images/mirakl-logo.png',
        alt: 'Mirakl',
        type: 'image',
    });

    const getTemplateFromProduct = useCallback(
        ({
            productName,
            tenantId,
        }: {
            productName: string;
            tenantId?: string;
        }) => {
            if (productName === 'MMP') {
                if (!tenantId) {
                    throw Error('tenantId cannot be empty if product is MMP');
                }
                return {
                    logoAlt: formatMessage({ id: 'operator.logo.alt' }),
                    logoUrl: `https://${tenantId}.mirakl.net/mmp/media/operator-interface-logo-after-update`,
                    stepperTitle: 'user.profile.confirmation.MMP.title',
                };
            }

            return {
                logoAlt: formatMessage({
                    id: `product.${productName}.logo.alt`,
                }),
                logoUrl:
                    '/static/images/' +
                    formatMessage({
                        id: `product.${productName}.logo`,
                    }),
                stepperTitle: 'user.profile.confirmation.title',
            };
        },
        [formatMessage]
    );

    const query = useQuery({
        token: 'string',
    });

    const onSubmit = (profile: CreateProfileType) => {
        return createProfile(query.token, profile).catch((reason) => {
            if (reason.response.status === 412) {
                navigate({
                    to: reason.response.data.location,
                    shouldNotShowConfirmModal: true,
                });
            }
            return Promise.reject(reason);
        });
    };

    useEffect(() => {
        fetchInvitation(query.token)
            .then((fetchedInvitation) => {
                setInvitation(fetchedInvitation);
                if (fetchedInvitation.language) {
                    setLocale(fetchedInvitation.language);
                }
            })
            .finally(() => setLoadingInvitation(false));

        fetchAvailableLanguages()
            .then((languages) => setAvailableLanguage(languages))
            .finally(() => setLoadingLang(false));

        fetchTimeZones()
            .then((timeZones) => setAvailableTimeZones(timeZones))
            .finally(() => setTimeZoneLoading(false));
    }, [handleError, query.token, setLocale]);

    // [ACCOUNT-956] Do not put this `useEffect` in the one that fetches the invitation.
    // Explanation: when a setLocale is called, the `formatMessage` ref is updated, and thus the useEffect is triggered since the `formatMessage` is in the dependencies.
    // If we put together the setLocale and the formatMessage, there may be a numerous loops of rendering.
    useEffect(() => {
        if (invitation) {
            const templateForProduct = getTemplateFromProduct(invitation);
            setTemplate(templateForProduct);

            setLogo({
                url: templateForProduct.logoUrl,
                alt: templateForProduct.logoAlt,
                type: 'image',
            });
        }
    }, [invitation, getTemplateFromProduct]);

    return loadingInvitation || loadingLang || timeZoneLoading ? (
        <PageLoader />
    ) : (
        invitation && (
            <OnboardingStepper<CreateProfileType>
                buttonLabels={{
                    last: formatMessage({ id: 'button.continue' }),
                }}
                steps={[
                    {
                        media: logo,
                        title: formatMessage({
                            id: template.stepperTitle,
                        }),
                        illustration: 'onboarding-stepper',
                        content: (
                            <ProfileInformationStep
                                invitation={invitation}
                                languages={availableLanguages}
                                timeZones={availableTimeZones}
                            />
                        ),
                    },
                    {
                        media: logo,
                        title: formatMessage({
                            id: template.stepperTitle,
                        }),
                        illustration: 'onboarding-stepper',
                        content: <PasswordStep />,
                    },
                ]}
                successUrl={(location) => {
                    navigate({
                        href: location as string,
                        shouldNotShowConfirmModal: true,
                    });
                }}
                onGlobalSubmit={onSubmit}
            />
        )
    );
};

export default ProfileOnboardingPage;
